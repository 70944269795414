import { Container, Spinner } from 'react-bootstrap'

type CircularLoaderProps = {
  width: string,
  height: string
  marginTop: string
  marginBottom: string
  borderWidth?: string
}

export function CircularLoader(props: CircularLoaderProps) {
  const { width, height, marginTop, marginBottom, borderWidth } = props
  return (
    <Spinner
      animation='border'
      variant='primary'
      // style={{
      //   width: width,
      //   height: height,
      //   marginTop: marginTop,
      //   marginBottom: marginBottom,
      //   borderWidth: borderWidth || '4px',
      // }}
    />
  )
}

export function AnimatedLoader() {
  return (
    <Container
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        columnGap: '5px',
      }}
    >
      <Spinner
        animation='grow'
        variant='danger'
        style={{
          width: '10px',
          height: '10px',
          borderWidth: '2px',
        }}
      />
      <Spinner
        animation='grow'
        variant='success'
        style={{
          width: '10px',
          height: '10px',
          borderWidth: '2px',
        }}
      />{' '}
      <Spinner
        animation='grow'
        variant='warning'
        style={{
          width: '10px',
          height: '10px',
          borderWidth: '2px',
        }}
      />
    </Container>
  )
}

export function AnimatedLoaderDark() {
  return (
    <Container
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        columnGap: '5px',
      }}
    >
      <Spinner
        animation='grow'
        style={{
          width: '10px',
          height: '10px',
          borderWidth: '2px',
        }}
      />
      <Spinner
        animation='grow'
        style={{
          width: '10px',
          height: '10px',
          borderWidth: '2px',
        }}
      />{' '}
      <Spinner
        animation='grow'
        style={{
          width: '10px',
          height: '10px',
          borderWidth: '2px',
        }}
      />
    </Container>
  )
}