/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {getUserByToken, login} from '../core/_requests'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {loginApi, signupApi} from '../../../redux/slices/AuthSlices'
import {useAuth} from '../core/Auth'
import {useDispatch} from 'react-redux'
import { toast } from 'react-toastify'
import { GoogleLogin , useGoogleLogin,  CodeResponse, TokenResponse } from "@react-oauth/google"
import axios, { AxiosError } from 'axios'
import GoogleIcon from './GoogleIcon'
import { Button } from 'react-bootstrap'
import { REGISTRATION_TYPE, USER_ROLE } from 'src/utils/constants'
import { useAppSelector } from 'src/Redux/Store/hooks'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const dispatch = useDispatch<any>()
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const { data } = useAppSelector(state=>state.subdomain)

  async function getUserDetail(userData:TokenResponse){
    setLoading(true)
    const userInfo: any = await new Promise(resolve => {
      const xhr = new XMLHttpRequest();

      xhr.open('GET', `https://www.googleapis.com/oauth2/v3/userinfo`);
      xhr.setRequestHeader('Authorization', `Bearer ${userData.access_token}`)
      xhr.onload = function () {
          if (this.status >= 200 && this.status < 300)
            resolve(JSON.parse(this.responseText));
           else resolve({ err: '404' });
          };
          xhr.send();
        });

    if(userInfo){
        dispatch(loginApi({ email: userInfo?.email, password:userInfo.sub })).then((response:any)=>{
          if (response.payload.error.response.data.status_code == 400) {
            dispatch(signupApi({
              first_name : userInfo?.given_name ,
              last_name: userInfo?.family_name ,
              email: userInfo?.email,  //userInfo?.email,
              password: userInfo?.sub,
              organization_id:null,
              user_type:  USER_ROLE["admin"],
              request_via: REGISTRATION_TYPE['google_oauth']}),
              ).then((res)=>{
                dispatch(loginApi({ email: userInfo?.email, password:userInfo.sub }))
                setLoading(false)
              })
              .catch((er:AxiosError)=>{
                console.log('er', er.message)
              })

            setLoading(false)
          } else {
            // toast.success('Logged in successfully')
            setLoading(false)
          }

        }).catch((err)=>{
          console.log("login error -------> ", err );
          setLoading(false)
        })
    }

  }

    const googleLogin = useGoogleLogin({
        onSuccess: (codeResponse) => {
                    getUserDetail(codeResponse)
        },
        onError: (error) => console.log('Login Failed:', error),
        onNonOAuthError:(err)=>{
          console.log('err', err)
        },
        error_callback(nonOAuthError) {
          toast.info("Authentication process dismissed")
        },
    });

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {email, password} = values
        const Data = {email, password , organization_id: data.id }

        dispatch(loginApi(Data)).then((response: any) => {
          if (response.payload.error?.response.data.status_code == 400) {
            setStatus({ loggedIn:false, msg: 'The login details are incorrect'})
            // toast.error(response.payload.error.response.data.massage)
            setLoading(false)
          } else {
            setStatus({ loggedIn:true, msg: 'Logged in successfully'})
            // toast.success('Logged in successfully')
            setLoading(false)
          }
        })
        .catch((err)=>{
          console.log("unable to login", err);
          toast.error("Login failed! please login after some time. ")
          setStatus({ loggedIn:false, msg: "Login failed! please login after some time. "})
          setLoading(false)
        })

        // const {data: auth} = await login(values.username, values.password)
        // saveAuth(auth)
        // const {data: user} = await getUserByToken(auth.api_token)
        // setCurrentUser(user)
      } catch (error) {
        console.error(error)
        // saveAuth(undefined)
        // setStatus('The login details are incorrect')
        // setSubmitting(false)
        // setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}  
      noValidate
      id='kt_login_signin_form'
    >
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Sign In</h1>
        <div className='text-gray-500 fw-semibold fs-6'>Sign in to AppConnect</div>
      </div>

      {/*/!* begin::Login options *!/*/}
      {/*<div className='row g-3 mb-9'>*/}
      {/*  /!* begin::Col *!/*/}
      {/*  <div className='col-md-6'>*/}
      {/*    /!* begin::Google link *!/*/}
      {/*    <a*/}
      {/*      href='#'*/}
      {/*      className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'*/}
      {/*    >*/}
      {/*      <img*/}
      {/*        alt='Logo'*/}
      {/*        src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}*/}
      {/*        className='h-15px me-3'*/}
      {/*      />*/}
      {/*      Sign in with Google*/}
      {/*    </a>*/}
      {/*    /!* end::Google link *!/*/}
      {/*  </div>*/}
      {/*  /!* end::Col *!/*/}

      {/*  /!* begin::Col *!/*/}
      {/*  <div className='col-md-6'>*/}
      {/*    /!* begin::Google link *!/*/}
      {/*    <a*/}
      {/*      href='#'*/}
      {/*      className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'*/}
      {/*    >*/}
      {/*      <img*/}
      {/*        alt='Logo'*/}
      {/*        src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}*/}
      {/*        className='theme-light-show h-15px me-3'*/}
      {/*      />*/}
      {/*      <img*/}
      {/*        alt='Logo'*/}
      {/*        src={toAbsoluteUrl('/media/svg/brand-logos/apple-black-dark.svg')}*/}
      {/*        className='theme-dark-show h-15px me-3'*/}
      {/*      />*/}
      {/*      Sign in with Apple*/}
      {/*    </a>*/}
      {/*    /!* end::Google link *!/*/}
      {/*  </div>*/}
      {/*  /!* end::Col *!/*/}
      {/*</div>*/}
      {/*/!* end::Login options *!/*/}

      {/*/!* begin::Separator *!/*/}
      {/*<div className='separator separator-content my-14'>*/}
      {/*  <span className='w-125px text-gray-500 fw-semibold fs-7'>Or with email</span>*/}
      {/*</div>*/}
      {/* end::Separator */}

      {formik.status ? (
        <div className={`mb-lg-15 alert ${ formik.status.loggedIn ? "alert-success" : "alert-danger" }`}>
          <div className='alert-text font-weight-bold'>{formik.status.msg}</div>
        </div>
      ): (<div></div>)}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
        <input
          placeholder='Password'
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />

        {/* begin::Link */}
        <Link to='/auth/forgot-password' className='link-primary'>
          Forgot Password ?
        </Link>
        {/* end::Link */}
      </div>
      {/* end::Wrapper */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}

      {/* signin with google start  */}
      <div className='mb-4 w-100 d-flex justify-content-center'  >
        <Button
          variant='secondary'
          className='w-100'
          onClick={()=>googleLogin()}
        >
          <span className='fw-bold fs-md-6' style={{fontSize:"12px"}} >
            <GoogleIcon/> &nbsp; Continue with google
          </span>
        </Button>
      </div>
      {/* signin with google end  */}

      <div className='text-gray-500 text-center fw-semibold fs-6'>
        Not a Member yet?{' '}
        <Link to='/auth/registration' className='link-primary'>
          Sign up
        </Link>
      </div>
    </form>
  )
}
