import { AxiosClient } from '../../AxiosClient/axiosClient'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const connectOauthAsync = createAsyncThunk(
    'post/connectOauthAsync',
    async (payload: any, toolkit) => {
        let payloadBaod = {}
        if (payload?.platform_id) {
            payloadBaod = { user_id: payload.user_id, available_platform_id: payload.available_platform_id, platform_id: payload?.platform_id }
        } else {
            payloadBaod = { user_id: payload.user_id, available_platform_id: payload.available_platform_id }
        }
        return await AxiosClient(
            'POST',
            `/handleOAuthCallback${payload.query_string}`,
            payloadBaod, toolkit
        )
    }
);

export const disconnectOauthAsync = createAsyncThunk(
    'disconnect_user_platform/disconnectOauthAsync',
    async (payload: any, toolkit) => {
        return await AxiosClient(
            'POST', `/disconnect-user-platform`,
            payload, toolkit
        )
    }
);
