import React, { FC, useEffect, useState } from 'react'
import { KTSVG } from '../../../../_metronic/helpers'
import { Modal } from 'react-bootstrap'
import sync from '../../../../asset/icons/sync.png'
interface PropType {
  icon: string
  title: string
  onSave: () => void
  showModal: boolean
  description?: string
  setShowModal: (status: boolean) => void
}

const ConfirmationModal = (props: PropType) => {
  const { setShowModal } = props
  const [show, setShow] = useState(false)

  const handleClose = () => {
    setShow(false);
    setShowModal(false)
  }
  const handleShow = () => setShow(true)

  useEffect(() => {
    setShow(props.showModal)
  }, [props.showModal])
   
  return (

    <Modal show={show} onHide={handleClose}>

      <div className='modal-content'>
        {/* <div>
        {props?.initialLocation?.name}
      </div> */}

        <div className='modal-header pb-2 justify-content-end border-0'>
          <div className='btn btn-icon btn-sm btn-active-light-primary ms-2' onClick={handleClose}>
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
          </div>
        </div>

        <div className='modal-body py-0 text-center'>

          <div
            className='m-auto text-center d-flex align-items-center justify-content-center border border-warning border-4 rounded-circle p-4'
            style={{ width: '90px', height: '90px' }}
          >
            {
              props.title.includes('Selected Orders') ||  props.title.includes('Orders') ? (
                <img src={sync} alt="Synced Status" style={{ width: '100px', height: 'auto' }} />
              ) :
                (
                  <KTSVG path={props.icon} className='svg-icon svg-icon-5x text-warning' />
                )
            }
          </div>

          <div className='py-4 mt-5'>
            <h1>{props.title}</h1>
            <p className='mt-4 fw-bold text-muted fs-6'>{props?.description}</p>
          </div>
        </div>
        <div className='modal-footer mb-5 border-0 justify-content-center'>
          <button
            type='button'
            className='btn btn-light'
            data-bs-dismiss='modal'
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            type='button'
            className='btn btn-light-danger'
            data-bs-dismiss='modal'
            onClick={() => {
              handleClose();
              props.onSave()
            }}
          >
            <div className='px-4'>Yes</div>
          </button>
        </div>
      </div>
    </Modal>
  )
}

export { ConfirmationModal }
