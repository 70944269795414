import React, { Fragment, useEffect, useState } from 'react'
import { useAppSelector, useAppDispatch } from 'src/Redux/Store/hooks'
import DefaultOptionMapping from './DefaultOptionMapping'
import classes from './mapping.module.css'
import axios from 'axios'
import { Formik, Form } from 'formik'
import Combination from './Combination'
import {
  initialState,
  formikInitialValues,
  combinationPayload,
  destinationSelect,
  destinationString,
  sourceSelect_destinationText,
  sourceDestinationString,
  sourceText_destinationSelect,
  sourceDestinationSelect,
} from './utils'
import {
  Form as BootstrapForm, Row, Col, Card, Container,
  Button, Spinner, Stack,
} from 'react-bootstrap'

import { mappingData } from 'src/Redux/Slices/workflowFieldMapping/AvailableFlowMappingSlice'

type individualMappingProps = {
  id: number
  workflowID: number
  isRequired: boolean
  displayName: string
  sourceCode: string | number
  availableWorkflow_flow: number
  shortDescription: string | null
  destinationCode: string | number
  platform_source: string
  platform_destination: string
  sourceID: number
  destinationID: number
}

function IndividualMapping(props: individualMappingProps) {
  const {
    id,
    workflowID,
    sourceCode,
    destinationCode,
    displayName,
    isRequired,
    shortDescription,
    availableWorkflow_flow,
    platform_source,
    platform_destination,
    sourceID, destinationID
  } = props
  const dispatch = useAppDispatch()
  const [localLoader, setLoacalLoader] = useState(false)
  const [dispalyBody, setDisplayBody] = useState(true)
  const [dotIndicator, setDotIndicator] = useState('primary')
  const [completionMessage, setCompletionMessage] = useState('')
  const [sourceSideField, setSourceSideField] = useState({} as mappingData)
  const [destinationSideField, setDestinationSIdeField] = useState({} as mappingData)

  const { sourceMappings, destinationMappings } = useAppSelector(
    (state) => state.availableFlowMapping
  )


  useEffect(() => {
    let sourceSideData = {} as mappingData
    let destinationSideData = {} as mappingData
    const combination = [...sourceMappings, ...destinationMappings]

    //:::  Previous Logic::: //

    // for (let s_data of sourceMappings) {
    //   if (sourceCode === s_data.api_code) {
    //     sourceSideData = s_data
    //   }
    // }

    // for (let d_data of destinationMappings) {
    //   if (destinationCode === d_data.api_code) {
    //     destinationSideData = d_data            
    //   }
    // }

    // ::: New Logic ::: //
    for (let s_data of combination) {
      if (sourceCode === s_data.api_code) {
        sourceSideData = s_data
      }
    }
    for (let d_data of combination) {
      if (destinationCode === d_data.api_code) {
        destinationSideData = d_data
      }
    }
    setSourceSideField(sourceSideData)
    setDestinationSIdeField(destinationSideData)
  }, [sourceMappings, destinationMappings])

  const onSubmit = async (values: formikInitialValues) => {
    // console.log('FormikValues', values)
    let combinationData = [] as combinationPayload[]
    let payload = {}

    if (values.combination?.length) {
      for (let data of values.combination) {
        let sourcePlatform_child = '',
          destinationPlatrom_child = ''
        if (data.sourceChild_array?.length) {
          // If there is any child for source side then get the last child value
          let sourceChild = data.sourceChild_array.filter((ele) => ele !== null)
          let sourceChild_lastIndex = sourceChild[sourceChild?.length - 1]
          let sourceChild_lastIndexValue = sourceChild_lastIndex?.child_ID
          sourcePlatform_child = sourceChild_lastIndexValue
        }
        if (data.destinationChild_array?.length) {
          // If there is any child for destination side then get the last child value
          let destinationChild = data.destinationChild_array.filter((ele) => ele !== null)
          let destinationChild_lastIndex = destinationChild[destinationChild?.length - 1]
          let destinationChild_lastIndexValue = destinationChild_lastIndex?.child_ID
          destinationPlatrom_child = destinationChild_lastIndexValue
        }
        let combination_obj = {
          isDefault: false,
          source_platform_data: sourcePlatform_child
            ? sourcePlatform_child
            : data.source_platform_data === ''
              ? null
              : data.source_platform_data,
          source_platform_data_str: data.source_platform_data_str,
          destination_platform_data: destinationPlatrom_child
            ? destinationPlatrom_child
            : data.destination_platform_data === ''
              ? null
              : data.destination_platform_data,
          destination_platform_data_str: data.destination_platform_data_str,
        }
        combinationData.push(combination_obj)
      }
    }

    // This is final combination for default_destination Option and combination
    // let workflow_field_mapping_data_array = [...combinationData, values.default_destinationOption]
    let workflow_field_mapping_data_array = platform_source === null ? [values.default_destinationOption] : [...combinationData, values.default_destinationOption]

    // Note : This payload is when there is source, destination, default_destination Option and combination
    payload = {
      isDefault: false,
      default_value: null,
      workflow: workflowID,
      source_field: sourceSideField?.id || null,
      isInternalMapping: values.map_internally,
      destination_field: destinationSideField?.id,
      workflow_field_mapping_data: workflow_field_mapping_data_array,
    }

    // console.log('payload', payload)
    try {
      setLoacalLoader(true)
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/workflowfield-mappingdata`,
        payload
      )
      if (response?.status === 201) {
        setDotIndicator('success')
        setCompletionMessage(response?.statusText)
        setDisplayBody(false)
      }
    } catch (error) {
      console.log('Somthing Went Wrong', error)
      setDotIndicator('danger')
      setCompletionMessage('Somthing Went Wrong')
    } finally {
      setLoacalLoader(false)
    }
  }

  return (
    <Fragment>
      <Card>
        <Container>
          <Card.Title className='mt-2 mb-1 d-flex justify-content-start align-items-baseline'>
            <i className={`bi bi-circle-fill fs-9 text-${dotIndicator} me-2`}></i>
            {displayName} &nbsp;&nbsp; <b className={`text-${dotIndicator}`}>{completionMessage}</b>
          </Card.Title>
          <Card.Subtitle className='mb-2 text-muted'>{shortDescription}</Card.Subtitle>
          <Card.Body>
            {dispalyBody ? (
              <Formik
                initialValues={initialState}
                onSubmit={onSubmit}
                validationSchema={
                  sourceSideField.id == undefined
                    ?
                    // Removing For Now As Vipul Sir Said
                    //  destinationSideField.input_type === 'TEXT'
                    //   ? destinationString
                    //   : destinationSelect
                    ''
                    : sourceSideField.input_type === 'SELECT' &&
                      destinationSideField.input_type === 'TEXT'
                      ? sourceSelect_destinationText
                      : sourceSideField.input_type === 'TEXT' &&
                        destinationSideField.input_type === 'SELECT'
                        ? sourceText_destinationSelect
                        : sourceSideField.input_type === 'TEXT' &&
                          destinationSideField.input_type === 'TEXT'
                          ? sourceDestinationString
                          : sourceDestinationSelect
                }
              >
                {(Formik) => {
                  return (
                    <Form>
                      <Row>
                        <Col xs={12} md={6}>
                          <BootstrapForm.Control
                            type='text'
                            name='source_field'
                            value={platform_source !== null ? (platform_source + ' - ' + sourceSideField.display_name) : ''}
                            disabled
                            readOnly
                          />
                        </Col>
                        <Col xs={12} md={6}>
                          <BootstrapForm.Control
                            type='text'
                            name='destination_field'
                            value={platform_destination + ' - ' + destinationSideField.display_name}
                            disabled
                            readOnly
                          />
                        </Col>
                        <Col xs={12} md={6} className={classes.default_text}>
                          Default Option :
                        </Col>
                        <Col xs={12} md={6} className='mt-3'>
                          <DefaultOptionMapping
                            Formik={Formik}
                            sourceSideField={sourceSideField}
                            destinationSideField={destinationSideField}
                            destinationPlatformName={platform_destination}
                            destinationOptionName={destinationSideField.display_name}
                          />
                        </Col>
                      </Row>
                      <Combination
                        Formik={Formik}
                        sourceSideField={sourceSideField}
                        destinationSideField={destinationSideField}
                        sourcePlatformName={platform_source}
                        sourceOptionName={sourceSideField.display_name}
                        destinationPlatformName={platform_destination}
                        destinationOptionName={destinationSideField.display_name}
                      />
                      <Stack className={classes.submit_btn}>
                        <Button size='sm' type='submit' className='w-25 mt-7'>
                          {localLoader && (
                            <Spinner
                              className='me-2'
                              as='span'
                              animation='border'
                              size='sm'
                              role='status'
                              aria-hidden='true'
                            />
                          )}
                          {localLoader ? ' Saving...' : 'Save'}
                        </Button>
                      </Stack>
                    </Form>
                  )
                }}
              </Formik>
            ) : null}
          </Card.Body>
        </Container>
      </Card>
    </Fragment>
  )
}

export default IndividualMapping
