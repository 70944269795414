import React, { Fragment, useEffect, useState } from 'react'
import { Card, Row, Col, Button } from 'react-bootstrap'
import classes from './countRecord.module.css'
import axios from 'axios'
import ScreenWidth from 'src/app/modules/widgets/components/ScreenWidth'
import { useSelector } from 'react-redux'
type countRecordProps = {
    workflowID: string | number
    isLoading: boolean
    setIsLoading: (status: boolean) => void
}
type countDetails = {
    total_order_response: {
        total_order_count: number,
        synced_order_count: number,
        failed_order_count: number,
    },
    total_inventory_adjustment_response: {
        total_inventory_adjustment_counts: number,
        synced_inventory_adjustment_counts: number,
        failed_inventory_adjustment_counts: number
    },
    total_shipment_response: {
        total_order_shipment_counts: number,
        synced_order_shipment_counts: number,
        failed_order_shipment_counts: number
    }
}

function CountRecord(props: countRecordProps) {
    const { workflowID, isLoading, setIsLoading } = props
    const [screenWidth] = ScreenWidth()
    const WorkflowDetails = useSelector((state: any) => state.Workflow.WorkflowDetails)?.data
    const [logAction, setLogAction] = useState('')
    const [logActionAdded, setLogActionAdded] = useState(false)
    const [getCountDetails, setCountDetails] = useState<countDetails | null>(null)
    const fetchAvailableWorkflowAsync = async (workflowId: number) => {
        setIsLoading(true)
        try {
            if(logAction){
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/workflow-data-count-for-custom-shipment-flow/?workflow_id=${workflowId}`
                );
                setCountDetails(response?.data)   
            }else{
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/workflow-data-count/?workflow_id=${workflowId}`
                );
                setCountDetails(response?.data)
            }
        } catch (error) {
            console.log('An Error Occured', error)
        } finally {
            setIsLoading(false)
        }
    }
    useEffect(() => {
        if(logActionAdded){
            fetchAvailableWorkflowAsync(Number(workflowID))
        }
    }, [logAction, logActionAdded ])

    useEffect(() => {
        WorkflowDetails?.workflow_action.map((ac: { action: { action: string } }) => {
            if (ac?.action?.action == "CUSTOM_IMPORT_SHIPMENTS" ) {
                setLogAction(ac?.action?.action)                
            }
        })
        setLogActionAdded(true)
    }, [])

    return (
        <Fragment>
            <span className={classes.refreshContainer} >
                {/* <h4>Order-Shipment-Adjustment Record</h4> */}
                <Button
                    size='sm'
                    variant='secondary'
                    title='Refresh'
                    className='bg-secondary btn ms-4'
                    onClick={() => {
                        fetchAvailableWorkflowAsync(Number(workflowID))
                    }}
                >
                    {
                        !isLoading ?
                            <i className="bi bi-arrow-repeat fw-bold fs-2"></i> :
                            <i className="fa-solid fa-rotate fa-spin"></i>
                    }
                </Button>
            </span>
            <Row className='container'>
                {/* Order Count::: */}
                {
                    getCountDetails?.total_order_response ? (
                        <Col xs={12} md={6} lg={4}>
                            <Card style={{ width: screenWidth >= 320 && screenWidth <= 375 ? '20rem' : '25rem' }}>
                                <Card.Body className={classes.card_container}>
                                    <Card.Title>Order Sync</Card.Title>
                                    <Card.Text className={classes.count_container}>
                                        <p className={classes.count_text}><span>{getCountDetails?.total_order_response?.total_order_count}</span><span>Total</span></p>
                                        <p className={classes.count_text}><span>{getCountDetails?.total_order_response?.synced_order_count}</span><span>Synced</span></p>
                                        <p className={classes.count_text}><span>{getCountDetails?.total_order_response?.failed_order_count}</span><span>Failed</span></p>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    ) : null
                }

                {/* Shipment Count::: */}
                {
                    getCountDetails?.total_shipment_response ? (
                        <Col xs={12} md={6} lg={4}>
                            <Card style={{ width: screenWidth >= 320 && screenWidth <= 375 ? '20rem' : '25rem' }}>
                                <Card.Body className={classes.card_container}>
                                    <Card.Title>Shipment Sync</Card.Title>
                                    <Card.Text className={classes.count_container}>
                                        <p className={classes.count_text}><span>{getCountDetails?.total_shipment_response?.total_order_shipment_counts}</span><span>Total</span></p>
                                        <p className={classes.count_text}><span>{getCountDetails?.total_shipment_response?.synced_order_shipment_counts}</span><span>Synced</span></p>
                                        <p className={classes.count_text}><span>{getCountDetails?.total_shipment_response?.failed_order_shipment_counts}</span><span>Failed</span></p>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    ) : null
                }

                {/* Adjustment Count::: */}
                {
                    getCountDetails?.total_inventory_adjustment_response ? (
                        <Col xs={12} md={6} lg={4}>
                            <Card style={{ width: screenWidth >= 320 && screenWidth <= 375 ? '20rem' : '25rem' }}>
                                <Card.Body className={classes.card_container}>
                                    <Card.Title>Adjustment Sync</Card.Title>
                                    <Card.Text className={classes.count_container}>
                                        <p className={classes.count_text}><span>{getCountDetails?.total_inventory_adjustment_response?.total_inventory_adjustment_counts}</span><span>Total</span></p>
                                        <p className={classes.count_text}><span>{getCountDetails?.total_inventory_adjustment_response?.synced_inventory_adjustment_counts}</span><span>Synced</span></p>
                                        <p className={classes.count_text}><span>{getCountDetails?.total_inventory_adjustment_response?.failed_inventory_adjustment_counts}</span><span>Failed</span></p>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    ) : null
                }

            </Row>
        </Fragment>
    )
}

export default CountRecord
