import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { getInvitedUsersAsync, inviteUserAsync } from "./inviteUserAsync";
import { InvitedUserType } from "src/app/modules/invite-users/Interface";

const initialState : { invitedUserLoader:boolean, getInvitedUserLoader:boolean, invitedUsers:InvitedUserType[]|[] } = {
    invitedUserLoader:false,
    
    //list data
    getInvitedUserLoader:false,
    invitedUsers:[]
}

const inviteUserSlice = createSlice({
    name:"inviteUser",
    initialState,
    extraReducers:(builder)=>{

        // invite user start
        builder.addMatcher(isAnyOf(inviteUserAsync.pending),(state, payload)=>{
            console.log('submit invite user in pending state');
            state.invitedUserLoader = true            
        })
        builder.addMatcher(isAnyOf(inviteUserAsync.rejected),(state, payload)=>{
            console.log("submit invite user in rejected state");
            state.invitedUserLoader = false
        })
        builder.addMatcher(isAnyOf(inviteUserAsync.fulfilled),(state, payload)=>{
            console.log("submit invite user in fulfilled state", payload.payload);
            state.invitedUserLoader = false
        })
        // invite user end

        // invite user get start
        builder.addMatcher(isAnyOf(getInvitedUsersAsync.pending),(state, payload)=>{
            console.log('getInvitedUsersAsync pending state');
            state.getInvitedUserLoader = true            
        })
        builder.addMatcher(isAnyOf(getInvitedUsersAsync.rejected),(state, payload)=>{
            console.log("getInvitedUsersAsync rejected state");
            state.getInvitedUserLoader = false
        })
        builder.addMatcher(isAnyOf(getInvitedUsersAsync.fulfilled),(state, payload)=>{
            console.log("getInvitedUsersAsync fulfilled state", payload.payload);
            state.getInvitedUserLoader = false
            state.invitedUsers = payload.payload
        })
        // invite user get end
    },
    reducers:{}
})

export const {} = inviteUserSlice.actions
export default inviteUserSlice.reducer