import React, { Fragment, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'src/Redux/Store/hooks'
import { mappingData, field_data } from 'src/Redux/Slices/workflowFieldMapping/AvailableFlowMappingSlice'
import { Formik, Form } from 'formik'
import { initialState, formikInitialValues, combinationPayload } from './utils'
import DefaultOptionMapping from './DefaultOptionMapping'
import {
  Form as BootstrapForm, Row, Col, Card, Container,
  Button, Spinner, Stack,
} from 'react-bootstrap'
import classes from '../createMappingFields/mapping.module.css'
import Combination from './Combination'
import axios from 'axios'
type individualUpdateProps = {
  id: number
  workflowID: number
  isRequired: boolean
  displayName: string
  sourceCode: string | number
  availableWorkflow_flow: number
  shortDescription: string | null
  destinationCode: string | number
  platform_source: string
  platform_destination: string
  sourceID: number
  destinationID: number
}

function IndividualUpdate(props: individualUpdateProps) {
  const {
    id, workflowID, displayName, sourceID, destinationID, sourceCode, destinationCode,
    platform_source, platform_destination, availableWorkflow_flow, isRequired, shortDescription
  } = props
  const { mappingLoader, mappingList } = useAppSelector((state) => state.mappingList)
  const { sourceMappings, destinationMappings } = useAppSelector((state) => state.availableFlowMapping)
  const [existingMappindData, setExistingMappingData] = useState<any>([])
  const [existingSourceSide, setExistingSourceSide] = useState({} as mappingData)
  const [existingDestinationSide, setExistingDestinationSide] = useState({} as mappingData)
  const [sourceSideMapping, setSourceSideMapping] = useState({} as mappingData)
  const [destinationSideMapping, setDestinationSideMapping] = useState({} as mappingData)
  const [dotIndicator, setDotIndicator] = useState('primary')
  const [completionMessage, setCompletionMessage] = useState('')
  const [localLoader, setLoacalLoader] = useState(false)
  const [dispalyBody, setDisplayBody] = useState(true)

  useEffect(() => {
    // This Source & Destination Is Taken Out From Already Created Mapping Data
    let sourceData = {} as mappingData
    let destinationData = {} as mappingData

    for (let existingMapping of mappingList) {
      // As Destination Will Definately Be there so Comparing With Destination 
      if (existingMapping?.destination_field?.api_code === destinationCode) {
        setExistingMappingData(existingMapping)
        sourceData = existingMapping?.source_field
        destinationData = existingMapping?.destination_field
      }
    }
    setExistingSourceSide(sourceData)
    setExistingDestinationSide(destinationData)

    // This Source & Destination Is Taken Out From All Source & Destination Mapping List
    let sourceSideData = {} as mappingData
    let destinationSideData = {} as mappingData
    const combination = [...sourceMappings, ...destinationMappings]

    //:::  Previous Logic::: //
    // for (let s_data of sourceMappings) {
    //   if (sourceCode === s_data.api_code) {
    //     sourceSideData = s_data
    //   }
    // }
    // for (let d_data of destinationMappings) {
    //   if (destinationCode === d_data.api_code) {
    //     destinationSideData = d_data            
    //   }
    // }

    // ::: New Logic ::: //
    for (let s_data of combination) {
      if (sourceCode === s_data.api_code) {
        sourceSideData = s_data
      }
    }
    for (let d_data of combination) {
      if (destinationCode === d_data.api_code) {
        destinationSideData = d_data
      }
    }

    // console.log('sourceCode', sourceCode, sourceSideData, 'destinationCode', destinationCode, destinationSideData)
    setSourceSideMapping(sourceSideData)
    setDestinationSideMapping(destinationSideData)
  }, [sourceCode, destinationCode])


  const onSubmit = async (values: formikInitialValues) => {
    // console.log('Formik Values', values)
    let combinationData = [] as combinationPayload[]
    let payload = {}

    if (values.combination?.length) {
      for (let data of values.combination) {
        let sourcePlatform_child = '',
          destinationPlatrom_child = ''
        if (data.sourceChild_array?.length) {
          // If there is any child for source side then get the last child value
          let sourceChild = data.sourceChild_array.filter((ele) => ele !== null)
          let sourceChild_lastIndex = sourceChild[sourceChild?.length - 1]
          let sourceChild_lastIndexValue = sourceChild_lastIndex?.child_ID
          sourcePlatform_child = sourceChild_lastIndexValue
        }
        if (data.destinationChild_array?.length) {
          // If there is any child for destination side then get the last child value
          let destinationChild = data.destinationChild_array.filter((ele) => ele !== null)
          let destinationChild_lastIndex = destinationChild[destinationChild?.length - 1]
          let destinationChild_lastIndexValue = destinationChild_lastIndex?.child_ID
          destinationPlatrom_child = destinationChild_lastIndexValue
        }
        let combination_obj = {
          id: data?.id,
          isDefault: false,
          source_platform_data: sourcePlatform_child
            ? sourcePlatform_child
            : data.source_platform_data === ''
              ? null
              : data.source_platform_data,
          source_platform_data_str: data.source_platform_data_str,
          destination_platform_data: destinationPlatrom_child
            ? destinationPlatrom_child
            : data.destination_platform_data === ''
              ? null
              : data.destination_platform_data,
          destination_platform_data_str: data.destination_platform_data_str,
        }
        combinationData.push(combination_obj)
      }
    }

    // This is final combination for default_destination Option and combination
    let workflow_field_mapping_data_array = platform_source === null ? [values.default_destinationOption] : [...combinationData, values.default_destinationOption]

    // Note : This payload is when there is source, destination, default_destination Option and combination
    payload = {
      isDefault: false,
      default_value: null,
      workflow: workflowID,
      source_field: existingSourceSide?.id || null,
      isInternalMapping: values.map_internally,
      destination_field: existingDestinationSide?.id,
      workflow_field_mapping_data: workflow_field_mapping_data_array,
    }

    try {
      setLoacalLoader(true)
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/workflowfield-mappingdata/update/${existingMappindData?.id}`,
        payload
      )
      console.log('response', response)
      if (response?.status === 201) {
        setDotIndicator('success')
        setCompletionMessage('Updated')
        setDisplayBody(false)
      }
    } catch (error) {
      console.log('Somthing Went Wrong', error)
      setDotIndicator('danger')
      setCompletionMessage('Somthing Went Wrong')
    } finally {
      setLoacalLoader(false)
    }
  }

  return (
    <Fragment>
      <Card>
        <Container>
          <Card.Title className='mt-2 mb-1 d-flex justify-content-start align-items-baseline'>
            <i className={`bi bi-circle-fill fs-9 text-${dotIndicator} me-2`}></i>
            {displayName} &nbsp;&nbsp; <b className={`text-${dotIndicator}`}>{completionMessage}</b>
          </Card.Title>
          <Card.Subtitle className='mb-2 text-muted'>{shortDescription}</Card.Subtitle>
          <Card.Body>
            {
              dispalyBody ? (
                <Formik
                  initialValues={initialState}
                  onSubmit={onSubmit}
                >
                  {
                    (Formik) => {
                      return (
                        <Form>
                          <Row>
                            <Col xs={12} md={6}>
                              <BootstrapForm.Control
                                type='text'
                                name='source_field'
                                value={platform_source !== null ? (platform_source + ' - ' + existingSourceSide?.display_name) : ''}
                                disabled
                                readOnly
                              />
                            </Col>
                            <Col xs={12} md={6}>
                              <BootstrapForm.Control
                                type='text'
                                name='destination_field'
                                value={platform_destination + ' - ' + existingDestinationSide?.display_name}
                                disabled
                                readOnly
                              />
                            </Col>
                            <Col xs={12} md={6} className={classes.default_text}>Default :</Col>

                            <Col xs={12} md={6} className='mt-3'>
                              <DefaultOptionMapping
                                Formik={Formik}
                                existingMappindData={existingMappindData}
                                destinationSideMapping={destinationSideMapping}
                                destinationPlatformName={platform_destination}
                                destinationOptionName={existingDestinationSide.display_name}
                              />
                            </Col>
                          </Row>
                          <Combination
                            Formik={Formik}
                            existingMappindData={existingMappindData}
                            sourceSideField={sourceSideMapping}
                            destinationSideField={destinationSideMapping}
                            sourcePlatformName={platform_source}
                            sourceOptionName={existingSourceSide?.display_name}
                            destinationPlatformName={platform_destination}
                            destinationOptionName={existingDestinationSide?.display_name}
                          />
                          <Stack className={classes.submit_btn}>
                            <Button size='sm' type='submit' className='w-25 mt-7'>
                              {localLoader && (
                                <Spinner
                                  className='me-2'
                                  as='span'
                                  animation='border'
                                  size='sm'
                                  role='status'
                                  aria-hidden='true'
                                />
                              )}
                              {localLoader ? ' Updating...' : 'Update'}
                            </Button>
                          </Stack>
                        </Form>
                      )
                    }
                  }
                </Formik>
              )
                : null
            }
          </Card.Body>
        </Container>
      </Card>

    </Fragment>
  )
}

export default IndividualUpdate
