import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import {
    sourceSideSettingAsync,
    destinationSideSettingAsync,
    sourceSideShortCodeAsync,
    destinationSideShortCodeAsync,
} from './platformSettingAsync'

type platformSettingSlice = {
    loading: boolean
    shortCodeLoader: boolean
    sourceSideSetting: any
    destinationSideSetting: any
    sourceSideShortCode: string[] | [],
    destinationSideShortCode: string[] | []
}

const initialState: platformSettingSlice = {
    loading: false,
    shortCodeLoader: false,
    sourceSideSetting: [],
    destinationSideSetting: [],
    sourceSideShortCode: [],
    destinationSideShortCode: [],
}

const platformSettingSlice = createSlice({
    name: 'platformSettingSlice',
    initialState,
    reducers: { emptyPlatformSettings: () => initialState },
    //Pending Statements:
    extraReducers: (builder) => {
        builder.addMatcher(isAnyOf(
            sourceSideSettingAsync.pending,
            destinationSideSettingAsync.pending,
        ), (state, action) => {
            state.loading = true
        })
        builder.addMatcher(isAnyOf(
            sourceSideShortCodeAsync.pending,
            destinationSideShortCodeAsync.pending
        ), (state, action) => {
            state.shortCodeLoader = true
        })
        //Success Statements:
        builder.addMatcher(isAnyOf(sourceSideSettingAsync.fulfilled), (state, action) => {
            state.loading = false
            state.sourceSideSetting = action.payload
        })
        builder.addMatcher(isAnyOf(destinationSideSettingAsync.fulfilled), (state, action) => {
            state.loading = false
            state.destinationSideSetting = action.payload
        })
        builder.addMatcher(isAnyOf(sourceSideShortCodeAsync.fulfilled), (state, action) => {
            state.shortCodeLoader = false
            state.sourceSideShortCode = action.payload?.data
        })
        builder.addMatcher(isAnyOf(destinationSideShortCodeAsync.fulfilled), (state, action) => {
            state.shortCodeLoader = false
            state.destinationSideShortCode = action.payload?.data
        })

        // Reject Statements:
        builder.addMatcher(isAnyOf(
            sourceSideSettingAsync.rejected,
            destinationSideSettingAsync.rejected,
        ), (state, action) => {
            state.loading = false
        })
        builder.addMatcher(isAnyOf(
            sourceSideShortCodeAsync.rejected,
            destinationSideShortCodeAsync.rejected,
        ), (state, action) => {
            state.shortCodeLoader = false
        })
    },
})

export const { emptyPlatformSettings } = platformSettingSlice.actions;
export default platformSettingSlice.reducer;
