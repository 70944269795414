import React, { Fragment, useEffect, useState } from 'react'
import { Stack, Form } from 'react-bootstrap'
import IndividualMapping from './IndividualMapping';
import { useAppSelector, useAppDispatch } from 'src/Redux/Store/hooks';
import { AnimatedLoader } from 'src/app/modules/widgets/components/CustomLoaders'

import {
    getSourceMappingAsync,
    getDestionationMappingAsync
} from 'src/Redux/Slices/workflowFieldMapping/AvailableFlowMappingAsync'

import {
    emptyAvailableFLowMapping,
    availableActionFlow,
    mappingParems,
    mappingData,
    field_data,
} from 'src/Redux/Slices/workflowFieldMapping/AvailableFlowMappingSlice';

type mappingFieldProps = {
    displayName: string
    sourceID: number
    destinationID: number
    workflowID: number
}

function CreateMappingFields(props: mappingFieldProps) {
    const { displayName, sourceID, destinationID, workflowID } = props
    const dispatch = useAppDispatch();
    const [fieldMappingList, setFieldMappingList] = useState<mappingParems[] | []>([])
    const {
        loading,
        sourceDestinationMappingLoader,
        sourceMappings,
        destinationMappings,
        fieldMappingByAvailableWorkflow,
    } = useAppSelector((state) => state.availableFlowMapping)


    useEffect(() => {
        // Step 01: Finding matching dispalyName from available_action_flows
        let filtered_available_action_flows = {} as availableActionFlow
        let filterMappings = [] as mappingParems[]

        if (fieldMappingByAvailableWorkflow?.available_action_flows?.length) {
            for (let data of fieldMappingByAvailableWorkflow?.available_action_flows) {
                if (data.display_name === displayName) {
                    filtered_available_action_flows = data
                }
            }
        }

        // Step 02: Finding matching id === available_workflow_flow
        if (fieldMappingByAvailableWorkflow?.available_workflow_field_mapping?.length) {
            for (let mapping of fieldMappingByAvailableWorkflow?.available_workflow_field_mapping) {
                if (filtered_available_action_flows?.id === mapping?.available_workflow_flow) {
                    filterMappings.push(mapping)
                }
            }
        }

        if (filterMappings?.length) {
            setFieldMappingList(filterMappings)
        }

        // step 03: API call for source and destination mapping(Shifted To (AvailableWorkFlows.tsx) Parent Page.)

        // dispatch(getSourceMappingAsync({ source_platform_id: sourceID }))
        // dispatch(getDestionationMappingAsync({ destination_platform_id: destinationID }))



        // console.log('filterMappings', filterMappings)
        // console.log('filtered_available_action_flows', filtered_available_action_flows)
        // console.log('fieldMappingByAvailableWorkflow', fieldMappingByAvailableWorkflow)
    }, [fieldMappingByAvailableWorkflow])

    return (
        <Fragment>
            <Stack className={sourceDestinationMappingLoader ? 'd-block' : 'd-none'}>
                <AnimatedLoader />
            </Stack>
            {
                fieldMappingList?.length ? (<h4 className='text-primary'>Field Mapping :</h4>) : null
            }
            {
                fieldMappingList?.length ? fieldMappingList.map((ele: mappingParems) => {
                    return (
                        <Stack key={ele.id} className={sourceDestinationMappingLoader ? 'd-none' : 'd-block mt-3'}>
                            <IndividualMapping
                                id={ele.id}
                                workflowID={workflowID}
                                isRequired={ele.isRequired}
                                displayName={ele.display_name}
                                sourceCode={ele.field_code_source}
                                shortDescription={ele.short_description}
                                destinationCode={ele.field_code_destination}
                                availableWorkflow_flow={ele.available_workflow_flow}
                                platform_source={ele?.platform_source}
                                platform_destination={ele?.platform_destination}
                                sourceID={sourceID}
                                destinationID={destinationID}
                            />
                        </Stack>
                    )
                }) : null
            }
        </Fragment>
    )
}

export default CreateMappingFields;

