import {Formik, FormikHelpers} from 'formik'
import React, {FC, useCallback, useEffect, useState} from 'react'
import {Button, Col, Form, Row} from 'react-bootstrap'
import {useAppDispatch, useAppSelector} from 'src/Redux/Store/hooks'
import Select, {MultiValue} from 'react-select'
import * as yup from 'yup'
import {getPlatformDetailByIdAsync} from 'src/Redux/Slices/platform/platformAsync'
import {
  SELECTED_PLATFORM_ACTION,
  available_platform_action,
} from 'src/_interfaces/PlatformSlice.Interface'
import {INITIAL_VALUE_TYPE_OF_ADD_AVAILABLE_WORKFLOW} from './Availableworkflow.interface'
import {toast} from 'react-toastify'
import {
  getAvailableWorkflowsAsync,
  updateAvailableWorkflowAsync,
} from 'src/Redux/Slices/availableWorkflow/availableWorkflowAction'
import {AVAILABLE_WORKFLOW} from 'src/_interfaces/AvailableWorkflow.Interface'
import AppEditor from 'src/utils/AppEditor'

const EditAvailableWorkflowForm: FC<{
  handleClose: () => void
  editData: AVAILABLE_WORKFLOW | null
}> = ({handleClose, editData}) => {
  const dispatch = useAppDispatch()
  const [selectedPlatform, setSelectedPlatform] = useState<{
    type: 'SOURCE' | 'DESTINATION' | null
    id: string | null
  }>({type: null, id: null})
  const [platformActions, setPlatformActions] = useState<{
    sourcePlatformActions: available_platform_action[] | []
    destinationPlatformActions: available_platform_action[] | []
  }>({sourcePlatformActions: [], destinationPlatformActions: []})

  const [selectedPlatformActions, setSelectedPlatformActions] = useState<{
    source: MultiValue<SELECTED_PLATFORM_ACTION> | []
    destination: MultiValue<SELECTED_PLATFORM_ACTION> | []
  }>({source: [], destination: []})

  const {data} = useAppSelector((state) => state.platform)
  const {getPlatformDetailLoader} = useAppSelector((state) => state.platformCombination)
  const {updateAvailableWorkflowLoader} = useAppSelector((state) => state.availableWorkflow)
  const schema = yup.object().shape({
    name: yup.string().required(),
    description: yup.string().required(),
    destination_available_platform: yup.number().required('requires'),
    source_available_platform: yup.number().required('requires'),
    available_action_for_source: yup
      .array()
      .min(1, 'Field requires at least on value')
      .of(
        yup.object().shape({
          action_id: yup.number(),
          platform_id: yup.number(),
        })
      )
      .required('requires'),
    available_action_for_destination: yup
      .array()
      .min(1, 'Field requires at least on value')
      .of(
        yup.object().shape({
          action_id: yup.number(),
          platform_id: yup.number(),
        })
      )
      .required('requires'),
  })

  const initialValues: INITIAL_VALUE_TYPE_OF_ADD_AVAILABLE_WORKFLOW = {
    name: editData ? editData.name : '',
    description: editData ? editData.description : '',
    source_available_platform: editData ? editData.source_available_platform.id : null,
    destination_available_platform: editData ? editData.destination_available_platform.id : null,
    available_action_for_source: editData
      ? editData.available_actions
          .filter((item) => {
            if (item.platform.id == editData.source_available_platform.id) {
              return item
            }
          })
          .map((re) => ({action_id: re.action.id, platform_id: re.platform.id}))
      : [],
    available_action_for_destination: editData
      ? editData.available_actions
          .filter((item) => {
            if (item.platform.id == editData.destination_available_platform.id) {
              return item
            }
          })
          .map((re) => ({action_id: re.action.id, platform_id: re.platform.id}))
      : [],
  }

  useEffect(() => {
    if (selectedPlatform.id && selectedPlatform.type) {
      if (selectedPlatform.type == 'DESTINATION') {
        dispatch(getPlatformDetailByIdAsync({platformId: Number(selectedPlatform.id)})).then(
          (response) => {
            // console.log('response of destination platform', response)
            setPlatformActions((pre) => ({
              ...pre,
              destinationPlatformActions: response.payload.data.available_platform_action,
            }))
          }
        )
      } else if (selectedPlatform.type == 'SOURCE') {
        dispatch(getPlatformDetailByIdAsync({platformId: Number(selectedPlatform.id)})).then(
          (response) => {
            // console.log('response of source platform', response)
            setPlatformActions((pre) => ({
              ...pre,
              sourcePlatformActions: response.payload.data.available_platform_action,
            }))
          }
        )
      } else {
        return
      }
    }
  }, [selectedPlatform])

  useEffect(() => {
    if (editData) {
      dispatch(
        getPlatformDetailByIdAsync({platformId: Number(editData.destination_available_platform.id)})
      ).then((response) => {
        // console.log('response of destination platform', response)
        setPlatformActions((pre) => ({
          ...pre,
          destinationPlatformActions: response.payload.data.available_platform_action,
        }))
      })
      dispatch(
        getPlatformDetailByIdAsync({platformId: Number(editData.source_available_platform.id)})
      ).then((response) => {
        // console.log('response of source platform', response)
        setPlatformActions((pre) => ({
          ...pre,
          sourcePlatformActions: response.payload.data.available_platform_action,
        }))
      })
      if (editData.available_actions.length) {
        let source: SELECTED_PLATFORM_ACTION[] = []
        let destinations: SELECTED_PLATFORM_ACTION[] = []
        let copyEditDataAvailableActions = [...editData.available_actions]
        copyEditDataAvailableActions.forEach((item) => {
          if (item.platform.id == editData.source_available_platform.id) {
            let shadow = {...item}
            shadow.label = item.action.action_name
            shadow.value = item.action.id
            source.push(shadow)
          } else if (item.platform.id == editData.destination_available_platform.id) {
            let shadow = {...item}
            shadow.label = item.action.action_name
            shadow.value = item.action.id
            destinations.push(shadow)
          }
        })
        console.log('source and destination---------------->', source, destinations)

        setSelectedPlatformActions({source, destination: destinations})
      }
    }
    return () => {
      setSelectedPlatformActions({source: [], destination: []})
    }
  }, [editData])

  return (
    <>
      <Formik
        validationSchema={schema}
        initialValues={initialValues}
        onSubmit={(
          values: INITIAL_VALUE_TYPE_OF_ADD_AVAILABLE_WORKFLOW,
          handlers: FormikHelpers<INITIAL_VALUE_TYPE_OF_ADD_AVAILABLE_WORKFLOW>
        ) => {
          const payload = {
            name: values.name,
            description: values.description,
            destination_available_platform: values.destination_available_platform!,
            source_available_platform: values.source_available_platform!,
            action_and_platform: [
              ...values.available_action_for_source,
              ...values.available_action_for_destination,
            ],
          }
          console.log('payload', payload)
          // check the existance of edit data
          if (editData)
            dispatch(
              updateAvailableWorkflowAsync({updateData: payload, updateId: editData.id})
            ).then(() => {
              handlers.resetForm()
              setSelectedPlatform({type: null, id: null})
              setSelectedPlatformActions({source: [], destination: []})
              dispatch(
                getAvailableWorkflowsAsync('https://useappconnect.com:8005/available-workflows')
              )
              handleClose()
              toast.success('Workflow updated successfully')
            })
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFormikState,
        }) => {
          return (
            <Form data-testid='login-form' noValidate validated={false} onSubmit={handleSubmit}>
              <Row className='pb-4'>
                <Form.Group as={Col}>
                  <Form.Label>Workflow Name</Form.Label>
                  <Form.Control
                    placeholder='Workflow name'
                    name='name'
                    required
                    autoComplete='off'
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!!errors.name && touched.name}
                    isValid={!!values.name && touched.name}
                  />
                  <Form.Control.Feedback type='invalid'>
                    Workflow name is required
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className='pb-4'>
                <Form.Group as={Col}>
                  <Form.Label>Description</Form.Label>
                  <div onBlur={handleBlur}  >
                    <AppEditor
                    placeholder='description'
                    value={values.description}
                    setValue={(val)=>{
                      console.log("edited value of this\n", val);
                      setFormikState((pre)=>{
                        return {...pre, values:{...pre.values, description:val }}
                      })
                    }}
                    editorStyle={{
                        border: !!errors.description && touched.description ? "0.5px solid red" : !!values.description && touched.description  ? "0.5px solid #59d08f" : "inherit" ,
                        borderRadius:"5px"
                    }}
                    />
                  </div>
                  <Form.Control
                    placeholder='description'
                    name='description'
                    as={'textarea'}
                    rows={3}
                    required
                    autoComplete='off'
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!!errors.description && touched.description}
                    isValid={!!values.description && touched.description}
                    className='d-none'
                  />
                  <Form.Control.Feedback type='invalid'>
                    Workflow description is required
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>
                      Select Platform <span>&#x28;source&#x29;</span>{' '}
                    </Form.Label>
                    <Form.Select
                      aria-label='Select Source Platform'
                      value={values.source_available_platform ?? ''}
                      required
                      name='source_available_platform'
                      onChange={(e) => {
                        if (values.destination_available_platform) {
                          if (`${values.destination_available_platform}` == e.target.value) {
                            return toast.info('Destination and source platform can not be same')
                          }
                        }
                        console.log(e.target.value)
                        setSelectedPlatform({type: 'SOURCE', id: e.target.value})
                        setFormikState((pre) => ({
                          ...pre,
                          values: {
                            ...pre.values,
                            source_available_platform: Number(e.target.value),
                          },
                        }))
                      }}
                      onBlur={handleBlur}
                      isInvalid={
                        !!errors.source_available_platform && touched.source_available_platform
                      }
                      isValid={
                        !!values.source_available_platform && touched.source_available_platform
                      }
                    >
                      <option value={''}>Select Source</option>
                      {data.length ? (
                        data.map((platform) => (
                          <option key={'available_platform' + platform.id} value={platform.id}>
                            {platform.display_name}
                          </option>
                        ))
                      ) : (
                        <option disabled value=''>
                          No data found
                        </option>
                      )}
                    </Form.Select>
                    <Form.Control.Feedback type='invalid'>
                      Source platform is required
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>
                      Select Platform <span>&#x28;destination&#x29;</span>{' '}
                    </Form.Label>
                    <Form.Select
                      aria-label='select destination platform'
                      value={values.destination_available_platform ?? ''}
                      name='destination_available_platform'
                      onChange={(e) => {
                        console.log(e.target.value)
                        if (values.source_available_platform) {
                          if (`${values.source_available_platform}` == e.target.value)
                            return toast.info('Source and destination platform can not be same')
                        }
                        setSelectedPlatform({type: 'DESTINATION', id: e.target.value})
                        setFormikState((pre) => ({
                          ...pre,
                          values: {
                            ...pre.values,
                            destination_available_platform: Number(e.target.value),
                          },
                        }))
                      }}
                      onBlur={handleBlur}
                      isInvalid={
                        !!errors.destination_available_platform &&
                        touched.destination_available_platform
                      }
                      isValid={
                        !!values.destination_available_platform &&
                        touched.destination_available_platform
                      }
                    >
                      <option value={''}>Select Destination</option>
                      {data.length ? (
                        data.map((platform) => (
                          <option key={'available_platform' + platform.id} value={platform.id}>
                            {platform.display_name}
                          </option>
                        ))
                      ) : (
                        <option disabled value=''>
                          No data found
                        </option>
                      )}
                    </Form.Select>
                    <Form.Control.Feedback type='invalid'>
                      Destination platform is required
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group as={Col} md='12' className='py-4'>
                    <Select
                      required
                      value={selectedPlatformActions.source}
                      isLoading={getPlatformDetailLoader && selectedPlatform.type == 'SOURCE'}
                      options={platformActions.sourcePlatformActions.map(
                        (item: available_platform_action) => ({
                          label: item.action.action_name,
                          value: item.action.id,
                          ...item,
                        })
                      )}
                      name='available_action_for_source'
                      isMulti={true}
                      placeholder={'Select Platform Actions...'}
                      onChange={(selectedItems) => {
                        setSelectedPlatformActions((pre) => ({...pre, source: selectedItems}))
                        const modifiedArray = selectedItems.map((item) => {
                          return {action_id: item.action.id, platform_id: item.platform.id}
                        })

                        setFormikState((pre) => {
                          return {
                            ...pre,
                            values: {
                              ...pre.values,
                              available_action_for_source: modifiedArray,
                            },
                          }
                        })
                      }}
                      onBlur={handleBlur}
                      classNames={{
                        control: (state) => {
                          return `py-1 border border-secondary ${
                            errors.available_action_for_source
                              ? 'border-danger'
                              : 'border-secondary'
                          }`
                        },
                      }}
                    />
                    <span className='text-danger'>
                      {errors?.available_action_for_source
                        ? errors.available_action_for_source.toString()
                        : ''}
                    </span>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Col} md='12' className='py-4'>
                    <Select
                      required
                      value={selectedPlatformActions.destination}
                      isLoading={getPlatformDetailLoader && selectedPlatform.type == 'DESTINATION'}
                      options={platformActions.destinationPlatformActions.map(
                        (item: available_platform_action) => ({
                          label: item.action.action_name,
                          value: item.id,
                          ...item,
                        })
                      )}
                      name='available_action_for_destination'
                      isMulti={true}
                      placeholder={'Select Platform Actions...'}
                      onChange={(selectedItems) => {
                        const modifiedArray = selectedItems.map((item) => {
                          return {action_id: item.action.id, platform_id: item.platform.id}
                        })
                        setSelectedPlatformActions((pre) => ({...pre, destination: selectedItems}))
                        setFormikState((pre) => {
                          return {
                            ...pre,
                            values: {
                              ...pre.values,
                              available_action_for_destination: modifiedArray,
                            },
                          }
                        })
                      }}
                      onBlur={handleBlur}
                      classNames={{
                        control: (state) => {
                          return `py-1 border ${
                            errors.available_action_for_destination
                              ? 'border-danger'
                              : 'border-secondary'
                          }`
                        },
                      }}
                    />
                    <span className='text-danger'>
                      {errors?.available_action_for_destination &&
                      !values.available_action_for_destination.length
                        ? errors.available_action_for_destination.toString()
                        : ''}
                    </span>
                  </Form.Group>
                </Col>
              </Row>
              <Button disabled={updateAvailableWorkflowLoader} type='submit' size='sm'>
                {updateAvailableWorkflowLoader ? '...' : 'Update Workflow'}
              </Button>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

export default EditAvailableWorkflowForm
