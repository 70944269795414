import React, { Fragment, useEffect, useState } from 'react'
import axios from 'axios'
import Header from './components/Header'
import classes from './components/shipment.module.css'
import { lineItems } from './components/ShipmentLineItems'
import ShipmentTracking from './components/ShipmentTracking'
import ShipmentLineItems from './components/ShipmentLineItems'
import { Modal, Container, Stack, Row, Col } from 'react-bootstrap'
import { CircularLoader } from 'src/app/modules/widgets/components/CustomLoaders'

type individualShipmentDetailsProps = {
  OpenModal: boolean
  closeModal: (state: boolean) => void
  individualShipmentID: number | null
  shipmentData: {
    orderID: string
    shipmentID: string | number
    trackingNumber: string
    trackingCompany: string
    shipmentStatus: string
  }
}

function IndividualShipmentDetails(props: individualShipmentDetailsProps) {
  const { OpenModal, closeModal, shipmentData, individualShipmentID } = props
  const [lineItemsList, setLineItemsList] = useState<lineItems[] | []>([])
  const [loading, setLoading] = useState(false)
  const [rawData, setRawData] = useState<any>()

  const getShipmentDetailsByID = async (id: number) => {
    setLoading(true)
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/platform-order-shipment/${id}`
      )
      setRawData(response?.data)
      setLineItemsList(response?.data?.shipment_line_items)
    } catch (error) {
      console.log('Somthing Went Wrong', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (individualShipmentID) {
      getShipmentDetailsByID(individualShipmentID)
    }
  }, [individualShipmentID])

  return (
    <Modal
      show={OpenModal}
      onHide={() => closeModal(true)}
      size='lg'
      centered
      style={{ backgroundColor: '#0007' }}
    >
      <div className='modal-content'>
        <Fragment>
          {loading ? (
            <Stack className={classes.loader}>
              <CircularLoader
                height='70px'
                width='70px'
                marginBottom='10px'
                marginTop='10px'
                borderWidth='4px'
              />
            </Stack>
          ) : (
            <Fragment>
              <Modal.Header>
                <Header closeModal={closeModal} shipmentID={shipmentData?.shipmentID} rawData={rawData} />
              </Modal.Header>
              <Modal.Body>
                <Container>
                  <Row>
                    <Col xs={12}>
                      <ShipmentTracking shipmentData={shipmentData} rawData={rawData} />
                    </Col>
                    <Col xs={12}>
                      <ShipmentLineItems
                        lineItemsList={lineItemsList}
                        
                      />
                    </Col>
                  </Row>
                </Container>
              </Modal.Body>
            </Fragment>
          )}
        </Fragment>
      </div>
    </Modal>
  )
}

export default IndividualShipmentDetails
