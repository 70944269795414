import * as Yup from 'yup'


type formikInitialValues = {
    combination: any; previouslySavedMaps: any; defaultDestination: any, field_to_default_text_dropdown: any,
    field_to_default_text_textField: any, only_default_destination_provided: any, map_internally: boolean
}
export const initialValues: formikInitialValues = {
    combination: [],
    previouslySavedMaps: [],
    defaultDestination: {},
    field_to_default_text_dropdown: '',
    field_to_default_text_textField: '',
    only_default_destination_provided: {},
    map_internally: false
}

export const validationSchema_WithCombination = Yup.object({
    defaultDestination: Yup.object().required('Destination Option Is Required'),
    combination: Yup.array()
        .of(
            Yup.object().shape({
                source_platform_data: Yup.string().required('Required'), // these constraints take precedence
                destination_platform_data: Yup.string().required('Required'), // these constraints take precedence
            })
        )
        .required('') // these constraints are shown if and only if inner constraints are satisfied
})

export const validationSchema_WithoutCombination = Yup.object({
    only_default_destination_provided: Yup.object().required('Destination Option Is Required'),
})









//:::Case 01 When Conbination: Source And Destination Input Type Are 'SELECT'
export const validationSchemaAllFields = Yup.object({
    default_destinationPlatformData: Yup.string().required('Destination Option Is Required'),
    combination: Yup.array()
      .of(
        Yup.object().shape({
          source_platform_data: Yup.string().required('Required'), 
          destination_platform_data: Yup.string().required('Required'), 
        })
      )
      .required('') // these constraints are shown if and only if inner constraints are satisfied
  })
  //:::Case 02 When Conbination: Source Input Type Is 'TEXT' And Destination Input Type Is 'SELECT'
  export const validationSchema_sourceTextField = Yup.object({
    default_destinationPlatformData: Yup.string().required('Destination Option Is Required'),
    combination: Yup.array()
      .of(
        Yup.object().shape({
          source_platform_data_str: Yup.string().required('Required'), 
          destination_platform_data: Yup.string().required('Required'), 
        })
      )
      .required('') // these constraints are shown if and only if inner constraints are satisfied
  })
  //:::Case 03 When Conbination: Source Input Type Is 'SELECT' And Destination Input Type Is 'TEXT'
  export const validationSchema_destinationTextField = Yup.object({
    default_destinationPlatformData_str: Yup.string().required('Destination Option Is Required'),
    combination: Yup.array()
      .of(
        Yup.object().shape({
          source_platform_data: Yup.string().required('Required'), 
          destination_platform_data_str: Yup.string().required('Required'), 
        })
      )
      .required('') // these constraints are shown if and only if inner constraints are satisfied
  })
  //:::Case 04  When Conbination: Source And Destination Input Type Are 'TEXT'
  export const validationSchema_source_destinationTextField = Yup.object({
    default_destinationPlatformData_str: Yup.string().required('Destination Option Is Required'),
    combination: Yup.array()
      .of(
        Yup.object().shape({
          source_platform_data_str: Yup.string().required('Required'), 
          destination_platform_data_str: Yup.string().required('Required'), 
        })
      )
      .required('') // these constraints are shown if and only if inner constraints are satisfied
  })
  
  // Only Destination i.e. Default Destination
  export const validationSchemaSingleField = Yup.object({
    default_destinationPlatformData: Yup.string().required('Destination Option Is Required'),
  })



  