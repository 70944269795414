import React, { Fragment, useState, useEffect } from 'react'
import axios from 'axios'
import Header from './components/Header'
import Payment from './components/Payment'
import Shipment from './components/Shipment'
import OrderLineItem from './components/OrderLineItem'
import classes from './components/orderDetail.module.css'
import PersonalDetails from './components/PersonalDetails'
import { Modal, Container, Row, Col, Stack } from 'react-bootstrap'
import { OrderData_type, orderShipmentType } from './components/utils'
import { CircularLoader } from '../../../widgets/components/CustomLoaders'

type individualOrderDetailProps = {
    OpenModal: boolean
    closeModal: (state: boolean) => void
    orderID: number
    setOrderID: (data: number) => void
    fetchUsers:(page:number,loadingRequired:boolean)=>void
    currentPageOfFetchUser:number
}

function IndividualOrderDetail(props: individualOrderDetailProps) {
    const { OpenModal, closeModal, orderID, setOrderID } = props
    const [loading, setLoading] = useState(false)
    const [OrderData, setOrderData] = useState({} as OrderData_type)
    const [orderShipment, setOrderShipment] = useState<orderShipmentType[]>([])

    const fetchGetOrderListById = async (order_id: number, afterSync=false ) => {
        let isDataFetched = false
       if(!afterSync){
        setLoading(true)
        //    setTimeout(() => {setLoading(true)}, 50);
       } 
        await axios.get(`${process.env.REACT_APP_API_URL}/orders/${order_id}/`).then((response)=>{
           setOrderData(response?.data?.order)
           setOrderShipment(response?.data?.order?.platform_order_shipments)
           setLoading(false)
           isDataFetched = true
       })
        .catch((err)=>{
            console.log('err', err)
            setLoading(false)
            isDataFetched = false
        })        

        return isDataFetched
    }

    useEffect(() => {
        if (orderID) {
            fetchGetOrderListById(orderID)
        }
    }, [orderID])

    return (
        <Modal
            show={OpenModal}
            onHide={() => closeModal(true)}
            size='xl'
            centered
            style={{ backgroundColor: '#0007' }}
        >
            <div className='modal-content'>
                {loading ? (
                    <Stack className={classes.loader}>
                        <CircularLoader
                            height='70px'
                            width='70px'
                            marginBottom='10px'
                            marginTop='10px'
                            borderWidth='4px'
                        />
                    </Stack>
                ) : (
                    <Fragment>
                        <Modal.Header>
                            <Header
                                orderID={orderID}
                                OrderData={OrderData}
                                closeModal={closeModal}
                                setOrderID={setOrderID}
                                fetchGetOrderListById={fetchGetOrderListById}
                                fetchUsers={props.fetchUsers}
                                currentPageOfFetchUser={props.currentPageOfFetchUser}
                            />
                        </Modal.Header>

                        <Modal.Body>
                            <Container>
                                <Row>
                                    <Col xs={12} lg={8}>
                                        <OrderLineItem OrderData={OrderData} />
                                        <Shipment orderShipment={orderShipment} />
                                        <Payment OrderData={OrderData} />
                                    </Col>
                                    <Col xs={12} lg={4}>
                                        <PersonalDetails OrderData={OrderData} />
                                    </Col>
                                </Row>
                            </Container>
                        </Modal.Body>
                    </Fragment>
                )}
            </div>
        </Modal>
    )
}

export default IndividualOrderDetail
