import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import SourceSetting from './SourceSetting'
import DestinationSetting from './DestinationSetting'
import classes from '../components/availableFlows.module.css'
import { useAppSelector, useAppDispatch } from 'src/Redux/Store/hooks'
import ScreenWidth from 'src/app/modules/widgets/components/ScreenWidth'
import { Container, Row, Col, Stack, Button, Spinner } from 'react-bootstrap'
import { emptyForm } from 'src/Redux/Slices/workflowFormSlice/workflowFormSlice'
import { AnimatedLoader } from 'src/app/modules/widgets/components/CustomLoaders'
import { createWorkFlowAsync } from 'src/Redux/Slices/workflowFormSlice/workflowFormAsync'
import { getFieldMappingByAvailableWorkflowAsync } from 'src/Redux/Slices/workflowFieldMapping/AvailableFlowMappingAsync'
import Mapping from '../mapping/Mapping'
import CreateMappingFields from '../mapping/createMappingFields/CreateMappingFields'
import UpdateMappingFields from '../mapping/updateMappingFields/UpdateMappingFields'

import {
    emptyAvailableFLowMapping,
    availableActionFlow,
    mappingParems,
    mappingData,
    field_data,
} from 'src/Redux/Slices/workflowFieldMapping/AvailableFlowMappingSlice';

type viewFlowSettingsProps = {
    id: number
    workflowID: number
    action1: number
    action2: number
    sourceID: number
    destinationID: number
    workflow_info: number
    displayName: string
}

type payloadState = {
    platform: string | number,
    workflow: string | number,
    settings: any
}

function ViewFlowSettings(props: viewFlowSettingsProps) {
    const { id, action1, action2, workflowID, sourceID, destinationID, workflow_info, displayName } = props
    const dispatch = useAppDispatch();
    const [screenWidth] = ScreenWidth()
    // Loaders:::
    const [sourceLoader, setSourceLoader] = useState(true)
    const [destinationLoader, setDestinationLoader] = useState(true)
    // Payloads:::
    const [sourcePayload, setSourcePayload] = useState({} as payloadState)
    const [destinationPayload, setDestinationPayload] = useState({} as payloadState)
    const [localLoader, setLocalLoader] = useState(false)
    // Mapping Modal
    const [openModal, setOpenModal] = useState(false)
    const [modalType, setModalType] = useState<string>('')
    // Complete Mapping Visibility
    const [ShowMappingCompletionSection, setShowMappingCompletionSection] = useState(false)

    const { sourceSideSetting, destinationSideSetting, loading } = useAppSelector((state) => state.platformSettings)
    const { fieldMappingByAvailableWorkflow, sourceMappings, destinationMappings } = useAppSelector((state) => state.availableFlowMapping)
    const { mappingLoader, mappingList } = useAppSelector((state) => state.mappingList)


    // Additional Logic:::
    useEffect(() => {
        // Step 01: Finding matching dispalyName from available_action_flows
        let filtered_available_action_flows = {} as availableActionFlow
        let filterMappings = [] as mappingParems[]

        if (fieldMappingByAvailableWorkflow?.available_action_flows?.length) {
            for (let data of fieldMappingByAvailableWorkflow?.available_action_flows) {
                if (data.display_name === displayName) {
                    filtered_available_action_flows = data
                }
            }
        }

        // Step 02: Finding matching id === available_workflow_flow
        if (fieldMappingByAvailableWorkflow?.available_workflow_field_mapping?.length) {
            for (let mapping of fieldMappingByAvailableWorkflow?.available_workflow_field_mapping) {
                if (filtered_available_action_flows?.id === mapping?.available_workflow_flow) {
                    filterMappings.push(mapping)
                }
            }
        }

        // console.log('filterMappings', displayName, filterMappings)

        if (filterMappings?.length) {
            for (let data of filterMappings) {
                let sourceCode = data?.field_code_source
                let destinationCode = data?.field_code_destination

                let sourceSideData = [] as mappingData[]
                let destinationSideData = [] as mappingData[]
                const combination = [...sourceMappings, ...destinationMappings]

                for (let s_data of combination) {
                    if (sourceCode === s_data.api_code) {
                        sourceSideData.push(s_data)
                    }
                } for (let d_data of combination) {
                    if (destinationCode === d_data.api_code) {
                        destinationSideData.push(d_data)
                    }
                }
                // console.log('displayName', displayName)
                // console.log('sourceSideData', sourceSideData)
                // console.log('destinationSideData', destinationSideData)

                if (sourceSideData?.length || destinationSideData?.length) {
                    setShowMappingCompletionSection(true)
                } else {
                    setShowMappingCompletionSection(false)
                }
            }
        }
    }, [fieldMappingByAvailableWorkflow])
   
    return (
        <Container>
            <Stack className={(loading || mappingLoader) ? classes.show : classes.hide}>
                <AnimatedLoader />
            </Stack>
            <Row className={
                `${(loading || mappingLoader) ? 'd-none' : 'd-flex'}  
                ${(sourcePayload.settings?.length || destinationPayload.settings?.length) ? classes.visible : ''}`}>
                {
                    (sourcePayload.settings?.length || destinationPayload.settings?.length) ?
                        (<h4 className='text-primary'>Workflow Setting :</h4>) : (<h4 className='mb-7'>No Settings Available Now.</h4>)
                }
                <Col xs={12} md={6}>
                    <SourceSetting
                        id={id}
                        sourceID={sourceID}
                        workflowID={workflowID}
                        destinationID={destinationID}
                        setSourceLoader={setSourceLoader}
                        setSourcePayload={setSourcePayload}
                    />
                </Col>
                <Col xs={12} md={6}>
                    <DestinationSetting
                        id={id}
                        workflowID={workflowID}
                        sourceID={sourceID}
                        destinationID={destinationID}
                        setDestinationLoader={setDestinationLoader}
                        setDestinationPayload={setDestinationPayload}
                    />
                </Col>
                <Col xs={12} className={classes.button}
                    style={{ display: (sourcePayload?.settings?.length || destinationPayload?.settings?.length) ? 'flex' : 'none' }}
                >
                    <Button
                        variant='primary'
                        size='sm'
                        style={{ width: screenWidth <= 320 || screenWidth <= 500 ? '50%' : '30%' }}
                        onClick={async () => {
                            setLocalLoader(true)
                            const [res1, res2] = await Promise.all([
                                dispatch(createWorkFlowAsync(sourcePayload) as any),
                                dispatch(createWorkFlowAsync(destinationPayload) as any)
                            ])
                            if (res1 && res2) {
                                toast.success('Updated Successfully')
                                dispatch(emptyForm())
                                setLocalLoader(false)
                            } else {
                                toast.error('Somthing Went Worng')
                                dispatch(emptyForm())
                                setLocalLoader(false)
                            }
                        }}
                        disabled={localLoader}
                    >
                        {localLoader && (
                            <Spinner
                                as='span'
                                size='sm'
                                role='status'
                                className='me-2'
                                animation='border'
                                aria-hidden='true'
                            />
                        )}
                        {localLoader ? 'Updating Changes...' : 'Update Changes'}
                    </Button>
                </Col>
                {
                    mappingList?.length ?
                        (
                            // <Col xs={12}
                            //     className={ShowMappingCompletionSection ? classes.complete_mapping : 'd-none'}>
                            //     <Button
                            //         size='sm'
                            //         variant='outline-success'
                            //         style={{ width: screenWidth <= 320 || screenWidth <= 500 ? '50%' : '30%' }}
                            //         className='px-3 py-2 border border-1 border-primary rounded bg-hover-primary text-hover-white fs-sm-8 inline-block'
                            //         onClick={() => {
                            //             setOpenModal(true)
                            //             setModalType('update')
                            //         }}
                            //     >
                            //         Update Mapping
                            //     </Button>
                            // </Col>
                            <Stack>
                                <UpdateMappingFields displayName={displayName} sourceID={sourceID} destinationID={destinationID} workflowID={workflowID} />
                            </Stack>
                        )
                        :
                        (
                            // <Col xs={12}
                            //     className={ShowMappingCompletionSection ? classes.complete_mapping : 'd-none'}>
                            //     <Button
                            //         size='sm'
                            //         variant='outline-success'
                            //         style={{ width: screenWidth <= 320 || screenWidth <= 500 ? '50%' : '30%' }}
                            //         className='px-3 py-2 border border-1 border-primary rounded bg-hover-primary text-hover-white fs-sm-8 inline-block'
                            //         onClick={() => {
                            //             setOpenModal(true)
                            //             setModalType('create')
                            //         }}
                            //     >
                            //         Complete Mapping
                            //     </Button>
                            // </Col>
                            <Stack>
                                <CreateMappingFields displayName={displayName} sourceID={sourceID} destinationID={destinationID} workflowID={workflowID} />
                            </Stack>
                        )
                }
            </Row>
            <Mapping
                modalShow={openModal}
                setModalShow={setOpenModal}
                workflowID={workflowID}
                workflow_info={workflow_info}
                sourceID={sourceID}
                destinationID={destinationID}
                displayName={displayName}
                modalType={modalType}
            />
        </Container>
    )
}

export default ViewFlowSettings;
