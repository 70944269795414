import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit'
import {
  getAvailablePlatform,
  getConnectedPlatform,
  createPlatform,
  getAvailablePlatformbyPlatformId,
  deletePlatform,
} from '../../services/PlatformService'
import { AVAILABLE_PLATFORM_TYPE } from './PlatformSlice.interface'

interface PlatformState {
  loader: boolean
  data: AVAILABLE_PLATFORM_TYPE[]
  error: string | null | unknown
  ConnectedPlatformdDetails: any
  APlatformByIdDetails: any
}

const initialState: PlatformState = {
  loader: false,
  data: [],
  error: null,
  APlatformByIdDetails: null,
  ConnectedPlatformdDetails: null,
}

export const getAvailablePlatformApi = createAsyncThunk(
  'available-platform/api/get',
  async (params: {} | undefined, { rejectWithValue, fulfillWithValue }) => {
    try {
      const result = await getAvailablePlatform(params)
      return result.data
    } catch (error) {
      return rejectWithValue({ error })
    }
  }
)

export const getAvailablePlatformbyPlatformIdApi = createAsyncThunk(
  'available-platform-by-platform-id/api/get',
  async (id: any, { rejectWithValue, fulfillWithValue }) => {
    try {
      const result = await getAvailablePlatformbyPlatformId(id)
      return result.data
    } catch (error) {
      return rejectWithValue({ error })
    }
  }
)

export const getConnectedPlatformApi = createAsyncThunk(
  'connected-platform/api/get',
  async (userId: number, { rejectWithValue, fulfillWithValue }) => {
    try {
      const result = await getConnectedPlatform(userId)
      return result.data
    } catch (error) {
      return rejectWithValue({ error })
    }
  }
)

export const createPlatformApi = createAsyncThunk(
  'create-platform/api/post',
  async (FormData: any, { rejectWithValue, fulfillWithValue }) => {
    try {
      const result = await createPlatform(FormData)
      return result
    } catch (error) {
      return rejectWithValue({ error })
    }
  }
)
export const deletePlatformApi = createAsyncThunk(
  'delete-platform/api',
  async (id: any, { rejectWithValue, fulfillWithValue }) => {
    try {
      const result = await deletePlatform(id)
      return result
    } catch (error) {
      return rejectWithValue({ error })
    }
  }
)

export const platformSlice = createSlice({
  name: 'platform',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getConnectedPlatformApi.fulfilled, (state, action) => {
        state.ConnectedPlatformdDetails = action.payload?.data
      })
      .addCase(getAvailablePlatformbyPlatformIdApi.fulfilled, (state, action) => {
        state.APlatformByIdDetails = action.payload
      })
      .addMatcher(isAnyOf(getAvailablePlatformApi.pending), (state) => {
        state.loader = true
        state.error = null
      })
      .addMatcher(isAnyOf(getAvailablePlatformApi.fulfilled), (state, action) => {
        state.loader = false
        state.data = action.payload.data
        state.error = null
      })
      .addMatcher(isAnyOf(getAvailablePlatformApi.rejected), (state, action) => {
        state.loader = false
        state.error = action.payload
      })
  },
})

export default platformSlice.reducer
