import { Suspense, useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { I18nProvider } from '../_metronic/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'
import { MasterInit } from '../_metronic/layout/MasterInit'
import { AuthInit } from './modules/auth'
import { ThemeModeProvider } from '../_metronic/partials'
import Loader from './modules/loader/Loader'
import { getSubdomainDetailAsync } from '../Redux/Slices/subdomain/subdomainAsync'
import { useAppDispatch, useAppSelector } from '../Redux/Store/hooks'
import {
  setLoggedInByAdminData,
  setLoggedInData,
} from '../Redux/Slices/loggedInUser/loggedInUserSlice'
import 'react-toastify/dist/ReactToastify.css'
import AlertModal from 'src/_metronic/helpers/components/AlertModal'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { GOOGLE_OAUTH_CLIENT_ID } from 'src/_config/config'
import { ToastContainer } from 'react-toastify'
import { LOCAL_STORAGE_FIELDS } from 'src/utils/constants'

const App = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { subdomainLoader } = useAppSelector((state) => state.subdomain)
  const { modalProp } = useAppSelector((state) => state.info)

  const handleSubDomain = () => {
    try {
      console.log('process.env.NODE_ENV', process.env.NODE_ENV)
      // let tempurl = "https://constadevelopment.useappconnect.com:8005" // used this for testing purpose
      console.log('hostname', window.location.host)
      const hostname = window.location.host
      const hostnameArray = hostname.split('.')
      console.log('hostnameArray', hostnameArray)
      if (hostnameArray.length >= 2) {
        console.log('Inn')
        dispatch(getSubdomainDetailAsync({ subdomain: hostnameArray[0] }))
          .then((response) => {
            console.log('response', response)
            if (response?.payload?.data?.status == false && hostnameArray[0] !== 'app' && hostnameArray[0] !== 'testuseapp' ) {
              document.location =
                document.location.protocol + '//' + 'app.' + hostnameArray[1] + '/auth'
            }
          })
          .catch((error) => {
            console.log('Somthing Went Wrong', error)
            navigate('/auth')
          })
      } else {
        console.log('out')
        document.location =
          document.location.protocol + '//' + 'app.' + hostnameArray[0] + '/auth'
      }
      // if (process.env.NODE_ENV === "production") {
      //   if (hostnameArray.length >= 4) {
      //     dispatch(getSubdomainDetailAsync({ subdomain: hostnameArray[0] })).then((res) => {
      //       if (!res?.payload?.data) {
      //         document.location = document.location.protocol + "//" + hostnameArray[1] + "." + hostnameArray[2]
      //       }
      //     })
      //       .catch((error) => {
      //         console.log("org not exist", error);
      //         navigate('/auth')
      //       })
      //   }
      // } else {
      //   if (hostnameArray.length == 2) {
      //     dispatch(getSubdomainDetailAsync({ subdomain: hostnameArray[0] }))
      //       .then((response) => {
      //         if (response?.payload?.data?.status == false && hostnameArray[0] !== 'app') {
      //           document.location = document.location.protocol + '//' + 'app.' + hostnameArray[1] + '/auth'
      //         }
      //       })
      //       .catch((error) => {
      //         console.log('Somthing Went Wrong', error)
      //         navigate('/auth')
      //       })
      //   }
      //   else {
      //     document.location = document.location.protocol + '//' + 'app.' + hostnameArray[hostnameArray.length - 1] + '/auth'
      //   }
      // }
    } catch (error) {
      console.log('Somthing Went Wrong', error)
      navigate('/auth')
    }
  }

  useEffect(() => {
    try {
      // setting logged in user detail start
      const loggedInData = localStorage.getItem(LOCAL_STORAGE_FIELDS.user_details)
      dispatch(setLoggedInByAdminData())
      if (loggedInData) {
        let parsedLoggedInData = JSON.parse(loggedInData)
        dispatch(setLoggedInData(parsedLoggedInData))
      }
      handleSubDomain()
    } catch (error) {
      console.log('error in catch block', error)
      navigate('/auth/login')
    }
  }, [])

  if (subdomainLoader) return <Loader />

  return (
    <>
      <GoogleOAuthProvider clientId={GOOGLE_OAUTH_CLIENT_ID}>
        <Suspense fallback={<LayoutSplashScreen />}>
          <I18nProvider>
            <LayoutProvider>
              <ThemeModeProvider>
                <AuthInit>
                  <Outlet />
                  <MasterInit />
                </AuthInit>
              </ThemeModeProvider>
            </LayoutProvider>
          </I18nProvider>
        </Suspense>
        <AlertModal {...modalProp} />
        <ToastContainer />
      </GoogleOAuthProvider>
    </>
  )
}

export { App }
