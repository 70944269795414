import React, { FC, useEffect, useState } from 'react'
import { Button, Modal, Row, Col } from 'react-bootstrap'
import { useFormik } from 'formik'
import Loader from '../../loader/Loader'
import { KTSVG } from '../../../../_metronic/helpers'
import { ToastContainer, toast } from 'react-toastify'
import { useSelector, useDispatch } from 'react-redux'
import { emptyForm } from 'src/Redux/Slices/workflowFormSlice/workflowFormSlice'

import {
  createPlatformApi,
  getAvailablePlatformbyPlatformIdApi,
  getConnectedPlatformApi,
} from '../../../redux/slices/PlatformSlices'
interface PropType {
  data: any
  OpenModal: boolean
  closeModal: () => void
  oauthURL: any
  getOnlyDisplayText: any
  setOnlyDisplayText: (data: any) => void
  displayButtonName: string
}

const FormModal: FC<PropType> = (props) => {
  // const loader = useSelector((state:any) => state?.location?.loader);
  const enableReinitialize = true
  const dispatch = useDispatch<any>()
  const [loading, setLoading] = useState(false)
  const fetchConnectedPlatformGetApi = () => {
    const userId = Number(localStorage.getItem('user'))
    dispatch(getConnectedPlatformApi(userId))
  }

  const formik = useFormik({
    initialValues: {
      identifier: '',
      client_id: '',
      client_secret: '',
      token_id: '',
      token_secret: '',
      refresh_token: '',
    },
    enableReinitialize,

    onSubmit: async (values, { setStatus, resetForm }) => {
      resetForm()
      const userId = Number(localStorage.getItem('user'))

      const FormData = {
        user: userId,
        name: APlatformByIdDetails?.name,
        type: APlatformByIdDetails?.type,
        code: 'ABC123',
        // display_name: APlatformByIdDetails?.display_name,
        display_name: props.getOnlyDisplayText,
        credentials: {
          identifier: values?.identifier || null,
          client_id: values?.client_id || null,
          client_secret: values?.client_secret || null,
          token_id: values?.token_id || null,
          token_secret: values?.token_secret || null,
          refresh_token: values?.refresh_token || null,
        },
        settings: [
          {
            name: 'isCreateOrder',
            value: 'False',
          },
          {
            name: 'isUseDefaultCustomer',
            value: 'False',
          },
          {
            name: 'isUseDefaultCustomerEmail',
            value: '',
          },
        ],
        isConnected: true,
        isActive: true,
        isOrderSyncProcessing: false,
        isNewOrderSyncProcessing: false,
        isOrderCreateProcessing: false,
        platform_info: props.data,
      }

      console.log('FormData', FormData)
      dispatch(createPlatformApi(FormData)).then((response: any) => {
        console.log('response', response)
        if (response?.payload?.data?.status_code == 201) {
          // Show success toast notification
          // props.closeModal()
          fetchConnectedPlatformGetApi()
          toast.success(response?.payload?.data?.massage)
          props.setOnlyDisplayText('')
        } else {
          // props.closeModal()
          fetchConnectedPlatformGetApi()
          // Show error toast notification
          toast.error('error')
        }
        props.closeModal()
        props.setOnlyDisplayText('')
      })
    },
  })

  // ------------------------------------------------

  // --------------------------------------------------------------------Step 02 Formik Start
  function getInputBox(input_type: any, name: any, display_name: any) {
    if (input_type == 'text') {
      return (
        <input
          placeholder={display_name}
          type='text'
          name={name}
          onChange={formik.handleChange}
          className='form-control form-control-lg form-control-solid'
        />
      )
    }
  }
  // --------------------------------------------------------------------Step 02 Formik End

  // --------------------------------------------------------------------Step 01 Getting API Response Start
  const fetchAvailablePlatformGetApi = () => {
    setSpinner(true)

    const id = props?.data
    dispatch(getAvailablePlatformbyPlatformIdApi(id)).then((response: any) => {
      if (response) {
        setSpinner(false)
      }
    })
  }

  useEffect(() => {
    if (props?.data) {
      fetchAvailablePlatformGetApi()
    }
  }, [props?.data])

  const APlatformByIdDetails = useSelector(
    (state: any) => state.platform.APlatformByIdDetails
  )?.data
  const [spinner, setSpinner] = useState(true)
  // --------------------------------------------------------------------Step 01 Getting API Response End

  //  ProcessOauth Start-------------------------------
  useEffect(() => {
    if (props.data) {
      let availablePlatformID: any = { available_platform_id: props.data }
      localStorage.setItem('processOauth_ID', JSON.stringify(availablePlatformID))
    }
  }, [props.data])
  //  ProcessOauth Start-------------------------------

  // --------------------------------------------------------------------Step 03 Preparing Form Start
  return (
    <Modal
      show={props.OpenModal}
      onHide={props.closeModal}
      //  className='mt-sm-20 pt-sm-10'
      size='lg'
      centered
    >
      <ToastContainer />
      {spinner ? (
        <Loader />
      ) : (
        <div className='modal-content'>
          <div className='modal-body px-5'>
            <form className='form w-100' onSubmit={formik.handleSubmit} noValidate>
              <div className=' my-4 '>
                <div className='px-4'>
                  <div className='d-flex justify-content-between mb-4'>
                    <h2 className='pb-4 text-center '>
                      Connect {APlatformByIdDetails?.display_name} Platform
                    </h2>

                    <div
                      className='btn btn-icon btn-sm btn-active-light-primary '
                      onClick={() => {
                        props.closeModal()
                        dispatch(emptyForm()) // To clear workflow redux state
                      }}
                      style={{ marginTop: '-10px' }}
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr061.svg'
                        className='svg-icon svg-icon-2x'
                      />
                    </div>
                  </div>

                  <div className='row mb-4'>
                    <Row>
                      <Col xs={12}>
                        <input type="text" placeholder="Friendly Name" className='mb-5 form-control form-control-lg form-control-solid'
                          value={props.getOnlyDisplayText} onChange={(event: any) => props.setOnlyDisplayText(event.target.value)} />
                      </Col>
                    </Row>

                    {APlatformByIdDetails?.available_platform_auth_requirement?.map(
                      (items: any) => {
                        const { input_type, display_name, name, id } = items
                        const inputBox = getInputBox(input_type, name, display_name)
                        return (
                          <div className='col' key={id}>
                            {inputBox}
                          </div>
                        )
                      }
                    )}
                  </div>

                  <div>
                    {props.oauthURL ? (
                      <>
                        <a
                          href={props.oauthURL}
                          rel="noreferrer"
                          target="_blank"
                        >
                          <button
                            type='button'
                            id='kt_sign_in_submit'
                            className='btn btn-md w-100'
                            style={{ backgroundColor: '#2CA01C', color: 'white', lineHeight: '20px' }}
                            onClick={() => localStorage.setItem('oauthDisplayText', JSON.stringify(props.getOnlyDisplayText))}
                          >
                            Connect To {props?.displayButtonName}
                          </button>
                        </a>
                      </>
                    ) : (
                      <button
                        type='submit'
                        id='kt_sign_in_submit'
                        className='btn btn-md w-100'
                        style={{ backgroundColor: '#2CA01C', color: 'white', lineHeight: '20px' }}
                        disabled={formik.isSubmitting || !formik.isValid}
                      >
                        {!loading && <span className='indicator-label'>Connect To {props?.displayButtonName}</span>}
                        {loading && (
                          <span className='indicator-progress' style={{ display: 'block' }}>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </Modal>
  )
  // --------------------------------------------------------------------Step 03 Preparing Form End

}

export { FormModal }



