import { organizationBasedPlatformAsync } from './platformAsync'
import { createSlice, isAnyOf } from '@reduxjs/toolkit'

type organizationPlatformsState = {
    status_code: number,
    message: string,
    data: {
        id: number,
        name: string,
        display_name: string,
        auth_route_name: string,
        isActive: boolean,
        isOauth: boolean,
        logo_ref: string,
        type: string,
    }[]
}
type orgInitialState = {
    loading: boolean
    organizationPlatforms: organizationPlatformsState | null
}

const initialState: orgInitialState = {
    loading: false,
    organizationPlatforms: null,
}

const organizationPlatformsSlice = createSlice({
    name: 'organizationSlice',
    initialState,
    reducers: { emptyOrganizationPlatform: () => initialState },
    extraReducers: (builder) => {
        builder.addMatcher(isAnyOf(organizationBasedPlatformAsync.pending), (state, action) => {
            state.loading = true
        })
        builder.addMatcher(isAnyOf(organizationBasedPlatformAsync.rejected), (state, action) => {
            state.loading = false
        })
        builder.addMatcher(isAnyOf(organizationBasedPlatformAsync.fulfilled), (state, action) => {
            state.loading = false
            state.organizationPlatforms = action.payload
        })
    },
})

export const { emptyOrganizationPlatform } = organizationPlatformsSlice.actions;
export default organizationPlatformsSlice.reducer;
