import React, { Fragment, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { Button, Container } from 'react-bootstrap'
import ViewMappings from './MappingsList'
import { ConfirmationModal } from '../../ConfirmationModal'
import MappingComponentsModal from './components/MappingModal'
import {
  sourcePlatformProcessAsync, destinationPlatformProcessAsync
} from '../../../../../../Redux/Slices/workflowFormSlice/workflowFormAsync'
import { emptyForm } from '../../../../../../Redux/Slices/workflowFormSlice/workflowFormSlice'
import { emptyPlatformCombinons } from '../../../../../../Redux/Slices/platform/platformSlice'
import CreateMapping from './CreateMapping/CreateMapping'

type FieldMappingProps = {
  workflowID: number
  sourceplatform_ID: number
  destinationplatform_ID: number
}

function FieldMapping(props: FieldMappingProps) {
  const dispatch = useDispatch()
  const { workflowID, sourceplatform_ID, destinationplatform_ID } = props
  const [modalShow, setModalShow] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const { sourcePlatformProcess, destinationPlatformProcess } = useSelector((state: any) => state.workflowForm)
  const { loading, initilizeProcess } = useSelector((state: { platformProcess: { loading: boolean, initilizeProcess: { results: [] } } }) => state.platformProcess)

  const handleRefetchEvent = () => {
    setShowConfirmationModal(!showConfirmationModal)
  }

  const handleExecuteRefetchEvent = () => {
    dispatch(sourcePlatformProcessAsync(
      { platform: sourceplatform_ID, workflow: workflowID }) as any)
    dispatch(destinationPlatformProcessAsync(
      { platform: destinationplatform_ID, workflow: workflowID }) as any)
    setShowConfirmationModal(!showConfirmationModal)
  }

  useEffect(() => {
    if (sourcePlatformProcess.status_code == 201 && destinationPlatformProcess.status_code == 201) {
      toast.success(sourcePlatformProcess.massage)
      // toast.success(destinationPlatformProcess.massage)
      dispatch(emptyForm())
      dispatch(emptyPlatformCombinons())
    }
  }, [sourcePlatformProcess, destinationPlatformProcess])

  return (
    <Container>
      <div
        style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', marginBottom: '10px', columnGap: '10px' }}
      >
        <Button variant='primary' size='sm' onClick={() => handleRefetchEvent()}
          disabled={initilizeProcess?.results?.length > 0}
        >
          <i className="bi bi-arrow-clockwise"></i>  Refetch Data
        </Button>
        <Button variant='primary' size='sm' onClick={() => setModalShow(true)}>
          Create Field Mapping
        </Button>
      </div>


      {/* <MappingComponentsModal
        modalShow={modalShow}
        setModalShow={setModalShow}
        workflowID={workflowID}
        sourceplatform_ID={sourceplatform_ID}
        destinationplatform_ID={destinationplatform_ID}
      /> */}

      <CreateMapping
        modalShow={modalShow}
        setModalShow={setModalShow}
        workflowID={workflowID}
        sourceplatform_ID={sourceplatform_ID}
        destinationplatform_ID={destinationplatform_ID}
      />


      <ViewMappings
        workflowID={workflowID}
        sourceplatform_ID={sourceplatform_ID}
        destinationplatform_ID={destinationplatform_ID}
        createFieldMappingModal={modalShow}
      />


      <ConfirmationModal
        icon='/media/icons/duotune/general/gen044.svg'
        title='Refetch Data?'
        description='Are you sure you want to refetch your data. This might take 2-4 minutes.'
        onSave={handleExecuteRefetchEvent}
        showModal={showConfirmationModal}
        setShowModal={setShowConfirmationModal}
      />
    </Container>
  )
}

export default FieldMapping


