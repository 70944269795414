import { dateTimeFormatter } from '../../../../../widgets/components/DateTimeFormater'

type shipmentActions = {
    handleShipmentClick: (data: shipmentData) => void,
    mouseHoverID: string
    setMouseHoverID: (id: string) => void
}

type rowData = {
    id: number
    api_id: string
    tracking_number: string
    tracking_company: string
    shipment_status: string
    created_at: string
    platform_order: { order_api_id: string }
    destination_sync_status : string
}

type columnData = {
    name: string | React.ReactElement
    selector?: (row: rowData) => void
    cell?: (row: rowData) => void
    sortable?: boolean
    width?: string
}

export type shipmentData = {
    id: number
    orderID: string
    shipmentID: string
    trackingNumber: string
    trackingCompany: string
    shipmentStatus: string
}


const shipmentLogs =
    (
        { handleShipmentClick, mouseHoverID, setMouseHoverID }: shipmentActions
    ): columnData[] => [
            { name: '', selector: (row) => '', width: '2px' },
            {
                name: 'Order Id',
                selector: (row) => row?.platform_order?.order_api_id,
                width: '200px',
                sortable: true,
            },
            {
                name: 'Shipment Id',
                selector: (row) => row?.api_id,
                width: '200px',
                sortable: true,
            },
            {
                name: 'Tracking Number',
                selector: (row) => row?.tracking_number,
                sortable: true,
                width: '250px',
            },
            {
                name: 'Tracking Company',
                selector: (row) => row?.tracking_company,
            },
            {
                name: 'Shipment Status',
                selector: (row) => row?.shipment_status,
            },
            {
                name: 'Created at',
                cell: (row) => dateTimeFormatter(row?.created_at),
                width: '250px',
                sortable: true,
            },
            {
                name: 'Sync Status',
                cell: (row) => row?.destination_sync_status,
                width: '250px',
                sortable: true,
            },
            {
                name: 'View',
                cell: (row) => (
                    <div
                        onMouseEnter={() => setMouseHoverID(row?.api_id || '')}
                        onMouseLeave={() => setMouseHoverID('')}
                    >
                        <i
                            className='bi bi-eye-fill fs-2 cursor-pointer'
                            onClick={() =>
                                handleShipmentClick({
                                    orderID: row?.platform_order?.order_api_id || '',
                                    shipmentID: row?.api_id || '',
                                    trackingNumber: row?.tracking_number || '',
                                    trackingCompany: row?.tracking_company || '',
                                    shipmentStatus: row?.shipment_status || '',
                                    id: row?.id
                                })
                            }
                            style={{ color: mouseHoverID == row?.api_id ? '#4098FF' : '' }}
                        ></i>
                    </div>
                ),
                sortable: false,
            },
        ]

export default shipmentLogs;
