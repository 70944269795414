import {Formik} from 'formik'
import React, {FC, useState} from 'react'
import {Button, Col, Form, Modal, Row} from 'react-bootstrap'
import * as Yup from 'yup'
import {toast} from 'react-toastify'
import Select from 'react-select'
import { INITIAL_VALUE_FOR_CREATE_ORG } from 'src/_interfaces/Organization.Interface'
import { useAppDispatch, useAppSelector } from 'src/Redux/Store/hooks'
import { RootState } from 'src/Redux/Store/storets'
import { createOrganizationAsync, getOrganizationsListWithoutLoadingAsync } from 'src/Redux/Slices/organizations/organizationAsync'
import { useThemeMode } from 'src/_metronic/partials'

const CreateOrganizationModal: FC<{visible: boolean; handleClose: () => void}> = ({
  visible = false,
  handleClose,
}) => {
  const [validated, setValidated] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const { mode } = useThemeMode()
  const {userList, createOrganizationLoader, paginationData } = useAppSelector(
    (state: RootState) => state.organization
  )

  const schema = Yup.object().shape({
    display_name: Yup.string().required().min(3),
    subdomain: Yup.string().required().min(3),
    logo: Yup.string().required().min(3),
  })

  const handleCreateOrganization = async (
    event: React.FormEvent<HTMLFormElement>,
    values: INITIAL_VALUE_FOR_CREATE_ORG,
    handleReset: () => void
  ) => {
    event.preventDefault()

    const form = event.currentTarget
    if (form.checkValidity()) {
      dispatch(createOrganizationAsync(values)).then((response) => {
        console.log('response', response.payload.status)
        if (response.payload.status == '400') {
          return toast.error(response?.payload?.data?.data?.subdomain[0])
        }
        toast.success('Organization created successfully!')
        if(paginationData.currentPage){
          dispatch(getOrganizationsListWithoutLoadingAsync(paginationData.currentPage))
        }
        handleClose()
        handleReset()
        setValidated(false)
      })
    } else {
      toast.error('Please fill required fields')
    }
    // console.log("submitting values------->", values);
    setValidated(true)
  }

  const InitialValues: INITIAL_VALUE_FOR_CREATE_ORG = {
    user: 1,
    display_name: '',
    subdomain: '',
    logo: 'https://theme.zdassets.com/theme_assets/11606116/c271c0edf9baeb714db2ccef96cbd6d86a0a296f.png',
  }

  return (
    <Modal
      show={visible}
      onHide={() => {
        handleClose()
        setValidated(false)
      }}
      size='lg'
      centered
      className='bg-light'
      key={'createOrganizationModal'}
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Organization with detail</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          validationSchema={schema}
          initialValues={InitialValues}
          onSubmit={() => console.log('submitted')}
        >
          {({
            values,
            handleChange,
            handleBlur,
            errors,
            touched,
            resetForm,
            setErrors,
            handleReset,
            setFormikState,
          }) => {
            return (
              <>
                <Form
                  noValidate
                  validated={validated}
                  onSubmit={(event) => handleCreateOrganization(event, values, handleReset)}
                >
                  <Row className='mb-3'>
                    <Select
                      required
                      options={userList.map((item) => {
                        if (item?.first_name && item?.last_name) {
                          return {
                            label: `${item.first_name} ${item.last_name}  ( ${item.email} ) `,
                            value: item.id,
                          }
                        } else {
                          return {label: `${item.username}   ( ${item.email} ) `, value: item.id}
                        }
                      })}
                      delimiter='first_name'
                      name='user'
                      isMulti={false}
                      placeholder={'Select use...'}
                      onChange={(selectedItems) => {
                        setFormikState((pre) => {
                          return {...pre, values: {...values, user: selectedItems?.value as number}}
                        })
                      }}
                      onBlur={handleBlur}
                      classNames={{
                        valueContainer:(props) =>{
                          return "bg-light"
                        },
                        singleValue:(props) =>{
                          return mode=='dark' ? "text-white" : ""
                        },
                        menu:(state)=> {
                          return "bg-light"
                        },
                        control: (state) => {
                          if (state.isFocused) {
                            return 'border border-1 shadow-none border-secondary py-1 bg-light'
                          } else if (!values.user && validated) {
                            return 'border border-1 shadow-none border-danger bg-dark bg-light '
                          }
                          return "py-1 bg-light"
                        },
                      }}
                    />
                  </Row>
                  <Row className='mb-3'>
                    <Form.Group as={Col}>
                      <Form.Label>Display Name</Form.Label>
                      <Form.Control
                        placeholder='Display name'
                        name='display_name'
                        required
                        autoComplete='off'
                        value={values.display_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.display_name && touched.display_name}
                        isValid={!!values.display_name && touched.display_name}
                      />
                      <Form.Control.Feedback type='invalid'>
                        Display name is required
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col}>
                      <Form.Label>Subdomain Name</Form.Label>
                      <Form.Control
                        placeholder='Subdomain name'
                        name='subdomain'
                        required
                        autoComplete='off'
                        value={values.subdomain}
                        onChange={(event)=>{
                          const { value } = event.target
                          if(value.split(" ").length>=2){
                           return setErrors({subdomain:"subdomain can not contain space"})
                            
                          }
                          handleChange(event)
                        } 
                      }
                        onBlur={handleBlur}
                        isInvalid={!!errors.subdomain && touched.subdomain}
                        isValid={!!values.subdomain && touched.subdomain}
                      />
                      <Form.Control.Feedback type='invalid'>
                        { errors.subdomain ? errors.subdomain : "Subdomain is required example 'https://subdomain.domain.com'  --> 'subdomain' part"}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  <Row className='mb-3'>
                    <Form.Group as={Col}>
                      <Form.Label>Logo Url</Form.Label>
                      <Form.Control
                        placeholder='logo url '
                        name='logo'
                        required
                        value={values.logo}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.logo && touched.logo}
                        isValid={!!values.logo && touched.logo}
                      />
                      <Form.Control.Feedback type='invalid'>
                        Logo url is required
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  <div className='d-flex align-items-center justify-content-center mt-8'>
                    <Button
                      size='sm'
                      disabled={createOrganizationLoader}
                      variant='primary'
                      type='submit'
                    >
                      {createOrganizationLoader ? '...' : 'Create Organization'}
                    </Button>
                  </div>
                </Form>
              </>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default CreateOrganizationModal
