import {FormikErrors, FormikTouched} from 'formik'
import React, {FC} from 'react'
import {Button, Col, Form, Row} from 'react-bootstrap'

interface SectionValues {
  input_type: string
  is_required: boolean
  name: string
  display_name: string
}
type hideCloseButton = boolean

const PlatformSettingWithChild: FC<{
  avplIndex: number
  removeThisSection: () => void
  handleChange: React.ChangeEventHandler<any>
  sectionValues: SectionValues
  errors: FormikErrors<SectionValues | undefined>
  handleBlur: (event: React.FocusEvent<any, Element>) => void
  touched: FormikTouched<SectionValues | undefined>
  fieldError: FormikErrors<SectionValues | undefined | string>
  hideCloseButton: hideCloseButton
}> = ({
  avplIndex,
  removeThisSection,
  handleChange,
  sectionValues,
  errors,
  handleBlur,
  touched,
  fieldError,
  hideCloseButton = false,
}) => {
  const isErrObj = typeof fieldError == 'object' ? fieldError : undefined
  const isTouchedObj = typeof touched == 'object' ? touched : undefined
  const hideClose = hideCloseButton ? 'd-none' : 'd-flex'

  const AUTH_INPUT_TYPES = [
    {label: 'BOOLEAN', value: 'boolean'},
    {label: 'BUTTON', value: 'button'},
    {label: 'TEXT', value: 'text'},
    {label: 'INTEGER', value: 'integer'},
    {label: 'EMAIL', value: 'email'},
    {label: 'PHONE', value: 'phone'},
    {label: 'ADDRESS', value: 'address'},
    {label: 'MULTILINE_TEXT', value: 'multiline_text'},
    {label: 'IMAGE', value: 'image'},
    {label: 'SELECT', value: 'select'},
    {label: 'MULTISELECT', value: 'multiselect'},
  ]

  return (
    <>
      <Row key={'authFieldSetup' + avplIndex} className='bg-light p-md-2 m-md-4 m-1 rounded'>
        <div className={` justify-content-end ${hideClose} `}>
          <Button size='sm' variant='outline-light' onClick={() => removeThisSection()}>
            <span className='svg-icon  svg-icon-2x text-danger'>
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                className='mh-50px'
              >
                <path
                  opacity='0.3'
                  d='M6.7 19.4L5.3 18C4.9 17.6 4.9 17 5.3 16.6L16.6 5.3C17 4.9 17.6 4.9 18 5.3L19.4 6.7C19.8 7.1 19.8 7.7 19.4 8.1L8.1 19.4C7.8 19.8 7.1 19.8 6.7 19.4Z'
                  fill='currentColor'
                ></path>
                <path
                  d='M19.5 18L18.1 19.4C17.7 19.8 17.1 19.8 16.7 19.4L5.40001 8.1C5.00001 7.7 5.00001 7.1 5.40001 6.7L6.80001 5.3C7.20001 4.9 7.80001 4.9 8.20001 5.3L19.5 16.6C19.9 16.9 19.9 17.6 19.5 18Z'
                  fill='currentColor'
                ></path>
              </svg>
            </span>
          </Button>
        </div>
        <Form.Group as={Col} md='6' className='mb-4'>
          <Form.Label>Input Type</Form.Label>
          <Form.Select
            value={sectionValues.input_type}
            onChange={handleChange}
            name={`available_platform_setting.child[${avplIndex}].input_type`}
            required
            onBlur={handleBlur}
            isInvalid={!!isErrObj?.input_type && isTouchedObj?.input_type}
            isValid={!!sectionValues.input_type && isTouchedObj?.input_type}
          >
            <option value={''}>Select Input type </option>
            {AUTH_INPUT_TYPES.map((ip_type, index) => (
              <option key={index + 'ip_type' + avplIndex} value={ip_type.value}>
                {ip_type.label}
              </option>
            ))}
          </Form.Select>
          <Form.Control.Feedback type='invalid'>Input type is required</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md='6' className='mb-4'>
          <Form.Label>Name</Form.Label>
          <Form.Control
            type='text'
            required
            name={`available_platform_setting.child[${avplIndex}].name`}
            value={sectionValues.name}
            placeholder='name'
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={!!isErrObj?.name && isTouchedObj?.name}
            isValid={!!sectionValues.name && isTouchedObj?.name}
          />
          <Form.Control.Feedback type='invalid'>Name is required</Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} md='6' className='mb-4'>
          <Form.Label>Display Name</Form.Label>
          <Form.Control
            type='text'
            required
            name={`available_platform_setting.child[${avplIndex}].display_name`}
            value={sectionValues.display_name}
            placeholder='Display name'
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={!!isErrObj?.display_name && isTouchedObj?.display_name}
            isValid={!!sectionValues.display_name && isTouchedObj?.display_name}
          />
          <Form.Control.Feedback type='invalid'>Display name is required</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md='6' className='mb-4'>
          <Form.Check
            type='switch'
            id={`available_platform_setting_child_${avplIndex}`}
            label='Required'
            className='align-self-center mt-8 fw-bold'
            name={`available_platform_setting.child[${avplIndex}].is_required`}
            checked={sectionValues.is_required}
            onChange={handleChange}
            // bsPrefix={`float-none`}
            // isValid={values.isActive}
          />
        </Form.Group>
      </Row>
    </>
  )
}

export default PlatformSettingWithChild
