/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC, useEffect, useState } from 'react'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import { PrivateRoutes } from './PrivateRoutes'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { Logout, AuthPage, useAuth } from '../modules/auth'
import { App } from '../App'
import { useDispatch, useSelector } from 'react-redux'
import { useAppSelector } from '../../Redux/Store/hooks'
import { RootState } from '../../Redux/Store/storets'
import { USER_ROLE } from '../../utils/constants'
import { AdminRoutes } from './AdminRoutes'
import OrganizationConnect from '../modules/auth/organizationConnect/OrganizationConnect'
import { OrganizationRoutes } from './OrginazationRoutes'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env

const AppRoutes: FC = () => {
  const [isAuthenticated, setisAuthenticated] = useState(false)
  const subdomainId = useAppSelector((state: RootState) => state.subdomain.data.id)

  const userData = useSelector((state: any) => state.auth.data)
  const { loginUser } = useAppSelector((state: RootState) => state.loggedInUser)
  console.log('loginUser', loginUser, 'USER_ROLE', USER_ROLE['user'])

  const checkUser = () => {
    // let userid: any = sessionStorage.getItem('user')
    let userid: any = localStorage.getItem('user')

    // console.log(userid)

    if (!userid || userid === 'undefined' && userData !== 200) {
      setisAuthenticated(false)

    } else {
      setisAuthenticated(true)
    }
  }

  useEffect(() => {
    checkUser()
  }, [])

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {/* verify invite user route  */}
          <Route path='organization-connect' element={<OrganizationConnect />} />
          {/* verify invite user route  */}

          {
            //  Newely Added::: Start
            isAuthenticated && (loginUser.user_type == USER_ROLE['admin'] && loginUser.organization) ?
              (
                <>
                  <Route path='/*' element={<OrganizationRoutes />} />
                  <Route path='auth/*' element={<Navigate to='/admin' />} />
                  <Route index element={<Navigate to='/admin' />} />
                </>
              ) :
              //  Newely Added::: End

              isAuthenticated && (loginUser.user_type == USER_ROLE['user'] || loginUser.user_type == USER_ROLE['admin']) ? (
                <>
                  <Route path='/*' element={<PrivateRoutes />} />
                  <Route index element={<Navigate to='/dashboard' />} />
                </>
              ) : isAuthenticated && (loginUser.user_type == USER_ROLE['superadmin']) ?
                (
                  <>
                    <Route path='/*' element={<AdminRoutes />} />
                    <Route path='auth/*' element={<Navigate to='/superadmin' />} />
                    <Route index element={<Navigate to='/superadmin' />} />
                  </>
                ) : (
                  <>
                    <Route path='auth/*' element={<AuthPage />} />
                    <Route path='*' element={<Navigate to='/auth' />} />
                  </>
                )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export { AppRoutes }
