import React, { Fragment } from 'react';
import ExportDataTable from './components/ExportDataTable';

type exportStatusProps = {
    workflowID: number
}


function ExportStatus({ workflowID }: exportStatusProps) {
    return (
        <Fragment>
            <ExportDataTable {...{ workflowID }} />
        </Fragment>
    )
}

export default ExportStatus
