import React, {FC, Fragment, useEffect, useState} from 'react'
import {Button, Pagination} from 'react-bootstrap'
import {ToastContainer} from 'react-toastify'
import OrganizationsTable from './components/OrganizationsTable'
import {
  getOrganizationsListAsync,
  getUserListForCreateOrganizationAsync,
} from 'src/Redux/Slices/organizations/organizationAsync'
import {PageTitle} from 'src/_metronic/layout/core'
import CreateOrganizationModal from './components/CreateOrganizationModal'
import {useAppDispatch, useAppSelector} from 'src/Redux/Store/hooks'
import {RootState} from 'src/Redux/Store/storets'
import {USER_ROLE} from 'src/utils/constants'
import {setCurrentPage} from 'src/Redux/Slices/organizations/organizationSlice'
import Loader from '../../loader/Loader'

const Organizations: FC = () => {
  const [visibleCreateModal, setVisibleCreateModal] = useState<boolean>(false)
  const [tableIndexMultiple, setTableIndexMultiple] = useState<number>(0)
  const dispatch = useAppDispatch()
  const {getUserListLoader, paginationData} = useAppSelector(
    (state: RootState) => state.organization
  )
  const {loginUser} = useAppSelector((state: RootState) => state.loggedInUser)

  useEffect(() => {
    dispatch(
      getUserListForCreateOrganizationAsync({
        param: {user_id: loginUser.id, page_size: 100, user_type: USER_ROLE['admin']},
        directPath: null,
      })
    )
    dispatch(
      getOrganizationsListAsync(
        `https://useappconnect.com:8005/organizations-list/?user_id=${loginUser.id}&page_size=5&page=1`
      )
    )
  }, [])

  const handlePagination = (paginationPath: string | null) => {
    if (!paginationPath) return
    dispatch(setCurrentPage(paginationPath))
    dispatch(getOrganizationsListAsync(paginationPath))
      .then((data) => {
        if (data.payload) {
          if (paginationData.previous == paginationPath) {
            setTableIndexMultiple(tableIndexMultiple > 0 ? tableIndexMultiple - 5 : 0)
          } else {
            setTableIndexMultiple(tableIndexMultiple + 5)
          }
        }
      })
      .catch((err) => {
        console.log('err', err)
      })
  }

  if (getUserListLoader) return <Loader />

  return (
    <>
      <PageTitle breadcrumbs={[]}>Organizations</PageTitle>
      <div className='card h-50 p-7 mt-2'>
        <div className='d-sm-flex justify-content-between align-items-center '>
          <h4 className='fw-bolder m-0 text-dark '>Organizations</h4>
          <Button
            variant='primary'
            className='py-1 sm:mt-4 '
            size='sm'
            onClick={() => setVisibleCreateModal(true)}
          >
            <span className='d-flex flex-row'>
              <h1 className='text-white mb-0'>+</h1>
              <p className='mb-0 align-self-center ms-2'>Create Organization</p>
            </span>
          </Button>
        </div>
        <div className='separator my-2'></div>
        <div>
          <OrganizationsTable tableIndexMultiple={tableIndexMultiple} />
          <Pagination size='lg'>
            <Pagination.Prev
              active={paginationData.previous ? true : false}
              className={`bg-secondary fs-4 rounded cursor-pointer`}
              onClick={() => handlePagination(paginationData.previous)}
            />
            <Pagination.Next
              active={paginationData.next ? true : false}
              className={`bg-secondary fs-4 rounded cursor-pointer `}
              onClick={() => handlePagination(paginationData.next)}
            />
          </Pagination>
        </div>
        <ToastContainer />
        <CreateOrganizationModal
          key={'createOrganizationModal'}
          handleClose={() => setVisibleCreateModal(false)}
          visible={visibleCreateModal}
        />
      </div>
    </>
  )
}

export default Organizations
