import React, { Fragment, useEffect, useState } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import '../FieldMapping/UpdaeMappings/style.css'
import DataTable from 'react-data-table-component'
import { ConfirmationModal } from '../../ConfirmationModal'
import ViewMappedModal from './UpdaeMappings/UpdateMappingModal'
import { CircularLoader } from 'src/app/modules/widgets/components/CustomLoaders'
import { useAppDispatch, useAppSelector } from '../../../../../../Redux/Store/hooks'
import { emptyUpdatedMappedFields } from '../../../../../../Redux/Slices/workflowFieldMapping/FieldMappingUpdateSlice'
import { customStyles_light, customStyles_dark } from '../Logs/ColumnComponents/ColumnStyle'
import NoColumns from '../Logs/ColumnComponents/NoColumns'
import UpdateMapping from './UpdaeMappings/UpdateMapping'
import classes from './components/FieldMapping.module.css'
import { Stack } from 'react-bootstrap'


type viewMappingsProps = {
    workflowID: number
    sourceplatform_ID: any
    destinationplatform_ID: any
    createFieldMappingModal: boolean
}

function ViewMappings(props: viewMappingsProps) {
    const dispatch = useAppDispatch()
    const { workflowID, sourceplatform_ID, destinationplatform_ID, createFieldMappingModal } = props
    const [mappingID, setMappingID] = useState<number>()
    const [modalShow, setModalShow] = useState(false)
    const [deleteItemID, setDeleteItemID] = useState<number>(0)
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [editButtonID, setEditButtonID] = useState('')
    const [deleteButtonID, setDeleteButtonID] = useState('')
    const themeMode = localStorage.getItem('kt_theme_mode_value')
    const { deleteLoader, getWorkflowWiseMapping, createFieldMapping, deleteMappedData } = useAppSelector(
        (state: any) => state.MappedFields
    )
    const { updateLoader, updateWorkflowFieldMapping } = useAppSelector(
        (state: any) => state.updatedMappings
    )

    // React Data Table Section:::
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [totalRows, setTotalRows] = useState(0) //It is count of total number of data
    const [perPage, setPerPage] = useState(10) // It is each page display data limit
    const [currentPage, setCurrentPage] = useState(1) // It is current displaying on UI

    // --------------------------------------------------------------First Time Data Calling On Table Start
    const fetchUsers = async (page: any) => {
        setLoading(true)
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/workflowfield-mappingdata?workflow_id=${workflowID}&page=${page}&limit=${perPage}`
            )
            setData(response?.data?.data)
            setTotalRows(response?.data?.count)
            setCurrentPage(page)
            setLoading(false)
        }
        catch (error) { console.log('Somthing Went Wrong', error) }
        finally { setLoading(false) }
    }
    useEffect(() => {
        if (workflowID) {
            fetchUsers(1) // fetch page 1 of users
            dispatch(emptyUpdatedMappedFields())
        }
    }, [workflowID, createFieldMapping, updateWorkflowFieldMapping?.data])
    // --------------------------------------------------------------First Time Data Calling On Table End
    // ---------------------------------------------------------------------------Pagination Section Start
    const handlePageChange = (page: any) => {
        fetchUsers(page) // Page Number. i.e.1/2/3
    }

    const handlePerRowsChange = async (newPerPage: any, page: any) => {
        setLoading(true)
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/workflowfield-mappingdata?workflow_id=${workflowID}&page=${page}&limit=${newPerPage}`
            )
            setData(response?.data)
            setPerPage(newPerPage)
            setCurrentPage(page)
            setLoading(false)
        }
        catch (error) { console.log('Somthing Went Wrong', error) }
        finally { setLoading(false) }
    }
    // ---------------------------------------------------------------------------Pagination Section End

    const handleEdit = (id: number) => {
        setMappingID(id)
        setModalShow(true)
    }
    const handleDeleteEvent = (id: number) => {
        setDeleteItemID(id)
        setShowConfirmationModal(!showConfirmationModal)
    }
    const handleDelete = async () => {
        try {
            const response = await axios.delete(
                `${process.env.REACT_APP_API_URL}/workflowfieldmapping/delete/${deleteItemID}/`
            )
            if (response.status === 204) {
                toast.success('Mapping Deleted Successfully')
            }
        }
        catch (error) { toast.error(`${error}`) }
        finally { fetchUsers(1) }
    }

    const columnData: any[] = [
        // { name: '', selector: (row: any) => null, width: '10px' }, // Dummy Space
        { name: 'Field 01', selector: (row: any) => row?.source_field?.display_name },
        {
            name: 'Field 02',
            selector: (row: any) => row?.destination_field?.display_name,
            sortable: false,
        },
        {
            name: 'Edit',
            cell: (row: any) => (
                <div
                    onClick={() => handleEdit(row?.id)}
                    style={{ cursor: 'pointer' }}
                    onMouseEnter={() => setEditButtonID(row?.id)}
                    onMouseLeave={() => setEditButtonID('')}
                >
                    <i
                        className='bi bi-pencil-square fs-4'
                        style={{ color: row?.id == editButtonID ? '#3E97FF' : '' }}
                    ></i>
                </div>
            ),
            sortable: false,
            width: '150px',
        },
        {
            name: 'Delete',
            cell: (row: any) => (
                <div
                    onClick={() => handleDeleteEvent(row?.id)}
                    style={{ cursor: 'pointer' }}
                    onMouseEnter={() => setDeleteButtonID(row?.id)}
                    onMouseLeave={() => setDeleteButtonID('')}
                >
                    <i
                        className='bi bi-trash-fill fs-4'
                        style={{ color: row?.id == deleteButtonID ? 'red' : '' }}
                    ></i>
                </div>
            ),
            sortable: false,
        },
    ]

    return (
        <Fragment>
            {/* {!createFieldMappingModal && ( */}
            {
                loading ? (
                    <Stack className={classes.loader}>
                        <CircularLoader height='70px' width='70px' marginTop='30px' marginBottom='20px' borderWidth='4px' />
                    </Stack>
                )
                    :
                    (
                        <DataTable
                            // title='Field Mapping List'
                            columns={columnData as []}
                            data={data}
                            progressPending={loading}
                            // progressComponent={!createFieldMappingModal &&
                            //     <CircularLoader height='70px' width='70px' marginTop='30px' marginBottom='20px' borderWidth='4px' />}
                            pagination
                            paginationServer
                            paginationTotalRows={totalRows}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            // noDataComponent={<NoColumns />}
                            noDataComponent={<h6 style={{ color: 'black', padding: '20px' }}>No Records To Display !</h6>}
                            highlightOnHover={themeMode === 'light' ? true : false}
                            pointerOnHover
                            customStyles={themeMode === 'light' ? customStyles_light : customStyles_dark}

                        />
                    )
            }

            {/* )} */}

            {/* <ViewMappedModal
                modalShow={modalShow}
                setModalShow={setModalShow}
                mappingID={mappingID}
                setMappingID={setMappingID}
                sourceplatform_ID={sourceplatform_ID}
                destinationplatform_ID={destinationplatform_ID}
                fetchUsers={fetchUsers}
            /> */}

            <UpdateMapping
                modalShow={modalShow}
                setModalShow={setModalShow}
                mappingID={mappingID}
                setMappingID={setMappingID}
                sourceplatform_ID={sourceplatform_ID}
                destinationplatform_ID={destinationplatform_ID}
                fetchUsers={fetchUsers}

            />

            <ConfirmationModal
                icon='/media/icons/duotune/general/gen044.svg'
                title='Are you sure?'
                description='Are you sure you want to Delete!'
                onSave={handleDelete}
                showModal={showConfirmationModal}
                setShowModal={setShowConfirmationModal}
            />
        </Fragment>
    )
}

export default ViewMappings



