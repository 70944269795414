import React, { Fragment, useState } from 'react'
import UserProfile from './UserProfile/UserProfile';
import '../../../../workflow/components/WorkflowTabs/Tab.css'
import CustomTab from 'src/app/modules/workflow/components/WorkflowTabs/CustomTab'
import InviteUserPage from 'src/app/modules/invite-users/InviteUserPage';

const tabs = [
    { id: 1, lable: 'User Profile' },
    { id: 2, lable: 'Invited Users' },
]

function UserManagementTab() {
    const [activeTab, setActiveTab] = useState(tabs[0]?.id)

    const renderPage = () => {
        if (activeTab === 1) {
            return <UserProfile />
        } else if (activeTab === 2) {
            return <InviteUserPage />
        } else { return }
    };

    const handleTabClick = (tabId: number) => {
        setActiveTab(tabId);
    };
    return (
        <Fragment>
            <div className='tab-group'>
                {
                    tabs.map((tab) => {
                        return (
                            <CustomTab
                                key={tab.id}
                                label={tab.lable}
                                active={tab.id === activeTab}
                                onClick={() => handleTabClick(tab.id)}
                            />
                        )
                    })
                }
            </div>
            <div>{renderPage()}</div>
        </Fragment>
    )
}

export default UserManagementTab;
