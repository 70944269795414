import React, { Fragment, useEffect, useState } from 'react'
import ChildComponents from '../sub_settings/ChildComponents'
import { Container, Form, Button, Stack } from 'react-bootstrap'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import classes from '../CreateWorkflowCSS.module.css'
import Select from 'react-select'


type inputComponentProps = {
    applySettings: boolean
    display_name: string
    is_required: boolean
    settingOptions: any
    api_name: string
    childData: any
    dataCollectorFun: (data: {
        name: string
        value: string | boolean
        child: { name: string; value: string | number | boolean }[]
    }) => void
    shortCode: string[] | []
    displayShortcodes: boolean
    description: string

}
type childArrayProps = {
    name: string
    value: string | number | boolean
}

type multiselectDropdownProps = {
    value: string
    label: string
}

// Boolean Component
export function BooleanComponent(props: inputComponentProps) {
    const {
        api_name,
        display_name,
        is_required,
        childData,
        dataCollectorFun,
        settingOptions,
        applySettings,
        shortCode,
        displayShortcodes,
        description
    } = props
    const [isSwitchOn, setIsSwitchOn] = useState<boolean>(is_required)
    const [collectedChildData, setCollectedChildData] = useState<childArrayProps[]>([])
    const [isShortCodeVisible, setShortCodeVisible] = useState(false)
    const [copyMarkup, setCopyMarkup] = useState(false)
    const [buttonString, setButtonString] = useState('')
    const clildDataCollectionFun = (child_data: { name: string; value: string | number | boolean }) => {
        setCollectedChildData((prevValues) => {
            const index = prevValues.findIndex((item) => item?.name == child_data?.name)
            if (index === -1) {
                return [...prevValues, child_data]
            }
            if (index !== -1) {
                let updatedArray = [...prevValues]
                updatedArray[index] = { name: child_data?.name, value: child_data?.value }
                return updatedArray
            } else {
                return []
            }
        })
    }

    useEffect(() => {
        dataCollectorFun({
            name: api_name,
            value: isSwitchOn ? 'True' : 'False',
            child: [...collectedChildData],
        })
    }, [isSwitchOn, collectedChildData])

    const handleCopyMarkup = (data: string) => {
        setCopyMarkup(true)
        setButtonString(data)

        setTimeout(() => {
            setCopyMarkup(false)
            setButtonString('')
        }, 1000);
    }

    return (
        <Fragment>
            <Form className='d-flex justify-content-start align-items-baseline gap-3'>
                <Form.Label className='fs-6 text-muted text-hover-primary'>{display_name}</Form.Label>
                <Form.Check
                    type='switch'
                    className='mt-7'
                    id='custom-switch'
                    // label={display_name}
                    checked={isSwitchOn}
                    onChange={() => setIsSwitchOn(!isSwitchOn)}
                />
                {/* Copy To CLipboard Logic--------------------------------------------------- */}
                <Stack className={classes.shortCodeDisplayContainer}>
                    <p className='text-muted text-hover-primary fw-bold mt-1 cursor-pointer'
                        onClick={() => setShortCodeVisible(!isShortCodeVisible)}
                    >
                        {displayShortcodes ? 'View Short Codes' : null}
                    </p>
                </Stack>
                {
                    isShortCodeVisible ? (
                        <Stack className={classes.shortCodes}>
                            {
                                shortCode?.length ? shortCode.map((ele: string) => {
                                    return (
                                        <Fragment key={ele}>
                                            <CopyToClipboard text={ele} >
                                                <Button
                                                    key={ele}
                                                    size='sm'
                                                    variant={(copyMarkup && buttonString === ele) ? 'outline-success' : 'outline-primary'}
                                                    className={`px-3 py-2 border border-1 ${(copyMarkup && buttonString === ele) ? 'border-success' : 'border-primary'} rounded text-hover-dark fs-sm-8 inline-block`}
                                                    onClick={() => handleCopyMarkup(ele)}
                                                >
                                                    {(copyMarkup && buttonString === ele) ? <span className='fs-sm-8'>Copied !</span> : ele}
                                                </Button>
                                            </CopyToClipboard>
                                        </Fragment>
                                    )
                                }) : null
                            }
                        </Stack>
                    ) : null
                }
                {/* Copy To CLipboard Logic--------------------------------------------------- */}
            </Form>
            {
                description &&
                <Form.Text className='ms-7'><span style={{ color: 'red', fontWeight: 'bolder' }}>*</span>{description}</Form.Text>
            }

            <Stack className={isSwitchOn ? classes.show : classes.hide}>
                {childData && childData?.length
                    ? childData.map((cc: Record<string, any>) => {
                        return (
                            <Container key={cc.id}>
                                <ChildComponents
                                    settingOptions={cc?.available_platform_setting_options}
                                    clildDataCollectionFun={clildDataCollectionFun}
                                    displayName={cc?.display_name}
                                    applySettings={applySettings}
                                    isRequired={cc?.is_required}
                                    inputType={cc?.input_type}
                                    isSwitchOn={isSwitchOn}
                                    name={cc?.name}
                                    displayShortcodes={cc?.displayShortcodes}
                                    shortCode={shortCode}
                                    description={cc?.description}
                                />
                            </Container>
                        )
                    })
                    : null}
            </Stack>
        </Fragment>
    )
}
// Button Component
export function ButtonComponent(props: inputComponentProps) {
    const {
        api_name,
        display_name,
        is_required,
        childData,
        dataCollectorFun,
        settingOptions,
        applySettings,
        shortCode,
        displayShortcodes
    } = props
    const [isButtonActive, setIsButtonActive] = useState<boolean>(is_required)

    useEffect(() => {
        dataCollectorFun({
            name: api_name,
            value: isButtonActive ? 'Ture' : 'False',
            child: [],
        })
    }, [isButtonActive, applySettings])
    return (
        <Fragment>
            <Button
                className='me-3 mt-5'
                size='sm'
                variant={isButtonActive ? 'success' : 'primary'}
                onClick={() => setIsButtonActive(!isButtonActive)}
            >
                {display_name}
            </Button>
        </Fragment>
    )
}
// Contact Number Component
export function ContactNumberComponent(props: inputComponentProps) {
    const {
        api_name,
        display_name,
        is_required,
        childData,
        dataCollectorFun,
        settingOptions,
        applySettings,
        shortCode,
        displayShortcodes
    } = props
    const [clientContactNumber, setClientContactNumber] = useState<string>('')
    const [isShortCodeVisible, setShortCodeVisible] = useState(false)
    const [copyMarkup, setCopyMarkup] = useState(false)
    const [buttonString, setButtonString] = useState('')

    useEffect(() => {
        dataCollectorFun({
            name: api_name,
            value: clientContactNumber,
            child: [],
        })
    }, [clientContactNumber, applySettings])

    const handleCopyMarkup = (data: string) => {
        setCopyMarkup(true)
        setButtonString(data)

        setTimeout(() => {
            setCopyMarkup(false)
            setButtonString('')
        }, 1000);
    }

    return (
        <Fragment>
            <Form.Group>
                <Form.Label>{display_name}</Form.Label>
                <Form.Control
                    type='number'
                    placeholder={`${display_name}`}
                    value={clientContactNumber}
                    onChange={(event) => setClientContactNumber(event.target.value)}
                />
                {/* Copy To CLipboard Logic--------------------------------------------------- */}
                <Stack className={classes.shortCodeDisplayContainer}>
                    <p className='text-muted text-hover-primary fw-bold mt-1 cursor-pointer'
                        onClick={() => setShortCodeVisible(!isShortCodeVisible)}
                    >
                        {displayShortcodes ? 'View Short Codes' : null}
                    </p>
                </Stack>
                {
                    isShortCodeVisible ? (
                        <Stack className={classes.shortCodes}>
                            {
                                shortCode?.length ? shortCode.map((ele: string) => {
                                    return (
                                        <Fragment key={ele}>
                                            <CopyToClipboard text={ele} >
                                                <Button
                                                    key={ele}
                                                    size='sm'
                                                    variant={(copyMarkup && buttonString === ele) ? 'outline-success' : 'outline-primary'}
                                                    className={`px-3 py-2 border border-1 ${(copyMarkup && buttonString === ele) ? 'border-success' : 'border-primary'} rounded text-hover-dark fs-sm-8 inline-block`}
                                                    onClick={() => handleCopyMarkup(ele)}
                                                >
                                                    {(copyMarkup && buttonString === ele) ? <span className='fs-sm-8'>Copied !</span> : ele}
                                                </Button>
                                            </CopyToClipboard>
                                        </Fragment>
                                    )
                                }) : null
                            }
                        </Stack>
                    ) : null
                }
                {/* Copy To CLipboard Logic--------------------------------------------------- */}
            </Form.Group>
        </Fragment>
    )
}
// Email Component
export function EmailComponent(props: inputComponentProps) {
    const {
        api_name,
        display_name,
        is_required,
        childData,
        dataCollectorFun,
        settingOptions,
        applySettings,
        shortCode,
        displayShortcodes
    } = props
    const [clientEmail, setClientEmail] = useState<string>('')
    const [isShortCodeVisible, setShortCodeVisible] = useState(false)
    const [copyMarkup, setCopyMarkup] = useState(false)
    const [buttonString, setButtonString] = useState('')

    useEffect(() => {
        dataCollectorFun({
            name: api_name,
            value: clientEmail,
            child: [],
        })
    }, [clientEmail, applySettings])

    const handleCopyMarkup = (data: string) => {
        setCopyMarkup(true)
        setButtonString(data)

        setTimeout(() => {
            setCopyMarkup(false)
            setButtonString('')
        }, 1000);
    }

    return (
        <Fragment>
            <Form.Group>
                <Form.Label className='mt-3'>{display_name}</Form.Label>
                <Form.Control
                    type='email'
                    placeholder={`${display_name}`}
                    value={clientEmail}
                    onChange={(event) => setClientEmail(event.target.value)}
                />
                {/* Copy To CLipboard Logic--------------------------------------------------- */}
                <Stack className={classes.shortCodeDisplayContainer}>
                    <p className='text-muted text-hover-primary fw-bold mt-1 cursor-pointer'
                        onClick={() => setShortCodeVisible(!isShortCodeVisible)}
                    >
                        {displayShortcodes ? 'View Short Codes' : null}
                    </p>
                </Stack>
                {
                    isShortCodeVisible ? (
                        <Stack className={classes.shortCodes}>
                            {
                                shortCode?.length ? shortCode.map((ele: string) => {
                                    return (
                                        <Fragment key={ele}>
                                            <CopyToClipboard text={ele} >
                                                <Button
                                                    key={ele}
                                                    size='sm'
                                                    variant={(copyMarkup && buttonString === ele) ? 'outline-success' : 'outline-primary'}
                                                    className={`px-3 py-2 border border-1 ${(copyMarkup && buttonString === ele) ? 'border-success' : 'border-primary'} rounded text-hover-dark fs-sm-8 inline-block`}
                                                    onClick={() => handleCopyMarkup(ele)}
                                                >
                                                    {(copyMarkup && buttonString === ele) ? <span className='fs-sm-8'>Copied !</span> : ele}
                                                </Button>
                                            </CopyToClipboard>
                                        </Fragment>
                                    )
                                }) : null
                            }
                        </Stack>
                    ) : null
                }
                {/* Copy To CLipboard Logic--------------------------------------------------- */}
            </Form.Group>
        </Fragment>
    )
}
// Image Components
export function ImageComponent(props: inputComponentProps) {
    const {
        api_name,
        display_name,
        is_required,
        childData,
        dataCollectorFun,
        settingOptions,
        applySettings,
        shortCode,
        displayShortcodes
    } = props
    const [getImages, setImages] = useState<File[]>([])

    const handleFileChange: any = (event: any) => {
        if (event.target.files) {
            const filesArray = Array.from(event.target.files)
            setImages(filesArray as any)
        }
    }

    return (
        <Fragment>
            <Form.Group controlId='formFileMultiple'>
                <Form.Label className='mt-3'>{display_name}</Form.Label>
                <Form.Control type='file' multiple onChange={handleFileChange} />
            </Form.Group>
        </Fragment>
    )
}
// Integer Component
export function IntegerComponent(props: inputComponentProps) {
    const {
        api_name,
        display_name,
        is_required,
        childData,
        dataCollectorFun,
        settingOptions,
        applySettings,
        shortCode,
        displayShortcodes,
    } = props
    const [getIntegerValue, setIntegerValue] = useState<string>('')
    const [isShortCodeVisible, setShortCodeVisible] = useState(false)
    const [copyMarkup, setCopyMarkup] = useState(false)
    const [buttonString, setButtonString] = useState('')

    useEffect(() => {
        dataCollectorFun({
            name: api_name,
            value: getIntegerValue,
            child: [],
        })
    }, [getIntegerValue, applySettings])

    const handleCopyMarkup = (data: string) => {
        setCopyMarkup(true)
        setButtonString(data)

        setTimeout(() => {
            setCopyMarkup(false)
            setButtonString('')
        }, 1000);
    }

    return (
        <Fragment>
            <Form.Group>
                <Form.Label className='mt-3'>{display_name}</Form.Label>
                <Form.Control
                    type='number'
                    placeholder={`${display_name}`}
                    value={getIntegerValue}
                    onChange={(event) => setIntegerValue(event.target.value)}
                />
            </Form.Group>
            {/* Copy To CLipboard Logic--------------------------------------------------- */}
            <Stack className={classes.shortCodeDisplayContainer}>
                <p className='text-muted text-hover-primary fw-bold mt-1 cursor-pointer'
                    onClick={() => setShortCodeVisible(!isShortCodeVisible)}
                >
                    {displayShortcodes ? 'View Short Codes' : null}
                </p>
            </Stack>
            {
                isShortCodeVisible ? (
                    <Stack className={classes.shortCodes}>
                        {
                            shortCode?.length ? shortCode.map((ele: string) => {
                                return (
                                    <Fragment key={ele}>
                                        <CopyToClipboard text={ele} >
                                            <Button
                                                key={ele}
                                                size='sm'
                                                variant={(copyMarkup && buttonString === ele) ? 'outline-success' : 'outline-primary'}
                                                className={`px-3 py-2 border border-1 ${(copyMarkup && buttonString === ele) ? 'border-success' : 'border-primary'} rounded text-hover-dark fs-sm-8 inline-block`}
                                                onClick={() => handleCopyMarkup(ele)}
                                            >
                                                {(copyMarkup && buttonString === ele) ? <span className='fs-sm-8'>Copied !</span> : ele}
                                            </Button>
                                        </CopyToClipboard>
                                    </Fragment>
                                )
                            }) : null
                        }
                    </Stack>
                ) : null
            }
            {/* Copy To CLipboard Logic--------------------------------------------------- */}
        </Fragment>
    )
}
// Multipal Select Component
export function MultipleSelectComponent(props: inputComponentProps) {
    const {
        api_name,
        display_name,
        is_required,
        childData,
        dataCollectorFun,
        settingOptions,
        applySettings,
        shortCode,
        displayShortcodes,
    } = props
    const [dropdownValues, setDropdownValues] = useState<multiselectDropdownProps[]>([])
    const [selectedOptions, setSelectedOptions] = useState<multiselectDropdownProps[]>([])
    const [selectedOptionString, setSelectedOptionString] = useState<string>('')
    useEffect(() => {
        if (settingOptions?.length) {
            let settingDropdown = settingOptions.map((item: Record<string, any>) => {
                return {
                    value: item?.api_id,
                    label: item?.display_name,
                }
            })
            setDropdownValues(settingDropdown)
        }
    }, [settingOptions])

    const handleMultiSelectChange = (selectedValues: multiselectDropdownProps[]) => {
        setSelectedOptions(selectedValues)
        let inputValue = ''
        selectedValues.map((cc, index) => {
            inputValue += cc.value
            if (index < selectedValues.length - 1) {
                inputValue += ','
            }
            setSelectedOptionString(inputValue)
        })
    }
    useEffect(() => {
        dataCollectorFun({
            name: api_name,
            value: selectedOptionString,
            child: [],
        })
    }, [is_required, applySettings, selectedOptionString])

    return (
        <Fragment>
            <Form.Label className='mt-3'>{display_name}</Form.Label>
            <Select
                name={`${display_name}`}
                placeholder={`${display_name}`}
                defaultValue={selectedOptions}
                options={dropdownValues}
                isMulti
                className='basic-multi-select'
                classNamePrefix='select'
                onChange={handleMultiSelectChange as any}
            />
        </Fragment>
    )
}
// Select Component
export function SelectComponent(props: inputComponentProps) {
    const {
        api_name,
        display_name,
        is_required,
        childData,
        dataCollectorFun,
        settingOptions,
        applySettings,
        shortCode,
        displayShortcodes,
    } = props
    const [selectdValue, setSelectedValue] = useState<string>('')

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        let selectedValue = event.target.value
        setSelectedValue(selectedValue)
        // dataCollectorFun({ name: api_name, value: selectedValue, child: [] })
    }
    useEffect(() => {
        dataCollectorFun({
            name: api_name,
            value: selectdValue,
            child: [],
        })
    }, [is_required, applySettings, selectdValue])

    return (
        <Fragment>
            <Form.Label className='mt-3'>{display_name}</Form.Label>
            <Form.Select value={selectdValue} onChange={handleChange}>
                <option>Select Option</option>
                {settingOptions?.map((items: any) => (
                    <option key={items.id} value={items?.api_id}>
                        {items?.display_name}
                    </option>
                ))}
            </Form.Select>
        </Fragment>
    )
}
// Text Area Components:
export function TextAreaComponent(props: inputComponentProps) {
    const {
        api_name,
        display_name,
        is_required,
        childData,
        dataCollectorFun,
        settingOptions,
        applySettings,
        shortCode,
        displayShortcodes,
    } = props
    const [getAddress, setAddress] = useState<string>('')
    const [isShortCodeVisible, setShortCodeVisible] = useState(false)
    const [copyMarkup, setCopyMarkup] = useState(false)
    const [buttonString, setButtonString] = useState('')

    useEffect(() => {
        dataCollectorFun({
            name: api_name,
            value: getAddress,
            child: [],
        })
    }, [getAddress, applySettings])

    const handleCopyMarkup = (data: string) => {
        setCopyMarkup(true)
        setButtonString(data)

        setTimeout(() => {
            setCopyMarkup(false)
            setButtonString('')
        }, 1000);
    }

    return (
        <Fragment>
            <Form.Group controlId='exampleForm.ControlTextarea1'>
                <Form.Label className='mt-3'>{display_name}</Form.Label>
                <Form.Control
                    value={getAddress}
                    onChange={(event) => setAddress(event.target.value)}
                    as='textarea'
                    rows={3}
                />
                {/* Copy To CLipboard Logic--------------------------------------------------- */}
                <Stack className={classes.shortCodeDisplayContainer}>
                    <p className='text-muted text-hover-primary fw-bold mt-1 cursor-pointer'
                        onClick={() => setShortCodeVisible(!isShortCodeVisible)}
                    >
                        {displayShortcodes ? 'View Short Codes' : null}
                    </p>
                </Stack>
                {
                    isShortCodeVisible ? (
                        <Stack className={classes.shortCodes}>
                            {
                                shortCode?.length ? shortCode.map((ele: string) => {
                                    return (
                                        <Fragment key={ele}>
                                            <CopyToClipboard text={ele} >
                                                <Button
                                                    key={ele}
                                                    size='sm'
                                                    variant={(copyMarkup && buttonString === ele) ? 'outline-success' : 'outline-primary'}
                                                    className={`px-3 py-2 border border-1 ${(copyMarkup && buttonString === ele) ? 'border-success' : 'border-primary'} rounded text-hover-dark fs-sm-8 inline-block`}
                                                    onClick={() => handleCopyMarkup(ele)}
                                                >
                                                    {(copyMarkup && buttonString === ele) ? <span className='fs-sm-8'>Copied !</span> : ele}
                                                </Button>
                                            </CopyToClipboard>
                                        </Fragment>
                                    )
                                }) : null
                            }
                        </Stack>
                    ) : null
                }
                {/* Copy To CLipboard Logic--------------------------------------------------- */}
            </Form.Group>
        </Fragment>
    )
}
// Text Input Components:
export function TextComponent(props: inputComponentProps) {
    const {
        api_name,
        display_name,
        is_required,
        childData,
        dataCollectorFun,
        settingOptions,
        applySettings,
        shortCode,
        displayShortcodes,
    } = props
    const [getText, setText] = useState<string>('')
    const [isShortCodeVisible, setShortCodeVisible] = useState(false)
    const [copyMarkup, setCopyMarkup] = useState(false)
    const [buttonString, setButtonString] = useState('')

    useEffect(() => {
        dataCollectorFun({
            name: api_name,
            value: getText,
            child: [],
        })
    }, [getText, applySettings])

    const handleCopyMarkup = (data: string) => {
        setCopyMarkup(true)
        setButtonString(data)

        setTimeout(() => {
            setCopyMarkup(false)
            setButtonString('')
        }, 1000);
    }

    return (
        <Fragment>
            <Form.Group>
                <Form.Label className='mt-3'>{display_name}</Form.Label>
                <Form.Control
                    type='text'
                    placeholder={display_name}
                    value={getText}
                    onChange={(event) => setText(event.target.value)}
                />
                {/* Copy To CLipboard Logic--------------------------------------------------- */}
                <Stack className={classes.shortCodeDisplayContainer}>
                    <p className='text-muted text-hover-primary fw-bold mt-1 cursor-pointer'
                        onClick={() => setShortCodeVisible(!isShortCodeVisible)}
                    >
                        {displayShortcodes ? 'View Short Codes' : null}
                    </p>
                </Stack>
                {
                    isShortCodeVisible ? (
                        <Stack className={classes.shortCodes}>
                            {
                                shortCode?.length ? shortCode.map((ele: string) => {
                                    return (
                                        <Fragment key={ele}>
                                            <CopyToClipboard text={ele} >
                                                <Button
                                                    key={ele}
                                                    size='sm'
                                                    variant={(copyMarkup && buttonString === ele) ? 'outline-success' : 'outline-primary'}
                                                    className={`px-3 py-2 border border-1 ${(copyMarkup && buttonString === ele) ? 'border-success' : 'border-primary'} rounded text-hover-dark fs-sm-8 inline-block`}
                                                    onClick={() => handleCopyMarkup(ele)}
                                                >
                                                    {(copyMarkup && buttonString === ele) ? <span className='fs-sm-8'>Copied !</span> : ele}
                                                </Button>
                                            </CopyToClipboard>
                                        </Fragment>
                                    )
                                }) : null
                            }
                        </Stack>
                    ) : null
                }
                {/* Copy To CLipboard Logic--------------------------------------------------- */}
            </Form.Group>
        </Fragment>
    )
}
