import React, { Fragment, useRef, useEffect } from 'react'
import { Form, InputGroup, Stack, Container } from 'react-bootstrap'
import { statusTypes } from '../GenerateReport/components/utils'
type searchableProps = {
    searchText: string
    searchStatus: string
    isSearchClicked: boolean
    handleSearchText: () => void
    setPerPage: (data: number) => void
    fetchUsers: (data: number) => void
    setSearchText: (data: string) => void
    setCurrentPage: (data: number) => void
    setSearchStatus: (data: string) => void
    setIsSearchClicked: (data: boolean) => void
}

function Searchable(props: searchableProps) {
    const { searchText, setSearchText, isSearchClicked,
        setIsSearchClicked, fetchUsers, handleSearchText,
        searchStatus, setSearchStatus, setPerPage, setCurrentPage
    } = props
    const onCloseAPICallRef = useRef<HTMLInputElement | null>(null)

    const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value)
        if (event.target.value !== searchText) {
            setIsSearchClicked(false)
        }
    }
    const handleClick = () => {
        setIsSearchClicked(true)
    }
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault()
            setIsSearchClicked(true)
        }
    }

    //::: When 'x' Clicked Re-Fetch Table
    useEffect(() => {
        if (onCloseAPICallRef.current?.value == '') {
            fetchUsers(1)
        }
    }, [onCloseAPICallRef.current?.value])

    const searchStyle = {
        backgroundColor: isSearchClicked ? '#9DA5BA' : '',
        fontWeight: isSearchClicked ? 'bolder' : 'normal',
    }

    useEffect(() => {
        handleSearchText()
    }, [searchText, isSearchClicked])

    return (
        <Fragment>
            <Form.Label htmlFor='inlineFormInputGroupUsername' visuallyHidden>
                Search...
            </Form.Label>
            <Form.Select
                value={searchStatus}
                onChange={(event) => {
                    setSearchStatus(event.target.value)
                    setCurrentPage(1)
                    setPerPage(10)
                }}
                style={{ width: '15%', minWidth: '150px', fontWeight: '600' }}>
                <option value="">All Status</option>
                {
                    statusTypes.map((ele) => {
                        return (<option key={ele.id} value={ele.value}>{ele.name}</option>)
                    })
                }
            </Form.Select>
            <InputGroup style={{ width: '18%', minWidth: '150px' }}>
                <Form.Control
                    type='search'
                    id='inlineFormInputGroupUsername'
                    style={{ backgroundColor: 'white' }}
                    placeholder='Search...'
                    value={searchText}
                    ref={onCloseAPICallRef}
                    onKeyDown={handleKeyDown}
                    onChange={handleTextChange}
                />
                <InputGroup.Text className='cursor-pointer' style={searchStyle} onClick={handleClick}>
                    <b className='bi bi-search text-dark'></b>
                </InputGroup.Text>
            </InputGroup>
        </Fragment>
    )
}

export default Searchable;
