import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import { connectOauthAsync, disconnectOauthAsync } from './processOauthAsync'

type processOauthSlice = {
    loading: boolean
    connectOauth: any
    disconnectOauth: any
}

const initialState: processOauthSlice = {
    loading: false,
    connectOauth: [],
    disconnectOauth: []
}

const processOauthSlice = createSlice({
    name: 'processOauthSlice',
    initialState,
    reducers: { emptyOauth: () => initialState },
    //Pending Statements:
    extraReducers: (builder) => {
        builder.addMatcher(isAnyOf(
            connectOauthAsync.pending,
            disconnectOauthAsync.pending,
        ), (state, action) => {
            state.loading = true
        })
        //Success Statements:
        builder.addMatcher(isAnyOf(connectOauthAsync.fulfilled), (state, action) => {
            state.loading = false
            state.connectOauth = action.payload
        })
        builder.addMatcher(isAnyOf(disconnectOauthAsync.fulfilled), (state, action) => {
            state.loading = false
            state.disconnectOauth = action.payload
        })

        // Reject Statements:
        builder.addMatcher(isAnyOf(
            connectOauthAsync.rejected,
        ), (state, action) => {
            state.loading = false
            state.connectOauth = action.payload
        })
        builder.addMatcher(isAnyOf(
            disconnectOauthAsync.rejected,
        ), (state, action) => {
            state.loading = false
            state.disconnectOauth = action.payload
        })
    },
})

export const { emptyOauth } = processOauthSlice.actions;
export default processOauthSlice.reducer;
