import { AxiosClient } from '../../AxiosClient/axiosClient'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const allWorkflowCombunationAsync = createAsyncThunk(
    'available-workflows/allWorkflowCombunationAsync', async (params: {} | undefined, toolkit) => {
        return await AxiosClient(
            'GET', `/available-workflows`,
            [], toolkit, params
        )
    }
)