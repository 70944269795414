import React, { Fragment, useEffect, useState } from 'react'
import clsx from 'clsx'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import { useSelector, useDispatch } from 'react-redux'
import { ChangePasswordAsync } from '../../../../Redux/Slices/credentials/credentialsAsync'
import { emptycredentials } from '../../../../Redux/Slices/credentials/credentialsSlice'

const initialValues = {
    password: '',
    confirm_password: '',
}

const forgotPasswordSchema = Yup.object().shape({
    password: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Password is required'),
    confirm_password: Yup.string()
        .label('confirm password')
        .required()
        .oneOf([Yup.ref('password'), null as any], 'Passwords must match'),
})

function ResetPassword() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const searchParam = new URLSearchParams(location.search)
    const [errorState, setErrorState] = useState('')
    const [successState, setSuccessState] = useState('')
    const uid = searchParam.get('uid')
    const token = searchParam.get('token')
    const { loading, ChangePassword } = useSelector((state: any) => state.credentials)
    const formik = useFormik({
        initialValues,
        validationSchema: forgotPasswordSchema,

        onSubmit: async (values) => {
            let payload = {
                userID: uid,
                token: token,
                password: values.password,
                confirmPassword: values.confirm_password,
            }
            dispatch(ChangePasswordAsync(payload) as any)
                .then((response: any) => {
                    setErrorState(response?.error?.message)
                    setSuccessState(response?.success?.message)
                })
        },
    })
    useEffect(() => {
        if (ChangePassword?.message || successState?.length > 0) {
            toast.success(ChangePassword?.message)
            // toast.success(successState)
            dispatch(emptycredentials())
            setTimeout(() => {
                navigate('/auth/login')
            }, 2000);
        }
        if (ChangePassword?.error || errorState?.length > 0) {
            toast.error(ChangePassword?.message)
            toast.error(errorState)
            dispatch(emptycredentials())
            setErrorState('')
            setTimeout(() => {
                navigate('/auth/login')
            }, 2000);
        }
    }, [ChangePassword, errorState])

    useEffect(() => {
        if (!uid || !token) { navigate('/auth/login') }
    }, [token, uid])

    return (
        <Fragment>
            <form
                className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                noValidate
                id='kt_login_password_reset_form'
                onSubmit={formik.handleSubmit}
            >
                <div className='text-center mb-10'>
                    {/* begin::Title */}
                    <h1 className='text-dark fw-bolder mb-3'>Reset Password</h1>
                    {/* end::Title */}

                    {/* begin::Link */}
                    <div className='text-gray-500 fw-semibold fs-6'>
                        Enter your Password & Confirm Password.
                    </div>
                    {/* end::Link */}
                </div>

                <div className='fv-row mb-8'>
                    <label className='form-label fw-bolder text-gray-900 fs-6'>Password</label>
                    <input
                        type='password'
                        placeholder=''
                        autoComplete='off'
                        {...formik.getFieldProps('password')}
                        className={clsx(
                            'form-control bg-transparent',
                            { 'is-invalid': formik.touched.password && formik.errors.password },
                            {
                                'is-valid': formik.touched.password && !formik.errors.password,
                            }
                        )}
                    />
                    {formik.touched.password && formik.errors.password && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.password}</span>
                            </div>
                        </div>
                    )}
                </div>

                <div className='fv-row mb-8'>
                    <label className='form-label fw-bolder text-gray-900 fs-6'>Confirm Password</label>
                    <input
                        type='password'
                        placeholder=''
                        autoComplete='off'
                        {...formik.getFieldProps('confirm_password')}
                        className={clsx(
                            'form-control bg-transparent',
                            { 'is-invalid': formik.touched.confirm_password && formik.errors.confirm_password },
                            {
                                'is-valid': formik.touched.confirm_password && !formik.errors.confirm_password,
                            }
                        )}
                    />
                    {formik.touched.confirm_password && formik.errors.confirm_password && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.confirm_password}</span>
                            </div>
                        </div>
                    )}
                </div>
                {/* end::Form group */}

                {/* begin::Form group */}
                <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                    <button type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4'>
                        <span className='indicator-label'>Submit</span>
                        {loading && (
                            <span className='indicator-progress'>
                                Please wait...
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                    <Link to='/auth/login'>
                        <button
                            type='button'
                            id='kt_login_password_reset_form_cancel_button'
                            className='btn btn-light'
                            disabled={formik.isSubmitting || !formik.isValid}
                        >
                            Cancel
                        </button>
                    </Link>{' '}
                </div>
                {/* end::Form group */}
                <ToastContainer />
            </form>
        </Fragment>
    )
}

export default ResetPassword
