import React, { Fragment, useState, useEffect } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import classes from './components/availableFlows.module.css'
import { Container, Row, Col, Form, Stack, Button } from 'react-bootstrap'
import { useAppDispatch, useAppSelector } from 'src/Redux/Store/hooks'
import { emptyPlatformSettings } from 'src/Redux/Slices/platformSettings/platformSettingSlice'
import { updateActionStatusAsync } from '../../../../../../Redux/Slices/workflowDetailsModal/workflowDetailModalAsync'
import { emptyworkflowDetails } from '../../../../../../Redux/Slices/workflowDetailsModal/workflowDetailModalSlice'
import {
  sourceSideSettingAsync,
  destinationSideSettingAsync,
  sourceSideShortCodeAsync,
  destinationSideShortCodeAsync,
} from 'src/Redux/Slices/platformSettings/platformSettingAsync'
// Setting And Mapping Component:::
import ViewFlowSettings from './settings/ViewFlowSettings'
import IntegrationConfigration from './IntegrationConfigration'
import { CircularLoader } from 'src/app/modules/widgets/components/CustomLoaders'
import { emptyAvailableFLowMapping } from 'src/Redux/Slices/workflowFieldMapping/AvailableFlowMappingSlice'
import {
  getDestionationMappingAsync,
  getFieldMappingByAvailableWorkflowAsync,
  getSourceMappingAsync,
} from 'src/Redux/Slices/workflowFieldMapping/AvailableFlowMappingAsync'
import { emptyMappingList } from 'src/Redux/Slices/workflowFieldMapping/MappingListSlice'
import { getAllMappingsByWorkflowIDAsync } from 'src/Redux/Slices/workflowFieldMapping/MappingListAsync'
import { ConfigurationCheck } from './components/ConfigurationCheck'
import CustomTooltip from 'src/utils/Tooltip/CustomTooltip'

type availableWorkFlowsProps = {
  workflowID: string | number
}

export type workflow_action_type = {
  id: number
  isEnabled: boolean
  platform: number
  run_order: number
  action: {
    id: number
    action_name: string
    action_type: string
  }
}
export type workflow_actionFlow = {
  id: number
  display_name: string
  isEnabled: boolean
  workflow: number
  workflow_action1: number
  workflow_action2: number
}
export type workflowDetailsProps = {
  id: number
  source_platform: { id: number; name: string; platform_info: { id: number } }
  destination_platform: { id: number; name: string; platform_info: { id: number } }
  workflow_info: number
  workflow_action: workflow_action_type[]
  workflow_action_flow: workflow_actionFlow[]
}

function AvailableWorkFlows(props: availableWorkFlowsProps) {
  const { workflowID } = props
  const dispatch = useAppDispatch()
  const { loading, updateActionStatus } = useAppSelector(
    (state: {
      workflowDetailModal: {
        loading: boolean
        updateActionStatus: { status_code: number; massage: string }
      }
    }) => state.workflowDetailModal
  )
  const [loading_WFD, setLoading_WFD] = useState<boolean>(false)
  const [workflowDetails, setworkflowDetails] = useState<workflowDetailsProps | null>(null)

  const getWorkflowActionDetails = async (id: number | string) => {
    dispatch(emptyPlatformSettings())
    dispatch(emptyAvailableFLowMapping())
    dispatch(emptyMappingList())
    setLoading_WFD(true)
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/workflow?id=${id}`)
      let sourceID = await response?.data?.data?.source_platform?.id
      let destinationID = await response?.data?.data?.destination_platform?.id
      let workflowID = await response?.data?.data?.id
      let workflow_info = await response?.data?.data?.workflow_info

      // ::: API Hit To Check If Any Mapping Exist Mapping Exist Or Not
      dispatch(getAllMappingsByWorkflowIDAsync({ workflowID: Number(id), page: 1, limit: 2 }))
      // ::: Workflow Details API
      setworkflowDetails(response?.data?.data)
      // ::: Platform Settings API
      dispatch(sourceSideSettingAsync({ platform_id: sourceID, workflow_id: workflowID }))
      dispatch(destinationSideSettingAsync({ platform_id: destinationID, workflow_id: workflowID }))
      // ::: Platform Shortcode API, Where To Get Short Code for Source Side Have Pass Destination ID And Vice Versa
      dispatch(sourceSideShortCodeAsync({ platform_id: destinationID }))
      dispatch(destinationSideShortCodeAsync({ platform_id: sourceID }))
      // ::: API For Field Mapping
      dispatch(getSourceMappingAsync({ source_platform_id: sourceID }))
      dispatch(getDestionationMappingAsync({ destination_platform_id: destinationID }))
      //:::  Getting Current Workflow Field Mapping By Which Source & Destination Mapping Will Be Filtered
      dispatch(getFieldMappingByAvailableWorkflowAsync({ workflowInfo: workflow_info }))
    } catch (error) {
      console.error('Error fetching Workflow:', error)
    } finally {
      setLoading_WFD(false)
    }
  }

  useEffect(() => {
    if (workflowID) {
      getWorkflowActionDetails(workflowID)
    }
  }, [workflowID])

  useEffect(() => {
    if (updateActionStatus.status_code === 200) {
      // toast.success(updateActionStatus?.massage)
      toast.success('Flow Updated Successfully')
    }
    dispatch(emptyworkflowDetails())
  }, [updateActionStatus])

  return (
    <Container>
      <Row>
        {loading_WFD || loading ? (
          <span className={classes.upowrdLoader}>
            {' '}
            <CircularLoader
              width='70px'
              height='70px'
              borderWidth='4px'
              marginTop='20px'
              marginBottom='20px'
            />{' '}
          </span>
        ) : null}
        {workflowDetails &&
          workflowDetails.workflow_action_flow?.map((items: workflow_actionFlow) => {
            return (
              <Col xs={12} key={items.id} style={{ display: loading_WFD ? 'none' : 'block' }}>
                <CustomSwitchComponent
                  isEnabled={items?.isEnabled}
                  displayName={items?.display_name}
                  querryID={items?.id}
                  loading={loading}
                  workflowID={items?.workflow}
                  action1={items?.workflow_action1}
                  action2={items?.workflow_action2}
                  workflow_info={workflowDetails?.workflow_info}
                  sourceID={workflowDetails?.source_platform?.id}
                  destinationID={workflowDetails?.destination_platform?.id}
                />
              </Col>
            )
          })}
      </Row>
    </Container>
  )
}
export default AvailableWorkFlows

type CustomSwitchComponentProps = {
  querryID: number
  loading: boolean
  isEnabled: boolean
  displayName: string
  workflowID: number
  action1: number
  action2: number
  sourceID: number
  destinationID: number
  workflow_info: number
}
type payloadState = {
  platform: string | number
  workflow: string | number
  settings: any
}

function CustomSwitchComponent(props: CustomSwitchComponentProps) {
  const {
    isEnabled,
    displayName,
    querryID,
    loading,
    workflowID,
    action1,
    action2,
    sourceID,
    destinationID,
    workflow_info,
  } = props
  const dispatch = useAppDispatch()
  const [isSwitchOn, setIsSwitchOn] = useState(isEnabled)
  const [onHoverID, setOnHoverID] = useState<number>()
  const [openModal, setOpenModal] = useState(false)
  const [flag, setFlag] = useState(false)
  const [checkConfiguration, setCheckConfiguration] = useState(false)
  const { mappingLoader, mappingList } = useAppSelector((state) => state.mappingList)

  let payload = {
    querryID: querryID,
    isEnabled: isSwitchOn,
  }

  const handleChangeStatus = () => {
    setIsSwitchOn((prevState) => !prevState)
    setFlag(true)
  }

  const handleconfigurationCheck = () => {
    if (mappingList?.length === 0) {
      setCheckConfiguration(true)
    }
  }

  useEffect(() => {
    if (flag) {
      dispatch(updateActionStatusAsync(payload) as any)
      setFlag(false)
    }
  }, [flag])

  return (
    <Stack
      onMouseEnter={() => setOnHoverID(querryID)}
      onMouseLeave={() => setOnHoverID(0)}
      style={{
        backgroundColor: onHoverID == querryID ? '#dbdfe993' : '',
        borderRadius: '7px',
        padding: '10px',
      }}
    >
      <div className={classes.customSwitchcomponent_main}>
        <h5
          className={classes.switchHeadings}
          style={{ color: onHoverID == querryID ? '#3e97ff' : 'hsl(224, 17%, 66%)' }}
        >
          {displayName}
        </h5>
        <span className={classes.configrationCorner}>
          <Button onClick={() => setOpenModal(true)}
            size='sm'
            variant='outline-success'
            className='px-3 py-2 border border-1 border-primary rounded bg-hover-primary text-hover-white fs-sm-8 inline-block'
          >
            <i className='bi bi-sliders fs-6 text-hover-primary cursor-pointer'></i>
            {mappingList?.length === 0 ? 'Setup Flow Configurations' : 'Update Flow Configurations'}

          </Button>
          <Form onClick={handleconfigurationCheck}>
            {/* <CustomTooltip text='Please Complete Setup Flow Configurations To Enable This Flow'
            visibility={mappingList?.length === 0}
            > */}
              <Form.Check
                type='switch'
                id='custom-switch'
                disabled={loading }
                checked={isSwitchOn}
                onChange={handleChangeStatus}
              />
            {/* </CustomTooltip> */}
          </Form>
        </span>
      </div>
      {/* <div className={isSwitchOn ? classes.show : classes.hide}>
        <ViewFlowSettings
          id={querryID}
          action1={action1}
          action2={action2}
          workflowID={workflowID}
          sourceID={sourceID}
          destinationID={destinationID}
          workflow_info={workflow_info}
          displayName={displayName}
        />
      </div> */}

      <IntegrationConfigration
        id={querryID}
        action1={action1}
        action2={action2}
        workflowID={workflowID}
        sourceID={sourceID}
        destinationID={destinationID}
        workflow_info={workflow_info}
        displayName={displayName}
        modalShow={openModal}
        setModalShow={setOpenModal}
      />

      {/* <ConfigurationCheck
        icon='/media/icons/duotune/general/gen044.svg'
        title='Integration Configuration Not Enabled'
        showModal={checkConfiguration}
        setShowModal={setCheckConfiguration}
      /> */}
    </Stack>
  )
}
