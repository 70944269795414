import React, {Fragment, useEffect, useMemo, useState} from 'react'
import {formikInitialValues, combination} from './utils'
import {FormikProps, FieldArray, FieldArrayRenderProps, ErrorMessage} from 'formik'
import {mappingData} from 'src/Redux/Slices/workflowFieldMapping/AvailableFlowMappingSlice'
import Combination_SSub from '../createMappingFields/Combination_SSub'
import Combination_DSub from '../createMappingFields/Combination_DSub'
import {ConfirmationModal} from '../../../../ConfirmationModal'
import classes from '../createMappingFields/mapping.module.css'
import {Row, Col, Button, Stack} from 'react-bootstrap'
import TextError from 'src/ErrorMessage/TextError'
import {KTSVG} from 'src/_metronic/helpers'
import {toast} from 'react-toastify'
import {Form} from 'react-bootstrap'
import axios from 'axios'

type combinationProps = {
  sourceSideField: mappingData
  destinationSideField: mappingData
  Formik: FormikProps<formikInitialValues>
  existingMappindData: any
  sourcePlatformName: string
  sourceOptionName: string
  destinationPlatformName: string
  destinationOptionName: string
}

function Combination(props: combinationProps) {
  const {
    Formik,
    sourceSideField,
    destinationSideField,
    existingMappindData,
    sourcePlatformName,
    sourceOptionName,
    destinationPlatformName,
    destinationOptionName,
  } = props
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [deleteItemID, setDeleteItemID] = useState<number | null>(null)
  const [deleteItemIndex, setDeleteItemIndex] = useState<number | null>(null)
  const [fieldArrayState, setFieldArrayState] = useState<any>()

  useEffect(() => {
    const filtered_combinationData = existingMappindData?.workflow_field_mapping_data?.filter(
      (cc: any) => cc.isDefault === false
    )

    // Finding It's Absolute Parent:
    function process(id: string, array) {
      let search_parent = [] as {parent_id: number | null; child_id: number | null}[]
      let id_string_value
      id_string_value = array?.find((item) => item?.id === id)

      array?.filter((ele) => {
        if (ele?.id === id) {
          search_parent[0] = {parent_id: ele?.parent_data, child_id: ele?.id}
        }
      })

      if (search_parent[0]?.parent_id) {
        array?.filter((ele) => {
          if (ele?.id === search_parent[0]?.parent_id && ele?.parent_data) {
            search_parent[1] = {parent_id: ele?.parent_data, child_id: ele?.id}
          }
        })
      } else {
        return id
      }

      if (search_parent[1]?.parent_id) {
        array?.filter((ele) => {
          if (ele?.id === search_parent[1]?.parent_id && ele?.parent_data) {
            search_parent[2] = {parent_id: ele?.parent_data, child_id: ele?.id}
          }
        })
      } else {
        return {
          left_field: search_parent[search_parent?.length - 1]?.parent_id,
          rigth_field: id_string_value?.api_name,
          child_created_whileMappingFormation: id,
        }
      }

      if (search_parent[2]?.parent_id) {
        array?.filter((ele) => {
          if (ele?.id === search_parent[2]?.parent_id && ele?.parent_data) {
            search_parent[3] = {parent_id: ele?.parent_data, child_id: ele?.id}
          }
        })
      } else {
        return {
          left_field: search_parent[search_parent?.length - 1]?.parent_id,
          rigth_field: id_string_value?.api_name,
          child_created_whileMappingFormation: id,
        }
      }

      if (search_parent[3]?.parent_id) {
        array?.filter((ele) => {
          if (ele?.id === search_parent[3]?.parent_id && ele?.parent_data) {
            search_parent[4] = {parent_id: ele?.parent_data, child_id: ele?.id}
          }
        })
      } else {
        return {
          left_field: search_parent[search_parent?.length - 1]?.parent_id,
          rigth_field: id_string_value?.api_name,
          child_created_whileMappingFormation: id,
        }
      }

      if (search_parent[4]?.parent_id) {
        array?.filter((ele) => {
          if (ele?.id === search_parent[4]?.parent_id && ele?.parent_data) {
            search_parent[5] = {parent_id: ele?.parent_data, child_id: ele?.id}
          }
        })
      } else {
        return {
          left_field: search_parent[search_parent?.length - 1]?.parent_id,
          rigth_field: id_string_value?.api_name,
          child_created_whileMappingFormation: id,
        }
      }

      if (search_parent[5]?.parent_id) {
        array?.filter((ele) => {
          if (ele?.id === search_parent[5]?.parent_id && ele?.parent_data) {
            search_parent[6] = {parent_id: ele?.parent_data, child_id: ele?.id}
          }
        })
      } else {
        return {
          left_field: search_parent[search_parent?.length - 1]?.parent_id,
          rigth_field: id_string_value?.api_name,
          child_created_whileMappingFormation: id,
        }
      }

      if (search_parent[6]?.parent_id) {
        array?.filter((ele) => {
          if (ele?.id === search_parent[6]?.parent_id && ele?.parent_data) {
            search_parent[7] = {parent_id: ele?.parent_data, child_id: ele?.id}
          }
        })
      } else {
        return {
          left_field: search_parent[search_parent?.length - 1]?.parent_id,
          rigth_field: id_string_value?.api_name,
          child_created_whileMappingFormation: id,
        }
      }

      let display_id = search_parent[search_parent?.length - 1]?.parent_id
      return {
        left_field: display_id,
        rigth_field: id_string_value?.api_name,
        child_created_whileMappingFormation: id,
      }
    }

    filtered_combinationData?.map((cc: any, index: number) => {
      Formik.setFieldValue(`combination.${index}.id`, cc?.id)
      Formik.setFieldValue(`combination.${index}.isDefault`, cc?.isDefault)
      // When Source & Destination Both Have Select Options
      // Formik.setFieldValue(`combination.${index}.source_platform_data`,cc?.source_platform_data?.id || null)
      Formik.setFieldValue(
        `combination.${index}.source_platform_data`,
        process(cc?.source_platform_data?.id, sourceSideField?.field_data) || null
      )
      // Formik.setFieldValue(`combination.${index}.destination_platform_data`, cc?.destination_platform_data?.id || null)
      Formik.setFieldValue(
        `combination.${index}.destination_platform_data`,
        process(cc?.destination_platform_data?.id, destinationSideField?.field_data) || null
      )
      // When Source Or Destination Or Both Have Text Field
      Formik.setFieldValue(
        `combination.${index}.source_platform_data_str`,
        cc?.source_platform_data_str || null
      )
      Formik.setFieldValue(
        `combination.${index}.destination_platform_data_str`,
        cc?.destination_platform_data_str || null
      )
      // sourceChild_array and destinationChild_array is initially empty array
      Formik.setFieldValue(`combination.${index}.sourceChild_array`, [])
      Formik.setFieldValue(`combination.${index}.destinationChild_array`, [])
    })
  }, [existingMappindData])

  function hasDuplicate(arr: combination[]) {
    const seenSourceData = new Set()
    const seenSourceDataStr = new Set()

    return arr.some((cc: combination, index: number) => {
      const sourceData = +cc.source_platform_data
      const sourceDataStr = cc.source_platform_data_str
      if (
        sourceSideField?.input_type == 'TEXT' &&
        sourceDataStr !== null &&
        seenSourceDataStr.has(sourceDataStr)
      ) {
        Formik.setFieldValue(`combination.${index}.source_platform_data`, null)
        Formik.setFieldValue(`combination.${index}.source_platform_data_str`, '')
        Formik.setFieldValue(
          `combination.${index}.destination_platform_data`,
          cc.destination_platform_data
        )
        Formik.setFieldValue(
          `combination.${index}.destination_platform_data_str`,
          cc.destination_platform_data_str
        )
        Formik.setFieldValue(`combination.${index}.isDefault`, false)
        toast.error("Field 01 Can't Be Same !")
        return
      }
      if (
        sourceSideField?.input_type == 'SELECT' &&
        sourceData !== 0 &&
        seenSourceData.has(sourceData)
      ) {
        Formik.setFieldValue(`combination.${index}.source_platform_data`, '')
        Formik.setFieldValue(`combination.${index}.source_platform_data_str`, null)
        Formik.setFieldValue(
          `combination.${index}.destination_platform_data`,
          cc.destination_platform_data
        )
        Formik.setFieldValue(
          `combination.${index}.destination_platform_data_str`,
          cc.destination_platform_data_str
        )
        Formik.setFieldValue(`combination.${index}.isDefault`, false)
        toast.error("Field 01 Can't Be Same !")
        console.log('Formik.values.combination', Formik.values.combination)
        return true
      } else {
        seenSourceData.add(sourceData)
        seenSourceDataStr.add(sourceDataStr)
        return false
      }
    })
  }
  useMemo(() => {
    hasDuplicate(Formik.values.combination)
  }, [Formik.values.combination])

  const handleDeleteEvent = (fieldArrayProps: any, index: number) => {
    // console.log('Values at index', index, fieldArrayProps.form.values.combination[index]);
    setDeleteItemID(fieldArrayProps.form.values.combination[index]?.id)
    setDeleteItemIndex(index)
    setFieldArrayState(fieldArrayProps)
    setShowConfirmationModal(!showConfirmationModal)
  }

  const handleDelete = async () => {
    fieldArrayState.remove(deleteItemIndex)
    if (deleteItemID) {
      try {
        const response = await axios.delete(
          `${process.env.REACT_APP_API_URL}/workflowfieldmappingdata/delete/${deleteItemID}`
        )
        console.log('response', response)
        if (response.status === 204) {
          toast.success('Field Deleted Successfully')
        }
      } catch (error) {
        toast.error(`${error}`)
      }
    }
    setShowConfirmationModal(!showConfirmationModal)
  }

  return (
    <Fragment>
      {sourcePlatformName !== null ? (
        <Fragment>
          {/* Map Internally Start:---------------------------------------------------------------------- */}
          <Stack className={classes.mapInternally_container}>
            <input
              type='checkbox'
              id='map_intr'
              name='map_internally'
              checked={Formik.values.map_internally}
              onChange={(event) => {
                Formik.handleChange({
                  target: {
                    name: 'map_internally',
                    value: event.target.checked,
                  },
                })
                Formik.setFieldValue('combination', [])
              }}
            />
            <label htmlFor='map_intr'>Map Internally</label>
          </Stack>
          {/* Map Internally End:------------------------------------------------------------------------ */}

          {/* Mapping Combination Start:---------------------------------------------------------------------- */}
          <FieldArray name='combination'>
            {(fieldArrayProps: FieldArrayRenderProps) => {
              const {form} = fieldArrayProps
              const {values} = form
              return (
                <Fragment>
                  <Stack className={classes.comb_btn}>
                    <Button
                      size='sm'
                      onClick={() => {
                        fieldArrayProps.push({
                          id: null,
                          isDefault: false,
                          source_platform_data: sourceSideField?.input_type === 'TEXT' ? null : '',
                          destination_platform_data:
                            destinationSideField?.input_type === 'TEXT' ? null : '',
                          source_platform_data_str: null,
                          destination_platform_data_str: null,
                          sourceChild_array: [],
                          destinationChild_array: [],
                        })
                      }}
                    >
                      Add +
                    </Button>
                  </Stack>
                  {values?.combination?.length
                    ? values?.combination?.map((comb: combination, index: number) => (
                        <Row key={index}>
                          {/* Source Side */}
                          <Col xs={12} md={6}>
                            {sourceSideField?.input_type === 'TEXT' ? (
                              <Fragment>
                                <Form.Control
                                  type='text'
                                  name={`combination.${index}.source_platform_data_str`}
                                  placeholder={`Enter ${sourcePlatformName} ${sourceOptionName} ${
                                    index + 1
                                  }`}
                                  value={Formik.values.combination[index]?.source_platform_data_str}
                                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    Formik.setFieldValue(
                                      `combination.${index}.source_platform_data_str`,
                                      event.target.value
                                    )
                                  }}
                                />
                                <ErrorMessage
                                  name={`combination.${index}.source_platform_data_str`}
                                  component={TextError}
                                />
                              </Fragment>
                            ) : (
                              <Fragment>
                                <Form.Select
                                  as='select'
                                  name={`combination.${index}.source_platform_data`}
                                  // value={Formik.values.combination[index]?.source_platform_data}
                                  onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                    let selectedValue = event.target.value
                                    Formik.setFieldValue(
                                      `combination.${index}.source_platform_data`,
                                      selectedValue
                                    )
                                    Formik.setFieldValue(
                                      `combination.${index}.sourceChild_array`,
                                      []
                                    )
                                  }}
                                  value={
                                    Formik.values.combination[index]?.source_platform_data
                                      ?.left_field
                                      ? Formik.values.combination[index]?.source_platform_data
                                          ?.left_field
                                      : Formik.values.combination[index]?.source_platform_data
                                  }
                                >
                                  <option value=''>
                                    Select {sourcePlatformName} {sourceOptionName}
                                  </option>
                                  {/* {sourceSideField?.field_data?.map((ele) => (
                                                        ele.parent_data === null &&
                                                        <option key={ele.id} value={ele.id}>
                                                            {ele.api_name}
                                                        </option>
                                                    ))} */}
                                  {sourceSideField?.field_data?.map((items: any) => {
                                    if (items?.parent_data == null) {
                                      const isSelected =
                                        items?.id ===
                                        Formik.values.combination[index]?.source_platform_data
                                          ?.left_field

                                      return (
                                        <option key={items.id} value={items?.id}>
                                          {items?.api_name}
                                          {isSelected && ( // Render if this option is selected
                                            <>
                                              &nbsp;&nbsp;-&nbsp;&nbsp;
                                              {Formik.values.combination[index]
                                                ?.source_platform_data?.rigth_field ?? ''}
                                            </>
                                          )}
                                        </option>
                                      )
                                    }
                                    return null // If not a valid option, return null
                                  })}
                                </Form.Select>
                                <Combination_SSub
                                  index={index}
                                  Formik={Formik}
                                  dropdownData={sourceSideField?.field_data}
                                  platformName={sourceSideField?.display_name}
                                  parentID={Formik.values.combination[index]?.source_platform_data}
                                  fieldName='sourceChild_array'
                                />
                                <ErrorMessage
                                  name={`combination.${index}.source_platform_data`}
                                  component={TextError}
                                />
                              </Fragment>
                            )}
                          </Col>

                          {/* Destination Side */}
                          <Col xs={12} md={6}>
                            {destinationSideField?.input_type === 'TEXT' ? (
                              <Fragment>
                                <Form.Control
                                  type='text'
                                  name={`combination.${index}.destination_platform_data_str`}
                                  placeholder={`Enter ${destinationPlatformName} ${destinationOptionName} ${
                                    index + 1
                                  }`}
                                  value={
                                    Formik.values.combination[index]?.destination_platform_data_str
                                  }
                                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    Formik.setFieldValue(
                                      `combination.${index}.destination_platform_data_str`,
                                      event.target.value
                                    )
                                  }}
                                />
                                <ErrorMessage
                                  name={`combination.${index}.destination_platform_data_str`}
                                  component={TextError}
                                />
                                 <Stack
                                  style={{cursor: 'pointer'}}
                                  onClick={() => {
                                    // fieldArrayProps.remove(index)
                                    handleDeleteEvent(fieldArrayProps, index)
                                  }}
                                  className={classes.comb_cancel}
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/abstract/abs012.svg'
                                    className=' svg-icon-2x text-danger'
                                  />
                                </Stack>
                              </Fragment>
                            ) : (
                              <Fragment>
                                <Form.Select
                                  as='select'
                                  name={`combination.${index}.destination_platform_data`}
                                  // value={Formik.values.combination[index]?.destination_platform_data}
                                  onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                    let selectedValue = event.target.value
                                    Formik.setFieldValue(
                                      `combination.${index}.destination_platform_data`,
                                      selectedValue
                                    )
                                    Formik.setFieldValue(
                                      `combination.${index}.destinationChild_array`,
                                      []
                                    )
                                  }}
                                  value={
                                    Formik.values.combination[index]?.destination_platform_data
                                      ?.left_field
                                      ? Formik.values.combination[index]?.destination_platform_data
                                          ?.left_field
                                      : Formik.values.combination[index]?.destination_platform_data
                                  }
                                >
                                  <option value=''>
                                    Select {destinationPlatformName} {destinationOptionName}
                                  </option>
                                  {/* {destinationSideField?.field_data?.map((ele) => (
                                                        ele?.parent_data === null &&
                                                        <option key={ele.id} value={ele.id}>
                                                            {ele.api_name}
                                                        </option>
                                                    ))} */}
                                  {destinationSideField?.field_data?.map((items: any) => {
                                    if (items?.parent_data == null) {
                                      const isSelected =
                                        items?.id ===
                                        Formik.values.combination[index]?.destination_platform_data
                                          ?.left_field

                                      return (
                                        <option key={items.id} value={items?.id}>
                                          {items?.api_name}
                                          {isSelected && ( // Render if this option is selected
                                            <>
                                              &nbsp;&nbsp;-&nbsp;&nbsp;
                                              {Formik.values.combination[index]
                                                ?.destination_platform_data?.rigth_field ?? ''}
                                            </>
                                          )}
                                        </option>
                                      )
                                    }
                                    return null // If not a valid option, return null
                                  })}
                                </Form.Select>
                                <Combination_DSub
                                  index={index}
                                  Formik={Formik}
                                  dropdownData={destinationSideField?.field_data}
                                  platformName={destinationSideField?.display_name}
                                  parentID={
                                    Formik.values.combination[index]?.destination_platform_data
                                  }
                                  fieldName='destinationChild_array'
                                />
                                <ErrorMessage
                                  name={`combination.${index}.destination_platform_data`}
                                  component={TextError}
                                />

                                <Stack
                                  style={{cursor: 'pointer'}}
                                  onClick={() => {
                                    // fieldArrayProps.remove(index)
                                    handleDeleteEvent(fieldArrayProps, index)
                                  }}
                                  className={classes.comb_cancel}
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/abstract/abs012.svg'
                                    className=' svg-icon-2x text-danger'
                                  />
                                </Stack>
                              </Fragment>
                            )}
                          </Col>
                        </Row>
                      ))
                    : null}
                </Fragment>
              )
            }}
          </FieldArray>
          {/* Mapping Combination End:------------------------------------------------------------------------ */}
        </Fragment>
      ) : null}

      <ConfirmationModal
        icon='/media/icons/duotune/general/gen044.svg'
        title='Are you sure?'
        description='Are you sure you want to Delete!'
        onSave={handleDelete}
        showModal={showConfirmationModal}
        setShowModal={setShowConfirmationModal}
      />
    </Fragment>
  )
}

export default Combination
