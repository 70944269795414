import React, {FC, useEffect, useState, Fragment} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {ToastContainer, toast} from 'react-toastify'
import Loader from '../loader/Loader'
import {Button} from 'react-bootstrap'
import AddPlatFormModal from './components/AddPlatFormModal'
import {
  getAvailablePlatformsAsync,
  getPlatformDetailByIdAsync,
} from '../../../Redux/Slices/platform/platformAsync'
import {useAppDispatch, useAppSelector} from '../../../Redux/Store/hooks'
import {RootState} from '../../../Redux/Store/storets'
import EmptyItemTemplate from '../../../utils/EmptyItemTemplate'
import PlatformDetailModal from './components/PlatformDetailModal'
import PlatformCard from './components/PlatformCard'

const AdminPlatformPage: FC = () => {
  const dispatch = useAppDispatch()
  const [addPlatformModalVisible, setAddPlatformModalVisible] = useState<boolean>(false)
  const [viewPlatform, setViewPlatform] = useState<boolean>(false)
  const [platformId, setPlatformId] = useState<number | null>(null)

  const {getPlatformLoader, platforms} = useAppSelector(
    (state: RootState) => state.platformCombination
  )

  const fetchAvailablePlatformGetApi = () => {
    dispatch(getAvailablePlatformsAsync())
  }

  useEffect(() => {
    fetchAvailablePlatformGetApi()
  }, [])

  if (getPlatformLoader) return <Loader />

  return (
    <Fragment>
      <PageTitle breadcrumbs={[]}>Available Platform</PageTitle>

      <div className='card p-7'>
        <div className='d-flex justify-content-between align-items-center '>
          <h4 className='fw-bolder  text-dark'>Available Platform</h4>
          <Button
            variant='primary'
            className='py-1'
            size='sm'
            onClick={() => setAddPlatformModalVisible(true)}
          >
            <span className='d-flex flex-row align-items-center'>
              <h1 className='text-white mb-0'>+</h1>
              <p className='mb-0 align-self-center ms-2'>Add Platform</p>
            </span>
          </Button>
        </div>
        <div className='separator my-7'></div>

        <div className='row g-5 g-xl-8 my-2 '>
          {platforms.length ? (
            platforms?.map((item: any) => {
              return (
                <div className='col-sm-6 col-lg-4 col-xl-3 m-0' key={item?.id} onClick={() => null}>
                  <PlatformCard
                    className={`card-xl-stretch mb-5 mb-xl-8 bg-light-info bg-hover-secondary cursor-pointer`}
                    Logo={item?.logo_ref}
                    display_name={item?.display_name}
                    type={item?.type}
                    isActive={null}
                    showActiveStatus={false}
                    handleOnClick={() => {
                      setViewPlatform(true)
                      setPlatformId(item?.id)
                      dispatch(getPlatformDetailByIdAsync({platformId: item?.id}))
                    }}
                  />
                </div>
              )
            })
          ) : (
            <EmptyItemTemplate
              title='No Platform available yet'
              subtitle='Add a platform from above button'
            />
          )}
        </div>
      </div>
      <AddPlatFormModal
        key={'addPlatformModal'}
        visible={addPlatformModalVisible}
        handleClose={() => setAddPlatformModalVisible(false)}
      />
      <PlatformDetailModal
        visible={viewPlatform}
        handleClose={() => setViewPlatform(false)}
        available_platform={platformId}
      />
    </Fragment>
  )
}

export default AdminPlatformPage
