import {createSlice, isAnyOf} from '@reduxjs/toolkit'
import {
  createOrganizationAsync,
  getUserListForCreateOrganizationAsync,
  getOrganizationsListAsync,
  getOrganizationsListWithoutLoadingAsync,
} from './organizationAsync'
import {INITIAL_STATE_OF_ORGANIZATION_SLICE} from '../../../_interfaces/Organization.Interface'

const initialState: INITIAL_STATE_OF_ORGANIZATION_SLICE = {
  createOrganizationLoader: false,
  getUserListLoader: false, // this loader will user for getting all the data in organization page at parallel way

  //user data start..
  userList: [],
  userPaginationData: {
    count: 5,
    next: null,
    previous: null,
    currentPage: null,
    page: 1,
    page_size: 8,
  },
  //user data end..

  // organization data start..
  organizations: [],
  paginationData: {
    count: 5,
    next: null,
    previous: null,
    currentPage: null,
    page: 1,
    page_size: 5,
  },
  //organization data end..
}

const organizationSlice = createSlice({
  name: 'organizationSlice',
  initialState,
  extraReducers: (builder) => {
    // create organization start...
    builder.addMatcher(
      isAnyOf(createOrganizationAsync.pending, getOrganizationsListWithoutLoadingAsync.pending),
      (state, actions) => {
        console.log(
          'createOrganizationAsync or getOrganizationsListWithoutLoadingAsync in pending state'
        )
        state.createOrganizationLoader = true
      }
    )
    builder.addMatcher(
      isAnyOf(createOrganizationAsync.rejected, getOrganizationsListWithoutLoadingAsync.rejected),
      (state, actions) => {
        console.log(
          'createOrganizationAsync or getOrganizationsListWithoutLoadingAsync  in rejected state',
          actions
        )
        state.createOrganizationLoader = false
      }
    )
    builder.addMatcher(isAnyOf(createOrganizationAsync.fulfilled), (state, actions) => {
      console.log('createOrganizationAsync in fulfilled state', actions.payload)
      state.createOrganizationLoader = false
    })
    // create organization end...

    // get user list start ...
    builder.addMatcher(
      isAnyOf(getUserListForCreateOrganizationAsync.pending, getOrganizationsListAsync.pending),
      (state, actions) => {
        console.log(
          'getUserListForCreateOrganizationAsync and getOrganizationsListAsync in pending state'
        )
        state.getUserListLoader = true
      }
    )
    builder.addMatcher(
      isAnyOf(getUserListForCreateOrganizationAsync.rejected, getOrganizationsListAsync.rejected),
      (state, actions) => {
        console.log(
          'getUserListForCreateOrganizationAsync & getOrganizationsListAsync  in rejected state',
          actions
        )
        state.getUserListLoader = false
      }
    )
    builder.addMatcher(
      isAnyOf(getUserListForCreateOrganizationAsync.fulfilled),
      (state, actions) => {
        console.log(
          'getUserListForCreateOrganizationAsync in fulfilled state'
          // actions.payload?.results
        )
        state.userList = actions.payload.results
        state.userPaginationData = {
          ...state.userPaginationData,
          count: actions.payload.count,
          next: actions.payload.next,
          previous: actions.payload.previous,
        }
        state.getUserListLoader = false
      }
    )
    // get user list end ...

    //get organization list start...
    builder.addMatcher(isAnyOf(getOrganizationsListAsync.fulfilled), (state, actions) => {
      console.log(
        'getOrganizationsListAsync in fulfilled state'
        // actions.payload?.results
      )
      state.paginationData = {
        ...state.paginationData,
        count: actions.payload.count,
        next: actions.payload.next,
        previous: actions.payload.previous,
      }
      state.organizations = actions.payload.results
      state.getUserListLoader = false
    })
    builder.addMatcher(
      isAnyOf(getOrganizationsListWithoutLoadingAsync.fulfilled),
      (state, actions) => {
        console.log(
          'getOrganizationsListWithoutLoadingAsync in fulfilled state'
          // actions.payload?.results
        )
        state.paginationData = {
          ...state.paginationData,
          count: actions.payload.count,
          next: actions.payload.next,
          previous: actions.payload.previous,
        }
        state.organizations = actions.payload.results
        state.createOrganizationLoader = false
      }
    )
    //get organization list end...
  },
  reducers: {
    setCurrentPage: (state, actions: {payload: string}) => {
      state.paginationData.currentPage = actions.payload
    },
  },
})

export const {setCurrentPage} = organizationSlice.actions
export default organizationSlice.reducer
