import React, { Fragment, useState, useEffect, useMemo } from 'react'
import { Container, Col, Button, Form } from 'react-bootstrap'
import { toast } from 'react-toastify'
import '../UpdaeMappings/style.css'
import { DefaultMapping } from './ParentSideComponents'
import classes from '../components/FieldMapping.module.css'
import { KTSVG } from '../../../../../../../_metronic/helpers'
import TextError from '../../../../../../../ErrorMessage/TextError'
import { Field, FieldArray, ErrorMessage } from 'formik'
import ScreenWidth from '../../../../../widgets/components/ScreenWidth'

import SubCombinationSource from './SubCombinationSource'
import SubCombinationDestination from './SubCombinationDestination'

type withCombinationProps = {
  source_filteredData: any
  setFieldArrayDuplicity: (data: boolean) => void
  destination_filteredData: any
  Formik: any
  mapInternally_Status: boolean
  set_mapInternally_Status: (status: boolean) => void
}

function WithCombination(props: withCombinationProps) {
  const { source_filteredData, destination_filteredData, Formik, setFieldArrayDuplicity, mapInternally_Status, set_mapInternally_Status } = props
  const [screenWidth] = ScreenWidth()

  useEffect(() => {
    if (Formik.values.combination.length == 0) {
      Formik.setFieldValue('combination.0.source_platform_data', source_filteredData?.input_type == 'TEXT' ? null : "")
      Formik.setFieldValue('combination.0.source_platform_data_str', null)
      Formik.setFieldValue('combination.0.destination_platform_data', destination_filteredData?.input_type == 'TEXT' ? null : "")
      Formik.setFieldValue('combination.0.destination_platform_data_str', null)
      Formik.setFieldValue('combination.0.sourceChild_array', [])
      Formik.setFieldValue('combination.0.destinationChild_array', [])
      Formik.setFieldValue('combination.0.isDefault', false)
    }
  }, [])

  function hasDuplicate(arr) {
    const seenSourceData = new Set();
    const seenSourceDataStr = new Set();

    return arr.some((cc, index) => {
      const sourceData = +cc.source_platform_data;
      const sourceDataStr = cc.source_platform_data_str;
      if (source_filteredData?.input_type == "TEXT" && sourceDataStr !== null && seenSourceDataStr.has(sourceDataStr)) {
        Formik.setFieldValue(`combination.${index}.source_platform_data`, null);
        Formik.setFieldValue(`combination.${index}.source_platform_data_str`, '');
        Formik.setFieldValue(`combination.${index}.destination_platform_data`, cc.destination_platform_data);
        Formik.setFieldValue(`combination.${index}.destination_platform_data_str`, cc.destination_platform_data_str);
        Formik.setFieldValue(`combination.${index}.isDefault`, false);
        toast.error("Field 01 Can't Be Same !")
        setFieldArrayDuplicity(true)
        return;
      }
      if (source_filteredData?.input_type == "SELECT" && sourceData !== 0 && seenSourceData.has(sourceData)) {
        Formik.setFieldValue(`combination.${index}.source_platform_data`, '');
        Formik.setFieldValue(`combination.${index}.source_platform_data_str`, null);
        Formik.setFieldValue(`combination.${index}.destination_platform_data`, cc.destination_platform_data);
        Formik.setFieldValue(`combination.${index}.destination_platform_data_str`, cc.destination_platform_data_str);
        Formik.setFieldValue(`combination.${index}.isDefault`, false);
        toast.error("Field 01 Can't Be Same !")
        return true;
      }
      else {
        seenSourceData.add(sourceData);
        seenSourceDataStr.add(sourceDataStr);
        setFieldArrayDuplicity(false)
        return false;
      }
    });
  }
  useMemo(() => {
    hasDuplicate(Formik.values.combination);
  }, [Formik.values.combination]);

  return (
    <Fragment>
      <div className={classes.line_container}>
        <div className={classes.line}></div>
      </div>
      <Col xs={12}>
        <DefaultMapping
          Formik={Formik}
          source_filteredData={source_filteredData}
          destination_filteredData={destination_filteredData}
        />
      </Col>

      <div className={classes.mapInternally_container}>
        <input type='checkbox'
          id='map_intr'
          name='map_internally'
          onChange={(event) => {
            Formik.handleChange(event);
            set_mapInternally_Status(event.target.checked);
            Formik.setFieldValue('combination', []);
          }}
        />
        <label htmlFor="map_intr">Map Internally</label>
      </div>


      {
        Formik.values.map_internally === false ? (
          <div className={classes.child_div}>
            <FieldArray name='combination'>
              {(fieldArrayProps: any) => {
                const { form } = fieldArrayProps
                const { values } = form

                return (
                  <div style={{ width: '100%' }}>
                    <div id='combination_button_div'>
                      <Button
                        size='sm'
                        style={{ backgroundColor: '#487fbe' }}
                        onClick={() => {
                          fieldArrayProps.push({
                            isDefault: false,
                            source_platform_data: source_filteredData?.input_type == 'TEXT' ? null : "",
                            source_platform_data_str: null,
                            destination_platform_data: destination_filteredData?.input_type == 'TEXT' ? null : "",
                            destination_platform_data_str: null,
                            sourceChild_array: [],
                            destinationChild_array: []
                          })
                        }}
                      >
                        Add +
                      </Button>
                    </div>
                    <Container id='combination_container'>
                      {values.combination.map((comb: any, index: number) => {
                        return (
                          <Fragment>
                            <div
                              key={index}
                              id='combination_parent_div'
                              style={{
                                flexWrap: screenWidth <= 320 || screenWidth <= 500 ? 'wrap' : 'nowrap',
                              }}
                            >
                              <div id='combination_sourceField_div'>
                                {
                                  source_filteredData?.input_type == 'TEXT' ? (
                                    <Fragment>
                                      <Field
                                        placeholder='Field 01'
                                        name={`combination.${index}.source_platform_data_str`}
                                        id='combination_sourceField_str'
                                        type='text'
                                      />
                                      <ErrorMessage
                                        name={`combination.${index}.source_platform_data_str`}
                                        component={TextError}
                                      />
                                    </Fragment>

                                  ) : (
                                    <Fragment>
                                      <Field
                                        as='select'
                                        name={`combination.${index}.source_platform_data`}
                                        id='combination_sourceField'
                                        onChange={(event) => {
                                          const selectedValue = event.target.value;
                                          Formik.setFieldValue(`combination.${index}.source_platform_data`, selectedValue)
                                          Formik.setFieldValue(`combination.${index}.sourceChild_array`, [])
                                        }}
                                        value={Formik.values.combination[index]?.source_platform_data}
                                      >
                                        <option value=''>
                                          Select Field 01
                                        </option>
                                        {source_filteredData?.field_data?.map((items: any) => (
                                          items?.parent_data == null &&
                                          <option key={items.id} value={items?.id}>
                                            {items?.api_name}
                                          </option>
                                        ))}
                                      </Field>
                                      <SubCombinationSource
                                        Formik={Formik}
                                        index={index}
                                        parentID={Formik.values.combination?.[index]?.source_platform_data}
                                        data={source_filteredData?.field_data}
                                      />
                                      <ErrorMessage
                                        name={`combination.${index}.source_platform_data`}
                                        component={TextError}
                                      />
                                    </Fragment>

                                  )
                                }
                              </div>
                              <div
                                style={{
                                  display: screenWidth <= 320 || screenWidth <= 500 ? 'none' : 'block',
                                }}
                              >
                                <span className='previouslyMapped_arrow'>&rarr;</span>
                              </div>
                              <div id='combination_destinationField_div'>
                                {
                                  destination_filteredData?.input_type == 'TEXT' ? (
                                    <Fragment>
                                      <Field
                                        placeholder='Field 02'
                                        name={`combination.${index}.destination_platform_data_str`}
                                        id='combination_destinationField_str'
                                        type='text'
                                      />
                                      <ErrorMessage
                                        name={`combination.${index}.destination_platform_data_str`}
                                        component={TextError}
                                      />
                                    </Fragment>

                                  ) : (
                                    <Fragment>
                                      <Field
                                        as='select'
                                        name={`combination.${index}.destination_platform_data`}
                                        id='combination_destinationField'
                                        onChange={(event) => {
                                          const selectedValue = event.target.value;
                                          Formik.setFieldValue(`combination.${index}.destination_platform_data`, selectedValue)
                                          Formik.setFieldValue(`combination.${index}.destinationChild_array`, [])
                                        }}
                                        value={Formik.values.combination[index]?.destination_platform_data}
                                      >
                                        <option value=''>
                                          Select Field 02
                                        </option>
                                        {destination_filteredData?.field_data?.map((items: any) => (
                                          items?.parent_data == null &&
                                          <option key={items.id} value={items?.id}>
                                            {items?.api_name}
                                          </option>
                                        ))}
                                      </Field>
                                      <SubCombinationDestination
                                        Formik={Formik}
                                        index={index}
                                        parentID={Formik.values.combination?.[index]?.destination_platform_data}
                                        data={destination_filteredData?.field_data}
                                      />
                                      <ErrorMessage
                                        name={`combination.${index}.destination_platform_data`}
                                        component={TextError}
                                      />
                                    </Fragment>

                                  )
                                }


                              </div>
                              <span
                                onClick={() => {
                                  fieldArrayProps.remove(index)
                                }}
                                style={{ cursor: 'pointer' }}
                              >
                                <KTSVG
                                  path='/media/icons/duotune/abstract/abs012.svg'
                                  className=' svg-icon-2x text-danger'
                                />
                              </span>
                            </div>
                            <div style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              borderBottom: '2px solid #BDBDBD',
                              marginLeft: '30%',
                              marginRight: '30%',
                              marginTop: '12px'
                            }}>{""}</div>
                          </Fragment>
                        )
                      })}
                    </Container>
                  </div>
                )
              }}
            </FieldArray>
          </div>
        ) : null
      }


    </Fragment>
  )
}

export default WithCombination
