/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'
import {Row, Col} from 'react-bootstrap'
type Props = {
  className: string
  display_name: string
  type: string
  Logo: string
  isActive: any
  showActiveStatus: any
  handleOnClick: () => void
}

const PlatformCard: React.FC<Props> = ({
  className,
  display_name,
  type,
  Logo,
  isActive,
  showActiveStatus,
  handleOnClick,
}) => {
  
  return (
    <div className={`card position-relative  ${className}`} onClick={() => handleOnClick()}>
      {/* begin::Body */}
      <div className='card-body align-items-center p-7   '>
        {showActiveStatus ? (
          <div className='d-flex justify-content-between'>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              {isActive && (
                <div
                  className={`bg-${
                    isActive ? 'success' : 'secondary'
                  } rounded-circle border border-3 border-white h-15px w-15px`}
                ></div>
              )}
            </div>

            <div className=''></div>
          </div>
        ) : (
          ''
        )}
        <Row className=' d-flex'>
          <Col xs={6} lg={7} xxl={8}>
            <div className='d-flex flex-column flex-grow-1 py-2 py-lg-9 me-2 w-100'>
              <div
                className='fw-bolder  fs-4 mb-2 '
                style={{
                  fontWeight: 'bold',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                {display_name}
              </div>

              <span className='fw-bold fs-5' dangerouslySetInnerHTML={{__html: type}}></span>
            </div>
          </Col>
          <Col xs={6} lg={5} xxl={4} style={{display: 'flex', alignItems: 'flex-end'}}>
            <div>
              <img src={Logo} alt='' style={{width: '100%'}} />
            </div>
          </Col>
        </Row>
      </div>
      {/* end::Body */}
    </div>
  )
}

export default PlatformCard
