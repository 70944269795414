import React, { Fragment, useState, useEffect } from 'react'
import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik'
import { toast } from 'react-toastify'
import {
  FieldSelectionProps,
  formikInitialValues,
  validationSchemaAllFields,
  validationSchemaSingleField,
  validationSchema_sourceTextField,
  validationSchema_destinationTextField,
  validationSchema_source_destinationTextField,
} from './utils'
import { KTSVG } from '../../../../../../../_metronic/helpers'
import { useDispatch, useSelector } from 'react-redux'
import classes from '../components/FieldMapping.module.css'

import {
  SourceSideParentSelect,
  DestinationSideParentSelect,
  DefaultMapping,
} from './ParentSideComponents'
import { Container, Row, Col, Form as BootstrapForm, Button, Spinner } from 'react-bootstrap'
import {
  getSourcePlatformFieldsAsync,
  getDestinationPlatformFieldsAsync,
  createWorkflowFieldMappingAsync,
  getWorkflowWiseMappingAsync,
} from '../../../../../../../Redux/Slices/workflowFieldMapping/FieldMappingAsync'
import { emptyMappedFields } from '../../../../../../../Redux/Slices/workflowFieldMapping/FieldMappingSlice'
import TextError from '../../../../../../../ErrorMessage/TextError'
import WithoutCombination from './WithoutCombination'
import WithCombination from './WithCombination'
import { AnimatedLoader } from 'src/app/modules/widgets/components/CustomLoaders'


const initialValues: formikInitialValues = {
  sourceParentValue: '',
  destinationParentValue: '',
  default_sourcePlatformData: null,
  default_destinationPlatformData: '',
  default_destinationPlatformData_str: '',
  default_case_source_destination_platformData: true,
  combination: [],
  map_internally: false,
}
type filtered_value_obj = {
  isDefault: boolean;
  source_platform_data: any;
  source_platform_data_str: any;
  destination_platform_data: any;
  destination_platform_data_str: any;
}

function FieldSelection(props: FieldSelectionProps) {
  const { workflowID, sourceplatform_ID, destinationplatform_ID, setModalShow } = props
  const dispatch = useDispatch()
  const [disableFields, setDisableFields] = useState(false)
  const [conminedState, setCombinedState] = useState<any | []>([])
  const [source_filteredData, source_setFilteredData] = useState<any>()
  const [destination_filteredData, destination_setFilteredData] = useState<any>()
  const [fieldArrayDuplicity, setFieldArrayDuplicity] = useState(false)
  const [mapInternally_Status, set_mapInternally_Status] = useState(false)
  const { loading, createLoader, getSourceFields, getDestinationFields, createFieldMapping } =
    useSelector((state: any) => state.MappedFields)


  // To get all values of Source and Destination
  useEffect(() => {
    dispatch(getSourcePlatformFieldsAsync({ source_platform_id: sourceplatform_ID }) as any)
    dispatch(
      getDestinationPlatformFieldsAsync({ destination_platform_id: destinationplatform_ID }) as any
    )
  }, [sourceplatform_ID, destinationplatform_ID])

  // Combining all Source and Destination
  useEffect(() => {
    if (getSourceFields?.data && getDestinationFields?.data) {
      setCombinedState([...getSourceFields?.data, ...getDestinationFields?.data])
    }
  }, [getSourceFields, getDestinationFields])


  const onSubmit = async (values: formikInitialValues) => {
    // console.log('Formik', values)
    let defaultMapping;
    if (destination_filteredData?.input_type == "SELECT") {
      defaultMapping = {
        source_platform_data: values.default_sourcePlatformData,
        destination_platform_data: values.default_destinationPlatformData,
        destination_platform_data_str: null,
        isDefault: values.default_case_source_destination_platformData,
      }
    } if (destination_filteredData?.input_type == "TEXT") {
      defaultMapping = {
        source_platform_data: values.default_sourcePlatformData,
        destination_platform_data: null,
        destination_platform_data_str: values.default_destinationPlatformData_str, // Different Value
        isDefault: values.default_case_source_destination_platformData,
      }
    }

    let filtered_combination = [] as filtered_value_obj[];
    if (values.combination?.length) {
      values.combination.map((cc) => {
        let source_platform_data_child = '', destination_platform_data_child = ''
        if (cc?.sourceChild_array?.length) {
          // If there is any child for source side then get the last child value
          let s_child = cc.sourceChild_array.filter((ele) => ele !== null)
          let s_child_last_index = s_child[s_child?.length - 1]
          let s_child_last_index_value = s_child_last_index?.child_ID
          source_platform_data_child = s_child_last_index_value
        }
        if (cc?.destinationChild_array?.length) {
          // If there is any child for destination side then get the last child value
          let d_child = cc.destinationChild_array.filter((ele) => ele !== null)
          let d_child_last_index = d_child[d_child?.length - 1]
          let d_child_last_index_value = d_child_last_index?.child_ID
          destination_platform_data_child = d_child_last_index_value
        }
        let filtered_value_obj = {
          isDefault: false,
          source_platform_data: source_platform_data_child ? source_platform_data_child : cc.source_platform_data,
          source_platform_data_str: cc.source_platform_data_str,
          destination_platform_data: destination_platform_data_child ? destination_platform_data_child : cc.destination_platform_data,
          destination_platform_data_str: cc.destination_platform_data_str,
        }
        filtered_combination.push(filtered_value_obj)
        // console.log('values.combination', cc)
        // console.log('filtered_value_obj', filtered_value_obj)
        // console.log('source_platform_data_child', source_platform_data_child)
        // console.log('destination_platform_data_child', destination_platform_data_child)
      })
    }
    // Before Child Logic:::
    // let workflow_field_mapping_data_array = [...values.combination, defaultMapping]

    // After Child Logic
    let workflow_field_mapping_data_array = [...filtered_combination, defaultMapping]

    const payload = {
      workflow: workflowID,
      source_field: source_filteredData == undefined ? null : values.sourceParentValue,
      destination_field: values.destinationParentValue,
      isInternalMapping: values.map_internally,
      isDefault: false,
      default_value: null,
      workflow_field_mapping_data: workflow_field_mapping_data_array,
    }
    // console.log('payload', payload)
    dispatch(createWorkflowFieldMappingAsync(payload) as any)
  }

  useEffect(() => {
    if (createFieldMapping.status_code === 201) {
      toast.success(createFieldMapping.massage)
      dispatch(emptyMappedFields())
      dispatch(getWorkflowWiseMappingAsync({ workflowID: workflowID }) as any)
      setModalShow(false)
    }
    if (createFieldMapping.status_code > 300) {
      toast.error(createFieldMapping.massage)
      dispatch(emptyMappedFields())
      dispatch(getWorkflowWiseMappingAsync({ workflowID: workflowID }) as any)
      setModalShow(false)
    }
  }, [createFieldMapping])


  return (
    <Fragment>
      {loading ? (
        <div className='d-flex justify-content-center mt-10 mb-10'>
          <AnimatedLoader />
        </div>
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={source_filteredData == undefined ? validationSchemaSingleField :
            mapInternally_Status ? null :
              (source_filteredData?.input_type == "TEXT" && destination_filteredData?.input_type) == "TEXT" ?
                validationSchema_source_destinationTextField :
                source_filteredData?.input_type == "TEXT" ? validationSchema_sourceTextField :
                  destination_filteredData?.input_type == "TEXT" ? validationSchema_destinationTextField
                    : validationSchemaAllFields}
          onSubmit={onSubmit}
        >
          {(Formik) => {
            console.log('FormikValues', Formik.values)
            return (
              <Container className='px-15'>
                <Form>
                  <Row>
                    <Col xs={12} md={6}>
                      <p className='mt-3 fw-bold text-muted fs-5 text-justify text-hover-primary'>
                        Field 01
                      </p>
                      <SourceSideParentSelect
                        Formik={Formik}
                        parentData={conminedState}
                        disableFields={disableFields}
                        source_setFilteredData={source_setFilteredData}
                        sourceplatform_ID={sourceplatform_ID}
                        destinationplatform_ID={destinationplatform_ID}
                      />
                    </Col>
                    <Col xs={12} md={6}>
                      <p className='mt-3 fw-bold text-muted fs-5 text-justify text-hover-primary'>
                        Field 02
                      </p>
                      <DestinationSideParentSelect
                        Formik={Formik}
                        parentData={conminedState}
                        disableFields={disableFields}
                        destination_setFilteredData={destination_setFilteredData}
                        sourceplatform_ID={sourceplatform_ID}
                        destinationplatform_ID={destinationplatform_ID}
                      />
                    </Col>
                    <Col xs={12}>
                      <div className={disableFields ? classes.hidden : classes.visible}>
                        <Button
                          size='sm'
                          className='w-xs-100 w-md-25 mb-10'
                          style={{ backgroundColor: '#487fbe' }}
                          onClick={() => setDisableFields(true)}
                          disabled={
                            destination_filteredData == undefined
                            || Formik.values.sourceParentValue == Formik.values.destinationParentValue
                            || Formik.values.destinationParentValue == ''
                          }
                        >
                          Continue
                        </Button>
                      </div>
                    </Col>

                    {disableFields &&
                      source_filteredData !== undefined &&
                      destination_filteredData !== undefined ? (
                      <WithCombination
                        source_filteredData={source_filteredData}
                        setFieldArrayDuplicity={setFieldArrayDuplicity}
                        destination_filteredData={destination_filteredData}
                        mapInternally_Status={mapInternally_Status}
                        set_mapInternally_Status={set_mapInternally_Status}
                        Formik={Formik}
                      />
                    ) : disableFields &&
                      source_filteredData == undefined &&
                      destination_filteredData !== undefined ? (
                      <WithoutCombination
                        source_filteredData={source_filteredData}
                        destination_filteredData={destination_filteredData}
                        Formik={Formik}
                      />
                    ) : null
                    }

                    <Col xs={12}>
                      <div className={disableFields ? classes.visible : classes.hidden}>
                        <Button
                          variant='primary'
                          type='submit'
                          size='sm'
                          className='w-xs-100 w-md-25 mb-10'
                          style={{ backgroundColor: '#487fbe' }}
                          disabled={fieldArrayDuplicity}
                        >
                          {createLoader && (
                            <Spinner
                              className='me-2'
                              as='span'
                              animation='border'
                              size='sm'
                              role='status'
                              aria-hidden='true'
                            />
                          )}
                          {createLoader ? 'Creating Mapping...' : 'Create Mapping'}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Container>
            )
          }}
        </Formik>
      )}
    </Fragment>
  )
}

export default FieldSelection


