import React, { Fragment } from 'react'
import { Modal } from 'react-bootstrap'
import ViewFlowSettings from './settings/ViewFlowSettings'
import classes from './components/availableFlows.module.css'
import CloseButton from '../FieldMapping/components/CloseButton'
import { useAppDispatch, useAppSelector } from 'src/Redux/Store/hooks'
import { getAllMappingsByWorkflowIDAsync } from 'src/Redux/Slices/workflowFieldMapping/MappingListAsync'

type integartionProps = {
    modalShow: boolean
    setModalShow: (status: boolean) => void
    id: number
    action1: number
    action2: number
    workflowID: number
    workflow_info: number
    sourceID: number
    destinationID: number
    displayName: string
}


function IntegrationConfigration(props: integartionProps) {
    const dispatch = useAppDispatch()
    const {
        modalShow, setModalShow, workflow_info, workflowID, displayName, sourceID, destinationID,
        action1, action2, id
    } = props
    const { loading } = useAppSelector((state) => state.availableFlowMapping)

    return (
        <Fragment>
            <Modal
                size='xl' centered
                show={modalShow}
                onHide={() => setModalShow(true)}
                style={{ backgroundColor: '#0007' }}
            >
                <div onClick={() => {
                    dispatch(getAllMappingsByWorkflowIDAsync({ workflowID: Number(workflowID), page: 1, limit: 2 }))
                    setModalShow(false)
                }}>
                    <CloseButton />
                </div>
                <Fragment>
                    <Modal.Header className={classes.modal_header}>
                        Integration Configuration
                    </Modal.Header>
                    <Modal.Body>
                        <ViewFlowSettings
                            id={id}
                            action1={action1}
                            action2={action2}
                            workflowID={workflowID}
                            sourceID={sourceID}
                            destinationID={destinationID}
                            workflow_info={workflow_info}
                            displayName={displayName}
                        />
                    </Modal.Body>
                </Fragment>
            </Modal>
        </Fragment>
    )
}

export default IntegrationConfigration
