import React, { CSSProperties, FC } from 'react'
import { Button, Form, FormControl, InputGroup } from 'react-bootstrap'
import { toast } from 'react-toastify'

type AppSearchProps = {
  searchString: string
  setSearchString: (param: string) => void
  onSubmit: () => void
  containerStyle?: CSSProperties
  containerClass?: string
  placeholder?: string

}

const AppSearchBar: FC<AppSearchProps> = ({ searchString, setSearchString, onSubmit, containerClass, containerStyle, placeholder }) => {
  return (
    <div className={'d-flex flex-row w-sm-25 justify-content-sm-end' + " " + containerClass} style={containerStyle} >
      <Form
        action=''
        onSubmit={(ev) => {
          ev.preventDefault()
        }}
      >
        <InputGroup size='sm' className='justify-content-end'>
          <FormControl
            value={searchString}
            style={{ maxWidth: '140px' }}
            type='input'
            size='sm'
            placeholder={placeholder || 'search user..'}
            onChange={(ev) => {
              setSearchString(ev.target.value)
            }}
            enterKeyHint='search'
            className='border-right-0'
          />
          <Button
            className={
              searchString ? 'border border-secondary border-2 border-left-0 ' : 'd-none'
            }
            variant='white'
            onClick={() => setSearchString('')}
          >
            X
          </Button>
          <Button
            size='sm'
            type='submit'
            onClick={() => {
              if (!searchString) return toast.warning('No text to search yet..')
              onSubmit()
            }}
          >
            <i className="bi bi-search"></i>
          </Button>
        </InputGroup>
      </Form>
    </div>
  )
}

export default AppSearchBar