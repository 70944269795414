import { Button, Spinner } from 'react-bootstrap';
import { dateTimeFormatter } from 'src/app/modules/widgets/components/DateTimeFormater';

type rowData = {
    id: number
    type: string
    params: string
    status: string
    workflow: number
    created_at: string
    requested_at: string
    download_url: string | null
}

export type columnData = {
    name: string | React.ReactElement
    selector?: (row: rowData) => void
    cell?: (row: rowData) => void
    sortable?: boolean
    width?: string
}

export const StatusColumn = ({ handleClick, CSVLoading, CSV_fileID }): columnData[] => [
    {
        name: 'Request Date',
        selector: (row) => dateTimeFormatter(row.requested_at),
        sortable: false,
    },
    {
        name: 'Status',
        selector: (row) => row.status,
    },
    {
        name: 'Order Type',
        selector: (row) => row.type,
    },
    {
        name: 'Download',
        selector: (row) => {
            return (
                <>
                    {
                        row.status === 'FINISHED' ? (
                            <Button
                                disabled={CSVLoading}
                                size='sm'
                                variant='primary'
                                onClick={() => handleClick(row.id, row.type)}
                            >
                                {CSVLoading && CSV_fileID === row.id && (
                                    <Spinner
                                        as='span'
                                        size='sm'
                                        role='status'
                                        className='me-2'
                                        animation='border'
                                        aria-hidden='true'
                                    />
                                )}
                                {(CSVLoading && CSV_fileID === row.id) ? 'Downloading...' : 'Download'}
                            </Button>
                        ) : "Download Not Available"
                    }
                </>
            )
        },
    },
];




