import React, { useState, useEffect, useCallback, useMemo, Fragment } from 'react'
import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik'
import { Container, Stack, Row, Col, Button } from 'react-bootstrap'
import SettingDestination from './settings/SettingDestination'
import PlatformImage from './platforms_actions/PlatformImage'
import SettingSource from './settings/SettingSource'
import classes from './CreateWorkflowCSS.module.css'
import Spinner from 'react-bootstrap/Spinner'
import { initialState, oAuthValues, formInitialStateType } from './utils'
import { toast } from 'react-toastify'
import { createWorkFlowFormDataAsync } from '../../../../../Redux/Slices/workflowFormSlice/workflowFormAsync'
import { CircularLoader } from '../../../widgets/components/CustomLoaders'
import ComponentsSourceSide from './platforms_actions/ComponentsSourceSide'
import PlatformNotAvailable from './platforms_actions/PlatformNotAvailable'
import { useAppDispatch, useAppSelector } from '../../../../../Redux/Store/hooks'
import { IntegrationConfirmation } from './platforms_actions/IntegrationConfirmation'
import ComponentsDestinationSide from './platforms_actions/ComponentsDestinationSide'
import { useNavigate } from 'react-router-dom'
import PlatformConnect from './platform_connection/PlatformConnect'
import PlatformOauth from './platform_connection/PlatformOauth'
import { actionCollection, actionFlows } from 'src/Redux/Slices/stateSave/connectPlatformDataSlice'
import ActionFlowSettings from './settings/ActionFlowSettings'
import { sourceSideShortCodeAsync, destinationSideShortCodeAsync } from 'src/Redux/Slices/platformSettings/platformSettingAsync'


type createWorkFlowProps = {
  closeModal: (data: boolean) => void
  OpenModal: boolean
  sp_id: string | number
  dp_id: string | number
  sp_aid: number[] | null
  dp_aid: number[] | null
  sourceImage: string
  destinationImage: string
  destinationName: string
  description: string
  sourceName: string
  actionCollectionArray: actionCollection[]
  action_flows: actionFlows[]
  flowID: number | null
}

function CreateWorkflow(props: createWorkFlowProps) {
  const {
    closeModal,
    OpenModal,
    sp_id,
    dp_id,
    sp_aid,
    dp_aid,
    sourceImage,
    destinationImage,
    sourceName,
    destinationName,
    description,
    actionCollectionArray,
    action_flows, flowID
  } = props
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [workflowId, setWorkflowID] = useState('')
  const userId = Number(localStorage.getItem('user'))
  const [isTimeDelay, setIsTimeDelay] = useState(true)
  const [applySettings, setApplySettings] = useState(false)
  const [isConfirmation, setIsConfirmation] = useState(false)
  const { loading, createWorkFlowFormData, getPlatformSourceloader, getPlatformDestinationLoader } =
    useAppSelector((state: any) => state.workflowForm)
  // ::: Platform Connect State
  const [isPlatformConnectModalOpen, setIsPlatformConnectModalOpen] = useState<boolean>(false)
  const [isSourcePlatformConnectModalOpen, set_isSourcePlatformConnectModalOpen] = useState<boolean>(false)
  const [isDestinationPlatformConnectModalOpen, set_isDestinationPlatformConnectModalOpen] = useState<boolean>(false)
  const [onlySourceConnect, setOnlySourceConnect] = useState<boolean>(false)
  const [onlyDestinationConnect, setOnlyDestinationConnect] = useState<boolean>(false)
  const [both_SourDestConnect, setBoth_SourDestConnect] = useState<boolean>(false)
  const [iframePlatformName, setIframePlatformName] = useState<string | null>(null);
  const [iframeSrc, setIframeSrc] = useState<string | null>(null);
  const [openIframeModal, setOpenIFrameModal] = useState(false)
  const [oauth_ids, setOauth_ids] = useState<oAuthValues>({} as oAuthValues)

  // Generating Workflow ID For Both Source And Destination:::
  const onSubmit = async (values: formInitialStateType) => {
    let sourcePlatformValues = values.sourceAvailableActions.map((item: any) => {
      return { action: item, platform: values.sourcePlatform.sp_parentId, isEnabled: false }
    })
    let destinationPlatformValues = values.destinationAvailableActions.map((item: any) => {
      return { action: item, platform: values.destinationPlatform.dp_parentId, isEnabled: false }
    })

    // Combining source_actionFlow and destination_actionFlow
    let combinedActionFlow = await [...values.source_actionFlow, ...values.destination_actionFlow]
    type dataFlow = {
      display_name: string
      workflow_action_1: { action: number, platform: number }
      workflow_action_2: { action: number, platform: number }
      isEnabled: boolean
    }
    let workflow_action_flow = [] as dataFlow[];

    if (action_flows?.length) {
      for (let i = 0; i < action_flows.length; i++) {
        // let data1 = values.source_actionFlow.find((ele) => ele?.main_id === action_flows[i]?.available_workflow_action1)
        // let data2 = values.destination_actionFlow.find((ele) => ele?.main_id === action_flows[i]?.available_workflow_action2)
        let data1 = combinedActionFlow.find((ele) => ele?.main_id === action_flows[i]?.available_workflow_action1)
        let data2 = combinedActionFlow.find((ele) => ele?.main_id === action_flows[i]?.available_workflow_action2)

        let dataCollection = {
          display_name: action_flows[i]?.display_name,
          workflow_action_1: { action: Number(data1?.action_id), platform: Number(data1?.source_id) },
          workflow_action_2: { action: Number(data2?.action_id), platform: Number(data2?.source_id) },
          isEnabled: false
        }
        workflow_action_flow.push(dataCollection)
        // console.log('dataCollection', dataCollection)
      }
    }

    // console.log('action_flows', action_flows)
    // console.log('source', values.source_actionFlow)
    // console.log('destination', values.destination_actionFlow)

    const payload = {
      user: userId,
      workflow_info: flowID,
      source_platform: values.sourcePlatform.sp_parentId,
      destination_platform: values.destinationPlatform.dp_parentId,
      isEnabled: false,
      workflow_action: [...sourcePlatformValues, ...destinationPlatformValues],
      workflow_action_flow: workflow_action_flow,
    }
    // console.log('payload', payload)
    dispatch(createWorkFlowFormDataAsync(payload))
  }

  useEffect(() => {
    if (createWorkFlowFormData.status_code == 201) {
      setWorkflowID(createWorkFlowFormData?.data?.id)
    }
  }, [createWorkFlowFormData])

  useEffect(() => {
    if (isSourcePlatformConnectModalOpen && isDestinationPlatformConnectModalOpen) {
      setBoth_SourDestConnect(true)
    }
  }, [isSourcePlatformConnectModalOpen, isDestinationPlatformConnectModalOpen,])

  setTimeout(() => {
    setIsTimeDelay(false)
  }, 2000);


  // When parentObj_source or parentObj_destination Has Length === 0 
  const handleConnect = () => {
    if (isSourcePlatformConnectModalOpen === true && isDestinationPlatformConnectModalOpen === true) {
      setBoth_SourDestConnect(true)
      setOnlySourceConnect(true)
      setOnlyDestinationConnect(true)
    }
    if (isSourcePlatformConnectModalOpen === true && isDestinationPlatformConnectModalOpen === false) {
      setOnlySourceConnect(true)
    }
    if (isSourcePlatformConnectModalOpen === false && isDestinationPlatformConnectModalOpen === true) {
      setOnlyDestinationConnect(true)
    }
  }

  useMemo(() => {
    // ::: Platform Shortcode API, Where To Get Short Code for Source Side Have Pass Destination ID And Vice Versa
    if (sp_id && dp_id) {
      dispatch(sourceSideShortCodeAsync({ platform_id: Number(dp_id) }))
      dispatch(destinationSideShortCodeAsync({ platform_id: Number(sp_id) }))
    }
  }, [sp_id, dp_id])

  return (
    <Container>
      <Formik initialValues={initialState} onSubmit={onSubmit}>
        {(Formik) => {
          return (
            <Fragment>
              {(isTimeDelay) && (
                <Stack className={classes.class_loader}>
                  <CircularLoader
                    height='60px'
                    width='60px'
                    marginTop='5px'
                    marginBottom='5px'
                    borderWidth='4px'
                  />
                </Stack>
              )}
              <Form>
                <Row
                  className={
                    (isTimeDelay)
                      ? classes.hide
                      : classes.show
                  }
                >
                  <Col xs={12}>
                    <PlatformImage
                      sourceImage={sourceImage}
                      destinationImage={destinationImage}
                      sourceName={sourceName}
                      destinationName={destinationName}
                      description={description}
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <ComponentsSourceSide
                      setApplySettings={setApplySettings}
                      setWorkflowID={setWorkflowID}
                      applySettings={applySettings}
                      closeModal={closeModal}
                      workflowId={workflowId}
                      OpenModal={OpenModal}
                      Formik={Formik}
                      sp_id={sp_id}
                      sp_aid={sp_aid}
                      set_isSourcePlatformConnectModalOpen={set_isSourcePlatformConnectModalOpen}
                      set_isDestinationPlatformConnectModalOpen={set_isDestinationPlatformConnectModalOpen}
                      setIframeSrc={setIframeSrc}
                      setIframePlatformName={setIframePlatformName}
                      setOpenIFrameModal={setOpenIFrameModal}
                      setOauth_ids={setOauth_ids}
                      sourceName={sourceName}
                      actionCollectionArray={actionCollectionArray}
                      action_flows={action_flows}
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <ComponentsDestinationSide
                      Formik={Formik}
                      workflowId={workflowId}
                      dp_id={dp_id}
                      dp_aid={dp_aid}
                      isSourcePlatformConnectModalOpen={isSourcePlatformConnectModalOpen}
                      set_isDestinationPlatformConnectModalOpen={set_isDestinationPlatformConnectModalOpen}
                      isDestinationPlatformConnectModalOpen={isDestinationPlatformConnectModalOpen}
                      setIframeSrc={setIframeSrc}
                      setIframePlatformName={setIframePlatformName}
                      setOpenIFrameModal={setOpenIFrameModal}
                      setOauth_ids={setOauth_ids}
                      destinationName={destinationName}
                      actionCollectionArray={actionCollectionArray}
                      action_flows={action_flows}
                    />
                  </Col>
                  {!workflowId && (
                    <Col xs={12} className={classes.button}>
                      <Button
                        // type='submit'
                        onClick={() => {
                          Formik.values.sourcePlatform.sp_parentId == null
                            ? toast.error('Please Select Source Platform!')
                            : Formik.values.destinationPlatform.dp_parentId == null
                              ? toast.error('Please Select Destination Platform!')
                              : (iframeSrc !== null)
                                ? setOpenIFrameModal(true)
                                : onSubmit(Formik.values)
                          // : setIsConfirmation(true)
                        }}
                        size='sm'
                        style={{ marginTop: '10px' }}
                      >
                        {loading ? "Connecting..." : "Next Step"}
                      </Button>
                    </Col>
                  )}

                  <Col
                    xs={12}>
                    <ActionFlowSettings
                      Formik={Formik}
                      workflowId={workflowId}
                      applySettings={applySettings}
                      setApplySettings={setApplySettings}
                      sourceName={sourceName}
                      destinationName={destinationName}
                    />
                  </Col>
                </Row>
              </Form>

              <IntegrationConfirmation
                title='Are You Sure'
                description='You Want To Connect Integration'
                onSave={onSubmit}
                showModal={isConfirmation}
                setShowModal={setIsConfirmation}
                Formik={Formik}
                closeCreateWorkflowModal={closeModal}
              />

              {(isSourcePlatformConnectModalOpen &&
                isDestinationPlatformConnectModalOpen) ? (
                <PlatformNotAvailable
                  icon='/media/icons/duotune/general/gen044.svg'
                  platformName={`${sourceName} And ${destinationName}`}
                  onSave={handleConnect}
                  showModal={both_SourDestConnect}
                  setShowModal={setBoth_SourDestConnect}
                  set_isSourcePlatformConnectModalOpen={set_isSourcePlatformConnectModalOpen}
                  set_isDestinationPlatformConnectModalOpen={set_isDestinationPlatformConnectModalOpen}
                />
              ) : isSourcePlatformConnectModalOpen ? (
                <PlatformNotAvailable
                  icon='/media/icons/duotune/general/gen044.svg'
                  platformName={sourceName}
                  onSave={handleConnect}
                  showModal={isSourcePlatformConnectModalOpen}
                  setShowModal={set_isSourcePlatformConnectModalOpen}
                  set_isSourcePlatformConnectModalOpen={set_isSourcePlatformConnectModalOpen}
                  set_isDestinationPlatformConnectModalOpen={set_isDestinationPlatformConnectModalOpen}
                />
              ) : isDestinationPlatformConnectModalOpen ? (
                <PlatformNotAvailable
                  icon='/media/icons/duotune/general/gen044.svg'
                  platformName={destinationName}
                  onSave={handleConnect}
                  showModal={isDestinationPlatformConnectModalOpen}
                  setShowModal={set_isDestinationPlatformConnectModalOpen}
                  set_isSourcePlatformConnectModalOpen={set_isSourcePlatformConnectModalOpen}
                  set_isDestinationPlatformConnectModalOpen={set_isDestinationPlatformConnectModalOpen}
                />
              ) : null}
              {/* Platform Connect Area */}
              {
                both_SourDestConnect ?
                  (<Fragment>
                    <PlatformConnect platform_id={dp_id} modalShow={onlyDestinationConnect} setModalShow={setOnlyDestinationConnect} />
                    <PlatformConnect platform_id={sp_id} modalShow={onlySourceConnect} setModalShow={setOnlySourceConnect} />
                  </Fragment>) :
                  onlySourceConnect ? (<PlatformConnect platform_id={sp_id} modalShow={onlySourceConnect} setModalShow={setOnlySourceConnect} />) :
                    onlyDestinationConnect ? (<PlatformConnect platform_id={dp_id} modalShow={onlyDestinationConnect} setModalShow={setOnlyDestinationConnect} />)
                      : null
              }
              {
                openIframeModal ? (<PlatformOauth modalShow={openIframeModal} setModalShow={setOpenIFrameModal} iframeSrc={iframeSrc} setIframeSrc={setIframeSrc} platformName={iframePlatformName} oauth_ids={oauth_ids} closeModal={closeModal} />)
                  : null
              }
            </Fragment>
          )
        }}
      </Formik>
    </Container>
  )
}

export default CreateWorkflow;