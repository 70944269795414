import React, { Fragment } from 'react'
import { Stack, Card, Container, Row, Col } from 'react-bootstrap'
import classes from './product.module.css'
import { variants } from './utils'

type productInfoProps = {
    name: string
    type: string
    quantity: string | number | null
    api_code: string
    sku:string
    status: string
    varients: variants[]
}

function ProductInfo(props: productInfoProps) {
    const { name, type, quantity, api_code, sku, status, varients } = props
    const themeMode = localStorage.getItem('kt_theme_mode_value')
    return (
        <Fragment>
            {/* Varients Card */}
            <Card className='mt-3'>
                <Container>
                    <Card.Title className={classes.card_header}>Product Variants :</Card.Title>
                    {
                        varients?.length && varients.map((ele) => {
                            return (
                                <Stack key={ele.id}
                                    className={classes.varientContainer}
                                    style={{ backgroundColor: themeMode === 'light' ? '#f2effa' : '#2F264F' }}
                                >
                                    <Row>
                                        <Col xs={12} md={4} className={classes.productInfoContainer}>
                                            <Card.Subtitle className={classes.card_subHeading_product}>Product ID</Card.Subtitle>
                                            <Card.Subtitle className={classes.card_text_productDetail}>&nbsp;:&nbsp;{ele.product_id}</Card.Subtitle>
                                        </Col>
                                        <Col xs={12} md={4} className={classes.productInfoContainer}>
                                            <Card.Subtitle className={classes.card_subHeading_product}>Quantity</Card.Subtitle>
                                            <Card.Subtitle className={classes.card_text_productDetail}>&nbsp;:&nbsp;{ele.quantity}</Card.Subtitle>
                                        </Col>
                                        <Col xs={12} md={4} className={classes.productInfoContainer}>
                                            <Card.Subtitle className={classes.card_subHeading_product}>Price</Card.Subtitle>
                                            <Card.Subtitle className={classes.card_text_productDetail}>&nbsp;:&nbsp;{ele.price}</Card.Subtitle>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} className={classes.productInfoContainer}>
                                            <Card.Subtitle className={classes.card_subHeading_product}>Status</Card.Subtitle>
                                            <Card.Subtitle className={classes.card_text_productDetail}>&nbsp;:&nbsp;{ele.destination_sync_status}</Card.Subtitle>
                                        </Col>
                                        <Col xs={12} className={classes.productInfoContainer}>
                                            <Card.Subtitle className={classes.card_subHeading_product}>SKU</Card.Subtitle>
                                            <Card.Subtitle className={classes.card_text_productDetail}>&nbsp;:&nbsp;{ele.sku}</Card.Subtitle>
                                        </Col>
                                    </Row>
                                </Stack>
                            )
                        })
                    }
                </Container>
            </Card>

        </Fragment>
    )
}

export default ProductInfo
