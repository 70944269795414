import { AxiosClient } from '../../AxiosClient/axiosClient'
import { createAsyncThunk } from '@reduxjs/toolkit'


export const resetPasswordAsync = createAsyncThunk(
    'reset-password/resetPasswordAsync', async (payload: { email: string }, toolkit) => {
        return await AxiosClient(
            'POST', `/reset-password/`, payload, toolkit
        )
    }
)

export const ChangePasswordAsync = createAsyncThunk(
    'reset-password-verification/ChangePasswordAsync',
    async (payload: { userID: any, token: any, password: string | number, confirmPassword: string | number }, toolkit) => {
        let payloadBody = { new_password: payload.password, confirm_new_password: payload.confirmPassword }
        return await AxiosClient(
            'POST', `/reset-password-verification?uid=${payload.userID}&token=${payload.token}`, payloadBody, toolkit
        )
    }
)
export const ChangePasswordProfileAsync = createAsyncThunk(
    'user/update-password/ChangePasswordProfile',
    async (payload: { previous_password: string | number, new_password: string | number, confirm_new_password: string | number, userID: string | number }, toolkit) => {
        let payloadBody = { previous_password: payload.previous_password, new_password: payload.new_password, confirm_password: payload.confirm_new_password }
        return await AxiosClient(
            'PUT', `/user/update-password/${payload.userID}`, payloadBody, toolkit
        )
    }
)


