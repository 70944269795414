import * as Yup from 'yup'


export type initialStateTypes = {
    startDate: string
    endDate: string
    orderType: string
    destination_sync_status: string
}
export const initialState: initialStateTypes = {
    startDate: '',
    endDate: '',
    orderType: '',
    destination_sync_status: ''

}

export const validationSchems = Yup.object({
    startDate: Yup.date().required('Start Date Is Required'),
    endDate: Yup.date()
        .required('End Date Is Required')
        .min(Yup.ref('startDate'), 'End Date Must Be After Start Date'),
    orderType: Yup.string().required('Please Select Order Type'),
    destination_sync_status: Yup.string().required('Please Select Status Type'),
})

export const orderTypesParameters = [
    { id: 1, name: 'SALES ORDER', value: 'SALESORDER' },
    { id: 2, name: 'PURCHASE ORDER', value: 'PURCHASEORDER' },
    { id: 3, name: 'CREDIT NOTE', value: 'CREDITNOTE' },
    { id: 4, name: 'INVOICE', value: 'INVOICE' },
    { id: 5, name: 'EXCHANGE', value: 'EXCHANGE' },
    { id: 5, name: 'REFUND ORDER', value: 'REFUND_ORDER' },
];

export const statusTypes = [
    { id: 1, name: 'SYNCED', value: 'SYNCED' },
    { id: 1, name: 'PENDING', value: 'PENDING' },
    { id: 1, name: 'ERROR', value: 'ERROR' },
]


// SALES_ORDER = "SALESORDER"
// PURCHASE_ORDER = "PURCHASEORDER"
// CREDIT_NOTE = "CREDITNOTE"
// INVOICE = "INVOICE"
// EXCHANGE = "EXCHANGE"
// REFUND_ORDER = "REFUND_ORDER"