import React, { Fragment, useState, useEffect } from 'react';
import { FormikProps } from 'formik';
import { Form, Row, Col } from 'react-bootstrap';
import { useAppSelector } from 'src/Redux/Store/hooks';
import { formInitialStateType, workflowAction } from '../utils';
// Page Import:::
import SettingSource from './SettingSource';
import SettingDestination from './SettingDestination';

type actionFlowControllerProps = {
    // Action FLow Data
    display_name: string
    isEnabled: boolean
    action1: number
    action2: number
    // Parent Data
    Formik: FormikProps<formInitialStateType>
    workflowId: string
    applySettings: boolean
    // Setting Collector 
    setindividual_SourceSettingCollector: any
    setindividual_DestinationSettingCollector: any
    setSourceLoader: (status: boolean) => void
    setDestinationLoader: (status: boolean) => void
    sourceName: string
    destinationName: string

}
type dataCollectionArrayProps = {
    name: string
    value: string | boolean
    child: { name: string; value: string | number | boolean }[]
}

function ActionFlowController(props: actionFlowControllerProps) {
    const { Formik, applySettings, workflowId, display_name, isEnabled, action1, action2,
        setindividual_SourceSettingCollector, setindividual_DestinationSettingCollector,
        setSourceLoader, setDestinationLoader, sourceName, destinationName,
    } = props

    const [isSwitchOn, setIsSwitchOn] = useState(isEnabled)
    const [sourceAction, setSourceAction] = useState<workflowAction>({} as workflowAction)
    const [destinationAction, setDestinationAction] = useState<workflowAction>({} as workflowAction)
    const { createWorkFlowFormData } = useAppSelector((state: any) => state.workflowForm)

    // Data Collection Source side:::
    const [dataCollectionArray_source, setDataCollectionArray_source] = useState<dataCollectionArrayProps[]>([])
    const [dataCollectionArray_destination, setDataCollectionArray_destination] = useState<dataCollectionArrayProps[]>([])

    // Short Code :::
    const { sourceSideShortCode, destinationSideShortCode } = useAppSelector((state) => state.platformSettings)


    useEffect(() => {
        let allActions = [] as workflowAction[]
        let filteredAction = [] as workflowAction[]

        if (createWorkFlowFormData?.data?.workflow_action?.length) {
            allActions = createWorkFlowFormData?.data?.workflow_action
        }

        if (allActions?.length) {
            let data1 = allActions.filter((ele) => ele.id === action1)
            let data2 = allActions.filter((ele) => ele.id === action2)
            filteredAction.push(...data1, ...data2)
        }

        if (filteredAction?.length) {
            let sourceAction = filteredAction.find((ele) => ele.platform === Number(Formik.values.sourcePlatform.sp_parentId))
            let destinationAction = filteredAction.find((ele) => ele.platform === Number(Formik.values.destinationPlatform.dp_parentId))
            if (sourceAction) { setSourceAction(sourceAction) }
            if (destinationAction) { setDestinationAction(destinationAction) }
        }
    }, [createWorkFlowFormData, action1, action2])

    // Handling Source Side:::
    const dataCollectorFun_source = (data: {
        name: string
        value: string | boolean
        child: { name: string; value: string | number | boolean }[]
    }) => {
        setDataCollectionArray_source((prevValue) => {
            let index = prevValue?.findIndex((item: Record<string, any>) => item?.name === data?.name)
            if (index === -1) {
                return [...prevValue, data]
            }
            if (index !== -1) {
                let updatedArray = [...prevValue]
                updatedArray[index] = { name: data?.name, value: data?.value, child: data?.child }
                return updatedArray
            } else {
                return []
            }
        })
    }

    // Handling Destination Side:::
    const dataCollectorFun_destination = (data: {
        name: string
        value: string | boolean
        child: { name: string; value: string | number | boolean }[]
    }) => {
        setDataCollectionArray_destination((prevValue) => {
            let index = prevValue?.findIndex((item: Record<string, any>) => item?.name === data?.name)
            if (index === -1) {
                return [...prevValue, data]
            }
            if (index !== -1) {
                let updatedArray = [...prevValue]
                updatedArray[index] = { name: data?.name, value: data?.value, child: data?.child }
                return updatedArray
            } else {
                return []
            }
        })
    }

    useEffect(() => {
        setindividual_SourceSettingCollector({
            name: display_name,
            value: isSwitchOn ? "True" : "False",
            child: dataCollectionArray_source,
        })
        setindividual_DestinationSettingCollector({
            name: display_name,
            value: isSwitchOn ? "True" : "False",
            child: dataCollectionArray_destination,
        })
    }, [dataCollectionArray_source, dataCollectionArray_destination, isSwitchOn])

    return (
        <Fragment>
            <Form className='d-flex justify-content-start align-items-baseline gap-3'>
                <Form.Label className='fs-6 text-muted text-hover-primary'>{display_name}</Form.Label>
                <Form.Check
                    type='switch'
                    className='mt-7'
                    id='custom-switch'
                    // label={display_name}
                    checked={isSwitchOn}
                    onChange={() => setIsSwitchOn(!isSwitchOn)}
                />
            </Form>
            {/* <Form.Text className='ms-7'><span style={{ color: 'red', fontWeight:'bolder' }}>*</span>Looks good!</Form.Text> */}


            <Row style={{ display: isSwitchOn ? 'flex' : 'none' }}>
                <Col xs={12} md={6}>
                    <SettingSource
                        Formik={Formik}
                        applySettings={applySettings}
                        workflowId={workflowId}
                        action_id={sourceAction?.action?.id}
                        dataCollectorFun_source={dataCollectorFun_source}
                        setSourceLoader={setSourceLoader}
                        sourceName={sourceName}
                        shortCode={sourceSideShortCode}
                    />
                </Col>
                <Col xs={12} md={6}>
                    <SettingDestination
                        Formik={Formik}
                        applySettings={applySettings}
                        workflowId={workflowId}
                        action_id={destinationAction?.action?.id}
                        dataCollectorFun_destination={dataCollectorFun_destination}
                        setDestinationLoader={setDestinationLoader}
                        destinationName={destinationName}
                        shortCode={destinationSideShortCode}
                    />
                </Col>
            </Row>
        </Fragment>
    )
}

export default ActionFlowController
