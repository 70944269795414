import React, {FormEventHandler, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {ADD_PLATFORM_ACTION_DATA, AddPlatformModal} from './Interface'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import {Formik} from 'formik'
import * as Yup from 'yup'
import {useAppDispatch, useAppSelector} from '../../../../Redux/Store/hooks'
import {toast} from 'react-toastify'
import {RootState} from '../../../../Redux/Store/storets'
import {addPlatformActionAsync} from '../../../../Redux/Slices/platform/platformAsync'

const AddPlatformActionModal = ({visible, handleClose}: AddPlatformModal) => {
  const [validated, setValidated] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const {addPlatformActionLoader} = useAppSelector((state: RootState) => state.platformCombination)
  const schema = Yup.object().shape({
    action: Yup.string().required(),
    action_type: Yup.string().required(),
    action_name: Yup.string().required(),
  })

  const handleAddPlatformAction = async (
    event: React.FormEvent<HTMLFormElement>,
    values: ADD_PLATFORM_ACTION_DATA,
    resetFunction: () => void
  ) => {
    event.preventDefault()
    const form = event.currentTarget
    if (!form.checkValidity()) {
      toast.error('Form is invalid', {
        position: 'top-right',
      })
      event.stopPropagation()
    } else {
      dispatch(addPlatformActionAsync(values)).then((res) => {
        resetFunction()
        toast.success('Action created', {
          position: 'top-right',
        })
        handleClose()
        setValidated(false)
      })
    }
    setValidated(true)
  }

  const InitialValues: ADD_PLATFORM_ACTION_DATA = {
    action: '',
    action_type: '',
    action_name: '',
  }

  return (
    <Modal
      show={visible}
      onHide={() => {
        handleClose()
        setValidated(false)
      }}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      className='bg-light'
      key={'addPlatformActionModal'}
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Platform Action</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          validationSchema={schema}
          initialValues={InitialValues}
          onSubmit={() => console.log('submitted')}
        >
          {({
            values,
            handleChange,
            handleBlur,
            errors,
            touched,
            resetForm,
            setErrors,
            handleReset,
          }) => {
            return (
              <>
                <Form
                  noValidate
                  validated={validated}
                  onSubmit={(event) => handleAddPlatformAction(event, values, handleReset)}
                >
                  <Row className='mb-3'>
                    <Form.Group as={Col}>
                      <Form.Label>Action Name</Form.Label>
                      <Form.Control
                        placeholder='Action name'
                        name='action_name'
                        required
                        value={values.action_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.action_name && touched.action_name}
                        isValid={!!values.action_name && touched.action_name}
                      />
                      <Form.Control.Feedback type='invalid'>
                        Action name is required
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  <Row className='mb-3'>
                    <Form.Group as={Col} >
                      <Form.Label>Action Type</Form.Label>
                      <Form.Control
                        placeholder='Action type'
                        name='action_type'
                        required
                        value={values.action_type}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.action_type && touched.action_type}
                        isValid={!!values.action_type && touched.action_type}
                      />
                      <Form.Control.Feedback type='invalid'>
                        Action type is required
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} >
                      <Form.Label>Action</Form.Label>
                      <Form.Control
                        placeholder='Action'
                        name='action'
                        required
                        value={values.action}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.action && touched.action}
                        isValid={!!values.action && touched.action}
                      />
                      <Form.Control.Feedback type='invalid'>
                        Action is required
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  <div className='d-flex align-items-center justify-content-center mt-8'>
                    <Button
                      size='sm'
                      disabled={addPlatformActionLoader}
                      variant='primary'
                      type='submit'
                    >
                      {addPlatformActionLoader ? 'Adding...' : 'Add action'}
                    </Button>
                  </div>
                </Form>
              </>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default AddPlatformActionModal
