import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Card, Row, Col, Button } from 'react-bootstrap'
import { useAppDispatch, useAppSelector } from '../../../Redux/Store/hooks'
import { availableWorkflowCombunationAsync } from '../../../Redux/Slices/platform/platformAsync'
import { initialAPIDataBundle, availavle_platform_data, available_platform_action_type } from './utils'
import PlatformCard from './PlatformCard'
import classes from './style.module.css'
import AppSearchBar from 'src/utils/AppSearchBar'
import Loader from 'src/app/modules/loader/Loader'
import { PageTitle } from '../../../_metronic/layout/core'

function AvailablePlatformCombination() {
    const dispatch = useAppDispatch()
    const { workflowLoader, workflowCombination } = useAppSelector((state) => state.platformCombination)
    const userId = Number(localStorage.getItem('user'))
    const userDetails = localStorage.getItem('user_details')
    const userDetailsParsed = JSON.parse(userDetails || '')
    const organization_data = useAppSelector(state=>state.subdomain)
    const organization_id = organization_data.data.id
    const [searchString, setSearchString] = useState<string>('')
    const { createWorkFlow } = useAppSelector(
        (state: {
            workflowForm: {
                createWorkFlow: { status_code: number; massage: string }

            }
        }) => state.workflowForm
    )
    useEffect(() => {
        if (searchString === '' || (createWorkFlow.status_code >= 200 && createWorkFlow.status_code <= 300)) {
            if (organization_id) {
                dispatch(availableWorkflowCombunationAsync({organization_id: organization_id}))
            } else {
                dispatch(availableWorkflowCombunationAsync({organization_id: organization_id}))
            }
            
        }
    }, [searchString, createWorkFlow, organization_id])

    return (
        <div>
            {workflowLoader && <Loader />}

            {
                workflowCombination?.results && workflowCombination?.results.length > 0 ? (
                    <Fragment>
                        <PageTitle breadcrumbs={[]}>Available Integrations</PageTitle>
                        <div className={classes.appSearchBar}>
                            <AppSearchBar
                                searchString={searchString}
                                setSearchString={(st) => { setSearchString(st) }}
                                onSubmit={() => {
                                    if (organization_id) {
                                        dispatch(availableWorkflowCombunationAsync({ search_string: searchString, organization_id: organization_id }))     
                                    } else {
                                        dispatch(availableWorkflowCombunationAsync({ search_string: searchString}))
                                    }
                                }}
                                placeholder='Search Platform...'
                            />
                        </div>
                    </Fragment>
                ) : null
            }
            <Row>
                {workflowCombination?.results.map((item: initialAPIDataBundle) => {
                    return (
                        <Col key={item?.id} xs={12} sm={6} md={6} lg={6} xl={4} className='mb-5'>
                            <PlatformCard
                                flowID={item?.id}
                                sourcePlatformID={item?.source_available_platform?.id}
                                destinationPlatformID={item?.destination_available_platform?.id}
                                description={item?.description}
                                sourceImage={item?.source_available_platform?.logo_ref}
                                destinationImage={item?.destination_available_platform?.logo_ref}
                                sourcePlatformName={item?.source_available_platform?.display_name}
                                destinationPlatformName={item?.destination_available_platform?.display_name}
                                sourcePlatformAction={item?.source_available_platform?.available_platform_action}
                                destinationPlatformAction={item?.destination_available_platform?.available_platform_action}
                                available_actions={item?.available_actions}
                                parent_available_action_type={item?.available_action_flows}
                                isActive={false}
                            />
                        </Col>
                    )
                })}
                {
                    workflowCombination?.results.length === 0 ?
                        <Col xs={12}><h4 className='fw-bold mt-7'>No Available Platform Integration</h4></Col>
                        : null
                }
            </Row>
        </div>
    )
}

export default AvailablePlatformCombination

