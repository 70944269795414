import { AxiosClient } from '../../AxiosClient/axiosClient'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const getAllSourcePlatformAsync = createAsyncThunk(
  'get/getAllSourcePlatform',
  async (payload: { userId: number }, toolkit) => {
    return await AxiosClient(
      'GET',
      `/platform?user=${payload.userId || ''}&type=SOURCE`,
      [],
      toolkit
    )
  }
);

export const getAllDestinationPlatformAsync = createAsyncThunk(
  'get/getAllDestinationPlatform',
  async (payload: { userId: number }, toolkit) => {
    return await AxiosClient(
      'GET',
      `/platform?user=${payload.userId}&type=DESTINATION`,
      [],
      toolkit
    )
  }
);

export const getAllSourceActionNameAsync = createAsyncThunk(
  'get/getAllSourceActionName',
  async (payload: { sourcePlatformId: string | number }, toolkit) => {
    return await AxiosClient(
      'GET',
      `/available-platform?id=${payload.sourcePlatformId}`,
      [],
      toolkit
    )
  }
);

export const getAllDestinationActionNameAsync = createAsyncThunk(
  'get/getAllDestinationActionName',
  async (payload: { destinationPlatformId: string | number }, toolkit) => {
    return await AxiosClient(
      'GET',
      `/available-platform?id=${payload.destinationPlatformId}`,
      [],
      toolkit
    )
  }
);

export const createWorkFlowFormDataAsync = createAsyncThunk(
  'post/createWorkFlowFormData',
  async (payload: any, toolkit) => {
    return await AxiosClient('POST', `/workflow`, payload, toolkit
    )
  }
);

// Used in workflow modal switch
export const getSourcePlatformSettingAsync = createAsyncThunk(
  'get/getPlatformSetting',
  async (payload: { action_id: number, platform_id: string }, toolkit) => {
    return await AxiosClient(
      'GET',
      `/availableplatform-setting?action_id=${payload.action_id}&platform_id=${payload.platform_id}`,
      [],
      toolkit
    )
  }
);
// Used in workflow modal switch
export const getDestinationPlatformSettingAsync = createAsyncThunk(
  'get/getDestinationPlatformSetting',
  async (payload: { action_id: number, platform_id: string }, toolkit) => {
    return await AxiosClient(
      'GET',
      `/availableplatform-setting?action_id=${payload.action_id}&platform_id=${payload.platform_id}`,
      [],
      toolkit
    )
  }
);

export const createWorkFlowAsync = createAsyncThunk(
  'post/createWorkFlow',
  async (payload: any, toolkit) => {
    return await AxiosClient('POST', `/platform-setting/update`, payload, toolkit
    )
  }
);

export const sourcePlatformProcessAsync = createAsyncThunk(
  'platform-initprocess/sourcePlatformProcess',
  async (payload: any, toolkit) => {
    return await AxiosClient('POST', `/platform-initprocess`, payload, toolkit
    )
  }
);

export const destinationPlatformProcessAsync = createAsyncThunk(
  'platform-initprocess/destinationPlatformProcess',
  async (payload: any, toolkit) => {
    return await AxiosClient('POST', `/platform-initprocess`, payload, toolkit
    )
  }
);

