import axios from 'axios'
import { isJson } from './isJson'
import { errorMessage } from './isErrorSlice'

const AxiosClient = async (
  type: string,
  api: string,
  payload: any,
  toolkit: any,
  params?: object,
  directPath: string | null = null,
  content = 'application/json',
) => {
  const AxiosTypeString: any = {
    GET: 'get',
    POST: 'post',
    PUT: 'put',
    PATCH: 'patch',
    DELETE: 'delete',
  }

  return await axios({
    method: AxiosTypeString[type],
    url: directPath ? directPath : `${process.env.REACT_APP_API_URL}${api}`,
    data: payload,
    params: { ...params },
    headers: {
      'Content-Type': content,
      Authorization: isJson(localStorage.getItem('auth'))
        ? JSON.parse(localStorage.getItem('auth') as any)?.token
        : null,
    },
  })
    .then((response) => {
      return toolkit.fulfillWithValue(response.data)
    })
    .catch((error) => {
      toolkit.dispatch(errorMessage(error.response))
      return toolkit.rejectWithValue(error.response)
    })
}

export { AxiosClient }
