import axios from "axios"
const userId = Number(localStorage.getItem('user'))


const handlePerRowsChange = async (
    newPerPage: number, page: number,
    { workflowID, searchText, allEndPoints, logAction,
        setLoading, setData, setPerPage, setCurrentPage,
        setCheckedAll, setAllRowChecked, searchStatus
    }
) => {
    setLoading(true)
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/${allEndPoints[logAction] as any
            }/?user_id=${userId}&page=${page}&limit=${newPerPage}&workflow_id=${workflowID}&search_string=${searchText}&destination_sync_status=${searchStatus}`
        )
        setData(response.data?.results)
        setPerPage(newPerPage)
        setCurrentPage(page)
    } catch (error) {
        console.error('Error fetching users:', error)
    } finally {
        setLoading(false)
        setCheckedAll(false)
        setAllRowChecked(false)
    }
}

export default handlePerRowsChange;