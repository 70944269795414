import React, { Fragment, useEffect, useMemo, useState } from 'react'
import CloseButton from '../../IndividualWorkflowTabs/FieldMapping/components/CloseButton'
import { createPlatformApi, getAvailablePlatformbyPlatformIdApi } from 'src/app/redux/slices/PlatformSlices'
import { AnimatedLoader, CircularLoader } from 'src/app/modules/widgets/components/CustomLoaders'
import { isPlatformConnected } from 'src/Redux/Slices/stateSave/connectPlatformViaCreateWorkflow'
import { useAppDispatch, useAppSelector } from 'src/Redux/Store/hooks'
import { initialSateKeys, initialState, platformValues } from './utils'
import { Container, Modal } from 'react-bootstrap'
import FormComponent from './FormComponent'
import { toast } from 'react-toastify'
import { Formik, Form } from 'formik'
import axios from 'axios'

type platformConnectProps = {
    platform_id: number | string
    modalShow: boolean
    setModalShow: (status: boolean) => void
}

function PlatformConnect(props: platformConnectProps) {
    const dispatch = useAppDispatch()
    const { platform_id, modalShow, setModalShow } = props
    const userId = Number(localStorage.getItem('user'))
    const [platformLoader, setPlatformLoader] = useState(false)
    const [submitFormLoader, setSubmitFormLoader] = useState(false)
    const [rawPlatformData, setRawPlatormData] = useState<platformValues[] | []>([])
    const [filteredArray, setFilteredArray] = useState<platformValues | null>(null)

    // Getting All Platforms And Filtering Desired Platform
    useMemo(() => {
        const getAllPlatforms = async () => {
            setPlatformLoader(true)
            axios
                .get(`${process.env.REACT_APP_API_URL}/available-platform`)
                .then((response) => {
                    setRawPlatormData(response?.data?.data)
                    return response?.data?.data
                })
                .then((dataArray) => {
                    const filteredData: platformValues = dataArray?.find(
                        (ele: platformValues) => ele?.id === platform_id
                    )
                    setFilteredArray(filteredData)
                    setPlatformLoader(false)
                })
                .catch((error) => {
                    console.log('Somthing Went Wrong', error)
                    setPlatformLoader(false)
                })
        }
        getAllPlatforms()
    }, [platform_id])

    useMemo(() => {
        if (platform_id) {
            let availablePlatformID: { available_platform_id: number } = {
                available_platform_id: Number(platform_id),
            }
            localStorage.setItem('processOauth_ID', JSON.stringify(availablePlatformID))
        }
    }, [platform_id])

    const onSubmit = async (values: initialSateKeys) => {
        const payload = {
            user: userId,
            name: filteredArray?.name,
            type: filteredArray?.type,
            code: 'ABC123',
            display_name: values.display_name,
            credentials: {
                identifier: values.identifier || null,
                client_id: values.client_id || null,
                client_secret: values.client_secret || null,
                token_id: values.token_id || null,
                token_secret: values.token_secret || null,
                refresh_token: values.refresh_token || null
            },
            settings: [
                {
                    name: 'isCreateOrder',
                    value: 'False',
                },
                {
                    name: 'isUseDefaultCustomer',
                    value: 'False',
                },
                {
                    name: 'isUseDefaultCustomerEmail',
                    value: '',
                },
            ],
            isConnected: true,
            isActive: true,
            isOrderSyncProcessing: false,
            isNewOrderSyncProcessing: false,
            isOrderCreateProcessing: false,
            platform_info: platform_id,
        }
        // console.log('payload', payload)
        setSubmitFormLoader(true)
        dispatch(createPlatformApi(payload))
            .then((response: any) => {
                if (response?.payload?.data?.status_code >= 200 && response?.payload?.data?.status_code <= 300) {
                    toast.success(response?.payload?.data?.massage)
                    dispatch(isPlatformConnected({
                        platformType: filteredArray?.type,
                        connected: true
                    }))
                } else {
                    toast.error('Somthing Went Wrong')
                }
            })
        setSubmitFormLoader(false)
        setModalShow(false)
    }

    return (
        <Fragment>
            <Modal
                show={modalShow}
                onHide={() => setModalShow(true)}
                size='lg'
                centered
                style={{ backgroundColor: '#0007' }}
            >
                {/* Loader Before All */}
                {platformLoader ? (
                    <div className='mt-7 mb-7'>
                        <AnimatedLoader />
                    </div>
                ) : (
                    <Fragment>
                        {/* Close Button  Start*/}
                        <div onClick={() => { setModalShow(false) }}>
                            <CloseButton />
                        </div>
                        {/* Close Button  Start*/}

                        {/* Modal Heading Start*/}
                        <Modal.Header>
                            <Modal.Title className='fs-2 mb-n3'>
                                Connect {filteredArray?.display_name} Platform
                            </Modal.Title>
                        </Modal.Header>
                        {/* Modal Heading End*/}

                        <Fragment>
                            <Formik initialValues={initialState} onSubmit={onSubmit}>
                                {(Formik) => {
                                    return (
                                        <Form>
                                            <FormComponent
                                                Formik={Formik}
                                                filteredArray={filteredArray}
                                                submitFormLoader={submitFormLoader}
                                            />
                                        </Form>
                                    )
                                }}
                            </Formik>
                        </Fragment>
                    </Fragment>
                )}
            </Modal>
        </Fragment>
    )
}

export default React.memo(PlatformConnect);
