import React, { Fragment, FC, useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { KTSVG } from '../../../_metronic/helpers'
import Loader from '../loader/Loader'
import { useLocation, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import { PageTitle } from '../../../_metronic/layout/core'
import { ConfirmationModal } from './components/ConfirmationModal'
import { emplyWorkflowDetails } from '../../redux/slices/WorkflowSlices'
import CreateWorkflowModal from './components/CreateWorkflow/CreateWorkflowModal'
import { getWorkflowApi, deleteWorkflowApi } from '../../redux/slices/WorkflowSlices'
import { emptyForm } from '../../../Redux/Slices/workflowFormSlice/workflowFormSlice'
import { emptyPlatformCombinons } from '../../../Redux/Slices/platform/platformSlice'
import WorkflowDetailModal from './components/IndividualWorkflowTabs/WorkflowDetailModal'


const WorkflowPage: FC = () => {
  const [spinner, setSpinner] = useState(true)
  const location: any = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch<any>()
  const { sourcePlatformProcess, destinationPlatformProcess, createWorkFlow } = useSelector((state: any) => state.workflowForm)
  const [createWorkflowModalOpen, setCreateWorkflowModalOpen] = useState(false)
  const fetchWorkflowData = () => {
    const userId = Number(localStorage.getItem('user'))

    dispatch(getWorkflowApi(userId)).then((response: any) => {
      if (response) {
        setSpinner(false)
      }
    })
  }

  useEffect(() => {
    fetchWorkflowData()
  }, [])
  const WorkflowData = useSelector((state: any) => state.Workflow.WorkflowData)?.data

  // --------------------------------------------------------------

  const [showDetailsModal, setshowDetailsModal] = useState(false)
  const [ModalData, setModalData] = useState(null)

  const [showModalConfirmationModal, setShowModalConfirmationModal] = useState(false)
  const [selectedCardId, setSelectedCardId] = useState<any>()

  const openDetailsModal = () => {
    setshowDetailsModal(true)
  }

  const closeDetailsModal = () => {
    setshowDetailsModal(false)
    dispatch(emplyWorkflowDetails() as any)
    setModalData(null)
  }

  const OnCardClick = (id: any) => {
    openDetailsModal()
    setModalData(id)
  }


  const OnDeleteClick = (id: number) => {
    setSelectedCardId(id)
    setShowModalConfirmationModal(!showModalConfirmationModal)
    dispatch(emptyPlatformCombinons())
  }

  const handleDelete = () => {
    dispatch(deleteWorkflowApi(selectedCardId)).then((response: any) => {
      if (response.payload.status_code == 200) {
        toast.success(response.payload.massage)
        fetchWorkflowData()
      } else {
        toast.error(response.payload.massage)
      }
    })
    setShowModalConfirmationModal(false)
    setSelectedCardId(null)
  }

  useEffect(() => {
    if (sourcePlatformProcess.status_code === 201 &&
      destinationPlatformProcess.status_code === 201 &&
      createWorkFlow.status_code == 200) {
      toast.success('Success')
      dispatch(emptyForm())
    }
  }, [sourcePlatformProcess, destinationPlatformProcess, createWorkFlow])

  useEffect(() => {
    if (location?.state?.workflowId) {
      openDetailsModal()
      setModalData(location?.state?.workflowId)
    }
  }, [location])


  // Clear State That Holdes Message Comming From Dashboard Page:::
  useMemo(() => {
    const timer = setTimeout(() => {
      navigate('', { state: { workflowId: '', tabGroupIndex: 0 } });
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Fragment>
      {/* <PageTitle breadcrumbs={[]}>Workflow</PageTitle> */}
      <WorkflowDetailModal
        OpenModal={showDetailsModal}
        closeModal={closeDetailsModal}
        data={ModalData}
        tabGroupIndex={location?.state?.tabGroupIndex}
      />
      <ConfirmationModal
        icon='/media/icons/duotune/general/gen044.svg'
        title='Are you sure?'
        description='Are you sure you want to Delete!'
        onSave={handleDelete}
        showModal={showModalConfirmationModal}
        setShowModal={setShowModalConfirmationModal}
      />

      <div className='card p-7'>
        <div className='d-flex justify-content-between align-items-center '>
          <h4 className='fw-bolder m-0 text-dark align-self-end'>Active Integrations </h4>
        </div>
        <div className='separator my-7'></div>

        <div className='row '>
          {spinner ? (
            <Loader />
          ) : (
            <>
              {WorkflowData?.map((item: any) => (
                <div className='col-md-6 col-xxl-4 mb-7 position-relative' key={item?.id}>
                  <div className='card text-center bg-light-info cursor-pointer'>
                    <div className='d-flex'>
                      <div className='w-100 p-7 pe-0' onClick={() => OnCardClick(item?.id)}>
                        <div className='row'>
                          <div className='col-5'>
                            <div
                              style={{ width: '77px', height: '70px' }}
                              className='rounded m-auto mb-2 bg-white d-flex justify-content-between align-items-center '
                            >
                              <img
                                className='w-100 rounded '
                                src={item?.source_platform?.platform_info?.logo_ref}
                                alt=''
                              />
                            </div>

                            <div>
                              <p style={{ fontWeight: 'bold', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{item?.source_platform?.display_name}</p>
                            </div>
                            {/* <div>{item?.source_platform?.type}</div> */}
                          </div>
                          <div className='col-2 align-self-center mb-4'>
                            {/* <svg
                              width='72'
                              height='15'
                              viewBox='0 0 72 15'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                              className='w-100'
                            >
                              <path
                                d='M0 7.5H70M70 7.5L63.5 14M70 7.5L63.5 1'
                                stroke='black'
                                stroke-width='2'
                              />
                            </svg> */}
                            <span style={{ fontSize: '60px', marginLeft: '-15px', marginRight: '-15px' }}>&harr;</span>

                          </div>
                          <div className='col-5'>
                            <div
                              style={{ width: '77px', height: '70px' }}
                              className='rounded m-auto mb-2 bg-white d-flex justify-content-between align-items-center '
                            >
                              <img
                                className='w-100 rounded '
                                src={item?.destination_platform?.platform_info?.logo_ref}
                                alt=''
                              />
                            </div>

                            <div>
                              <p style={{ fontWeight: 'bold', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                {item?.destination_platform?.display_name}
                              </p>
                            </div>
                            {/* <div>{item?.destination_platform?.type}</div> */}
                          </div>
                        </div>
                      </div>
                      <div className=''>
                        <div
                          onClick={() => OnDeleteClick(item?.id)}
                          className=' rounded-circle d-flex justify-content-center m-4 align-items-center '
                          style={{ width: ' 20px', height: '20px', top: '-20px', right: '-10px' }}
                        >
                          <KTSVG
                            path='/media/icons/duotune/abstract/abs012.svg'
                            className=' svg-icon-2x text-danger'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </div>

    </Fragment>
  )
}

export default WorkflowPage
