import axios from "axios"
const userId = Number(localStorage.getItem('user'))

const handleSearchText = async (
    { workflowID, searchText, currentPage, perPage,
        isSearchClicked, setLoading, allEndPoints,
        logAction, setData, setTotalRows, setCurrentPage,
        setIsSearchClicked, setCheckedAll, setAllRowChecked,
        searchStatus }
) => {
    if (isSearchClicked) {
        setLoading(true)
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/${allEndPoints[logAction] as any
                }/?user_id=${userId}&page=${currentPage}&limit=${perPage}&workflow_id=${workflowID}&search_string=${searchText}&destination_sync_status=${searchStatus}`
            )
            setData(response.data?.results)
            setTotalRows(response.data.count)
            setCurrentPage(currentPage)
            setIsSearchClicked(false)
        } catch (error) {
            console.error('Error fetching users:', error)
        } finally {
            setLoading(false)
            setCheckedAll(false)
            setAllRowChecked(false)
        }
    }
}

export default handleSearchText;