import React, { Fragment, useEffect, useState } from 'react'
import axios from 'axios';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify'
import { Stack } from 'react-bootstrap';
import DataTable from 'react-data-table-component'
import classes from '../components/bulkOperation.module.css'
import { StatusColumn, columnData } from './StatusColumn';
import NoColumns from '../../Logs/ColumnComponents/NoColumns';
import { CircularLoader } from 'src/app/modules/widgets/components/CustomLoaders';
import { customStyles_light, customStyles_dark } from '../../Logs/ColumnComponents/ColumnStyle'

type exportDataTableProps = {
    workflowID: number
}

function ExportDataTable({ workflowID }: exportDataTableProps) {
    const [data, setData] = useState([])
    const [perPage, setPerPage] = useState(10)
    const [loading, setLoading] = useState(false)
    const [CSVLoading, setCSVLoading] = useState(false)
    const [totalRows, setTotalRows] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const themeMode = localStorage.getItem('kt_theme_mode_value')
    const [CSV_fileID, set_CSV_fileID] = useState<number | null>(null)

    const fetchCSVData = async (page: number) => {
        try {
            setLoading(true)
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/workflow-export-request/?workflow_id=${workflowID}`
            )
            setData(response.data?.results)
            setTotalRows(response.data.count)
            setCurrentPage(page)
        } catch (error) {
            console.error('Error fetching users:', error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (workflowID) {
            fetchCSVData(1) // Fetch Page 1
        }
    }, [workflowID])

    const handlePageChange = (page: number) => {
        fetchCSVData(page) // Page Number. i.e.1/2/3
    }

    const handlePerRowsChange = async (newPerPage: any, page: any) => {
        setLoading(true)
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/workflowfield-mappingdata?workflow_id=${workflowID}&page=${page}&limit=${newPerPage}`
            )
            setData(response?.data)
            setPerPage(newPerPage)
            setCurrentPage(page)
            setLoading(false)
        }
        catch (error) { console.log('Somthing Went Wrong', error) }
        finally { setLoading(false) }
    }

    // CSV Segment:::
    const handleClick = async (id: number, type: string) => {
        set_CSV_fileID(id)
        const fileName = type.toLowerCase();
        setCSVLoading(true)
        axios.get(`${process.env.REACT_APP_API_URL}/workflow-export-request/?export_id=${id}`)
            .then((response) => {
                const blob = new Blob([response?.data], { type: 'text/csv;charset=utf-8' });
                saveAs(blob, `${fileName}_${id}.csv`);
                setCSVLoading(false)
                toast.success('File Downloaded')
            })
            .catch((error) => {
                console.log('Sonthing Went Wrong', error)
                toast.error('Sonthing Went Wrong')
                setCSVLoading(false)
            })
    }
    return (
        <Fragment>
            {
                loading ? (
                    <Stack className={classes.loader}>
                        <CircularLoader height='70px' width='70px' marginTop='30px' marginBottom='20px' borderWidth='4px' />
                    </Stack>
                )
                    :
                    (
                        <DataTable
                            // title='Field Mapping List'
                            columns={StatusColumn({ handleClick, CSVLoading, CSV_fileID }) as any}
                            data={data}
                            progressPending={loading}
                            // progressComponent={
                            //     <CircularLoader height='70px' width='70px' marginTop='30px' marginBottom='20px' borderWidth='4px' />}
                            pagination
                            paginationServer
                            paginationTotalRows={totalRows}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            // noDataComponent={<NoColumns />}
                            noDataComponent={<h6 style={{ color: 'black', padding: '20px' }}>No Records To Display !</h6>}
                            highlightOnHover={themeMode === 'light' ? true : false}
                            pointerOnHover
                            customStyles={themeMode === 'light' ? customStyles_light : customStyles_dark}
                        />
                    )
            }

        </Fragment>
    )
}

export default ExportDataTable;
