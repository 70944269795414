import React, { Fragment, useState, useEffect } from 'react';
import { Container, Form, Button, Stack } from 'react-bootstrap'
import Select from 'react-select'
import AppEditor from 'src/utils/AppEditor';
import classes from '../CreateWorkflowCSS.module.css'
import { CopyToClipboard } from 'react-copy-to-clipboard'

type ChildComponentsProps = {
    clildDataCollectionFun: (data: { name: string, value: string | number | boolean }) => void;
    applySettings: boolean
    settingOptions: any;
    isRequired: boolean;
    displayName: string;
    isSwitchOn: boolean;
    inputType: string;
    name: string;
    displayShortcodes: boolean
    shortCode: string[] | []
    description: string
};
type multiselectDropdownProps = {
    value: string,
    label: string,
}
function ChildComponents(props: ChildComponentsProps) {
    const { name, displayName, inputType, isRequired, settingOptions, clildDataCollectionFun, applySettings, isSwitchOn, shortCode, displayShortcodes, description } = props;
    const [getImages, setImages] = useState<File[]>([])
    const [inputFieldvalue, setInputFieldValue] = useState('');
    const [inputFieldSwitch, setInputFieldSwitch] = useState(isRequired)
    const [isButtonActive, setIsButtonActive] = useState<boolean>(isRequired)
    const [selectedOptionString, setSelectedOptionString] = useState<string>('')
    const [dropdownValues, setDropdownValues] = useState<multiselectDropdownProps[]>([])
    const [selectedOptions, setSelectedOptions] = useState<multiselectDropdownProps[]>([])
    const [isShortCodeVisible, setShortCodeVisible] = useState(false)
    const [copyMarkup, setCopyMarkup] = useState(false)
    const [buttonString, setButtonString] = useState('')

    // Text Based Input Fields
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const inputValue = event.target.value;
        setInputFieldValue(inputValue);
        // clildDataCollectionFun({ name, value: inputValue });
    };

    // Switch Based Input Fields
    const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.checked;
        setInputFieldSwitch(inputValue)
        // clildDataCollectionFun({ name, value: inputValue ? 'True' : 'False' });
    }
    // Button Based Fields
    const handleButtonEvents = () => {
        setIsButtonActive(!isButtonActive)
        // clildDataCollectionFun({ name, value: !isButtonActive });
    }

    // Image Based Fields
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const filesArray = Array.from(event.target.files);
            setImages(filesArray);
        }
    }
    // Multiselect Dropdown 
    useEffect(() => {
        if (settingOptions?.length) {
            let settingDropdown = settingOptions.map((item: Record<string, any>) => {
                return {
                    value: item?.api_id,
                    label: item?.display_name,
                }
            })
            setDropdownValues(settingDropdown)
        }
    }, [settingOptions])

    const handleMultiSelectChange = (selectedValues: multiselectDropdownProps[]) => {
        setSelectedOptions(selectedValues)
        let inputValue = ''
        selectedValues.map((cc, index) => {
            inputValue += cc.value;
            if (index < selectedValues.length - 1) {
                inputValue += ','
            }
            setSelectedOptionString(inputValue)
        })
        // clildDataCollectionFun({ name, value: inputValue });
    }

    useEffect(() => {
        if (inputType == 'address' || inputType == 'email' || inputType == 'integer' || inputType == 'phone' || inputType == 'select' || inputType == 'text' || inputType == 'multiline_text') {
            clildDataCollectionFun({ name, value: inputFieldvalue });
        }
        if (inputType == 'boolean') {
            clildDataCollectionFun({ name, value: inputFieldSwitch ? 'True' : 'False' });
        }
        if (inputType == 'button') {
            clildDataCollectionFun({ name, value: isButtonActive ? 'True' : 'False' });
        }
        // if (inputType == 'multiselect' || inputType == 'multiline_text') {
        if (inputType == 'multiselect') {
            clildDataCollectionFun({ name, value: selectedOptionString });
        }
    }, [inputFieldvalue, inputFieldSwitch, isButtonActive, selectedOptions, selectedOptionString, applySettings, isSwitchOn])

    const handleCopyMarkup = (data: string) => {
        setCopyMarkup(true)
        setButtonString(data)

        setTimeout(() => {
            setCopyMarkup(false)
            setButtonString('')
        }, 1000);
    }

    let inputElement;
    if (inputType === 'address') {
        inputElement = (
            <Form.Group controlId='exampleForm.ControlTextarea1'>
                <Form.Label className='mt-3'>{displayName}</Form.Label>
                <Form.Control value={inputFieldvalue} onChange={handleInputChange} as='textarea' rows={2} />
                {/* Copy To CLipboard Logic--------------------------------------------------- */}
                <Stack className={classes.shortCodeDisplayContainer}>
                    <p className='text-muted text-hover-primary fw-bold mt-1 cursor-pointer'
                        onClick={() => setShortCodeVisible(!isShortCodeVisible)}
                    >
                        {displayShortcodes ? 'View Short Codes' : null}
                    </p>
                </Stack>
                {
                    isShortCodeVisible ? (
                        <Stack className={classes.shortCodes}>
                            {
                                shortCode?.length ? shortCode.map((ele: string) => {
                                    return (
                                        <Fragment key={ele}>
                                            <CopyToClipboard text={ele} >
                                                <Button
                                                    key={ele}
                                                    size='sm'
                                                    variant={(copyMarkup && buttonString === ele) ? 'outline-success' : 'outline-primary'}
                                                    className={`px-3 py-2 border border-1 ${(copyMarkup && buttonString === ele) ? 'border-success' : 'border-primary'} rounded text-hover-dark fs-sm-8 inline-block`}
                                                    onClick={() => handleCopyMarkup(ele)}
                                                >
                                                    {(copyMarkup && buttonString === ele) ? <span className='fs-sm-8'>Copied !</span> : ele}
                                                </Button>
                                            </CopyToClipboard>
                                        </Fragment>
                                    )
                                }) : null
                            }
                        </Stack>
                    ) : null
                }
                {/* Copy To CLipboard Logic--------------------------------------------------- */}
            </Form.Group>
        )
    } else if (inputType === 'boolean') {
        inputElement = (
            <Fragment>
                <Form className='d-flex justify-content-start align-items-baseline gap-3'>
                    <Form.Label className='fs-6 text-muted text-hover-primary'>{displayName}</Form.Label>
                    <Form.Check
                        type="switch"
                        className='mt-7'
                        id="custom-switch"
                        // label={displayName}
                        checked={inputFieldSwitch}
                        onChange={handleSwitchChange}
                    />
                    {/* Copy To CLipboard Logic--------------------------------------------------- */}
                    <Stack className={classes.shortCodeDisplayContainer}>
                        <p className='text-muted text-hover-primary fw-bold mt-1 cursor-pointer'
                            onClick={() => setShortCodeVisible(!isShortCodeVisible)}
                        >
                            {displayShortcodes ? 'View Short Codes' : null}
                        </p>
                    </Stack>
                    {
                        isShortCodeVisible ? (
                            <Stack className={classes.shortCodes}>
                                {
                                    shortCode?.length ? shortCode.map((ele: string) => {
                                        return (
                                            <Fragment key={ele}>
                                                <CopyToClipboard text={ele} >
                                                    <Button
                                                        key={ele}
                                                        size='sm'
                                                        variant={(copyMarkup && buttonString === ele) ? 'outline-success' : 'outline-primary'}
                                                        className={`px-3 py-2 border border-1 ${(copyMarkup && buttonString === ele) ? 'border-success' : 'border-primary'} rounded text-hover-dark fs-sm-8 inline-block`}
                                                        onClick={() => handleCopyMarkup(ele)}
                                                    >
                                                        {(copyMarkup && buttonString === ele) ? <span className='fs-sm-8'>Copied !</span> : ele}
                                                    </Button>
                                                </CopyToClipboard>
                                            </Fragment>
                                        )
                                    }) : null
                                }
                            </Stack>
                        ) : null
                    }
                    {/* Copy To CLipboard Logic--------------------------------------------------- */}
                </Form>
                {
                    description && (
                        <Form.Text className='ms-7'><span style={{ color: 'red', fontWeight: 'bolder' }}>*</span>{description}</Form.Text>
                    )
                }
            </Fragment>
        )
    } else if (inputType === 'button') {
        inputElement = (
            <Button
                className='me-3 mt-5'
                size='sm'
                variant={isButtonActive ? 'success' : 'primary'}
                onClick={handleButtonEvents}
            >
                {displayName}
            </Button>
        )
    } else if (inputType === 'email') {
        inputElement = (
            <Form.Group>
                <Form.Label className='mt-3'>{displayName}</Form.Label>
                <Form.Control
                    type='email'
                    placeholder={`${displayName}`}
                    value={inputFieldvalue}
                    onChange={handleInputChange}
                />
                {/* Copy To CLipboard Logic--------------------------------------------------- */}
                <Stack className={classes.shortCodeDisplayContainer}>
                    <p className='text-muted text-hover-primary fw-bold mt-1 cursor-pointer'
                        onClick={() => setShortCodeVisible(!isShortCodeVisible)}
                    >
                        {displayShortcodes ? 'View Short Codes' : null}
                    </p>
                </Stack>
                {
                    isShortCodeVisible ? (
                        <Stack className={classes.shortCodes}>
                            {
                                shortCode?.length ? shortCode.map((ele: string) => {
                                    return (
                                        <Fragment key={ele}>
                                            <CopyToClipboard text={ele} >
                                                <Button
                                                    key={ele}
                                                    size='sm'
                                                    variant={(copyMarkup && buttonString === ele) ? 'outline-success' : 'outline-primary'}
                                                    className={`px-3 py-2 border border-1 ${(copyMarkup && buttonString === ele) ? 'border-success' : 'border-primary'} rounded text-hover-dark fs-sm-8 inline-block`}
                                                    onClick={() => handleCopyMarkup(ele)}
                                                >
                                                    {(copyMarkup && buttonString === ele) ? <span className='fs-sm-8'>Copied !</span> : ele}
                                                </Button>
                                            </CopyToClipboard>
                                        </Fragment>
                                    )
                                }) : null
                            }
                        </Stack>
                    ) : null
                }
                {/* Copy To CLipboard Logic--------------------------------------------------- */}
            </Form.Group>
        )
    } else if (inputType === 'image') {
        inputElement = (
            <Form.Group controlId='formFileMultiple'>
                <Form.Label className='mt-3'>{displayName}</Form.Label>
                <Form.Control type='file' multiple onChange={handleFileChange} />
            </Form.Group>
        )
    } else if (inputType === 'integer') {
        inputElement = (
            <Form.Group>
                <Form.Label className='mt-3'>{displayName}</Form.Label>
                <Form.Control
                    type='number'
                    placeholder={`${displayName}`}
                    value={inputFieldvalue}
                    onChange={handleInputChange}
                />
                {/* Copy To CLipboard Logic--------------------------------------------------- */}
                <Stack className={classes.shortCodeDisplayContainer}>
                    <p className='text-muted text-hover-primary fw-bold mt-1 cursor-pointer'
                        onClick={() => setShortCodeVisible(!isShortCodeVisible)}
                    >
                        {displayShortcodes ? 'View Short Codes' : null}
                    </p>
                </Stack>
                {
                    isShortCodeVisible ? (
                        <Stack className={classes.shortCodes}>
                            {
                                shortCode?.length ? shortCode.map((ele: string) => {
                                    return (
                                        <Fragment key={ele}>
                                            <CopyToClipboard text={ele} >
                                                <Button
                                                    key={ele}
                                                    size='sm'
                                                    variant={(copyMarkup && buttonString === ele) ? 'outline-success' : 'outline-primary'}
                                                    className={`px-3 py-2 border border-1 ${(copyMarkup && buttonString === ele) ? 'border-success' : 'border-primary'} rounded text-hover-dark fs-sm-8 inline-block`}
                                                    onClick={() => handleCopyMarkup(ele)}
                                                >
                                                    {(copyMarkup && buttonString === ele) ? <span className='fs-sm-8'>Copied !</span> : ele}
                                                </Button>
                                            </CopyToClipboard>
                                        </Fragment>
                                    )
                                }) : null
                            }
                        </Stack>
                    ) : null
                }
                {/* Copy To CLipboard Logic--------------------------------------------------- */}
            </Form.Group>
        )
        // } else if (inputType === 'multiselect' || inputType === 'multiline_text') {
    } else if (inputType === 'multiselect') {
        inputElement = (
            <Fragment>
                <Form.Label className='mt-3'>{displayName}</Form.Label>
                <Select
                    name={`${displayName}`}
                    placeholder={`${displayName}`}
                    defaultValue={selectedOptions}
                    options={dropdownValues}
                    isMulti
                    className='basic-multi-select'
                    classNamePrefix='select'
                    onChange={handleMultiSelectChange as any}
                />
                {/* Copy To CLipboard Logic--------------------------------------------------- */}
                <Stack className={classes.shortCodeDisplayContainer}>
                    <p className='text-muted text-hover-primary fw-bold mt-1 cursor-pointer'
                        onClick={() => setShortCodeVisible(!isShortCodeVisible)}
                    >
                        {displayShortcodes ? 'View Short Codes' : null}
                    </p>
                </Stack>
                {
                    isShortCodeVisible ? (
                        <Stack className={classes.shortCodes}>
                            {
                                shortCode?.length ? shortCode.map((ele: string) => {
                                    return (
                                        <Fragment key={ele}>
                                            <CopyToClipboard text={ele} >
                                                <Button
                                                    key={ele}
                                                    size='sm'
                                                    variant={(copyMarkup && buttonString === ele) ? 'outline-success' : 'outline-primary'}
                                                    className={`px-3 py-2 border border-1 ${(copyMarkup && buttonString === ele) ? 'border-success' : 'border-primary'} rounded text-hover-dark fs-sm-8 inline-block`}
                                                    onClick={() => handleCopyMarkup(ele)}
                                                >
                                                    {(copyMarkup && buttonString === ele) ? <span className='fs-sm-8'>Copied !</span> : ele}
                                                </Button>
                                            </CopyToClipboard>
                                        </Fragment>
                                    )
                                }) : null
                            }
                        </Stack>
                    ) : null
                }
                {/* Copy To CLipboard Logic--------------------------------------------------- */}
            </Fragment>
        )
    } else if (inputType === 'phone') {
        inputElement = (
            <Form.Group>
                <Form.Label>{displayName}</Form.Label>
                <Form.Control
                    type='number'
                    placeholder={`${displayName}`}
                    value={inputFieldvalue}
                    onChange={handleInputChange}
                />
                {/* Copy To CLipboard Logic--------------------------------------------------- */}
                <Stack className={classes.shortCodeDisplayContainer}>
                    <p className='text-muted text-hover-primary fw-bold mt-1 cursor-pointer'
                        onClick={() => setShortCodeVisible(!isShortCodeVisible)}
                    >
                        {displayShortcodes ? 'View Short Codes' : null}
                    </p>
                </Stack>
                {
                    isShortCodeVisible ? (
                        <Stack className={classes.shortCodes}>
                            {
                                shortCode?.length ? shortCode.map((ele: string) => {
                                    return (
                                        <Fragment key={ele}>
                                            <CopyToClipboard text={ele} >
                                                <Button
                                                    key={ele}
                                                    size='sm'
                                                    variant={(copyMarkup && buttonString === ele) ? 'outline-success' : 'outline-primary'}
                                                    className={`px-3 py-2 border border-1 ${(copyMarkup && buttonString === ele) ? 'border-success' : 'border-primary'} rounded text-hover-dark fs-sm-8 inline-block`}
                                                    onClick={() => handleCopyMarkup(ele)}
                                                >
                                                    {(copyMarkup && buttonString === ele) ? <span className='fs-sm-8'>Copied !</span> : ele}
                                                </Button>
                                            </CopyToClipboard>
                                        </Fragment>
                                    )
                                }) : null
                            }
                        </Stack>
                    ) : null
                }
                {/* Copy To CLipboard Logic--------------------------------------------------- */}
            </Form.Group>
        )
    } else if (inputType === 'select') {
        inputElement = (
            <Fragment>
                <Form.Label className='mt-3'>{displayName}</Form.Label>
                <Form.Select value={inputFieldvalue} onChange={handleInputChange}>
                    <option value='' disabled>
                        Select Option
                    </option>
                    {
                        settingOptions?.map((items: any) =>
                            <option key={items.id} value={items?.api_id}>{items?.display_name}</option>
                        )
                    }
                </Form.Select>
            </Fragment>
        );
    } else if (inputType === 'text') {
        inputElement = (
            <Form.Group>
                <Form.Label className='mt-3'>{displayName}</Form.Label>
                <Form.Control
                    type='text'
                    placeholder={displayName}
                    value={inputFieldvalue}
                    onChange={handleInputChange}
                />
                {/* Copy To CLipboard Logic--------------------------------------------------- */}
                <Stack className={classes.shortCodeDisplayContainer}>
                    <p className='text-muted text-hover-primary fw-bold mt-1 cursor-pointer'
                        onClick={() => setShortCodeVisible(!isShortCodeVisible)}
                    >
                        {displayShortcodes ? 'View Short Codes' : null}
                    </p>
                </Stack>
                {
                    isShortCodeVisible ? (
                        <Stack className={classes.shortCodes}>
                            {
                                shortCode?.length ? shortCode.map((ele: string) => {
                                    return (
                                        <Fragment key={ele}>
                                            <CopyToClipboard text={ele} >
                                                <Button
                                                    key={ele}
                                                    size='sm'
                                                    variant={(copyMarkup && buttonString === ele) ? 'outline-success' : 'outline-primary'}
                                                    className={`px-3 py-2 border border-1 ${(copyMarkup && buttonString === ele) ? 'border-success' : 'border-primary'} rounded text-hover-dark fs-sm-8 inline-block`}
                                                    onClick={() => handleCopyMarkup(ele)}
                                                >
                                                    {(copyMarkup && buttonString === ele) ? <span className='fs-sm-8'>Copied !</span> : ele}
                                                </Button>
                                            </CopyToClipboard>
                                        </Fragment>
                                    )
                                }) : null
                            }
                        </Stack>
                    ) : null
                }
                {/* Copy To CLipboard Logic--------------------------------------------------- */}
            </Form.Group>
        )
    } else if (inputType === 'multiline_text') {
        inputElement = (
            <Fragment>
                <div style={{ height: '180px', overflow: 'auto' }}>
                    <AppEditor
                        placeholder={displayName}
                        setValue={setInputFieldValue}
                        value={inputFieldvalue}
                        editorStyle={{ marginTop: '15px', marginBottom: '25px', height: '120px' }}
                    />
                </div>
                {/* Copy To CLipboard Logic--------------------------------------------------- */}
                <Stack className={classes.shortCodeDisplayContainer}>
                    <p className='text-muted text-hover-primary fw-bold mt-1 cursor-pointer'
                        onClick={() => setShortCodeVisible(!isShortCodeVisible)}
                    >
                        {displayShortcodes ? 'View Short Codes' : null}
                    </p>
                </Stack>
                {
                    isShortCodeVisible ? (
                        <Stack className={classes.shortCodes}>
                            {
                                shortCode?.length ? shortCode.map((ele: string) => {
                                    return (
                                        <Fragment key={ele}>
                                            <CopyToClipboard text={ele} >
                                                <Button
                                                    key={ele}
                                                    size='sm'
                                                    variant={(copyMarkup && buttonString === ele) ? 'outline-success' : 'outline-primary'}
                                                    className={`px-3 py-2 border border-1 ${(copyMarkup && buttonString === ele) ? 'border-success' : 'border-primary'} rounded text-hover-dark fs-sm-8 inline-block`}
                                                    onClick={() => handleCopyMarkup(ele)}
                                                >
                                                    {(copyMarkup && buttonString === ele) ? <span className='fs-sm-8'>Copied !</span> : ele}
                                                </Button>
                                            </CopyToClipboard>
                                        </Fragment>
                                    )
                                }) : null
                            }
                        </Stack>
                    ) : null
                }
                {/* Copy To CLipboard Logic--------------------------------------------------- */}
            </Fragment>
        )
    }
    return (
        <Container className='ms-5'>
            {inputElement}
        </Container>
    );
}

export default ChildComponents;
