import React from 'react'
import './Tab.css'

type CustomTabProps = {
    key: number
    label: string
    active: boolean
    onClick: () => void
}

function CustomTab(props: CustomTabProps) {
    const { label, active, onClick } = props
    return (
        <div className={`tab ${active ? 'active' : ''}`} onClick={onClick}>
            {label}
        </div>
    )
}

export default CustomTab;
