import React, { Fragment, useEffect, useState } from 'react'
import { Button, Form, Stack } from 'react-bootstrap'
import classes from './logs.module.css'
import { useSelector } from 'react-redux'

type FilterLogsComponentProps = {
    logAction: string, setLogAction: (data: string) => void
}
export function FilterLogsComponent(props: FilterLogsComponentProps) {
    const { logAction, setLogAction } = props
    const themeMode = localStorage.getItem('kt_theme_mode_menu')
    const WorkflowDetails = useSelector((state: any) => state.Workflow.WorkflowDetails)?.data
    const [filtered_logList] = useState<{ key: number, value: string, label: string }[]>([])
    useEffect(() => {
        allLogsCase.filter((cc: { key: number, value: string, label: string }) => {
            WorkflowDetails?.workflow_action.map((ac: { action: { action: string } }) => {
                if (cc.value == ac?.action?.action) {
                    if (!filtered_logList.includes(cc)) {
                        return filtered_logList.push(cc)
                    }
                }
            })
        })
        setLogAction(filtered_logList?.[0]?.value)
    }, [WorkflowDetails])

    return (
        <Fragment>
            <div className={classes.log_filtr_dropdown}>
                <Form.Select
                    className='w-25 mb-n5'
                    style={{ minWidth: '210px', backgroundColor: themeMode === 'light' ? '' : 'white' }}
                    value={logAction}
                    onChange={(event) => setLogAction(event.target.value)}
                >
                    {
                        filtered_logList.map((items: { key: number, value: string, label: string }) => {
                            return (
                                <option key={items.key} value={items.value}>{items.label}</option>
                            )
                        })
                    }
                </Form.Select>
            </div>

        </Fragment>
    )
}

const allLogsCase = [
    { key: 1, value: 'IMPORT_ORDERS', label: 'Order Logs' },
    { key: 2, value: 'IMPORT_SHIPMENTS', label: 'Shipment Logs' },
    { key: 7, value: 'CUSTOM_IMPORT_SHIPMENTS', label: 'Custom Shipment Logs' },
    { key: 3, value: 'IMPORT_PRODUCTS', label: 'Product Logs' },
    { key: 4, value: 'IMPORT_INVENTORY_CHANGES ', label: 'Inventory Change Logs' },
    { key: 5, value: 'IMPORT_INVOICE', label: 'Order Logs' },
    { key: 6, value: 'IMPORT_INVENTORY', label: 'Inventory Logs' },
    { key : 7, value : 'IMPORT_BASIC_ORDERS', label: 'Order Logs'}
]
export const allEndPoints = {
    IMPORT_ORDERS: 'platform-orders',
    IMPORT_INVOICE: 'platform-orders',
    IMPORT_SHIPMENTS: 'platform-order-shipment',
    CUSTOM_IMPORT_SHIPMENTS:'platform-order-shipment-custom-flow',
    IMPORT_PRODUCTS: 'platform-product',
    IMPORT_INVENTORY_CHANGES: 'IMPORT_INVENTORY_CHANGES',
    IMPORT_INVENTORY: 'platform-product',
    IMPORT_BASIC_ORDERS : 'platform-orders'

}

// ::: Reserved Keys :::
// IMPORT_ORDERS = "IMPORT_ORDERS" --Done
// IMPORT_SHIPMENTS = "IMPORT_SHIPMENTS" --Done
// IMPORT_PRODUCTS = "IMPORT_PRODUCTS" --Done
// CREATE_ORDERS = "CREATE_ORDERS"
// CREATE_SHIPMENTS = "CREATE_SHIPMENTS"
// CREATE_PRODUCTS = "CREATE_PRODUCTS"
// INITIAL = "INITIAL"
// REFRESH_TOKEN = "REFRESH_TOKEN"
// IMPORT_INVOICE = "IMPORT_INVOICE"
// UPDATE_TRANSACTION = "UPDATE_TRANSACTION"
// RETRY_FAILED_TRANSACTION = "RETRY_FAILED_TRANSACTION"
// IMPORT_UPDATED_INVOICES = "IMPORT_UPDATED_INVOICES"
// IMPORT_INVENTORY_CHANGES = "IMPORT_INVENTORY_CHANGES" --Done
// CREATE_INVENTORY_CHANGES = "CREATE_INVENTORY_CHANGES"

type reloadButtonComponentProp = {
    setReload: (sateus: boolean) => void
    setSearchStatus: (data: string) => void
}
export const ReloadButtonComponent = (props: reloadButtonComponentProp) => {
    const { setReload, setSearchStatus } = props
    return (
        <Button
            size='sm'
            variant='secondary'
            style={{ height: '42px', position: 'relative', bottom: '1px' }}
            onClick={() => {
                setSearchStatus("")
                setReload(true)
            }}
        >
            <i className="bi bi-arrow-clockwise fs-1"></i>
        </Button>
    )
}