import React, { Fragment } from 'react'
import classes from './styles.module.css'
type logoHeaderProps = {
    sourceLogo: string
    destinationLogo: string
    destinationName: string
    sourceName: string
}

function LogoHeader({ sourceLogo, destinationLogo, sourceName, destinationName }: logoHeaderProps) {
    return (
        <Fragment>
            <div className={classes.container}>
                <div className={classes.img_name}>
                    <img src={sourceLogo}
                        style={{
                            marginRight: '20px',
                            width: 'auto',
                            minHeight: '70px',
                            maxHeight: '70px',
                        }}
                    />
                    <p style={{ position: 'relative', left: '-10px', textAlign: 'center' }}>{sourceName}</p>
                </div>

                <span style={{ fontSize: '40px', position: 'relative', top: '-15px', left: '-9px' }}>&rarr;</span>

                <div className={classes.img_name}>
                    <img src={destinationLogo}
                        style={{
                            marginRight: '20px',
                            width: 'auto',
                            minHeight: '70px',
                            maxHeight: '70px',
                        }}
                    />
                    <p style={{ position: 'relative', left: '-10px', textAlign: 'center' }}>{destinationName}</p>
                </div>
            </div>

        </Fragment>
    )
}

export default LogoHeader
