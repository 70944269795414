import React, { Fragment } from 'react'
import EditProfile from './components/EditProfile'
import { Container, Stack } from 'react-bootstrap'
import classes from './components/Profile.module.css'
import { ToastContainer } from 'react-toastify'

function MyProfile() {
    return (
        <Fragment>
            {/* <Stack >
                <p className={classes.page_heading}>Profile</p>
                <span className='mt-n2 ms-n1 text-muted text-hover-primary'>
                    <p className={classes.page_subHeading}>
                        <span>
                            <i className="bi bi-circle-fill"
                                style={{ fontSize: '7px', position: 'relative', top: '-1px', right: '-4px' }}
                            >
                            </i>
                        </span>&nbsp;
                        Account Overview</p>
                </span>
            </Stack> */}
            <Container>
                <EditProfile />
            </Container>
            <ToastContainer />
        </Fragment>
    )
}

export default MyProfile
