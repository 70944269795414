import { createSlice } from '@reduxjs/toolkit';

export type actionCollection = { main_id: number, p_name: string, p_type: string, p_id: number, a_name: string, a_id: number }
export type actionFlows = { available_workflow: number, available_workflow_action1: number, available_workflow_action2: number, display_name: string }

type stateData = {
    dashboardIntegratedModal: boolean
    sp_id: number | string
    dp_id: number | string
    sp_aid: number[] | null
    dp_aid: number[] | null
    sourceImage: string
    destinationImage: string
    sourceName: string
    destinationName: string
    description: string
    actionCollection: actionCollection[] | []
    action_flows: actionFlows[] | []
    flowID: number | null
}
type initialStates = {
    unActiveCardData: stateData
}

const initialState: initialStates = {
    unActiveCardData: {
        dashboardIntegratedModal: false,
        sp_id: '',
        dp_id: '',
        sp_aid: null,
        dp_aid: null,
        sourceImage: "",
        destinationImage: "",
        sourceName: "",
        destinationName: "",
        description: "",
        actionCollection: [],
        action_flows: [],
        flowID: null
    }
}

const connectPlatformDataSlice = createSlice({
    name: 'connectPlatformDataSlice',
    initialState,
    reducers: {
        dataDerivedFromCard: (state, action) => {
            state.unActiveCardData = action.payload
        },
        emptyDerivedData: () => initialState
    }
})

export const { dataDerivedFromCard, emptyDerivedData } = connectPlatformDataSlice.actions;
export default connectPlatformDataSlice.reducer;