import { AxiosClient } from '../../AxiosClient/axiosClient'
import { createAsyncThunk } from '@reduxjs/toolkit'



export const getFieldMappingByAvailableWorkflowAsync = createAsyncThunk(
    'available-workflows?available_workflow_id/getFieldMappingByAvailableWorkflow',
    async (payload: { workflowInfo: number }, toolkit) => {
        return await AxiosClient(
            'GET', `/available-workflows?available_workflow_id=${payload.workflowInfo}`, [], toolkit
        )
    }
);

export const getSourceMappingAsync = createAsyncThunk(
    'platform-fields/getSourceMapping',
    async (payload: { source_platform_id: number }, toolkit) => {
        return await AxiosClient(
            'GET', `/platform-fields?platform_id=${payload.source_platform_id}`,
            payload, toolkit
        )
    }
)

export const getDestionationMappingAsync = createAsyncThunk(
    'platform-fields/getDestionationMapping',
    async (payload: { destination_platform_id: number }, toolkit) => {
        return await AxiosClient(
            'GET', `/platform-fields?platform_id=${payload.destination_platform_id}`,
            payload, toolkit
        )
    }
)

