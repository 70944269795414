import {createSlice, isAnyOf} from '@reduxjs/toolkit'
import {createAvailableWorkflowAsync, getAvailableWorkflowsAsync, updateAvailableWorkflowAsync} from './availableWorkflowAction'
import {AVAILABLE_WORKFLOW_SLICE} from 'src/_interfaces/AvailableWorkflow.Interface'

const initialState: AVAILABLE_WORKFLOW_SLICE = {
  createAvailableWorkflowLoader: false,
  updateAvailableWorkflowLoader: false,

  // available workflows...
  getAvailableWorkflowLoader: false,
  availableWorkflows: [],
  paginationData: {
    count: 5,
    next: null,
    previous: null,
    currentPage: null,
    page: 1,
    page_size: 5,
  },
}

const availableWorkflowSlice = createSlice({
  name: 'availableWorkflow',
  initialState,
  extraReducers: (builder) => {

    // create available workflow start .....
    builder.addMatcher(isAnyOf(createAvailableWorkflowAsync.pending), (state) => {
      console.log('createAvailableWorkflowAsync in pending state')
      state.createAvailableWorkflowLoader = true
    })
    builder.addMatcher(isAnyOf(createAvailableWorkflowAsync.rejected), (state) => {
      console.log('createAvailableWorkflowAsync in rejected state')
      state.createAvailableWorkflowLoader = false
    })
    builder.addMatcher(isAnyOf(createAvailableWorkflowAsync.fulfilled), (state, action) => {
      state.createAvailableWorkflowLoader = false
      console.log('createAvailableWorkflowAsync in fulfilled state', action.payload)
    })
    // create available workflow end .....

    // get available workflows start....
    builder.addMatcher(isAnyOf(getAvailableWorkflowsAsync.pending), (state) => {
      console.log('getAvailableWorkflowsAsync in pending state')
      state.getAvailableWorkflowLoader = true
    })
    builder.addMatcher(isAnyOf(getAvailableWorkflowsAsync.rejected), (state, action) => {
      console.log('getAvailableWorkflowsAsync in rejected state')
      state.getAvailableWorkflowLoader = false
    })
    builder.addMatcher(isAnyOf(getAvailableWorkflowsAsync.fulfilled), (state, action) => {
      state.paginationData = {
        ...state.paginationData,
        count: action.payload.count,
        next: action.payload.next,
        previous: action.payload.previous,
      }
      state.availableWorkflows = action.payload.results
      state.getAvailableWorkflowLoader = false
      console.log('getAvailableWorkflowsAsync in fulfilled state', action.payload)
    })
    // get available workflows end....

     // update available workflow start .....
     builder.addMatcher(isAnyOf(updateAvailableWorkflowAsync.pending), (state) => {
      console.log('updateAvailableWorkflowAsync in pending state')
      state.updateAvailableWorkflowLoader = true
    })
    builder.addMatcher(isAnyOf(updateAvailableWorkflowAsync.rejected), (state) => {
      console.log('updateAvailableWorkflowAsync in rejected state')
      state.updateAvailableWorkflowLoader = false
    })
    builder.addMatcher(isAnyOf(updateAvailableWorkflowAsync.fulfilled), (state, action) => {
      state.updateAvailableWorkflowLoader = false
      console.log('updateAvailableWorkflowAsync in fulfilled state', action.payload)
    })
    // update available workflow end .....

  },
  reducers: {
    setCurrentPage: (state, actions: {payload: string}) => {
      state.paginationData.currentPage = actions.payload
    },
  },
})

export const {setCurrentPage} = availableWorkflowSlice.actions
export default availableWorkflowSlice.reducer
