/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { useIntl } from 'react-intl'
import { KTIcon } from '../../../../helpers'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'
import { useAppSelector } from '../../../../../Redux/Store/hooks'
import { RootState } from '../../../../../Redux/Store/storets'
import { USER_ROLE } from '../../../../../utils/constants'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const subdomainId = useAppSelector((state: RootState) => state.subdomain.data.id)
  const { loginUser } = useAppSelector((state: RootState) => state.loggedInUser)


  return (
    <>
      {/* ---------------------------------------- admin/Organization navigation menu Start ---------------------------------- */}
      {loginUser.user_type == USER_ROLE['admin'] && loginUser.organization !== null ? (
        <>
          <SidebarMenuItem
            to='dashboard'
            icon='element-11'
            title={'Dashboard'}
            fontIcon='bi-app-indicator'
          />
          {/* <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Services</span>
            </div>
          </div> */}

          <SidebarMenuItem
            to='orginazation'
            icon='abstract-28'
            title='Organization'
            fontIcon='bi-layers'
          />
          {/* Invite Users is commented for now as it is shifted to Manage Account */}
          
          {/* <SidebarMenuItem
            to='invite-users'
            icon='people'
            title='Invite Users'
            fontIcon='bi-layers'
          /> */}
          
        </>
      ) : loginUser.user_type == USER_ROLE['user'] ||
        (loginUser.user_type == USER_ROLE['admin'] && loginUser.organization === null) ? (
        <>
          <SidebarMenuItem
            to='/dashboard'
            icon='element-11'
            title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
            fontIcon='bi-app-indicator'
          />

          {/* <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Services</span>
            </div>
          </div> */}

          <SidebarMenuItem
            to='/workflow'
            icon='switch'
            title='Active Integrations'
            fontIcon='bi-layers'
          />
          <SidebarMenuItem
            to='/platform'
            icon='abstract-28'
            title='Connected Platforms'
            fontIcon='bi-layers'
          />

          {/* {(subdomainId && loginUser.user_type == USER_ROLE['admin']) && (
              <SidebarMenuItem
                to='/invite-users'
                icon='people'
                title='Invite Users'
                fontIcon='bi-layers'
              />
            )} */}
        </>
      ) : loginUser.user_type == USER_ROLE['superadmin'] ? (
        <>
          <SidebarMenuItem
            to='dashboard'
            icon='element-11'
            title={'Dashboard'}
            fontIcon='bi-app-indicator'
          />
          {/* <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Services</span>
            </div>
          </div> */}

          <SidebarMenuItem
            to='list-platforms'
            icon='abstract-28'
            title='Available Platforms'
            fontIcon='bi-layers'
          />

          <SidebarMenuItem
            to='list-organizations'
            icon='educare'
            title='Create Organization'
            fontIcon='bi-layers'
          />

          <SidebarMenuItem to='list-users' icon='profile-user' title='Users' fontIcon='bi-layers' />

          <SidebarMenuItem
            to='workflows-available'
            icon='abstract-22'
            title='Available Workflows'
            fontIcon='bi-layers'
          />
        </>
      ) : null}
      {/* ---------------------------------------- admin/Organization navigation menu end ---------------------------------- */}

      {/* ---------------------------------------- admin navigation menu start ---------------------------------- */}

      {/* 
      {(loginUser.user_type == USER_ROLE['user'] || loginUser.user_type == USER_ROLE['admin']) && (
        <>
          <SidebarMenuItem
            to='/dashboard'
            icon='element-11'
            title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
            fontIcon='bi-app-indicator'
          />

          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Services</span>
            </div>
          </div>

          <SidebarMenuItem
            to='/workflow'
            icon='switch'
            title='Active Workflows'
            fontIcon='bi-layers'
          />
          <SidebarMenuItem
            to='/platform'
            icon='abstract-28'
            title='Platforms'
            fontIcon='bi-layers'
          />

          {(subdomainId && loginUser.user_type == USER_ROLE['admin']) && (
            <SidebarMenuItem
              to='/invite-users'
              icon='people'
              title='Invite Users'
              fontIcon='bi-layers'
            />
          )}
        </>
      )} */}

      {/* ---------------------------------------- admin navigation menu end ---------------------------------- */}

      {/* ---------------------------------------- super admin navigation menu start ---------------------------------- */}

      {/* 
      {loginUser.user_type == USER_ROLE['superadmin'] && (
        <>
          <SidebarMenuItem
            to='dashboard'
            icon='element-11'
            title={'Dashboard'}
            fontIcon='bi-app-indicator'
          />
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Services</span>
            </div>
          </div>

          <SidebarMenuItem
            to='list-platforms'
            icon='abstract-28'
            title='Available Platforms'
            fontIcon='bi-layers'
          />

          <SidebarMenuItem
            to='list-organizations'
            icon='educare'
            title='Create Organization'
            fontIcon='bi-layers'
          />

          <SidebarMenuItem
            to='list-users'
            icon='profile-user'
            title='Users'
            fontIcon='bi-layers'
          />

          <SidebarMenuItem
            to='workflows-available'
            icon='abstract-22'
            title='Available Workflows'
            fontIcon='bi-layers'
          />
        </>
      )}
 */}

      {/* ---------------------------------------- super admin navigation menu end ---------------------------------- */}

      {/* <SidebarMenuItemWithSub*/}
      {/*  to='/crafted/pages'*/}
      {/*  title='Pages'*/}
      {/*  fontIcon='bi-archive'*/}
      {/*  icon='element-plus'*/}
      {/*> */}

      {/*  <SidebarMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>*/}
      {/*    <SidebarMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />*/}
      {/*    <SidebarMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />*/}
      {/*    <SidebarMenuItem*/}
      {/*      to='/crafted/pages/profile/campaigns'*/}
      {/*      title='Campaigns'*/}
      {/*      hasBullet={true}*/}
      {/*    />*/}
      {/*    <SidebarMenuItem*/}
      {/*      to='/crafted/pages/profile/documents'*/}
      {/*      title='Documents'*/}
      {/*      hasBullet={true}*/}
      {/*    />*/}
      {/*    <SidebarMenuItem*/}
      {/*      to='/crafted/pages/profile/connections'*/}
      {/*      title='Connections'*/}
      {/*      hasBullet={true}*/}
      {/*    />*/}
      {/*  </SidebarMenuItemWithSub>*/}

      {/*  <SidebarMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>*/}
      {/*    <SidebarMenuItem*/}
      {/*      to='/crafted/pages/wizards/horizontal'*/}
      {/*      title='Horizontal'*/}
      {/*      hasBullet={true}*/}
      {/*    />*/}
      {/*    <SidebarMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />*/}
      {/*  </SidebarMenuItemWithSub>*/}
      {/*</SidebarMenuItemWithSub>*/}
      {/*<SidebarMenuItemWithSub*/}
      {/*  to='/crafted/accounts'*/}
      {/*  title='Accounts'*/}
      {/*  icon='profile-circle'*/}
      {/*  fontIcon='bi-person'*/}
      {/*>*/}
      {/*  <SidebarMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />*/}
      {/*  <SidebarMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />*/}
      {/*</SidebarMenuItemWithSub>*/}
      {/*<SidebarMenuItemWithSub to='/error' title='Errors' fontIcon='bi-sticky' icon='cross-circle'>*/}
      {/*  <SidebarMenuItem to='/error/404' title='Error 404' hasBullet={true} />*/}
      {/*  <SidebarMenuItem to='/error/500' title='Error 500' hasBullet={true} />*/}
      {/*</SidebarMenuItemWithSub>*/}
      {/*<SidebarMenuItemWithSub*/}
      {/*  to='/crafted/widgets'*/}
      {/*  title='Widgets'*/}
      {/*  icon='element-7'*/}
      {/*  fontIcon='bi-layers'*/}
      {/*>*/}
      {/*  <SidebarMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />*/}
      {/*  <SidebarMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />*/}
      {/*  <SidebarMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />*/}
      {/*  <SidebarMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />*/}
      {/*  <SidebarMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />*/}
      {/*  <SidebarMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />*/}
      {/*</SidebarMenuItemWithSub>*/}
      {/*<div className='menu-item'>*/}
      {/*  <div className='menu-content pt-8 pb-2'>*/}
      {/*    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*<SidebarMenuItemWithSub*/}
      {/*  to='/apps/chat'*/}
      {/*  title='Chat'*/}
      {/*  fontIcon='bi-chat-left'*/}
      {/*  icon='message-text-2'*/}
      {/*>*/}
      {/*  <SidebarMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />*/}
      {/*  <SidebarMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />*/}
      {/*  <SidebarMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />*/}
      {/*</SidebarMenuItemWithSub>*/}
      {/*<SidebarMenuItem*/}
      {/*  to='/apps/user-management/users'*/}
      {/*  icon='abstract-28'*/}
      {/*  title='User management'*/}
      {/*  fontIcon='bi-layers'*/}
      {/*/>*/}
      {/*<div className='menu-item'>*/}
      {/*  <a*/}
      {/*    target='_blank'*/}
      {/*    className='menu-link'*/}
      {/*    href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}*/}
      {/*  >*/}
      {/*    <span className='menu-icon'>*/}
      {/*      <KTIcon iconName='code' className='fs-2' />*/}
      {/*    </span>*/}
      {/*    <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>*/}
      {/*  </a>*/}
      {/*</div> */}
    </>
  )
}

export { SidebarMenuMain }
