import React, { useEffect, useState } from 'react'
import { ErrorMessage } from 'formik'
import TextError from '../../../../../../../ErrorMessage/TextError'
import { Container, Row, Col, Form as BootstrapForm } from 'react-bootstrap'

type OnlyDefaultDestinationProvidedProps = {
  Formik: any
  previousMapping: any
  conminedState: any
}
export function OnlyDefaultDestinationProvided(props: OnlyDefaultDestinationProvidedProps) {
  const { Formik, conminedState, previousMapping } = props
  const [filtered_destintionData, set_filtered_destintionData] = useState<any>()
  const [updateID, setUpdateID] = useState<any>('')
  useEffect(() => {
    let filterdedData = conminedState?.find(
      (cc: any) => cc?.id == previousMapping?.destination_field?.id
    )
    set_filtered_destintionData(filterdedData)
  }, [conminedState, previousMapping])

  useEffect(() => {
    Formik.setFieldValue('only_default_destination_provided', {
      id: previousMapping?.workflow_field_mapping_data?.[0]?.id,
      source_platform_data: null,
      destination_platform_data:
        previousMapping?.workflow_field_mapping_data?.[0]?.destination_platform_data?.id,
      isDefault: true,
    })
    setUpdateID(previousMapping?.workflow_field_mapping_data?.[0]?.id)
  }, [conminedState, previousMapping])


  return (
    <Container style={{ marginBottom: '10px' }}>
      <Row>
        <Col xs={12} md={6}>
          <p className='mt-3 fw-bold text-muted fs-5 text-justify text-hover-primary'>
            Default Option
          </p>
        </Col>
        <Col xs={12} md={6}>
          <BootstrapForm.Select
            name='only_default_destination_provided'
            value={Formik.values.only_default_destination_provided.destination_platform_data}
            onChange={(event) => {
              let selectedValue = event.target.value
              Formik.setFieldValue('only_default_destination_provided', {
                id: updateID,
                source_platform_data: null,
                destination_platform_data: selectedValue,
                isDefault: true,
              })
            }}
            style={{
              backgroundColor: 'white',
              color: 'black',
              fontWeight: '600',
              border: '1px solid #99A1B7',
            }}
          >
            <option value='' disabled>
              Select Option
            </option>
            {filtered_destintionData?.field_data?.map((items: any) => (
              items?.parent_data === null &&
              <option key={items.id} value={items?.id}>
                {items?.api_name}
              </option>
            ))}
          </BootstrapForm.Select>
          <ErrorMessage name='only_default_destination_provided' component={TextError} />
        </Col>
      </Row>
    </Container>
  )
}
