import React from 'react'
import classes from './TestError.module.css'

function TextError(props) {
    return (
        <div className={classes.error}>
            {props.children}
        </div >
    )
}

export default TextError
