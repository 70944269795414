import React, { Fragment, useEffect, useState } from 'react'
import { Container, Stack, Modal, Row, Col, Card } from 'react-bootstrap'
import Slideshow from './components/ProductSlider/ProductSlider'
import Header from './components/Header'
import axios from 'axios'
import { product } from './components/utils'
import ProductInfo from './components/ProductInfo'
import classes from './components/product.module.css'
import { CircularLoader } from 'src/app/modules/widgets/components/CustomLoaders'

type individualProductDetailsProps = {
    product_ID: number | null
    OpenModal: boolean
    closeModal: (status: boolean) => void
}

function IndividualProductDetails(props: individualProductDetailsProps) {
    const { product_ID, OpenModal, closeModal } = props
    const [loading, setLoading] = useState(false)
    const [productDetails, setProductDetails] = useState({} as product)

    const getProductDetails = async (productID: number) => {
        setLoading(true)
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/platform-product/${productID}`
            )
            console.log(response?.data?.platform_products[0])
            setProductDetails(response?.data?.platform_products[0])
        }
        catch (error) { console.log('Somthing Went Wrong', error) }
        finally { setLoading(false) }
    }
    useEffect(() => {
        if (product_ID) {
            getProductDetails(product_ID)
        }
    }, [product_ID])

    return (
        <Modal
            show={OpenModal}
            onHide={() => closeModal(true)}
            size='lg'
            centered
            style={{ backgroundColor: '#0007' }}
        >
            <div className='modal-content'>
                {
                    loading ? (
                        <Stack className={classes.loader}>
                            <CircularLoader
                                height='60px'
                                width='60px'
                                marginTop='5px'
                                marginBottom='5px'
                                borderWidth='4px'
                            />
                        </Stack>
                    ) : (
                        <Fragment>
                            <Modal.Header>
                                <Header closeModal={closeModal} productID={productDetails?.id} />
                            </Modal.Header>

                            <Modal.Body>
                                <Card>
                                    <Container>
                                        <Card.Title className={classes.card_header}>Source ID: {productDetails?.api_id}</Card.Title>
                                        <Row>
                                            <Col xs={12} md={4} className='d-flex justify-content-center mb-5'>
                                                <Slideshow imageArray={productDetails?.images} />
                                            </Col>
                                            <Col xs={12} md={8}>
                                                <Card>
                                                    <Container>
                                                        <Card.Title className={classes.card_header}>Product Details :</Card.Title>
                                                        <span className={classes.productInfoGropuContainer}>
                                                            <span className={classes.productInfoContainer}>
                                                                <Card.Subtitle className={classes.card_subHeading_product}>Name</Card.Subtitle>
                                                                <Card.Subtitle className={classes.card_text_productDetail}>&nbsp;:&nbsp;{productDetails?.name}</Card.Subtitle>
                                                            </span>
                                                            <span className={classes.productInfoContainer}>
                                                                <Card.Subtitle className={classes.card_subHeading_product}>Type</Card.Subtitle>
                                                                <Card.Subtitle className={classes.card_text_productDetail}>&nbsp;:&nbsp;{productDetails?.product_type}</Card.Subtitle>
                                                            </span>
                                                            <span className={classes.productInfoContainer}>
                                                                <Card.Subtitle className={classes.card_subHeading_product}>Code</Card.Subtitle>
                                                                <Card.Subtitle className={classes.card_text_productDetail}>&nbsp;:&nbsp;{productDetails?.api_code}</Card.Subtitle>
                                                            </span>
                                                            <span className={classes.productInfoContainer}>
                                                                <Card.Subtitle className={classes.card_subHeading_product}>SKU</Card.Subtitle>
                                                                <Card.Subtitle className={classes.card_text_productDetail}>&nbsp;:&nbsp;{productDetails?.sku}</Card.Subtitle>
                                                            </span>
                                                            <span className={classes.productInfoContainer}>
                                                                <Card.Subtitle className={classes.card_subHeading_product}>Status</Card.Subtitle>
                                                                <Card.Subtitle className={classes.card_text_productDetail}>&nbsp;:&nbsp;{productDetails?.destination_sync_status}</Card.Subtitle>
                                                            </span>
                                                        </span>
                                                    </Container>
                                                </Card>
                                            </Col>
                                            <Col xs={12}>
                                                <ProductInfo
                                                    name={productDetails?.name}
                                                    type={productDetails?.product_type}
                                                    quantity={productDetails?.quantity}
                                                    api_code={productDetails?.api_code}
                                                    status={productDetails?.destination_sync_status}
                                                    sku={productDetails?.sku}
                                                    varients={productDetails?.variants}
                                                />
                                            </Col>
                                        </Row>
                                    </Container>
                                </Card>
                            </Modal.Body>
                        </Fragment>
                    )
                }

            </div>
        </Modal>
    )
}

export default IndividualProductDetails
