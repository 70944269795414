import React, {ChangeEvent, FC, useState} from 'react'
import {Button, Col, Collapse, Figure, Form, Modal, Row} from 'react-bootstrap'
import {useAppDispatch, useAppSelector} from '../../../../Redux/Store/hooks'
import {RootState} from '../../../../Redux/Store/storets'
import Loader from '../../loader/Loader'
import {FieldArray, Formik} from 'formik'
import AddAvailablePlatformSetting from './AddAvailablePlatformSetting'
import PlatformSettingWithChild from './PlatformSettingWithChild'
import {ADD_PLATFORM_SETTING, PLATFORM_SETTING, available_platform_actions} from './Interface'
import * as Yup from 'yup'
import {toast} from 'react-toastify'
import {addAvailablePlatformSettingAsync} from '../../../../Redux/Slices/platform/platformAsync'

const PlatformDetailModal: FC<{
  visible: boolean
  handleClose: () => void
  available_platform: number | null
}> = ({visible, handleClose, available_platform}) => {
  const [validated, setValidated] = useState<boolean>(false)

  const dispatch = useAppDispatch()
  const schema = Yup.object().shape({
    available_platform_action: Yup.number().required(),
    available_platform_setting: Yup.object().shape({
      input_type: Yup.string().required('Input  type is required'),
      name: Yup.string().required(),
      display_name: Yup.string().required(),
      is_required: Yup.boolean().required(),
      child: Yup.array()
        .of(
          Yup.object().shape({
            input_type: Yup.string().required('Input  type is required'),
            name: Yup.string().required(),
            display_name: Yup.string().required(),
            is_required: Yup.boolean().required(),
          })
        )
        .notRequired(),
    }),
  })

  const [addPlatformSetting, setAddPlatformSetting] = useState<boolean>(false)

  const {getPlatformDetailLoader, platformDetail, addAvailablePlatformSettingLoader} =
    useAppSelector((state: RootState) => state.platformCombination)

  const closeModalAction = () => {
    handleClose()
    setAddPlatformSetting(false)
    setValidated(false)
  }

  const handleAddPlatformSetting = async (
    event: React.FormEvent<HTMLFormElement>,
    values: ADD_PLATFORM_SETTING,
    handleResetForm: () => void
  ) => {
    event.preventDefault()
    const form = event.currentTarget
    setValidated(true)

    if (form.checkValidity()) {
      dispatch(addAvailablePlatformSettingAsync(values)).then(() => {
        toast.success('Available platform setting created successfully')
        closeModalAction()
        handleResetForm()
      })
    } else {
      toast.error('Please enter required fields properly')
    }
  }

  const InitialValues: ADD_PLATFORM_SETTING = {
    available_platform: available_platform as number,
    available_platform_action: '',
    available_platform_setting: {
      input_type: '',
      name: '',
      display_name: '',
      is_required: false,
      child: [],
    },
  }

  return (
    <Modal
      show={visible}
      onHide={() => {
        closeModalAction()
      }}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {addPlatformSetting
            ? 'Add Platform setting for ' + platformDetail?.display_name
            : getPlatformDetailLoader
            ? 'Platform is loading..'
            : 'Platform ' + platformDetail?.display_name}
        </Modal.Title>
      </Modal.Header>
      <Collapse key={'collapseOfBody'} in={!addPlatformSetting}>
        <Modal.Body>
          {getPlatformDetailLoader ? (
            <Loader />
          ) : (
            <>
              <div>
                <Row className='mb-3 justify-content-center'>
                  <Col md={'8'}>
                    <div className='row justify-content-center'>
                      <Figure className='col-md-6 border border-2 rounded p-4 text-center'>
                        <Figure.Image
                          width={171}
                          height={180}
                          alt='platform-logo'
                          src={platformDetail?.logo_ref}
                        />
                        <Figure.Caption>
                          Logo of platform {platformDetail?.display_name}
                        </Figure.Caption>
                      </Figure>
                    </div>
                    <div className='row bg-light bg-gradient rounded align-items-center'>
                      <div className=' p-1 p-md-4'>
                        <p className='h3 mb-0 text-center text-success text-break '>
                          {platformDetail?.display_name}
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </>
          )}
        </Modal.Body>
      </Collapse>
      <Collapse key={'collapseOfBody2'} in={addPlatformSetting}>
        <Modal.Body>
          <Formik
            validationSchema={schema}
            initialValues={InitialValues}
            onSubmit={() => console.log('submitting..')}
          >
            {({errors, values, handleChange, handleBlur, touched, resetForm}) => {
              return (
                <Form
                  noValidate
                  validated={validated}
                  onSubmit={(event) => handleAddPlatformSetting(event, values, () => resetForm())}
                >
                  <Form.Group as={Col} className='mb-4'>
                    <Form.Label>Select Platform Action</Form.Label>
                    <Form.Select
                      value={values.available_platform_action}
                      name='available_platform_action'
                      required
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={
                        !!errors.available_platform_action && touched.available_platform_action
                      }
                      isValid={
                        !!values.available_platform_action && touched.available_platform_action
                      }
                    >
                      <option value={''}>Select platform action </option>
                      {platformDetail?.available_platform_action.length &&
                        platformDetail?.available_platform_action?.map(
                          (platformAction: available_platform_actions, index: number) => (
                            <option key={index + 'type'} value={platformAction?.action?.id}>
                              {platformAction?.action?.action_name}
                            </option>
                          )
                        )}
                    </Form.Select>
                    <Form.Control.Feedback type='invalid'>
                      Platform type is required
                    </Form.Control.Feedback>
                  </Form.Group>
                  <AddAvailablePlatformSetting
                    errors={errors?.available_platform_setting ?? undefined}
                    touched={touched.available_platform_setting ?? undefined}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    removeThisSection={() => null}
                    key={'AvailablePlatform'}
                    sectionValues={{
                      input_type: values.available_platform_setting.input_type,
                      is_required: values.available_platform_setting.is_required,
                      name: values.available_platform_setting.name,
                      display_name: values.available_platform_setting.display_name,
                      child: [],
                    }}
                    hideCloseButton={true}
                  />
                  <FieldArray
                    name='available_platform_setting.child'
                    render={(arrayHelper) => {
                      return (
                        <div className='p-md-4 p-2 my-6 mx-2 border rounded'>
                          <div className='border border-top-0 border-bottom-2 border-left-0 border-right-0'>
                            <div className='d-flex align-items-center justify-content-between'>
                              <p className='h5'>Add platform setting child </p>
                              <Button
                                size='sm'
                                className='mb-4'
                                onClick={() =>
                                  arrayHelper.push({
                                    input_type: '',
                                    name: '',
                                    display_name: '',
                                    is_required: false,
                                  })
                                }
                              >
                                Add Setting
                              </Button>
                            </div>
                          </div>
                          <div className='my-4'>
                            {values.available_platform_setting.child?.length ? (
                              values.available_platform_setting.child.map(
                                (child: PLATFORM_SETTING, index: number) => {
                                  return (
                                    <PlatformSettingWithChild
                                      key={'platformSettingOfChild' + index}
                                      avplIndex={index}
                                      errors={
                                        errors.available_platform_setting
                                          ? errors.available_platform_setting[index]
                                          : undefined
                                      }
                                      fieldError={undefined}
                                      handleBlur={handleBlur}
                                      handleChange={handleChange}
                                      removeThisSection={() => arrayHelper.remove(index)}
                                      sectionValues={child}
                                      touched={
                                        touched.available_platform_setting
                                          ? touched.available_platform_setting[index]
                                          : undefined
                                      }
                                      hideCloseButton={false}
                                    />
                                  )
                                }
                              )
                            ) : (
                              <div className='p-4 bg-light rounded'>
                                <p className='h4'>Add setting from below button</p>
                              </div>
                            )}
                          </div>
                        </div>
                      )
                    }}
                  />
                  <div>
                    <Button
                      disabled={addAvailablePlatformSettingLoader}
                      type='submit'
                      variant='success'
                      size='sm'
                      className='mt-6 w-100 '
                    >
                      {addAvailablePlatformSettingLoader ? '...' : 'Submit'}
                    </Button>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </Modal.Body>
      </Collapse>
      <Modal.Footer className={addPlatformSetting ? 'd-none' : 'd-flex'}>
        <Button
          size='sm'
          variant='success'
          onClick={() => setAddPlatformSetting(!addPlatformSetting)}
        >
          Add Platform settings
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default PlatformDetailModal
