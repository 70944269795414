import { AxiosClient } from '../../AxiosClient/axiosClient'
import { createAsyncThunk } from '@reduxjs/toolkit'


export const getWorkflowDetailsAsync = createAsyncThunk(
    'platform-orders/getWorkflowDetails',
    async (payload: { userID: number, paginationpage: number, perPageNumber: number, id: number }, toolkit) => {
        return await AxiosClient(
            'GET', `/platform-orders/?user_id=${payload.userID}&page=${payload.paginationpage}&limit=${payload.perPageNumber}&workflow_id=${payload.id}`,
            payload, toolkit
        )
    }
)


export const updateActionStatusAsync = createAsyncThunk(
    'workflow-action-flow/update-status/updateActionStatus',
    async (payload: { querryID: number, isEnabled: boolean }, toolkit) => {
        let querryID = payload.querryID;
        let payloadBody = { isEnabled: payload.isEnabled }
        return await AxiosClient(
            'POST', `/workflow-action-flow/update-status/${querryID}`,
            payloadBody, toolkit
        )
    }
)

export const getSourcePlatformSettingsAsync = createAsyncThunk(
    'platform-setting_source/getPlatformSettings',
    async (payload: { platform_id: number, workflow_id: number }, toolkit) => {
        return await AxiosClient(
            'GET', `/platform-setting?platform_id=${payload.platform_id}&workflow_id=${payload.workflow_id}`,
            payload, toolkit
        )
    }
)

export const getDestinationPlatformSettingsAsync = createAsyncThunk(
    'platform-setting_destination/getPlatformSettings',
    async (payload: { platform_id: number, workflow_id: number }, toolkit) => {
        return await AxiosClient(
            'GET', `/platform-setting?platform_id=${payload.platform_id}&workflow_id=${payload.workflow_id}`,
            payload, toolkit
        )
    }
)
