import * as Yup from 'yup'

export type initialValueType = {
    displayName: string
    logoURL: string
    subDomain: string
}

export const initialValue: initialValueType = {
    displayName: '',
    logoURL: '',
    subDomain: ''
}

export const vadidationSchema = Yup.object().shape({
    displayName: Yup.string().required('Display name is reuired'),
    logoURL: Yup.string().required('Logo URL is required'),
})