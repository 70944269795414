import React, { Fragment, useState, useMemo } from 'react'
import { Field } from 'formik'
import { Stack, Button } from 'react-bootstrap'
import classes from '../components/FieldMapping.module.css'
import { KTSVG } from '../../../../../../../_metronic/helpers'

type subCombinationDestinationProps = {
    data: any
    Formik: any
    index: number
    parentID: string
}

type childDataType = {
    id: number
    api_name: string
    api_id: number
    api_code: any
    platform: number
    platform_field: number
    parent_data: number
}

function SubCombinationDestination(props: subCombinationDestinationProps) {
    const { Formik, parentID, data, index } = props
    const [filteredChild_one, setFilteredChild_one] = useState<childDataType[] | []>([])
    const [filteredChild_two, setFilteredChild_two] = useState<childDataType[] | []>([])
    const [filteredChild_three, setFilteredChild_three] = useState<childDataType[] | []>([])
    const [filteredChild_four, setFilteredChild_four] = useState<childDataType[] | []>([])
    const [filteredChild_five, setFilteredChild_five] = useState<childDataType[] | []>([])
    const [filteredChild_six, setFilteredChild_six] = useState<childDataType[] | []>([])
    const [filteredChild_seven, setFilteredChild_seven] = useState<childDataType[] | []>([])
    const [filteredChild_eigth, setFilteredChild_eigth] = useState<childDataType[] | []>([])
    const [showChild, setShowChild] = useState(true)
    const [new_parent, set_newParent] = useState<string>('')
    const [old_parent, set_oldParent] = useState<string>('')
    // Hit Only For First Time To Check Weather Parent Has Any Child Or Not
    useMemo(() => {
        const filteredData = data?.filter((cc: childDataType) => cc.parent_data === Number(parentID))
        setFilteredChild_one(filteredData)
        set_newParent(parentID)
    }, [parentID])

    // Handle Parent Changes Empty All Childs
    useMemo(() => {
        if (old_parent) {
            if (new_parent !== old_parent) {
                setFilteredChild_one([]);
                setFilteredChild_two([]);
                setFilteredChild_three([]);
                setFilteredChild_four([]);
                setFilteredChild_five([]);
                setFilteredChild_six([]);
                setFilteredChild_seven([]);
                setFilteredChild_eigth([]);
            }
        }
    }, [new_parent, old_parent])


    //Getting From First Component
    const handleSelectChange_one = (selectedValue: string) => {
        set_oldParent(parentID)
        const matchingItem = data.filter(
            (item: childDataType) => item.parent_data?.toString() === selectedValue
        )
        if (matchingItem) {
            setFilteredChild_two(matchingItem)
        }
    }
    // Getting From Second Component
    const handleSelectChange_two = (selectedValue: string) => {
        const matchingItem = data.filter(
            (item: childDataType) => item.parent_data?.toString() === selectedValue
        )
        if (matchingItem) {
            setFilteredChild_three(matchingItem)
        }
    }
    // Getting From Third Component
    const handleSelectChange_three = (selectedValue: string) => {
        const matchingItem = data.filter(
            (item: childDataType) => item.parent_data?.toString() === selectedValue
        )
        if (matchingItem) {
            setFilteredChild_four(matchingItem)
        }
    }
    // Getting From Fourth Component
    const handleSelectChange_four = (selectedValue: string) => {
        const matchingItem = data.filter(
            (item: childDataType) => item.parent_data?.toString() === selectedValue
        )
        if (matchingItem) {
            setFilteredChild_five(matchingItem)
        }
    }
    // Getting From Fifth Component
    const handleSelectChange_five = (selectedValue: string) => {
        const matchingItem = data.filter(
            (item: childDataType) => item.parent_data?.toString() === selectedValue
        )
        if (matchingItem) {
            setFilteredChild_six(matchingItem)
        }
    }
    // Getting From Sixth Component
    const handleSelectChange_six = (selectedValue: string) => {
        const matchingItem = data.filter(
            (item: childDataType) => item.parent_data?.toString() === selectedValue
        )
        if (matchingItem) {
            setFilteredChild_seven(matchingItem)
        }
    }
    // Getting From Seventh Component
    const handleSelectChange_seven = (selectedValue: string) => {
        const matchingItem = data.filter(
            (item: childDataType) => item.parent_data?.toString() === selectedValue
        )
        if (matchingItem) {
            setFilteredChild_eigth(matchingItem)
        }
    }

    // console.log('Formik', Formik.values.combination)
    return (
        <Fragment>
            {filteredChild_two?.length ? (
                <Stack className={classes.show_subCombination_container}>
                    {showChild ? (
                        <i
                            className='bi bi-eye-slash-fill fs-2 text-hover-danger'
                            onClick={() => setShowChild(false)}
                        ></i>
                    ) : (
                        <i
                            className='bi bi-eye-fill fs-2 text-hover-primary'
                            onClick={() => setShowChild(true)}
                        ></i>
                    )}
                </Stack>
            ) : null}

            <Stack className={showChild ? classes.show : classes.hidden}>
                {/* First Component */}
                {filteredChild_one?.length ? (
                    <Stack className={classes.individual_child_container}>
                        <Field
                            as='select'
                            name={`combination.${index}.destinationChild_array.${[0]}`}
                            id='combination_destinationField'
                            style={{ marginTop: '5px' }}
                            onChange={(event) => {
                                const selectedValue = event.target.value
                                handleSelectChange_one(selectedValue)
                                Formik.setFieldValue(`combination.${index}.destinationChild_array.${[0]}`, {
                                    parent_ID: parentID,
                                    child_ID: selectedValue,
                                })
                            }}
                            value={Formik.values.combination[index]?.destinationChild_array[0]?.child_ID || ''}
                        >
                            <option value=''>Sub Field 01</option>
                            {filteredChild_one?.map((items: childDataType) => (
                                <option key={items.id} value={items?.id}>
                                    {items?.api_name}
                                </option>
                            ))}
                        </Field>
                        <Button
                            variant='outline-light'
                            size='sm'
                            className={
                                filteredChild_one && filteredChild_two?.length === 0 ? classes.show : classes.hidden
                            }
                            onClick={() => {
                                setFilteredChild_one([])
                                Formik.setFieldValue(`combination.${index}.destinationChild_array.${[0]}`, null)
                            }}
                            style={{ cursor: 'pointer' }}
                        >
                            <KTSVG
                                path='/media/icons/duotune/abstract/abs012.svg'
                                className=' svg-icon-2x text-danger'
                            />
                        </Button>
                    </Stack>
                ) : null}
                {/* Second Component */}
                {filteredChild_two?.length ? (
                    <Stack className={classes.individual_child_container}>
                        <Field
                            as='select'
                            name={`combination.${index}.destinationChild_array.${[1]}`}
                            id='combination_destinationField'
                            style={{ marginTop: '5px' }}
                            onChange={(event) => {
                                const selectedValue = event.target.value
                                handleSelectChange_two(selectedValue)
                                Formik.setFieldValue(`combination.${index}.destinationChild_array.${[1]}`, {
                                    parent_ID: Formik.values.combination[index]?.destinationChild_array[0]?.child_ID,
                                    child_ID: selectedValue,
                                })
                            }}
                            value={
                                Formik.values.combination[index]?.destinationChild_array[1]?.child_ID || ''
                            }
                        >
                            <option value=''>Sub Field 02</option>
                            {filteredChild_two?.map((items: childDataType) => (
                                <option key={items.id} value={items?.id}>
                                    {items?.api_name}
                                </option>
                            ))}
                        </Field>
                        <Button
                            variant='outline-light'
                            size='sm'
                            className={
                                filteredChild_two && filteredChild_three?.length === 0
                                    ? classes.show
                                    : classes.hidden
                            }
                            onClick={() => {
                                setFilteredChild_two([])
                                Formik.setFieldValue(`combination.${index}.destinationChild_array.${[1]}`, null)
                            }}
                            style={{ cursor: 'pointer' }}
                        >
                            <KTSVG
                                path='/media/icons/duotune/abstract/abs012.svg'
                                className=' svg-icon-2x text-danger'
                            />
                        </Button>
                    </Stack>
                ) : null}
                {/* Third Component */}
                {filteredChild_three?.length ? (
                    <Stack className={classes.individual_child_container}>
                        <Field
                            as='select'
                            name={`combination.${index}.destinationChild_array.${[2]}`}
                            id='combination_destinationField'
                            style={{ marginTop: '5px' }}
                            onChange={(event) => {
                                const selectedValue = event.target.value
                                handleSelectChange_three(selectedValue)
                                Formik.setFieldValue(`combination.${index}.destinationChild_array.${[2]}`, {
                                    parent_ID: Formik.values.combination[index]?.destinationChild_array[1]?.child_ID,
                                    child_ID: selectedValue,
                                })
                            }}
                            value={
                                Formik.values.combination[index]?.destinationChild_array[2]?.child_ID || ''
                            }
                        >
                            <option value=''>Sub Field 03</option>
                            {filteredChild_three?.map((items: childDataType) => (
                                <option key={items.id} value={items?.id}>
                                    {items?.api_name}
                                </option>
                            ))}
                        </Field>
                        <Button
                            variant='outline-light'
                            size='sm'
                            className={
                                filteredChild_three && filteredChild_four?.length === 0
                                    ? classes.show
                                    : classes.hidden
                            }
                            onClick={() => {
                                setFilteredChild_three([])
                                Formik.setFieldValue(`combination.${index}.destinationChild_array.${[2]}`, null)
                            }}
                            style={{ cursor: 'pointer' }}
                        >
                            <KTSVG
                                path='/media/icons/duotune/abstract/abs012.svg'
                                className=' svg-icon-2x text-danger'
                            />
                        </Button>
                    </Stack>
                ) : null}
                {/* Fourth Component */}
                {filteredChild_four?.length ? (
                    <Stack className={classes.individual_child_container}>
                        <Field
                            as='select'
                            name={`combination.${index}.destinationChild_array.${[3]}`}
                            id='combination_destinationField'
                            style={{ marginTop: '5px' }}
                            onChange={(event) => {
                                const selectedValue = event.target.value
                                handleSelectChange_four(selectedValue)
                                Formik.setFieldValue(`combination.${index}.destinationChild_array.${[3]}`, {
                                    parent_ID: Formik.values.combination[index]?.destinationChild_array[2]?.child_ID,
                                    child_ID: selectedValue,
                                })
                            }}
                            value={
                                Formik.values.combination[index]?.destinationChild_array[3]?.child_ID || ''
                            }
                        >
                            <option value=''>Sub Field 04</option>
                            {filteredChild_four?.map((items: childDataType) => (
                                <option key={items.id} value={items?.id}>
                                    {items?.api_name}
                                </option>
                            ))}
                        </Field>
                        <Button
                            variant='outline-light'
                            size='sm'
                            className={
                                filteredChild_four && filteredChild_five?.length === 0
                                    ? classes.show
                                    : classes.hidden
                            }
                            onClick={() => {
                                setFilteredChild_four([])
                                Formik.setFieldValue(`combination.${index}.destinationChild_array.${[3]}`, null)
                            }}
                            style={{ cursor: 'pointer' }}
                        >
                            <KTSVG
                                path='/media/icons/duotune/abstract/abs012.svg'
                                className=' svg-icon-2x text-danger'
                            />
                        </Button>
                    </Stack>
                ) : null}
                {/* Five Component */}
                {filteredChild_five?.length ? (
                    <Stack className={classes.individual_child_container}>
                        <Field
                            as='select'
                            name={`combination.${index}.destinationChild_array.${[4]}`}
                            id='combination_destinationField'
                            style={{ marginTop: '5px' }}
                            onChange={(event) => {
                                const selectedValue = event.target.value
                                handleSelectChange_five(selectedValue)
                                Formik.setFieldValue(`combination.${index}.destinationChild_array.${[4]}`, {
                                    parent_ID: Formik.values.combination[index]?.destinationChild_array[3]?.child_ID,
                                    child_ID: selectedValue,
                                })
                            }}
                            value={
                                Formik.values.combination[index]?.destinationChild_array[4]?.child_ID || ''
                            }
                        >
                            <option value=''>Sub Field 05</option>
                            {filteredChild_five?.map((items: childDataType) => (
                                <option key={items.id} value={items?.id}>
                                    {items?.api_name}
                                </option>
                            ))}
                        </Field>
                        <Button
                            variant='outline-light'
                            size='sm'
                            className={
                                filteredChild_five?.length && filteredChild_six?.length === 0
                                    ? classes.show
                                    : classes.hidden
                            }
                            onClick={() => {
                                setFilteredChild_five([])
                                Formik.setFieldValue(`combination.${index}.destinationChild_array.${[4]}`, null)
                            }}
                            style={{ cursor: 'pointer' }}
                        >
                            <KTSVG
                                path='/media/icons/duotune/abstract/abs012.svg'
                                className=' svg-icon-2x text-danger'
                            />
                        </Button>
                    </Stack>
                ) : null}
                {/* Sixth Component */}
                {filteredChild_six?.length ? (
                    <Stack className={classes.individual_child_container}>
                        <Field
                            as='select'
                            name={`combination.${index}.destinationChild_array.${[5]}`}
                            id='combination_destinationField'
                            style={{ marginTop: '5px' }}
                            onChange={(event) => {
                                const selectedValue = event.target.value
                                handleSelectChange_six(selectedValue)
                                Formik.setFieldValue(`combination.${index}.destinationChild_array.${[5]}`, {
                                    parent_ID: Formik.values.combination[index]?.destinationChild_array[4]?.child_ID,
                                    child_ID: selectedValue,
                                })
                            }}
                            value={
                                Formik.values.combination[index]?.destinationChild_array[5]?.child_ID || ''
                            }
                        >
                            <option value=''>Sub Field 06</option>
                            {filteredChild_six?.map((items: childDataType) => (
                                <option key={items.id} value={items?.id}>
                                    {items?.api_name}
                                </option>
                            ))}
                        </Field>
                        <Button
                            variant='outline-light'
                            size='sm'
                            className={
                                filteredChild_six?.length && filteredChild_seven?.length === 0
                                    ? classes.show
                                    : classes.hidden
                            }
                            onClick={() => {
                                setFilteredChild_six([])
                                Formik.setFieldValue(`combination.${index}.destinationChild_array.${[5]}`, null)
                            }}
                            style={{ cursor: 'pointer' }}
                        >
                            <KTSVG
                                path='/media/icons/duotune/abstract/abs012.svg'
                                className=' svg-icon-2x text-danger'
                            />
                        </Button>
                    </Stack>
                ) : null}
                {/* Seventh Component */}
                {filteredChild_seven?.length ? (
                    <Stack className={classes.individual_child_container}>
                        <Field
                            as='select'
                            name={`combination.${index}.destinationChild_array.${[6]}`}
                            id='combination_destinationField'
                            style={{ marginTop: '5px' }}
                            onChange={(event) => {
                                const selectedValue = event.target.value
                                handleSelectChange_seven(selectedValue)
                                Formik.setFieldValue(`combination.${index}.destinationChild_array.${[6]}`, {
                                    parent_ID: Formik.values.combination[index]?.destinationChild_array[5]?.child_ID,
                                    child_ID: selectedValue,
                                })
                            }}
                            value={
                                Formik.values.combination[index]?.destinationChild_array[6]?.child_ID || ''
                            }
                        >
                            <option value=''>Sub Field 07</option>
                            {filteredChild_seven?.map((items: childDataType) => (
                                <option key={items.id} value={items?.id}>
                                    {items?.api_name}
                                </option>
                            ))}
                        </Field>
                        <Button
                            variant='outline-light'
                            size='sm'
                            className={
                                filteredChild_seven?.length && filteredChild_eigth?.length === 0
                                    ? classes.show
                                    : classes.hidden
                            }
                            onClick={() => {
                                setFilteredChild_seven([])
                                Formik.setFieldValue(`combination.${index}.destinationChild_array.${[6]}`, null)
                            }}
                            style={{ cursor: 'pointer' }}
                        >
                            <KTSVG
                                path='/media/icons/duotune/abstract/abs012.svg'
                                className=' svg-icon-2x text-danger'
                            />
                        </Button>
                    </Stack>
                ) : null}
                {/* Eigth Component */}
                {filteredChild_eigth?.length ? (
                    <Stack className={classes.individual_child_container}>
                        <Field
                            as='select'
                            name={`combination.${index}.destinationChild_array.${[7]}`}
                            id='combination_destinationField'
                            style={{ marginTop: '5px' }}
                            onChange={(event) => {
                                const selectedValue = event.target.value
                                // handleSelectChange_seven(selectedValue)
                                Formik.setFieldValue(`combination.${index}.destinationChild_array.${[7]}`, {
                                    parent_ID: Formik.values.combination[index]?.destinationChild_array[6]?.child_ID,
                                    child_ID: selectedValue,
                                })
                            }}
                            value={
                                Formik.values.combination[index]?.destinationChild_array[7]?.child_ID || ''
                            }
                        >
                            <option value=''>Sub Field 08</option>
                            {filteredChild_eigth?.map((items: childDataType) => (
                                <option key={items.id} value={items?.id}>
                                    {items?.api_name}
                                </option>
                            ))}
                        </Field>
                        <Button
                            variant='outline-light'
                            size='sm'
                            className={filteredChild_eigth?.length ? classes.show : classes.hidden}
                            onClick={() => {
                                setFilteredChild_eigth([])
                                Formik.setFieldValue(`combination.${index}.destinationChild_array.${7}`, null)
                            }}
                            style={{ cursor: 'pointer' }}
                        >
                            <KTSVG
                                path='/media/icons/duotune/abstract/abs012.svg'
                                className='svg-icon-2x text-danger'
                            />
                        </Button>
                    </Stack>
                ) : null}
            </Stack>
        </Fragment>
    )
}

export default SubCombinationDestination
