import {createAsyncThunk, createSlice, isAnyOf} from '@reduxjs/toolkit'
import {getCountData, getgetPlatformCountData} from '../../services/DashboardService'

interface DashboardState {
  loader: boolean
  data: any
  CountData: any
}

const initialState: DashboardState = {
  loader: false,
  data: {},
  CountData: null,
}

export const getCountDataApi = createAsyncThunk(
  'CountData/api/get',
  async (Id: any, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await getCountData(Id)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)
export const getPlatformCountDataApi = createAsyncThunk(
  'PlatformCountData/api/get',
  async (Id: any, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await getgetPlatformCountData(Id)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const WorkflowSlice: any = createSlice({
  name: 'Workflow',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getCountDataApi.fulfilled, (state, action) => {
      state.CountData = action.payload
    })
  },
})
export default WorkflowSlice.reducer
