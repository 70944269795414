import React, { useState } from 'react'
import { Container, Row, Col, Button, Spinner } from 'react-bootstrap'
import { Formik, Form, ErrorMessage } from 'formik'
import TextError from 'src/ErrorMessage/TextError'
import classes from './gererateReport.module.css'
import { initialState, initialStateTypes, validationSchems, orderTypesParameters, statusTypes } from './utils'
import { generateWorkflowReportAsync } from 'src/Redux/Slices/orderDetails/orderDetailsAsync'
import { toast } from 'react-toastify'
import axios from 'axios'
type reportFields = {
  workflowID: number
  setModalShow: (status: boolean) => void
}

function ReportFields({ workflowID, setModalShow }: reportFields) {
  const [loading, setLoading] = useState(false)

  const onSubmit = async (values: initialStateTypes) => {
    const payload = {
      from_date: values.startDate,
      to_date: values.endDate,
      type: values.orderType,
      destination_sync_status: values.destination_sync_status,
      workflow_id: workflowID
    }
    try {
      setLoading(true)
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/workflow-export-request/`, payload)
      if (response?.status === 200) {
        toast.success(response?.data?.message)
      }
    }
    catch (error) { toast.error('Somthing Went Wrong.') }
    finally {
      setLoading(false)
      setModalShow(false)
    }
  }

  return (
    <Container>
      <Formik initialValues={initialState} validationSchema={validationSchems} onSubmit={onSubmit}>
        {(Formik) => {
          return (
            <Form>
              <Row>
                <Col xs={12} md={6}>
                  <label className='form-label'>Start Date</label>
                  <input
                    type='date'
                    name='startDate'
                    className='form-control mb-3'
                    placeholder='DD-MM-YYYY'
                    value={Formik.values.startDate}
                    onChange={Formik.handleChange}
                    style={{ border: (Formik.touched.startDate && Formik.errors.startDate) ? '1px solid red' : '' }}
                  />
                  {/* <ErrorMessage name='startDate' component={TextError} /> */}
                </Col>

                <Col xs={12} md={6}>
                  <label className='form-label'>End Date</label>
                  <input
                    type='date'
                    name='endDate'
                    className='form-control mb-3'
                    placeholder='DD-MM-YYYY'
                    value={Formik.values.endDate}
                    onChange={Formik.handleChange}
                    disabled={!Formik.values.startDate}
                    style={{ border: (Formik.touched.endDate && Formik.errors.endDate) ? '1px solid red' : '' }}
                  />
                  {/* <ErrorMessage name='endDate' component={TextError} /> */}
                </Col>
              </Row>

              <Row>
                <Col xs={12} md={6}>
                  <label className='form-label'>Order Type</label>
                  <select
                    name='orderType'
                    className='form-select mb-3'
                    placeholder='Select Order Type'
                    value={Formik.values.orderType}
                    onChange={Formik.handleChange}
                    style={{ border: (Formik.touched.orderType && Formik.errors.orderType) ? '1px solid red' : '' }}
                  >
                    <option selected>Select Order Type</option>
                    {
                      orderTypesParameters.map((items) => {
                        return (
                          <option key={items.id} value={items.value}>
                            {items.name}
                          </option>
                        )
                      })
                    }
                  </select>
                  {/* <ErrorMessage name='orderType' component={TextError} /> */}
                </Col>

                {/* ---------------------------------- */}
                <Col xs={12} md={6}>
                  <label className='form-label'>Status</label>
                  <select
                    name='destination_sync_status'
                    className='form-select mb-3'
                    placeholder='Select Status'
                    value={Formik.values.destination_sync_status}
                    onChange={Formik.handleChange}
                    style={{ border: (Formik.touched.destination_sync_status && Formik.errors.destination_sync_status) ? '1px solid red' : '' }}
                  >
                    <option selected>Select Status</option>
                    {
                      statusTypes.map((items) => {
                        return (
                          <option key={items.id} value={items.value}>
                            {items.name}
                          </option>
                        )
                      })
                    }
                  </select>
                  {/* <ErrorMessage name='destination_sync_status' component={TextError} /> */}
                </Col>
              </Row>
              <Row>
                <Col xs={12} className={classes.button_container}>
                  <Button disabled={loading} type='reset' size='sm' variant='secondary'>
                    Reset
                  </Button>
                  <Button type='submit' size='sm' variant='primary'>
                    {loading && (
                      <Spinner
                        className='me-2'
                        as='span'
                        animation='border'
                        size='sm'
                        role='status'
                        aria-hidden='true'
                      />
                    )}
                    {loading ? 'Generating...' : 'Submit'}
                  </Button>
                </Col>
              </Row>
            </Form>
          )
        }}
      </Formik>
    </Container>
  )
}

export default ReportFields
