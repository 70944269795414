import { AxiosClient } from '../../AxiosClient/axiosClient'
import { createAsyncThunk } from '@reduxjs/toolkit'



export const getSourcePlatformFieldsAsync = createAsyncThunk(
    'platform-fields/getSourcePlatformFields',
    async (payload: { source_platform_id: number }, toolkit) => {
        return await AxiosClient(
            'GET', `/platform-fields?platform_id=${payload.source_platform_id}`,
            payload, toolkit
        )
    }
)

export const getDestinationPlatformFieldsAsync = createAsyncThunk(
    'platform-fields/getDestinationPlatformFields',
    async (payload: { destination_platform_id: number }, toolkit) => {
        return await AxiosClient(
            'GET', `/platform-fields?platform_id=${payload.destination_platform_id}`,
            payload, toolkit
        )
    }
)

export const createWorkflowFieldMappingAsync = createAsyncThunk(
    'workflowfield-mappingdata/createWorkflowFieldMapping',
    async (payload: any, toolkit) => {
        return await AxiosClient(
            'POST', `/workflowfield-mappingdata`, payload, toolkit
        )
    }
)

export const getWorkflowWiseMappingAsync = createAsyncThunk(
    'workflowfield-mappingdata/getWorkflowWiseMapping',
    async (payload: { workflowID: number }, toolkit) => {
        return await AxiosClient(
            'GET', `/workflowfield-mappingdata?workflow_id=${payload.workflowID}`,
            payload, toolkit
        )
    }
)

export const getMappedDataByIDAsync = createAsyncThunk(
    'workflowfield-mappingdata/getMappedDataByID',
    async (payload: { mappingID: number }, toolkit) => {
        return await AxiosClient(
            'GET', `/workflowfield-mappingdata?id=${payload.mappingID}`,
            payload, toolkit
        )
    }
)

export const updateWorkflowFieldMappingAsync = createAsyncThunk(
    'workflowfield-mappingdata/updateWorkflowFieldMapping',
    async (payload: any, toolkit) => {
        let id = payload?.id;
        delete payload.id;
        return await AxiosClient(
            'POST', `/workflowfield-mappingdata/update/${id}`, payload, toolkit
        )
    }
)
