import * as Yup from 'yup'

export type FieldSelectionProps = {
  workflowID: number
  sourceplatform_ID: number
  destinationplatform_ID: number
  setModalShow: (data: boolean) => void
}

export type formikInitialValues = {
  sourceParentValue: any
  destinationParentValue: any
  combination: any
  default_sourcePlatformData: null
  default_destinationPlatformData: any
  default_destinationPlatformData_str : any
  default_case_source_destination_platformData: boolean
  map_internally: boolean
}

//:::Case 01 When Conbination: Source And Destination Input Type Are 'SELECT'
export const validationSchemaAllFields = Yup.object({
  // default_destinationPlatformData: Yup.string().required('Destination Option Is Required'),
  combination: Yup.array()
    .of(
      Yup.object().shape({
        source_platform_data: Yup.string().required('Required'), 
        destination_platform_data: Yup.string().required('Required'), 
      })
    )
    .required('') // these constraints are shown if and only if inner constraints are satisfied
})
//:::Case 02 When Conbination: Source Input Type Is 'TEXT' And Destination Input Type Is 'SELECT'
export const validationSchema_sourceTextField = Yup.object({
  // default_destinationPlatformData: Yup.string().required('Destination Option Is Required'),
  combination: Yup.array()
    .of(
      Yup.object().shape({
        source_platform_data_str: Yup.string().required('Required'), 
        destination_platform_data: Yup.string().required('Required'), 
      })
    )
    .required('') // these constraints are shown if and only if inner constraints are satisfied
})
//:::Case 03 When Conbination: Source Input Type Is 'SELECT' And Destination Input Type Is 'TEXT'
export const validationSchema_destinationTextField = Yup.object({
  // default_destinationPlatformData_str: Yup.string().required('Destination Option Is Required'),
  combination: Yup.array()
    .of(
      Yup.object().shape({
        source_platform_data: Yup.string().required('Required'), 
        destination_platform_data_str: Yup.string().required('Required'), 
      })
    )
    .required('') // these constraints are shown if and only if inner constraints are satisfied
})
//:::Case 04  When Conbination: Source And Destination Input Type Are 'TEXT'
export const validationSchema_source_destinationTextField = Yup.object({
  // default_destinationPlatformData_str: Yup.string().required('Destination Option Is Required'),
  combination: Yup.array()
    .of(
      Yup.object().shape({
        source_platform_data_str: Yup.string().required('Required'), 
        destination_platform_data_str: Yup.string().required('Required'), 
      })
    )
    .required('') // these constraints are shown if and only if inner constraints are satisfied
})

// Only Destination i.e. Default Destination
export const validationSchemaSingleField = Yup.object({
  default_destinationPlatformData: Yup.string().required('Destination Option Is Required'),
})

