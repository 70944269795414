import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import {
    getFieldMappingByAvailableWorkflowAsync,
    getSourceMappingAsync,
    getDestionationMappingAsync,
} from './AvailableFlowMappingAsync'

// 'fieldMappingByAvailableWorkflow' data type start //
export type mappingParems = {
    id: number
    display_name: string
    field_code_source: string
    field_code_destination: string
    short_description: string | null
    isRequired: boolean
    available_workflow_flow: number
    platform_source : string
    platform_destination : string
}

export type availableActionFlow = {
    id: number
    display_name: string
    available_workflow: number
    available_workflow_action1: number
    available_workflow_action2: number
}

export type availableFlowMappingType = {
    id: number
    name: string
    description: string
    destination_available_platform: any
    source_available_platform: any
    available_actions: any
    available_action_flows: availableActionFlow[]
    available_workflow_field_mapping: mappingParems[]
}
// 'fieldMappingByAvailableWorkflow' data type end //

// 'Source and Destination' mapping data type start //
export type field_data = {
    id: number,
    api_name: string,
    api_id: number | string,
    api_code: string | number | null,
    platform: number,
    platform_field: number,
    parent_data: string | number | null,
}
export type mappingData = {
    id: number,
    platform: number,
    field_type: string,
    api_code: string,
    api_id: string | number | null,
    api_name: string,
    display_name: string,
    fetchOnInit: boolean,
    input_type: string,
    description: string,
    field_data: field_data[]
}
// 'Source and Destination' mapping data type end //


type availableFLowMappingSlice = {
    loading: boolean
    sourceDestinationMappingLoader: boolean
    fieldMappingByAvailableWorkflow: availableFlowMappingType
    sourceMappings: mappingData[]
    destinationMappings: mappingData[]
}

const initialState: availableFLowMappingSlice = {
    loading: false,
    fieldMappingByAvailableWorkflow: {} as availableFlowMappingType,
    sourceDestinationMappingLoader: false,
    sourceMappings: [] as mappingData[],
    destinationMappings: [] as mappingData[]
}

const availableFLowMappingSlice = createSlice({
    name: 'availableFLowMappingSlice',
    initialState,
    reducers: { emptyAvailableFLowMapping: () => initialState },
    //Pending Statements:
    extraReducers: (builder) => {
        builder.addMatcher(isAnyOf(
            getFieldMappingByAvailableWorkflowAsync.pending,
        ), (state, action) => {
            state.loading = true
        })
        builder.addMatcher(isAnyOf(
            getSourceMappingAsync.pending, getDestionationMappingAsync.pending,
        ), (state, action) => {
            state.sourceDestinationMappingLoader = true
        })
        //Success Statements:
        builder.addMatcher(isAnyOf(getFieldMappingByAvailableWorkflowAsync.fulfilled), (state, action) => {
            state.loading = false
            state.fieldMappingByAvailableWorkflow = action.payload
        })

        builder.addMatcher(isAnyOf(getSourceMappingAsync.fulfilled), (state, action) => {
            state.sourceDestinationMappingLoader = false
            state.sourceMappings = action.payload?.data
            
        })

        builder.addMatcher(isAnyOf(getDestionationMappingAsync.fulfilled), (state, action) => {
            state.sourceDestinationMappingLoader = false
            state.destinationMappings = action.payload?.data
        })
        // Reject Statements:
        builder.addMatcher(isAnyOf(
            getFieldMappingByAvailableWorkflowAsync.rejected,
        ), (state, action) => {
            state.loading = false
        })
        builder.addMatcher(isAnyOf(
            getSourceMappingAsync.rejected,getDestionationMappingAsync.rejected,
        ), (state, action) => {
            state.sourceDestinationMappingLoader = false
        })
    },
})

export const { emptyAvailableFLowMapping } = availableFLowMappingSlice.actions;
export default availableFLowMappingSlice.reducer;
