import React, { Fragment } from 'react'
import { Form } from 'react-bootstrap'
import { FormikProps } from 'formik'
import { formikInitialValues } from './utils'
import { mappingData } from 'src/Redux/Slices/workflowFieldMapping/AvailableFlowMappingSlice'


type defaultOptionsProps = {
    Formik: FormikProps<formikInitialValues>
    sourceSideField: mappingData
    destinationSideField: mappingData
    destinationPlatformName: string
    destinationOptionName: string
}



function DefaultOptionMapping(props: defaultOptionsProps) {
    const { Formik, sourceSideField, destinationSideField, destinationPlatformName, destinationOptionName } = props

    return (
        <Fragment>
            {destinationSideField?.input_type === 'TEXT' ? (
                <Form.Control
                    type='text'
                    placeholder={`Enter ${destinationPlatformName} ${destinationOptionName}`}
                    name='default_destinationOption'
                    value={Formik.values.default_destinationOption.destination_platform_data_str}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        Formik.setFieldValue('default_destinationOption', {
                            isDefault: true,
                            source_platform_data: null,
                            destination_platform_data: null,
                            destination_platform_data_str: event.target.value,
                        })
                    }}
                />
            ) : (
                <Form.Select
                    as='select'
                    name='default_destinationOption'
                    value={Formik.values.default_destinationOption.destination_platform_data}
                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                        let selectedValue = event.target.value
                        Formik.setFieldValue('default_destinationOption', {
                            isDefault: true,
                            source_platform_data: null,
                            destination_platform_data: selectedValue,
                            destination_platform_data_str: null,
                        })
                    }}
                >
                    <option value=''>Default {destinationPlatformName} {destinationOptionName}</option>
                    {destinationSideField?.field_data?.map((ele) => (
                        ele?.parent_data === null &&
                        <option key={ele.id} value={ele.id}>
                            {ele.api_name}
                        </option>

                    ))}
                </Form.Select>
            )}
        </Fragment>
    )
}

export default DefaultOptionMapping
