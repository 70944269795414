import {createSlice, isAnyOf} from '@reduxjs/toolkit'
import { USER_ROLES } from '../../../utils/constants'

interface LoggedInData {
  date_joined: string | null
  email: string
  first_name: string
  forgot_password_token: string | null
  id: number | null
  is_active: null | boolean
  is_superuser: null | boolean
  last_login: string
  last_name: string
  organization: number | null
  user_type: USER_ROLES
  username: null | string
}

const initialState: {loginUser: LoggedInData, loggedInByAdminData:LoggedInData, loggedInByAdmin:boolean } = {
  loginUser: {
    date_joined: null,
    email: '',
    first_name: '',
    forgot_password_token: null,
    id: null,
    is_active: null,
    is_superuser: null,
    last_login: '',
    last_name: '',
    organization: null,
    user_type: 'USER',
    username: null,
  },
  loggedInByAdmin:false,
  loggedInByAdminData:{
    date_joined: null,
    email: '',
    first_name: '',
    forgot_password_token: null,
    id: null,
    is_active: null,
    is_superuser: null,
    last_login: '',
    last_name: '',
    organization: null,
    user_type: 'USER',
    username: null,
  },
}

const loggedInSlice = createSlice({
  name: 'loggedInSlice',
  initialState,
  reducers: {
    setLoggedInData: (state, action) => {
      state.loginUser = action.payload
    },

    setLoggedInByAdminData : (state)=> {
        let adminDetailFromLocalStorage = localStorage.getItem("admin_detail")
        if(adminDetailFromLocalStorage){
          state.loggedInByAdmin = true
          state.loggedInByAdminData = JSON.parse(adminDetailFromLocalStorage)
        }
    }
  },
})

export const {setLoggedInData, setLoggedInByAdminData } = loggedInSlice.actions
export default loggedInSlice.reducer
