import React, { FC, Fragment } from 'react'
import AvailablePlatformCombination from './AvailablePlatformCombination'
import CreateWorkflowModal from 'src/app/modules/workflow/components/CreateWorkflow/CreateWorkflowModal'
import { dataDerivedFromCard } from 'src/Redux/Slices/stateSave/connectPlatformDataSlice'
import { useAppDispatch, useAppSelector } from 'src/Redux/Store/hooks'


const DashboardPage: FC = () => {

  return (
    <Fragment>
      <AvailablePlatformCombination />
      <HandleCreateWorkflowModalCmponent />
    </Fragment>
  )
}

const DashboardWrapper: FC = () => {
  return (
    <Fragment>
      <DashboardPage />
    </Fragment>
  )
}
export { DashboardWrapper }


const HandleCreateWorkflowModalCmponent = () => {
  const { unActiveCardData } = useAppSelector((state) => state.createWorkflowCardData)
  const dispatch = useAppDispatch()
  return (
    <Fragment>
      <CreateWorkflowModal
        OpenModal={unActiveCardData.dashboardIntegratedModal}
        // closeModal={setCreateWorkflowOpen}
        closeModal={() => dispatch(dataDerivedFromCard({
          ...unActiveCardData,
          dashboardIntegratedModal: false,
        }))}
        dashboardIntegratedModal={unActiveCardData.dashboardIntegratedModal}
        sp_id={unActiveCardData.sp_id}
        dp_id={unActiveCardData.dp_id}
        sp_aid={unActiveCardData.sp_aid}
        dp_aid={unActiveCardData.dp_aid}
        sourceImage={unActiveCardData.sourceImage}
        destinationImage={unActiveCardData.destinationImage}
        sourceName={unActiveCardData.sourceName}
        destinationName={unActiveCardData.destinationName}
        description={unActiveCardData.description}
        actionCollectionArray={unActiveCardData?.actionCollection}
        action_flows={unActiveCardData?.action_flows}
        flowID={unActiveCardData?.flowID}
      />
    </Fragment>
  )
}
