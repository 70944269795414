import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit'
import { login, register } from '../../services/AuthService'
import { AxiosClient } from 'src/Redux/AxiosClient/axiosClient'

interface AuthState {
  loader: boolean
  data: any
  error: string
  invitedUserData:{email:string|null, name:string|null}
}

const initialState: AuthState = {
  loader: false,
  data: [],
  error: '',
  invitedUserData:{
    email:null,
    name:null
  }
}

export const loginApi = createAsyncThunk(
  'login/api/post',
  async (loginData: any, { rejectWithValue, fulfillWithValue }) => {
    console.log('loginData', loginData)
    try {
      const result = await login(loginData)
      return result
    } catch (error) {
      return rejectWithValue({ error })
    }
  }
)

export const signupApi = createAsyncThunk(
  'signup/api/post',
  async (registerData: any, { rejectWithValue, fulfillWithValue }) => {
    console.log('registerData', registerData)
    try {
      const result = await register(registerData)
      return result
    } catch (error) {
      return rejectWithValue({ error })
    }
  }
)

export const verifyInviteUserAuthAsync = createAsyncThunk(
  "auth/verifyInviteUserAuthAsync",
  async function(params : { organization:string, token:string }, toolkit){
   return await AxiosClient("GET",`/varify-invited-user/`,[],toolkit, params )
  }
)

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(loginApi.pending, signupApi.pending), (state) => {
      state.loader = true
    })

    builder.addMatcher(isAnyOf(loginApi.fulfilled), (state, action) => {
      state.loader = false
      state.data = action.payload
      // Save user data to localStorage
      // sessionStorage.setItem('user', action.payload.data.data.id)
      // sessionStorage.setItem('user_details', JSON.stringify(action.payload.data.data))
      localStorage.setItem('user', action.payload.data.data.id)
      localStorage.setItem('user_details', JSON.stringify(action.payload.data.data))
      window.location.reload()
    })

    builder.addMatcher(isAnyOf(loginApi.rejected, signupApi.rejected), (state, action) => {
      state.loader = false
      // state.error = action.payload.message;
    })
  },
  reducers:{
    setInvitedUserData:(state, action:{payload:{name:string, email:string}})=>{
      state.invitedUserData.name = action.payload.name
      state.invitedUserData.email = action.payload.email
    }
  }
})

export const { setInvitedUserData } = authSlice.actions
export default authSlice.reducer
