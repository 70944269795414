import React, {FC} from 'react'
import {Button, Card} from 'react-bootstrap'
import {InfoCardProp} from '../AdminDashboard'
import {Link} from 'react-router-dom'
import style from '../style.module.css'

const DashboardInfoCard: FC<InfoCardProp> = ({
  title = '',
  subtitle = '',
  count = 0,
  buttonTitle = '',
  buttonLink = '',
}) => {
  return (
    <Card
      draggable={true}
      style={{width: '20rem', minHeight: '14rem'}}
      className={
        'bg-hover-light m-2 border border-1 border-secondary cursor-default ' + style.infoCard
      }
    >
      <Card.Header
        as={'h6'}
        className='fs-4 fw-bold  align-content-center py-5'
        style={{minHeight: '40px'}}
      >
        {title}
      </Card.Header>
      <Card.Body className='d-flex flex-column justify-content-between'>
        <Card.Title>
          {' '}
          Total : {count}{' '}
          <span style={{textTransform: 'lowercase'}} className='fs-6 fw-300'>
            {title}
          </span>{' '}
        </Card.Title>
        <Card.Text>{subtitle}</Card.Text>
        <Link to={buttonLink} className='btn btn-secondary py-1'>
          {buttonTitle}
        </Link>
      </Card.Body>
    </Card>
  )
}

export default DashboardInfoCard
