import React, { Fragment, useState, useEffect } from 'react';
import { Row, Col, Form, Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import ScreenWidth from 'src/app/modules/widgets/components/ScreenWidth';


function ParentField() {
    const [screenWidth] = ScreenWidth()
    const [sourcePlatform, setSourcePlatform] = useState<string>('')
    const [destinationPlatform, setDestinationPlatform] = useState<string>('')

    const {
        getMappedDataByID
    } = useSelector((state: {
        MappedFields: {
            getMappedDataByID: {
                data: {
                    source_field: { display_name: string },
                    destination_field: { display_name: string }
                }
            }
        }
    }) => state.MappedFields)

    useEffect(() => {
        setSourcePlatform(getMappedDataByID?.data?.source_field?.display_name)
        setDestinationPlatform(getMappedDataByID?.data?.destination_field?.display_name)
    }, [getMappedDataByID])

    return (
        <Fragment>
            <Container className='mb-7'>
                <Row>
                    <Col xs={12} md={6}>
                        <Form.Control
                            size="lg"
                            type="text"
                            value={sourcePlatform}
                            onChange={(event) => setSourcePlatform(event.target.value)}
                            disabled
                            style={{
                                marginBottom: (screenWidth => 320 && screenWidth <= 425) ? '4px' : '',
                                backgroundColor: 'whitesmoke', color: 'black', 
                            }}
                        />
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Control
                            size="lg"
                            type="text"
                            value={destinationPlatform}
                            onChange={(event) => setDestinationPlatform(event.target.value)}
                            disabled
                            style={{ backgroundColor: 'whitesmoke', color: 'black' }}
                        />
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default ParentField
