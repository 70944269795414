import React, { Fragment, useState, useEffect } from 'react'
import { Form, Button, Container, Stack } from 'react-bootstrap'
import Select from 'react-select'
import AppEditor from 'src/utils/AppEditor'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import classes from '../../components/availableFlows.module.css'
type ChildSettingComponentProps = {
    apiName: string
    inputType: string
    displayName: string
    previousValue: string
    shortCodeVisibility: boolean
    settings: { api_id: string; display_name: string }[]
    childDataCollectionFun: (data: { name: string, value: string }) => void
    shortCode: string[] | []
    description: string
}
type multiselectValues = { label: string; value: string }

function ChildSettingComponent(props: ChildSettingComponentProps) {
    const { inputType, apiName, displayName, previousValue, settings, childDataCollectionFun, shortCodeVisibility, shortCode, description } = props
    const [isActive, setisActive] = useState<boolean>(previousValue == 'True' ? true : false)
    const [isShortCodeVisible, setShortCodeVisible] = useState(false)
    const [copyMarkup, setCopyMarkup] = useState(false)
    const [buttonString, setButtonString] = useState('')
    const [textBasedValue, setTextBasedValue] = useState<string>(previousValue || '')
    //::: Multi-Select Input Field
    const [preSelectedValues, setPreSelectedValues] = useState<multiselectValues[] | []>()

    const handleTextBasedFields = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        setTextBasedValue(inputValue)
    }
    const handleSelectBasedFields = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const inputValue = event.target.value;
        setTextBasedValue(inputValue)
    }
    const handleActiveBaseFields = (event: React.ChangeEvent<HTMLInputElement>) => {
        setisActive(event.target.checked);
    }
    const handleButtonBaseFields = (event: React.MouseEvent<HTMLButtonElement>) => {
        setisActive(!isActive);
    };

    // ::: Multi-Select
    // Step 01: Preparing Dropdownoptions Getting From API
    useEffect(() => {
        if (settings.length) {
            let dropdownData = settings.map((vals: Record<string, any>) => {
                return {
                    label: vals?.display_name,
                    value: vals?.api_id,
                }
            })
            setPreSelectedValues(dropdownData)
        }
    }, [settings])
    // step 02 : Setting Default Values
    const defaultSelectedValues = settings.filter((option: { api_id: string | number }) => {
        return previousValue?.includes(option.api_id.toString())
    })
    const defaultSelectedOptions = defaultSelectedValues.map((value: Record<string, any>) => ({
        value: value.api_id,
        label: value.display_name,
    }))

    const [selectedValues, setSelectedValues] = useState(defaultSelectedOptions)
    // Step 03: Taking All Selected values
    const handleMultiSelectChange = (selectedOptions: any) => {
        setSelectedValues(selectedOptions)
    }

    useEffect(() => {
        if (inputType == 'address' || inputType == 'email' || inputType == 'integer' || inputType == 'phone' || inputType == 'select' || inputType == 'text' || inputType == 'multiline_text') {
            childDataCollectionFun({ name: apiName, value: textBasedValue });
        }
        if (inputType == 'boolean' || inputType == 'button') {
            childDataCollectionFun({ name: apiName, value: isActive ? 'True' : 'False' });
        }
        // if (inputType == 'multiselect' || inputType == 'multiline_text') {
        if (inputType == 'multiselect') {
            let selectedValue_string: string = ''
            selectedValues.map((item: Record<string, any>, index: number) => {
                if (index == selectedValues.length - 1) {
                    return (selectedValue_string += item?.value)
                } else {
                    return (selectedValue_string += item?.value + ',')
                }
            })
            childDataCollectionFun({
                name: apiName,
                value: selectedValue_string || '',
            })
        }
    }, [textBasedValue, isActive, settings, selectedValues])


    const handleCopyMarkup = (data: string) => {
        setCopyMarkup(true)
        setButtonString(data)

        setTimeout(() => {
            setCopyMarkup(false)
            setButtonString('')
        }, 1000);
    }

    let inputElement;
    if (inputType === 'address') {
        inputElement = (
            <Form.Group controlId='exampleForm.ControlTextarea1'>
                <Form.Label className='mt-3'>{displayName}</Form.Label>
                <Form.Control value={textBasedValue} onChange={handleTextBasedFields} as='textarea' rows={2} />
                {/* Copy To CLipboard Logic--------------------------------------------------- */}
                <Stack className={classes.shortCodeDisplayContainer}>
                    <p className='text-muted text-hover-primary fw-bold mt-1 cursor-pointer'
                        onClick={() => setShortCodeVisible(!isShortCodeVisible)}
                    >
                        {shortCodeVisibility ? 'View Short Codes' : null}
                    </p>
                </Stack>
                {
                    isShortCodeVisible ? (
                        <Stack className={classes.shortCodes}>
                            {
                                shortCode?.length ? shortCode.map((ele: string) => {
                                    return (
                                        <Fragment key={ele}>
                                            <CopyToClipboard text={ele} >
                                                <Button
                                                    key={ele}
                                                    size='sm'
                                                    variant={(copyMarkup && buttonString === ele) ? 'outline-success' : 'outline-primary'}
                                                    className={`px-3 py-2 border border-1 ${(copyMarkup && buttonString === ele) ? 'border-success' : 'border-primary'} rounded text-hover-dark fs-sm-8 inline-block`}
                                                    onClick={() => handleCopyMarkup(ele)}
                                                >
                                                    {(copyMarkup && buttonString === ele) ? <span className='fs-sm-8'>Copied !</span> : ele}
                                                </Button>
                                            </CopyToClipboard>
                                        </Fragment>
                                    )
                                }) : null
                            }
                        </Stack>
                    ) : null
                }
                {/* Copy To CLipboard Logic--------------------------------------------------- */}
            </Form.Group>
        )
    }
    else if (inputType === 'boolean') {
        inputElement = (
            <Fragment>
                <Form className='d-flex justify-content-start align-items-baseline gap-3'>
                    <Form.Label className='fs-6 text-muted text-hover-primary'>{displayName}</Form.Label>
                    <Form.Check
                        type="switch"
                        className='mt-7'
                        id="custom-switch"
                        // label={displayName}
                        checked={isActive}
                        onChange={handleActiveBaseFields}
                    />
                </Form>
                {
                    description &&
                    <Form.Text className='ms-7'><span style={{ color: 'red', fontWeight: 'bolder' }}>*</span>{description}</Form.Text>
                }
            </Fragment>
        )
    }
    else if (inputType === 'button') {
        inputElement = (
            <Button
                className='me-3 mt-5'
                size='sm'
                variant={isActive ? 'success' : 'primary'}
                onClick={handleButtonBaseFields}
            >
                {displayName}
            </Button>
        )
    }
    else if (inputType === 'email' || inputType === 'integer' || inputType === 'phone' || inputType === 'text') {
        inputElement = (
            <Form.Group controlId='exampleForm.ControlTextarea1'>
                <Form.Label className='mt-3'>{displayName}</Form.Label>
                <Form.Control
                    type={
                        inputType == 'email' ? 'email' : inputType == 'integer' ? 'number' :
                            inputType == 'phone' ? 'number' : 'text'}
                    value={textBasedValue} onChange={handleTextBasedFields}
                />
                {/* Copy To CLipboard Logic--------------------------------------------------- */}
                <Stack className={classes.shortCodeDisplayContainer}>
                    <p className='text-muted text-hover-primary fw-bold mt-1 cursor-pointer'
                        onClick={() => setShortCodeVisible(!isShortCodeVisible)}
                    >
                        {shortCodeVisibility ? 'View Short Codes' : null}
                    </p>
                </Stack>
                {
                    isShortCodeVisible ? (
                        <Stack className={classes.shortCodes}>
                            {
                                shortCode?.length ? shortCode.map((ele: string) => {
                                    return (
                                        <Fragment key={ele}>
                                            <CopyToClipboard text={ele} >
                                                <Button
                                                    key={ele}
                                                    size='sm'
                                                    variant={(copyMarkup && buttonString === ele) ? 'outline-success' : 'outline-primary'}
                                                    className={`px-3 py-2 border border-1 ${(copyMarkup && buttonString === ele) ? 'border-success' : 'border-primary'} rounded text-hover-dark fs-sm-8 inline-block`}
                                                    onClick={() => handleCopyMarkup(ele)}
                                                >
                                                    {(copyMarkup && buttonString === ele) ? <span className='fs-sm-8'>Copied !</span> : ele}
                                                </Button>
                                            </CopyToClipboard>
                                        </Fragment>
                                    )
                                }) : null
                            }
                        </Stack>
                    ) : null
                }
                {/* Copy To CLipboard Logic--------------------------------------------------- */}
            </Form.Group>
        )

    }
    // else if (inputType === 'multiselect' || inputType === 'multiline_text') {
    else if (inputType === 'multiselect') {
        inputElement = (
            <Fragment>
                <Form.Label className='fs-6 mt-5'>{displayName}</Form.Label>
                <Select
                    name={displayName}
                    placeholder={displayName}
                    value={selectedValues}
                    options={preSelectedValues}
                    isMulti
                    className='basic-multi-select'
                    classNamePrefix='select'
                    onChange={handleMultiSelectChange}
                />
                {/* Copy To ClipBoard Logic---------------------------------------------------- */}
                <Stack className={classes.shortCodeDisplayContainer}>
                    <p className='text-muted text-hover-primary fw-bold mt-1 cursor-pointer'
                        onClick={() => setShortCodeVisible(!isShortCodeVisible)}
                    >
                        {shortCodeVisibility ? 'View Short Codes' : null}
                    </p>
                </Stack>
                {
                    isShortCodeVisible ? (
                        <Stack className={classes.shortCodes}>
                            {
                                shortCode?.length ? shortCode.map((ele: string) => {
                                    return (
                                        <Fragment key={ele}>
                                            <CopyToClipboard text={ele} >
                                                <Button
                                                    key={ele}
                                                    size='sm'
                                                    variant={(copyMarkup && buttonString === ele) ? 'outline-success' : 'outline-primary'}
                                                    className={`px-3 py-2 border border-1 ${(copyMarkup && buttonString === ele) ? 'border-success' : 'border-primary'} rounded text-hover-dark fs-sm-8 inline-block`}
                                                    onClick={() => handleCopyMarkup(ele)}
                                                >
                                                    {(copyMarkup && buttonString === ele) ? <span className='fs-sm-8'>Copied !</span> : ele}
                                                </Button>
                                            </CopyToClipboard>
                                        </Fragment>
                                    )
                                }) : null
                            }
                        </Stack>
                    ) : null
                }
                {/* Copy To ClipBoard Logic---------------------------------------------------- */}
            </Fragment>
        )
    }
    else if (inputType === 'multiline_text') {
        inputElement = (
            <Fragment>
                <div style={{ height: '150px', overflow: 'auto' }}>
                    <AppEditor
                        placeholder={displayName}
                        setValue={setTextBasedValue}
                        value={textBasedValue}
                        editorStyle={{ marginTop: '15px', marginBottom: '25px', height: '90px' }}
                    />
                </div>
                {/* Copy To ClipBoard Logic---------------------------------------------------- */}
                <Stack className={classes.shortCodeDisplayContainer}>
                    <p className='text-muted text-hover-primary fw-bold mt-1 cursor-pointer'
                        onClick={() => setShortCodeVisible(!isShortCodeVisible)}
                    >
                        {shortCodeVisibility ? 'View Short Codes' : null}
                    </p>
                </Stack>
                {
                    isShortCodeVisible ? (
                        <Stack className={classes.shortCodes}>
                            {
                                shortCode?.length ? shortCode.map((ele: string) => {
                                    return (
                                        <Fragment key={ele}>
                                            <CopyToClipboard text={ele} >
                                                <Button
                                                    key={ele}
                                                    size='sm'
                                                    variant={(copyMarkup && buttonString === ele) ? 'outline-success' : 'outline-primary'}
                                                    className={`px-3 py-2 border border-1 ${(copyMarkup && buttonString === ele) ? 'border-success' : 'border-primary'} rounded text-hover-dark fs-sm-8 inline-block`}
                                                    onClick={() => handleCopyMarkup(ele)}
                                                >
                                                    {(copyMarkup && buttonString === ele) ? <span className='fs-sm-8'>Copied !</span> : ele}
                                                </Button>
                                            </CopyToClipboard>
                                        </Fragment>
                                    )
                                }) : null
                            }
                        </Stack>
                    ) : null
                }
                {/* Copy To ClipBoard Logic---------------------------------------------------- */}
            </Fragment>
        )
    } else if (inputType === 'select') {
        inputElement = (
            <Fragment>
                <Form.Label className='mt-3'>{displayName}</Form.Label>
                <Form.Select value={textBasedValue} onChange={handleSelectBasedFields}>
                    <option value='' disabled>
                        Select Option
                    </option>
                    {
                        settings?.map((items: any) =>
                            <option key={items.id} value={items?.api_id}>{items?.display_name}</option>
                        )
                    }
                </Form.Select>
                {/* Copy To ClipBoard Logic---------------------------------------------------- */}
                <Stack className={classes.shortCodeDisplayContainer}>
                    <p className='text-muted text-hover-primary fw-bold mt-1 cursor-pointer'
                        onClick={() => setShortCodeVisible(!isShortCodeVisible)}
                    >
                        {shortCodeVisibility ? 'View Short Codes' : null}
                    </p>
                </Stack>
                {
                    isShortCodeVisible ? (
                        <Stack className={classes.shortCodes}>
                            {
                                shortCode?.length ? shortCode.map((ele: string) => {
                                    return (
                                        <Fragment key={ele}>
                                            <CopyToClipboard text={ele} >
                                                <Button
                                                    key={ele}
                                                    size='sm'
                                                    variant={(copyMarkup && buttonString === ele) ? 'outline-success' : 'outline-primary'}
                                                    className={`px-3 py-2 border border-1 ${(copyMarkup && buttonString === ele) ? 'border-success' : 'border-primary'} rounded text-hover-dark fs-sm-8 inline-block`}
                                                    onClick={() => handleCopyMarkup(ele)}
                                                >
                                                    {(copyMarkup && buttonString === ele) ? <span className='fs-sm-8'>Copied !</span> : ele}
                                                </Button>
                                            </CopyToClipboard>
                                        </Fragment>
                                    )
                                }) : null
                            }
                        </Stack>
                    ) : null
                }
                {/* Copy To ClipBoard Logic---------------------------------------------------- */}
            </Fragment>
        );
    }

    return (
        <Container className='ms-5'>
            {inputElement}
        </Container>
    )
}

export default ChildSettingComponent
