import { AxiosClient } from '../../AxiosClient/axiosClient'
import { createAsyncThunk } from '@reduxjs/toolkit'



export const getAllMappingsByWorkflowIDAsync = createAsyncThunk(
    '/workflowfield-mappingdata?workflow_id/getAllMappingsByWorkflowID',
    async (payload: { workflowID: number, page: number, limit: number }, toolkit) => {
        return await AxiosClient(
            'GET', `/workflowfield-mappingdata?workflow_id=${payload.workflowID}&page=${payload.page}&limit=${payload.limit}`,
            payload, toolkit
        )
    }
)