import React, { Fragment } from 'react'
import { orderShipmentType } from './utils'
import { Row, Col, Card, Button, Stack } from 'react-bootstrap'
import classes from './orderDetail.module.css'

type shipmentProps = {
  orderShipment: orderShipmentType[]
}

function Shipment({ orderShipment }: shipmentProps) {
  return (
    <Fragment>
      <Card className='mt-5'>
        <Card.Header className={classes.card_header}>Shipment</Card.Header>

        {orderShipment?.length !== 0 ? ( // If Length Then Proceed :::
          <Fragment>
            <Row className='ps-10 pe-8 mt-3'>
              <Col xs={4}>
                <Card.Subtitle className={classes.card_subHeading_product}>
                  Tracking Number
                </Card.Subtitle>
              </Col>
              <Col xs={4}>
                <Card.Subtitle className={classes.card_subHeading}>Company</Card.Subtitle>
              </Col>
              <Col xs={4}>
                <Card.Subtitle className={classes.card_subHeading}>Shipment Status</Card.Subtitle>
              </Col>
            </Row>

            {orderShipment?.map((item: orderShipmentType) => {
              return (
                <Card.Body key={item.id} className='border-bottom border-secondary'>
                  <Row>
                    <Col xs={4}>
                      <Card.Text className={classes.card_text_productDetail}>
                        {item.tracking_number}
                      </Card.Text>
                    </Col>
                    <Col xs={4}>
                      <Card.Text className={classes.card_text}>{item.tracking_company}</Card.Text>
                    </Col>
                    <Col xs={4}>
                      <Card.Text className={classes.card_text}>{item.shipment_status}</Card.Text>
                    </Col>
                    <Col xs={12} className='mt-7'>
                      {item.platform_order_shipment_line_items.map((ele) => {
                        return (
                          <Stack key={ele.id} className={classes.shipment_product_details}>
                            <p className='mb-3 text-muted fw-bold'>
                              <span>Quantity:&nbsp;</span>
                              <span className='text-dark text-hover-primary'>{ele.quantity}</span>
                            </p>
                            <p className='mb-3 text-muted fw-bold'>
                              <span>Product ID:&nbsp;</span>
                              <span className='text-dark text-hover-primary'>{ele.api_id}</span>
                            </p>
                            <p className='mb-3 text-muted fw-bold'>
                              <span>Product Key:&nbsp;</span>
                              <span className='text-dark text-hover-primary'>
                                {ele.product_api_id}
                              </span>
                            </p>
                          </Stack>
                        )
                      })}
                    </Col>

                    <Stack className={classes.track_order_button}>
                      <a href={item.tracking_url} target='_blank'>
                        <Button variant='primary' size='sm'>
                          Track Online
                        </Button>
                      </a>
                    </Stack>
                  </Row>
                </Card.Body>
              )
            })}
          </Fragment>
        ) : (
          <Stack className={classes.no_record_message}>
            <p className='mb-3 text-dark fw-bold'>Currently No Shipment Records !</p>
          </Stack>
        )}
      </Card>
    </Fragment>
  )
}

export default Shipment
