import React, { Fragment, useEffect, useState } from 'react'
import { KTSVG } from '../../../../../../_metronic/helpers'
import { Modal } from 'react-bootstrap'

type PlatformNotAvailableProps = {
    icon: string
    onSave: () => void
    showModal: boolean
    platformName: string
    setShowModal: (status: boolean) => void
    set_isSourcePlatformConnectModalOpen: (status: boolean) => void
    set_isDestinationPlatformConnectModalOpen: (status: boolean) => void
}

function PlatformNotAvailable(props: PlatformNotAvailableProps) {
    const {
        setShowModal, platformName, icon, onSave, showModal,
        set_isSourcePlatformConnectModalOpen, set_isDestinationPlatformConnectModalOpen
    } = props
    const [show, setShow] = useState(false)

    const handleClose = () => {
        setShow(false)
        setShowModal(false)
        set_isSourcePlatformConnectModalOpen(false)
        set_isDestinationPlatformConnectModalOpen(false)
    }
    const handleShow = () => setShow(true)

    useEffect(() => {
        setShow(showModal)
    }, [showModal])

    return (
        <Fragment>
            <Modal show={show} onHide={handleClose}>
                <div className='modal-content'>
                    <div className='modal-header pb-2 justify-content-end border-0'>
                        <div
                            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                            onClick={handleClose}
                        >
                            <KTSVG
                                path='/media/icons/duotune/arrows/arr061.svg'
                                className='svg-icon svg-icon-2x'
                            />
                        </div>
                    </div>

                    <div className='modal-body py-0 text-center'>
                        <div
                            className='m-auto text-center d-flex align-items-center justify-content-center border border-warning border-4 rounded-circle p-4'
                            style={{ width: '90px', height: '90px' }}
                        >
                            <KTSVG path={icon} className='svg-icon svg-icon-5x text-warning' />
                        </div>

                        <div className='py-4 mt-5'>
                            <h2>Connect {platformName} Account.</h2>
                            <p className='mt-4 fw-bold text-muted fs-6'>
                                Connect New {platformName} Account For Integrations.
                            </p>
                        </div>
                    </div>
                    <div className='modal-footer mb-5 border-0 justify-content-center'>
                        <button
                            type='button'
                            className='btn btn-light'
                            data-bs-dismiss='modal'
                            onClick={handleClose}
                        >
                            Cancel
                        </button>
                        <button
                            type='button'
                            className='btn btn-light-danger'
                            data-bs-dismiss='modal'
                            onClick={() => {
                                handleClose()
                                onSave()
                            }}
                        >
                            <div className='px-4'>Connect Platform</div>
                        </button>
                    </div>
                </div>
            </Modal>
        </Fragment>
    )
}

export default PlatformNotAvailable
