import React, { Fragment, useState, useEffect } from 'react'
import { Modal, Container, Row, Col, Form as BootstrapForm, Spinner, Button } from 'react-bootstrap'
import './style.css'
import CloseButton from '../components/CloseButton'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik'
import { toast } from 'react-toastify'
import {
  getMappedDataByIDAsync,
  getSourcePlatformFieldsAsync,
  getDestinationPlatformFieldsAsync,
  updateWorkflowFieldMappingAsync,
} from '../../../../../../../Redux/Slices/workflowFieldMapping/FieldMappingAsync'
import { emptyUpdatedMappedFields } from '../../../../../../../Redux/Slices/workflowFieldMapping/FieldMappingUpdateSlice'
import { OnlyDefaultDestinationProvided } from './OnlyDefaultDestinationProvided'
import { PreviouslySavedMappings } from './PreviouslySavedMappings'
import { ReselectCombination } from './ReselectCombination'
import { DefaultMappedValue } from './DefaultMappedValue'
import { DefaultTextMapping } from './DefaultTextMapping'
import ParentField from './ParentField'
import {
  initialValues,
  validationSchema_WithCombination,
  validationSchema_WithoutCombination,
} from './ulits'
import { AnimatedLoader } from 'src/app/modules/widgets/components/CustomLoaders'

type updateMappingProps = {
  modalShow: boolean
  setModalShow: (status: boolean) => void
  mappingID: any
  sourceplatform_ID: any
  destinationplatform_ID: any
  setMappingID: (data: number) => void
  fetchUsers: (data: number) => void
}

type filtered_value_obj = {
  isDefault: boolean;
  source_platform_data: any;
  source_platform_data_str: any;
  destination_platform_data: any;
  destination_platform_data_str: any;
}

function UpdateMapping(props: updateMappingProps) {
  const {
    modalShow,
    setModalShow,
    mappingID,
    sourceplatform_ID,
    destinationplatform_ID,
    setMappingID,
    fetchUsers,
  } = props
  const dispatch = useDispatch()
  const [conminedState, setCombinedState] = useState<any | []>([])
  const [isCombinationArrayLength, setIsCombinationArrayLength] = useState(false)
  const [filtered_default_destination, set_filtered_default_destination] = useState<any>()
  const { loading, getMappedDataByID, getSourceFields, getDestinationFields } = useSelector(
    (state: any) => state.MappedFields
  )

  const { updateLoader, updateWorkflowFieldMapping } = useSelector(
    (state: any) => state.updatedMappings
  )

  // To get pre selected values
  useEffect(() => {
    if (mappingID !== 0 && mappingID !== undefined) {
      dispatch(getMappedDataByIDAsync({ mappingID: mappingID }) as any)
    }
  }, [mappingID])

  // To get all values for filteration precess
  useEffect(() => {
    if (sourceplatform_ID && destinationplatform_ID) {
      dispatch(getSourcePlatformFieldsAsync({ source_platform_id: sourceplatform_ID }) as any)
      dispatch(
        getDestinationPlatformFieldsAsync({ destination_platform_id: destinationplatform_ID }) as any
      )
    }
  }, [sourceplatform_ID, destinationplatform_ID])

  // Combining all Source and Destination
  useEffect(() => {
    if (getSourceFields?.data && getDestinationFields?.data) {
      setCombinedState([...getSourceFields?.data, ...getDestinationFields?.data])
    }
  }, [getSourceFields, getDestinationFields])

  // Default Destination Filteration
  useEffect(() => {
    const defaultDestination = conminedState.find(
      (item: any) => item?.id === getMappedDataByID?.data?.destination_field?.id
    )
    set_filtered_default_destination(defaultDestination)
  }, [conminedState, getMappedDataByID])

  const onSubmit = (values: any) => {
    let payload = {}
    // console.log('values', values)
    if (values.combination.length) {
      let filtered_combination = [] as filtered_value_obj[];
      values.combination.map((cc) => {
        let source_platform_data_child = '', destination_platform_data_child = ''
        if (cc?.sourceChild_array?.length) {
          // If there is any child for source side then get the last child value
          let s_child = cc.sourceChild_array.filter((ele) => ele !== null)
          let s_child_last_index = s_child[s_child?.length - 1]
          let s_child_last_index_value = s_child_last_index?.child_ID
          source_platform_data_child = s_child_last_index_value
        }
        if (cc?.destinationChild_array?.length) {
          // If there is any child for destination side then get the last child value
          let d_child = cc.destinationChild_array.filter((ele) => ele !== null)
          let d_child_last_index = d_child[d_child?.length - 1]
          let d_child_last_index_value = d_child_last_index?.child_ID
          destination_platform_data_child = d_child_last_index_value
        }
        let filtered_value_obj = {
          id: cc?.id,
          isDefault: false,
          source_platform_data: source_platform_data_child ? source_platform_data_child : cc.source_platform_data?.child_created_whileMappingFormation ? cc.source_platform_data?.child_created_whileMappingFormation : cc.source_platform_data,
          source_platform_data_str: cc.source_platform_data_str,
          destination_platform_data: destination_platform_data_child ? destination_platform_data_child : cc.destination_platform_data?.child_created_whileMappingFormation ? cc?.destination_platform_data?.child_created_whileMappingFormation : cc.destination_platform_data,
          destination_platform_data_str: cc.destination_platform_data_str,
        }
        filtered_combination.push(filtered_value_obj)
        // console.log('values.combination', cc)
        // console.log('filtered_value_obj', filtered_value_obj)
        // console.log('source_platform_data_child', source_platform_data_child)
        // console.log('destination_platform_data_child', destination_platform_data_child)
      })


      // Before Child Logic:::
      // let workflow_field_mapping_data_array = [...values.combination, values.defaultDestination]

      // After Child Logic
      let workflow_field_mapping_data_array = [...filtered_combination, values.defaultDestination]
      payload = {
        id: getMappedDataByID?.data?.id,
        workflow: getMappedDataByID?.data?.workflow,
        source_field: getMappedDataByID?.data?.source_field?.id,
        destination_field: getMappedDataByID?.data?.destination_field?.id,
        isInternalMapping: false,
        isDefault: false,
        default_value: null,
        workflow_field_mapping_data: workflow_field_mapping_data_array,
      }
    }

    if (values.combination.length === 0 && values.field_to_default_text_textField) {
      payload = {
        id: getMappedDataByID?.data?.id,
        workflow: getMappedDataByID?.data?.workflow,
        source_field: null,
        destination_field: getMappedDataByID?.data?.destination_field?.id,
        isInternalMapping: false,
        isDefault: true,
        default_value: values.field_to_default_text_textField,
        workflow_field_mapping_data: [],
      }
    }


    // if (values.combination.length === 0 && !values.field_to_default_text_textField) {
    if (values.combination.length === 0 && values.only_default_destination_provided) {
      payload = {
        id: getMappedDataByID?.data?.id,
        workflow: getMappedDataByID?.data?.workflow,
        source_field: null,
        destination_field: getMappedDataByID?.data?.destination_field?.id,
        isInternalMapping: false,
        isDefault: false,
        default_value: null,
        workflow_field_mapping_data: [values.only_default_destination_provided],
      }
    }

    if (values.combination.length === 0 && values.defaultDestination.isDefault === true) {
      payload = {
        id: getMappedDataByID?.data?.id,
        workflow: getMappedDataByID?.data?.workflow,
        source_field: getMappedDataByID?.data?.source_field?.id,
        destination_field: getMappedDataByID?.data?.destination_field?.id,
        isInternalMapping: values.map_internally,
        isDefault: false,
        default_value: null,
        workflow_field_mapping_data: [values.defaultDestination],
      }
    }
    // console.log('payload', payload)
    dispatch(updateWorkflowFieldMappingAsync(payload) as any)
  }

  useEffect(() => {
    if (updateWorkflowFieldMapping?.status_code === 201) {
      toast.success(updateWorkflowFieldMapping?.massage)
      setModalShow(false)
      setMappingID(0)
    }
    if (
      updateWorkflowFieldMapping?.data?.status_code >= 300 &&
      updateWorkflowFieldMapping?.data?.status_code <= 600
    ) {
      toast.error(updateWorkflowFieldMapping?.data?.massage)
      setModalShow(false)
      dispatch(emptyUpdatedMappedFields() as any)
      setMappingID(0)
    }
  }, [updateWorkflowFieldMapping])
  return (
    <Fragment>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(true)}
        size='xl'
        centered
        style={{ backgroundColor: '#0007' }}
      >
        <div
          onClick={() => {
            setModalShow(false)
            fetchUsers(1)
          }}
        >
          <CloseButton />
        </div>
        <Fragment>
          {loading ? (
            <div className='d-flex justify-content-center mt-20 mb-10'>
              <AnimatedLoader />
            </div>
          ) : (
            <Formik
              initialValues={initialValues}
              // validationSchema={isCombinationArrayLength ? validationSchema_WithCombination : validationSchema_WithoutCombination}
              onSubmit={onSubmit}
            >
              {(Formik) => {
                if (Formik.values.combination.length !== 0) {
                  setIsCombinationArrayLength(true)
                }
                if (Formik.values.combination.length == 0) {
                  setIsCombinationArrayLength(false)
                }

                return (
                  <Container className='p-7'>
                    <p className='mt-3 mb-10 fw-bold fs-3 text-justify text-primary'>Update Mapping</p>
                    <Form>
                      <Row>
                        <Col xs={12}>
                          {/*List View: When Source & Destination has value but Main isDefault is false */}
                          {/* {getMappedDataByID?.data?.source_field &&
                              getMappedDataByID?.data?.destination_field &&
                              getMappedDataByID?.data?.isDefault == false ? (
                              <PreviouslySavedMappings
                                Formik={Formik}
                                previousMapping={
                                  getMappedDataByID?.data?.workflow_field_mapping_data
                                }
                              />
                            ) : null} */}
                        </Col>
                        <Col xs={12}>
                          <ParentField />
                        </Col>
                        <Col xs={12}>
                          {/* When Source & Destination has value but Main isDefault is false */}
                          {getMappedDataByID?.data?.source_field &&
                            getMappedDataByID?.data?.destination_field &&
                            getMappedDataByID?.data?.isDefault == false ? (
                            <DefaultMappedValue //::Goes With Combination Reselection
                              Formik={Formik}
                              defaultMapping={filtered_default_destination}
                              getMappedDataByID={getMappedDataByID}
                            />
                          ) : null}
                        </Col>
                        <Col>
                          {/* When Source & Destination has value but Main isDefault is false */}
                          {getMappedDataByID?.data?.source_field &&
                            getMappedDataByID?.data?.destination_field ? (
                            <ReselectCombination
                              Formik={Formik}
                              conminedState={conminedState}
                              getMappedDataByID={getMappedDataByID}
                            />
                          ) : null}
                        </Col>
                        <Col xs={12}>
                          {/* When Source == null & Destination has value but Main isDefault is false */}
                          {getMappedDataByID?.data?.source_field == null &&
                            getMappedDataByID?.data?.destination_field &&
                            getMappedDataByID?.data?.isDefault == false ? (
                            <OnlyDefaultDestinationProvided
                              Formik={Formik}
                              previousMapping={getMappedDataByID?.data}
                              conminedState={conminedState}
                            />
                          ) : null}
                        </Col>
                        <Col xs={12}>
                          {/* When Source == null & Destination has value but Main isDefault is true */}
                          {getMappedDataByID?.data?.source_field == null &&
                            getMappedDataByID?.data?.destination_field &&
                            getMappedDataByID?.data?.isDefault == true ? (
                            <DefaultTextMapping
                              Formik={Formik}
                              previousMapping={getMappedDataByID?.data}
                              conminedState={conminedState}
                            />
                          ) : null}
                        </Col>
                      </Row>
                      <div className='updateButton_div'>
                        <Button
                          variant='primary'
                          type='submit'
                          size='sm'
                          className='w-xs-100 w-md-25 mt-10 mb-10'
                          style={{ backgroundColor: '#487fbe' }}
                        >
                          {updateLoader && (
                            <Spinner
                              className='me-2'
                              as='span'
                              animation='border'
                              size='sm'
                              role='status'
                              aria-hidden='true'
                            />
                          )}
                          {updateLoader ? 'Updating Changes...' : 'Update'}
                        </Button>
                      </div>
                    </Form>
                  </Container>
                )
              }}
            </Formik>
          )}
        </Fragment>
      </Modal>
    </Fragment>
  )
}

export default UpdateMapping

