
export type initialSateKeys = {
    display_name: string,
    identifier: string,
    client_id: string | number,
    client_secret: string | number,
    token_id: string | number,
    token_secret: string | number,
    refresh_token: string | number,
}

export const initialState: initialSateKeys = {
    display_name: '',
    identifier: '',
    client_id: '',
    client_secret: '',
    token_id: '',
    token_secret: '',
    refresh_token: '',
}

export type platformAuthRequirement = {
    id: number
    input_type: string
    name: string
    href: string
    display_name: string
    platform: number
}
export type platformValues = {
    id: number
    type: string
    name: string
    isOauth: boolean
    logo_ref: string
    isActive: boolean
    organization: any
    display_name: string
    auth_route_name: string
    available_platform_auth_requirement: platformAuthRequirement[]
}