import React, {FC} from 'react'
import {Image} from 'react-bootstrap'
import Table from 'react-bootstrap/Table'
import {FormattedDate} from 'react-intl'
import { useAppSelector } from 'src/Redux/Store/hooks'
import { RootState } from 'src/Redux/Store/storets'
import { ORGANIZATION_DATA } from 'src/_interfaces/Organization.Interface'

const OrganizationsTable: FC<{ tableIndexMultiple:number }> = ({tableIndexMultiple}) => {

  const { organizations } = useAppSelector((state:RootState)=>state.organization)

  return (
    <div>
      <Table responsive striped bordered hover className='h-50' cellPadding={2} >
        <thead className='border border-bottom-2 border-secondary'>
          <tr>
            {[
              '#',
              'Logo',
              'Organization Name',
              'Subdomain',
            //   'User Name',
            //   'User Email',
              'Created Date',
            ].map((head, index) => {
              return (
                <th className='fw-bold fs-5 align-middle text-center' key={'head' + index}>
                  {head}
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          { organizations.length ? organizations.map((organization:ORGANIZATION_DATA, orgIndex:number) => {
            return (
              <tr className='align-middle text-center' key={'org' + orgIndex}>
                <td>{orgIndex + 1 + tableIndexMultiple }</td>
                <td>
                  {' '}
                  <Image
                    style={{height: '50px', width: '80px', objectFit: 'contain'}}
                    alt={organization.display_name}
                    src={organization?.logo}
                    rounded
                  />
                </td>
                <td>{organization.display_name}</td>
                <td>{organization.subdomain}</td>
                {/* <td>{organization?.user}</td> */}
                {/* <td>{organization?.user}</td> */}
                <td>
                  <FormattedDate
                    value={new Date(organization.created_at)}
                    year='numeric'
                    month='long'
                    day='2-digit'
                  />
                </td>
              </tr>
            )
          }) : 
           <tr><td colSpan={5} className='text-center fs-4 fw-bold ' >No data found</td></tr>
          }
        </tbody>
      </Table>
    </div>
  )
}

export default OrganizationsTable
