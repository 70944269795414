import React, { useState, useEffect } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import {
    getSourcePlatformFieldsAsync,
    getDestinationPlatformFieldsAsync,
    createWorkflowFieldMappingAsync,
    getWorkflowWiseMappingAsync,
} from '../../../../../../../Redux/Slices/workflowFieldMapping/FieldMappingAsync'
import { emptyMappedFields } from '../../../../../../../Redux/Slices/workflowFieldMapping/FieldMappingSlice'
import { useDispatch, useSelector } from 'react-redux'
import classes from '../components/FieldMapping.module.css'
import { Container, Row, Col, Form as ReactBootstrapForm, Button, Spinner, Stack } from 'react-bootstrap'
import { AnimatedLoader } from 'src/app/modules/widgets/components/CustomLoaders'

type FieldDetaultProps = {
    workflowID: number
    sourceplatform_ID: number
    destinationplatform_ID: number
    setModalShow: (data: boolean) => void
}

type formikinitialState = { selectedValue: string, defaultText: string }
const initialState: formikinitialState = {
    selectedValue: '',
    defaultText: ''
}
const validationSchema = Yup.object({
    defaultText: Yup.string().required('Required'),
})
function FieldDefault(props: FieldDetaultProps) {
    const { workflowID, sourceplatform_ID, destinationplatform_ID, setModalShow } = props
    const dispatch = useDispatch()
    const [conminedState, setCombinedState] = useState<any | []>([])
    const { loading, createLoader, getSourceFields, getDestinationFields, createFieldMapping } = useSelector(
        (state: any) => state.MappedFields
    )
    const WorkflowDetails = useSelector((state: any) => state.Workflow.WorkflowDetails)?.data

    useEffect(() => {
        dispatch(getSourcePlatformFieldsAsync({ source_platform_id: sourceplatform_ID }) as any)
        dispatch(
            getDestinationPlatformFieldsAsync({ destination_platform_id: destinationplatform_ID }) as any
        )
    }, [sourceplatform_ID, destinationplatform_ID])

    useEffect(() => {
        if (getSourceFields?.data && getDestinationFields?.data) {
            setCombinedState([...getSourceFields?.data, ...getDestinationFields?.data])
        }
    }, [getSourceFields, getDestinationFields])


    const onSubmit = async (values: any) => {
        const payload = {
            workflow: workflowID,
            source_field: null,
            destination_field: values.selectedValue,
            isInternalMapping: false,
            isDefault: true,
            default_value: values.defaultText,
            workflow_field_mapping_data: [],
        }
        console.log('payload', payload)
        dispatch(createWorkflowFieldMappingAsync(payload) as any)
    }

    useEffect(() => {
        if (createFieldMapping.status_code === 201) {
            toast.success(createFieldMapping.massage)
            dispatch(emptyMappedFields())
            dispatch(getWorkflowWiseMappingAsync({ workflowID: workflowID }) as any)
            setModalShow(false)
        }
        if (createFieldMapping.status_code > 300) {
            toast.error(createFieldMapping.massage)
            dispatch(getWorkflowWiseMappingAsync({ workflowID: workflowID }) as any)
            dispatch(emptyMappedFields())
            setModalShow(false)
        }
    }, [createFieldMapping])

    const formik = useFormik({
        initialValues: initialState,
        onSubmit,
        validationSchema: validationSchema,
    })
    return (
        <Container className='px-15 py-7'>
            {
                loading ? <div className='d-flex justify-content-center mt-7 mb-10'><AnimatedLoader /></div> : (
                    <form onSubmit={formik.handleSubmit}>
                        <Row className='mt-10' >
                            <Col xs={12} md={6}>
                                <ReactBootstrapForm.Label>Select Field</ReactBootstrapForm.Label>
                                <ReactBootstrapForm.Select
                                    name='selectedValue'
                                    value={formik.values.selectedValue}
                                    style={{ backgroundColor: 'white', color: 'black', fontWeight: '600', marginBottom: '10px' }}
                                    onChange={(event) => { formik.handleChange(event) }}
                                >
                                    <option value='' disabled>
                                        Select Option
                                    </option>
                                    {conminedState?.map((items: any) => (
                                        <option key={items.id} value={items?.id}>
                                            {`${sourceplatform_ID == items?.platform ? WorkflowDetails?.source_platform
                                                ?.name
                                                : WorkflowDetails?.destination_platform
                                                    ?.name} - ` + items?.display_name}
                                        </option>
                                    ))}
                                </ReactBootstrapForm.Select>
                            </Col>
                            {
                                formik.values.selectedValue &&
                                <Col xs={12} md={6}>
                                    <ReactBootstrapForm.Group className="mb-3" controlId="formBasicEmail">
                                        <ReactBootstrapForm.Label>Default Text</ReactBootstrapForm.Label>
                                        <ReactBootstrapForm.Control
                                            type="text"
                                            placeholder="Default Text"
                                            name='defaultText'
                                            value={formik.values.defaultText}
                                            onChange={(event) => { formik.handleChange(event) }}
                                            style={{ backgroundColor: 'white', color: 'black' }}
                                        />
                                    </ReactBootstrapForm.Group>
                                    {formik.touched.defaultText && formik.errors.defaultText ? <p style={{ color: 'red', marginLeft: '7px' }}>{formik.errors.defaultText}</p> : null}
                                </Col>
                            }

                            <Col xs={12}>
                                <div className={formik.values.selectedValue?.length ? classes.visible : classes.hidden}>
                                    <Button
                                        variant='primary'
                                        type='submit'
                                        size='sm'
                                        className='w-xs-100 w-md-25 mb-10'
                                        style={{ backgroundColor: '#487fbe' }}
                                    >
                                        {createLoader && (
                                            <Spinner
                                                className='me-2'
                                                as='span'
                                                animation='border'
                                                size='sm'
                                                role='status'
                                                aria-hidden='true'
                                            />
                                        )}
                                        {loading ? 'Creating Mapping...' : 'Create Mapping'}
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </form>
                )
            }
        </Container>
    )
}

export default FieldDefault

