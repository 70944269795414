import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import { getAllMappingsByWorkflowIDAsync } from './MappingListAsync'

type mappingListSlice = {
    mappingLoader: boolean
    mappingList: any
}

const initialState: mappingListSlice = {
    mappingLoader: false,
    mappingList: []
}

const mappingListSlice = createSlice({
    name: 'mappingListSlice',
    initialState,
    reducers: { emptyMappingList: () => initialState },

    //Pending Statements:
    extraReducers: (builder) => {
        builder.addMatcher(isAnyOf(
            getAllMappingsByWorkflowIDAsync.pending,
        ), (state, action) => {
            state.mappingLoader = true
        })
        //Success Statements:
        builder.addMatcher(isAnyOf(getAllMappingsByWorkflowIDAsync.fulfilled), (state, action) => {
            state.mappingLoader = false
            state.mappingList = action.payload?.data
        })

        // Reject Statements:
        builder.addMatcher(isAnyOf(
            getAllMappingsByWorkflowIDAsync.rejected,
        ), (state, action) => {
            state.mappingLoader = false
        })
    },
})

export const { emptyMappingList } = mappingListSlice.actions;
export default mappingListSlice.reducer;
