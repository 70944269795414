import React from 'react'

function OrganizationDashboard() {
    return (
        <div>
            OrganizationDashboard
        </div>
    )
}

export default OrganizationDashboard
