import React, {useEffect, useState} from 'react'
import {Button, Pagination} from 'react-bootstrap'
import {PageTitle} from 'src/_metronic/layout/core'
import AddAvailableWorkflowModal from './components/AddAvailableWorkflowModal'
import {useAppDispatch, useAppSelector} from 'src/Redux/Store/hooks'
import {getAvailablePlatformApi} from 'src/app/redux/slices/PlatformSlices'
import Loader from '../../loader/Loader'
import AvailableWorkflowsTable from './components/AvailableWorkflowsTable'
import {setCurrentPage} from 'src/Redux/Slices/availableWorkflow/availableWorkflowSlice'
import {getAvailableWorkflowsAsync} from 'src/Redux/Slices/availableWorkflow/availableWorkflowAction'
import {AVAILABLE_WORKFLOW} from 'src/_interfaces/AvailableWorkflow.Interface'

const AvailableWorkflows = () => {
  const [openAddModal, setOpenAddModal] = useState<boolean>(false)
  const [editData, setEditData] = useState<null | AVAILABLE_WORKFLOW>(null)
  const [tableIndexMultiple, setTableIndexMultiple] = useState<number>(0)
  const dispatch = useAppDispatch()
  const {loader} = useAppSelector((state) => state.platform)
  const {paginationData, getAvailableWorkflowLoader} = useAppSelector(
    (state) => state.availableWorkflow
  )

  useEffect(() => {
    dispatch(getAvailablePlatformApi())
    dispatch(getAvailableWorkflowsAsync('https://useappconnect.com:8005/available-workflows'))
  }, [])

  const handlePagination = (paginationPath: string | null) => {
    if (!paginationPath) return
    dispatch(setCurrentPage(paginationPath))
    dispatch(getAvailableWorkflowsAsync(paginationPath))
      .then((data) => {
        if (data.payload) {
          if (paginationData.previous == paginationPath) {
            setTableIndexMultiple(tableIndexMultiple > 0 ? tableIndexMultiple - 5 : 0)
          } else {
            setTableIndexMultiple(tableIndexMultiple + 5)
          }
        }
      })
      .catch((err) => {
        console.log('err', err)
      })
  }

  const handleEditOnClick = () => {
    setOpenAddModal(true)
  }

  if (loader || getAvailableWorkflowLoader) return <Loader />

  return (
    <>
      <PageTitle breadcrumbs={[]}>Available Workflows</PageTitle>
      <div className='card min-h-50 p-7 mt-10'>
        <div className='d-flex justify-content-between align-items-center'>
          <h4 className='fw-bolder  text-dark'>Available Workflows</h4>
          <Button
            variant='primary'
            className='py-1'
            size='sm'
            onClick={() =>{ setEditData(null); setOpenAddModal(true)}}
          >
            <span className='d-flex flex-row align-items-center'>
              <h1 className='text-white mb-0'>+</h1>
              <p className='mb-0 align-self-center ms-2'>Add Available Workflows</p>
            </span>
          </Button>
        </div>
        <div className='separator my-7'></div>
        <div>
          <AvailableWorkflowsTable
            tableIndexMultiple={tableIndexMultiple}
            openEditModal={setOpenAddModal}
            setEditData={setEditData}
          />
          <Pagination size='lg'>
            <Pagination.Prev
              active={paginationData.previous ? true : false}
              className={`bg-secondary fs-4 rounded cursor-pointer`}
              onClick={() => handlePagination(paginationData.previous)}
            />
            <Pagination.Next
              active={paginationData.next ? true : false}
              className={`bg-secondary fs-4 rounded cursor-pointer `}
              onClick={() => handlePagination(paginationData.next)}
            />
          </Pagination>
        </div>
      </div>
      <AddAvailableWorkflowModal
        visible={openAddModal}
        handleClose={() => setOpenAddModal(false)}
        editData={editData}
      />
    </>
  )
}

export default AvailableWorkflows
