import React, {FC, ReactNode} from 'react'
import {Modal} from 'react-bootstrap'
import {KTSVG} from './KTSVG'
import { useAppDispatch, useAppSelector } from 'src/Redux/Store/hooks'
import { setInfoModalProps } from 'src/Redux/Slices/info/infoSlice'

type ALERT_MODAL_PROPS_FOR_INFO_TYPE = {
  modalIcon: string
  size?: 'sm' | 'lg' | 'xl' | '' | null
  visible: boolean
  title: string
  subtitle: ReactNode | string
  onClose: () => void
  isConfirmationDialogue?: boolean
  infoLabel?: string
  cancelLabel?: string
  acceptanceLabel?: string
  onCancel?: () => void
  onAccept?: () => void
  modalType: 'success' | 'warning' | 'danger'
}

const AlertModal: FC<ALERT_MODAL_PROPS_FOR_INFO_TYPE> = ({
  modalIcon = '',
  size = '',
  acceptanceLabel = 'Ok',
  cancelLabel = 'Cancel',
  infoLabel = 'Ok',
  onClose,
  title = '',
  subtitle = '',
  visible = false,
  onAccept,
  onCancel,
  isConfirmationDialogue = true,
  modalType = 'success',
}) => {

    const { modalProp } = useAppSelector((state)=>state.info)
    const dispatch = useAppDispatch()

  return (
    <Modal show={visible} centered  
     >
      <div className='modal-content'>
        <div className='modal-header pb-2 justify-content-end border-0'>
         { isConfirmationDialogue && <div className='btn btn-icon btn-sm btn-active-light-primary ms-2 bg-light' onClick={()=>onClose()} >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
          </div>}
        </div>

        <div className='modal-body py-0 text-center'>
          <div
            className={`m-auto text-center d-flex align-items-center justify-content-center border border-${modalType} border-4 rounded-circle p-4`}
            style={{width: '90px', height: '90px'}}
          >
            <KTSVG path={modalIcon} className={`svg-icon svg-icon-5x  text-${modalType}`} />
          </div>

          <div className='py-4 mt-5'>
            <h1>{title}</h1>
            {
                typeof subtitle =="string" ? 
                <p className='mt-4 fw-bold text-muted fs-6'>{subtitle}</p>
                : subtitle
            }
          </div>
        </div>
        {
          isConfirmationDialogue ?
          (<>
            <div className='modal-footer mb-5 border-0 justify-content-center'>
              <button type='button' className='btn btn-light' data-bs-dismiss='modal' onClick={()=>onClose()} >
                {cancelLabel}
              </button>
              <button type='button' className='btn btn-light-danger' data-bs-dismiss='modal' onClick={onAccept} >
                <div className='px-4'>{acceptanceLabel}</div>
              </button>
            </div>
          </>)
          : (
            <>
            <div className='modal-footer mb-5 border-0 justify-content-end'>
              <button type='button' className='btn btn-light-danger' data-bs-dismiss='modal' onClick={onClose} >
                <div className='px-4'>{cancelLabel}</div>
              </button>
            </div>
          </>
          )
        }
      </div>
    </Modal>
  )
}

export default AlertModal
