export const isJson = (data: any) => {
  try {
    JSON.parse(data)
  } catch (error) {
    return false
  }
  return true
}

export function ensureJSONString(value: any) {
  if (typeof value === 'string') {
    try {
      JSON.parse(value) // Check if it is a valid JSON string
      return [value] // Return the original value in an array since it is already a JSON string
    } catch (error) {
      // Not a valid JSON string, continue to stringify the value
    }
  }

  return [JSON.stringify(value)]
}
