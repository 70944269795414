import React, { Fragment } from 'react'
import {Container, Row, Col, Form } from 'react-bootstrap'
import TextError from '../../../../../../../ErrorMessage/TextError'
import { Formik, Field, FieldArray, ErrorMessage } from 'formik'


type withoutCombinationProps = {
    source_filteredData: any
    destination_filteredData: any
    Formik: any
}
function WithoutCombination(props: withoutCombinationProps) {
    const { Formik, source_filteredData, destination_filteredData } = props
    return (
        <Container>
            <Row>
                <Col xs={6}>
                    <p
                        className='mt-10 ms-3 fw-bold fs-6 text-left text-hover-primary'
                        style={{ color: 'black' }}
                    > Default Option</p>
                </Col>
                <Col xs={6}>
                    <Form.Select
                        name='default_destinationPlatformData'
                        value={Formik.values.default_destinationPlatformData}
                        onChange={(event) => Formik.handleChange(event)}
                        style={{ backgroundColor: 'white', color: 'black', fontWeight: '600', marginTop:'15px' }}
                    >
                        <option value='' disabled>
                            Select Option
                        </option>
                        {destination_filteredData?.field_data?.map((items: any) => (
                            <option key={items.id} value={items?.id}>
                                {items?.api_name}
                            </option>
                        ))}
                    </Form.Select>
                    <ErrorMessage name="default_destinationPlatformData" component={TextError} />

                </Col>
            </Row>
        </Container>
    )
}

export default WithoutCombination
