import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import classes from './formModal.module.css'
import { ToastContainer, toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import warning from '../../../../asset/icons/warning.png'
import { disconnectOauthAsync } from '../../../../Redux/Slices/processOauth/processOauthAsync'


function DisconnectOauth() {
    const { loading, disconnectOauth } = useSelector((state: {
        oauth: {
            loading: boolean,
            disconnectOauth: { status_code: number, message: string }
        }
    }) => state.oauth)
    const userId = Number(localStorage.getItem('user'))
    const [getError, setError] = useState<any>()
    const navigate = useNavigate()
    const param = useParams()
    const dispatch = useDispatch()


    useEffect(() => {
        if (disconnectOauth.status_code === 200) {
            toast.success(disconnectOauth.message)
            setTimeout(() => {
                navigate('/platform')
            }, 1000)
        }
        if (getError?.error?.message !== '' && getError?.error?.message !== undefined) {
            toast.error(getError?.error?.message)
            setError('')
            setTimeout(() => {
                navigate('/platform')
            }, 1000)
        }
    }, [disconnectOauth, getError])

    let payload = {
        user_id: userId,
        platform_name: param.platformName
    }
    const handleClick = () => {
        dispatch(disconnectOauthAsync(payload) as any)
            .then((response: any) => setError(response))
    }
    return (
        <div className={classes.visible_content_disconnect}>
            <div className={classes.disconnect_card}>
                <img src={warning} alt="warning" style={{ width: '90px', height: '90px' }} />

                <div className={classes.disconnect_card_message}>
                    <h3>Disconnect Account</h3>
                    <p className='mt-2 fw-bold text-muted fs-6'>Are Yor Sure You Want To Disconnect {param.platformName}?</p>
                </div>

                <div className={classes.disconnect_card_button}>
                    <Button variant='secondary' size='sm' onClick={() => navigate('/platform')}>Cancle</Button>
                    <Button variant='danger' size='sm' onClick={handleClick}>Yes</Button>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default DisconnectOauth
