import React, { Fragment, useState, useEffect } from 'react'
import { Form } from 'react-bootstrap'
import { toast } from 'react-toastify'
import classes from './styles.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { ConfirmationModal } from '../ConfirmationModal'
import { updateWorkflowStatusApi } from '../../../../redux/slices/WorkflowSlices'

type EnableWorkflowProps = {
    id: number | null
}
function EnableWorkflow({ id }: EnableWorkflowProps) {
    const dispatch = useDispatch<any>()
    const [loading, setloading] = useState(false)
    const [showModalConfirmationModal, setShowModalConfirmationModal] = useState(false)
    const WorkflowDetails = useSelector((state: any) => state.Workflow.WorkflowDetails)?.data
    const [isEnabled, setIsEnabled] = useState<boolean>(false)
    const themeMode = localStorage.getItem('kt_theme_mode_value')

    useEffect(() => {
        setIsEnabled(WorkflowDetails?.isEnabled)
    }, [WorkflowDetails?.isEnabled])

    const handleConfirm = () => {
        setShowModalConfirmationModal(true)
    }

    const FormData = { isEnabled: !isEnabled }

    const handleToggle = async () => {
        setloading(true)
        try {
            await dispatch(updateWorkflowStatusApi({ id, FormData })).then((response: any) => {
                console.log(response.payload.message)
                if (response) {
                    setloading(false)
                }

                toast.success(response.payload.message)
            })
            setIsEnabled(!isEnabled)
        } catch (error) {
            console.error('Error updating workflow status:', error)
        }
        finally {
            setloading(false)
        }
    }
    return (
        <Fragment>
            <Form className={classes.enableWorkflow_form}>
                <span
                    onClick={handleConfirm}
                    className={`${themeMode === 'light' ? 'text-muted' : 'text-white'} text-hover-primary fs-6 cursor-pointer`}
                >
                    Enable Integration 
                </span>
                <Form.Check
                    type='switch'
                    id='custom-switch'
                    disabled={loading}
                    checked={isEnabled}
                    style={{ cursor: 'pointer' }}
                    onChange={handleConfirm}
                />
            </Form>
            <ConfirmationModal
                icon='/media/icons/duotune/general/gen044.svg'
                title='Are you sure?'
                description='Are you sure you want to enable workflow !'
                onSave={handleToggle}
                showModal={showModalConfirmationModal}
                setShowModal={setShowModalConfirmationModal}
            />
        </Fragment>
    )
}

export default EnableWorkflow
