import { AxiosClient } from '../../AxiosClient/axiosClient'
import { createAsyncThunk } from '@reduxjs/toolkit'

type valueCombinator = {
    platform: string
    action: number
    isEnabled: boolean
}

export const updateWorkflowActionAsync = createAsyncThunk(
    'workflow-action/updateWorkflowAction',
    async (payload: { workflow: number | undefined, workflow_action: valueCombinator[] }, toolkit) => {
        return await AxiosClient(
            'POST', `/workflow-action/update`, payload, toolkit
        )
    }
)