import React, { Fragment } from 'react'
import classes from './product.module.css'

type headerProps = {
    closeModal: (status: boolean) => void
    productID: number
}

function Header({ closeModal, productID }: headerProps) {
    return (
        <Fragment>
            <div className={classes.breadcrumbs}>
                <div className={classes.primary_Headercomponent}>
                    {/* GO Back Section: */}
                    <div className={classes.breadcrumbs_orderDetails}>
                        <h3>Product ID : <span className='fw-bold text-hover-primary'>{productID}</span></h3>
                        <span className={classes.breadcrumbs_orderDetails_span}>
                            <p
                                className='fw-bold text-primary text-hover-success cursor-pointer'
                                onClick={() => {
                                    closeModal(false)
                                    // navigate('/workflow')
                                    window.location.reload()
                                }}
                            >
                                Workflow
                            </p>
                            &nbsp;<p>-</p>&nbsp;
                            <p>Individual Product Details</p>
                        </span>
                    </div>
                </div>

                <div
                    onClick={() => {
                        closeModal(false)
                    }}
                    className='btn btn-icon btn-sm btn-active-light-primary fs-2 fw-bolder text-hover-danger ms-3'
                    style={{ position: 'absolute', top: '0px', right: '0px' }}
                >
                    <span className='svg-icon svg-icon svg-icon-2x fw-bolder'>
                        <svg width='24' height='24' xmlns='http://www.w3.org/2000/svg' className='mh-50px'>
                            <rect
                                opacity='0.5'
                                x='6'
                                y='17.3137'
                                width='16'
                                height='2'
                                rx='1'
                                transform='rotate(-45 6 17.3137)'
                                fill='currentColor'
                            ></rect>
                            <rect
                                x='7.41422'
                                y='6'
                                width='16'
                                height='2'
                                rx='1'
                                transform='rotate(45 7.41422 6)'
                                fill='currentColor'
                            ></rect>
                        </svg>
                    </span>
                </div>
            </div>
        </Fragment>
    )
}

export default Header
