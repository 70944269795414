import React, { Fragment, useState, useEffect } from 'react'
import { useAppSelector } from 'src/Redux/Store/hooks'
import { Row, Col, Stack, Button, Spinner } from 'react-bootstrap'
import { FormikProps } from 'formik'
import ActionFlowController from './ActionFlowController'
import { formInitialStateType, action_flows } from '../utils'
import { useAppDispatch } from 'src/Redux/Store/hooks'
import {
    createWorkFlowAsync,
    sourcePlatformProcessAsync,
    destinationPlatformProcessAsync,
} from '../../../../../../Redux/Slices/workflowFormSlice/workflowFormAsync'
import classes from '../CreateWorkflowCSS.module.css'
import { CircularLoader } from 'src/app/modules/widgets/components/CustomLoaders'

type actionFlowProps = {
    Formik: FormikProps<formInitialStateType>
    workflowId: string
    applySettings: boolean
    setApplySettings: (status: boolean) => void
    sourceName: string
    destinationName: string
}
type settingCollection = {
    name: string,
    isEnabled: boolean,
    child: any
}

function ActionFlowSettings(props: actionFlowProps) {
    const dispatch = useAppDispatch()
    const { Formik, applySettings, workflowId, setApplySettings, sourceName, destinationName } = props
    const [actionFlows, setActionFlows] = useState<action_flows[] | []>([])
    const { createWorkFlowFormData } = useAppSelector((state: any) => state.workflowForm)

    // Individual Setting Collection:::
    const [individual_sourceSettingCollector, setindividual_SourceSettingCollector] = useState<any>()
    const [individual_destinationSettingCollector, setindividual_DestinationSettingCollector] = useState<any>()

    // Individual Setting Collection:::
    const [allSourceSettingCollection, setAllSourceSettingCollection] = useState<settingCollection[] | []>([])
    const [allDestinationSettingCollection, setAllDestinationSettingCollection] = useState<settingCollection[] | []>([])

    // Loaders
    const [sourceLoader, setSourceLoader] = useState(false)
    const [destinationLoader, setDestinationLoader] = useState(false)

    useEffect(() => {
        let actionFlow = [] as action_flows[]
        if (createWorkFlowFormData?.data?.workflow_action_flow?.length) {
            actionFlow = createWorkFlowFormData?.data?.workflow_action_flow
            setActionFlows(actionFlow)
        }
    }, [createWorkFlowFormData])

    useEffect(() => {
        if (individual_sourceSettingCollector) {
            setAllSourceSettingCollection((prevSourceData) => {
                // Find index of existing data with the same name
                const existingIndex = prevSourceData?.findIndex((item) => item?.name === individual_sourceSettingCollector?.name);

                // If the data with the same name exists, update it; otherwise, add a new entry
                if (existingIndex !== -1) {
                    const updatedSourceData = [...prevSourceData];
                    updatedSourceData[existingIndex] = individual_sourceSettingCollector;
                    return updatedSourceData;
                } else {
                    return [...prevSourceData, individual_sourceSettingCollector];
                }
            });
        }
    }, [individual_sourceSettingCollector]);

    useEffect(() => {
        if (individual_destinationSettingCollector) {
            setAllDestinationSettingCollection((prevDestinationData) => {
                const existingIndex = prevDestinationData?.findIndex((item) => item?.name === individual_destinationSettingCollector?.name)
                if (existingIndex !== -1) {
                    const updatedDestinationData = [...prevDestinationData];
                    updatedDestinationData[existingIndex] = individual_destinationSettingCollector;
                    return updatedDestinationData;
                } else {
                    return [...prevDestinationData, individual_sourceSettingCollector]
                }
            })
        }
    }, [individual_destinationSettingCollector])

    // API Calling:::
    useEffect(() => {
        // let source_data = allSourceSettingCollection.map((ele) => {
        //     if (ele.child.length) {
        //         return ele.child
        //     }
        // })

        // let destination_data = allDestinationSettingCollection.map((ele) => {
        //     if (ele.child.length) {
        //         return ele.child
        //     }
        // })

        // let source_filtered = source_data?.filter((ele) => ele !== undefined && ele !== null)
        // let destination_filtered = destination_data?.filter((ele) => ele !== undefined && ele !== null)

        // const source_setting = source_filtered.flatMap(innerArray => innerArray);
        // const destination_setting = destination_filtered.flatMap(innerArray => innerArray);

        // --------------------------------------Better Logic Start
        const source_setting = [] as { name: string, value: string, child: any }[]
        const destination_setting = [] as { name: string, value: string, child: any }[]
        if (allSourceSettingCollection) {
            for (let sourceData of allSourceSettingCollection) {
                if (sourceData.child.length) {
                    source_setting.push(...sourceData.child)
                }
            }
        }
        if(allDestinationSettingCollection){
            for(let destinationData of allDestinationSettingCollection){
                if(destinationData.child?.length){
                    destination_setting.push(...destinationData.child)
                }
            }
        }
        // console.log('source_setting', source_setting)
        // console.log('destination_setting', destination_setting)

        // --------------------------------------Better Logic End
        const payloadSource = {
            platform: Formik.values.sourcePlatform.sp_parentId,
            workflow: workflowId,
            settings: source_setting
        }
        const payloadDestination = {
            platform: Formik.values.destinationPlatform.dp_parentId,
            workflow: workflowId,
            settings: destination_setting
        }
        // console.log('payloadSource', payloadSource)
        // console.log('payloadDestination', payloadDestination)
        
        if (applySettings) {
            dispatch(createWorkFlowAsync(payloadSource))
            dispatch(sourcePlatformProcessAsync(
                { platform: Formik.values.sourcePlatform.sp_parentId, workflow: workflowId }))
            dispatch(createWorkFlowAsync(payloadDestination))
            dispatch(destinationPlatformProcessAsync(
                { platform: Formik.values.destinationPlatform.dp_parentId, workflow: workflowId }))
        }
    }, [applySettings])

    return (
        <Fragment>
            {workflowId && sourceLoader && destinationLoader && (
                <Col className={classes.class_loader}>
                    <CircularLoader
                        height='60px'
                        width='60px'
                        marginTop='5px'
                        marginBottom='5px'
                        borderWidth='4px'
                    />
                </Col>
            )}

            <Row
                style={{ display: (workflowId && (!sourceLoader || !destinationLoader)) ? 'flex' : 'none' }}
            >
                <Stack className={classes.show_line}>{''}</Stack>

                {
                    actionFlows?.length ? actionFlows.map((ele: action_flows) => {
                        return (
                            <Col xs={12}>
                                <ActionFlowController
                                    Formik={Formik}
                                    workflowId={workflowId}
                                    isEnabled={ele.isEnabled}
                                    applySettings={applySettings}
                                    action1={ele.workflow_action1}
                                    action2={ele.workflow_action2}
                                    display_name={ele.display_name}
                                    setSourceLoader={setSourceLoader}
                                    setDestinationLoader={setDestinationLoader}
                                    setindividual_SourceSettingCollector={setindividual_SourceSettingCollector}
                                    setindividual_DestinationSettingCollector={setindividual_DestinationSettingCollector}
                                    sourceName={sourceName}
                                    destinationName={destinationName}
                                />
                            </Col>
                        )
                    }) : null
                }

                <Col xs={12}>
                    {workflowId && (
                        <Stack className={classes.button}>
                            <Button
                                type='button'
                                variant='primary'
                                style={{ minWidth: '300px' }}
                                onClick={() => { setApplySettings(true) }}
                            >
                                {applySettings && (
                                    <Spinner
                                        className='me-2'
                                        as='span'
                                        animation='border'
                                        size='sm'
                                        role='status'
                                        aria-hidden='true'
                                    />
                                )}
                                {applySettings ? ' Saving...' : 'Save'}
                            </Button>
                        </Stack>
                    )}
                </Col>
            </Row>
        </Fragment>
    )
}

export default ActionFlowSettings






