import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export function getAvailablePlatform(params:{}|undefined) {
  const GET_AVAILABLE_PLATFORM_URL = `${API_URL}/available-platform`
  return axios.get(GET_AVAILABLE_PLATFORM_URL,{ params })

}
export function getAvailablePlatformbyPlatformId(id:any) {
  const GET_AVAILABLE_PLATFORM_URL = `${API_URL}/available-platform?id=${id}`
  return axios.get(GET_AVAILABLE_PLATFORM_URL)

}
export function getConnectedPlatform(userId: any) {
  const GET_CONNECTED_PLATFORM_URL = `${API_URL}/platform?user=${userId}`
  return axios.get(GET_CONNECTED_PLATFORM_URL)

}
export function createPlatform(FormData: object) {
  const CREATE_PLATFORM_URL = `${API_URL}/platform`
  return axios.post(CREATE_PLATFORM_URL, FormData)
}
export function deletePlatform(id: any) {
  const DELETE_PLATFORM_URL = `${API_URL}/platform/${id}`
  return axios.delete(DELETE_PLATFORM_URL)
}