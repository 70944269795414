import React, { useState, useEffect, Fragment } from 'react'
import Spinner from 'react-bootstrap/Spinner'
import { Row, Col, Stack } from 'react-bootstrap'
import classes from './formModal.module.css'
import { toast, ToastContainer } from 'react-toastify'
import { getAvailablePlatformApi } from '../../../redux/slices/PlatformSlices'
import { useDispatch, useSelector } from 'react-redux'

function ShopifyProcessOauth() {
    const dispatch = useDispatch<any>()
    const [currentColor, setCurrentColor] = useState('danger');
    const queryString = window.location.search;
    const PlatformDetails = useSelector((state: any) => state.platform.data)?.data
    const availableIDs = JSON.parse(localStorage.getItem('processOauth_ID') || 'null');


    // ::: Loader
    useEffect(() => {
        const colors = ['success', 'danger', 'warning'];
        let currentIndex = 0;
        const interval = setInterval(() => {
            currentIndex = (currentIndex + 1) % colors.length;
            setCurrentColor(colors[currentIndex]);
        }, 1000);
        return () => { clearInterval(interval) };
    }, []);


    useEffect(() => {
        dispatch(getAvailablePlatformApi())
    }, [])
    useEffect(() => {
        if (PlatformDetails) {
            const filterShopify = PlatformDetails?.find((item) => item?.name === "SHOPIFY")
            console.log('filterShopify', filterShopify)
            if (filterShopify?.id) {
                let availablePlatformID: any = { available_platform_id: filterShopify?.id }
                localStorage.setItem('processOauth_ID', JSON.stringify(availablePlatformID))
            }
        }
    }, [PlatformDetails])

    // Query String Parsing:::
    const parseQueryString = (queryString: string) => {
        const parems = new URLSearchParams(queryString)

        const hmac = parems.get('hmac')
        const host = parems.get('host')
        const shop = parems.get('shop')
        const timeStamp = parems.get('timestamp')

        return { host, hmac, shop, timeStamp }
    }
    const { host, hmac, shop, timeStamp } = parseQueryString(queryString)

    // Generate A Random State
    function generateRandomState() {
        return Math.random().toString(36).substring(7);
    }
    const state = generateRandomState()

    // Redirecting To New Page:::
    const authorizationURL = `https://${shop}/admin/oauth/authorize?client_id=7cfafd1e33aeee3416da64ec35dd74a7&scope=write_products,read_shipping&redirect_uri=http://localhost:3011/process-oauthcallback&state=${state}&grant_options[]=per-user`;

    useEffect(() => {
        if (availableIDs?.available_platform_id) {
            window.location.href = authorizationURL;
        }
    }, [availableIDs])

    return (
        <Fragment>
            <div className={classes.visible_content}>
                <Row>
                    <Col xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Spinner
                            animation='border'
                            variant={currentColor}
                            style={{
                                width: '100px',
                                height: '100px',
                                marginBottom: '50px',
                                borderWidth: '7px',
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <h1>Please Wait</h1>
                        <h1>Your Information Is Being Proceed...</h1>
                    </Col>
                </Row>
                <ToastContainer />
            </div>
        </Fragment>
    )
}

export default ShopifyProcessOauth
