import {createAsyncThunk} from '@reduxjs/toolkit'
import {AxiosClient} from 'src/Redux/AxiosClient/axiosClient'
import { CREATE_WORKFLOW_PAYLOAD } from 'src/_interfaces/AvailableWorkflow.Interface'



export const createAvailableWorkflowAsync = createAsyncThunk(
  'availableWorkflow/createAvailableWorkflowAsync',
  async function (payload: CREATE_WORKFLOW_PAYLOAD, toolkit) {
    return await AxiosClient('POST', '/create-available-workflow/', payload, toolkit)
  }
)

export const getAvailableWorkflowsAsync = createAsyncThunk(
  'availableWorkflow/getAvailableWorkflowsAsync',
  async function (directPath:string, toolkit) {
    return await AxiosClient('GET', '/available-workflows/',[], toolkit, [], directPath  )
  }
)

export const updateAvailableWorkflowAsync = createAsyncThunk(
  'availableWorkflow/updateAvailableWorkflowAsync',
  async function (payload: { updateData: CREATE_WORKFLOW_PAYLOAD , updateId : number }, toolkit) {
    return await AxiosClient('PUT', `/update-available-workflow/${payload.updateId}/` , payload.updateData, toolkit)
  }
)