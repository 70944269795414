import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import {
    updateWorkflowFieldMappingAsync,
} from './FieldMappingAsync'

type updateFieldMappingSlice = {
    updateLoader: boolean
    updateWorkflowFieldMapping: any
}

const initialState: updateFieldMappingSlice = {
    updateLoader: false,
    updateWorkflowFieldMapping: []
}

const updateFieldMappingSlice = createSlice({
    name: 'updateFieldMappingSlice',
    initialState,
    reducers: { emptyUpdatedMappedFields: () => initialState },
    //Pending Statements:
    extraReducers: (builder) => {
        builder.addMatcher(isAnyOf(
            updateWorkflowFieldMappingAsync.pending,
        ), (state, action) => {
            state.updateLoader = true
        })
        //Success Statements:
        builder.addMatcher(isAnyOf(updateWorkflowFieldMappingAsync.fulfilled), (state, action) => {
            state.updateLoader = false
            state.updateWorkflowFieldMapping = action.payload
        })
        // Reject Statements:
        builder.addMatcher(isAnyOf(
            updateWorkflowFieldMappingAsync.rejected,
        ), (state, action) => {
            state.updateLoader = false
            state.updateWorkflowFieldMapping = action.payload
        })
    },
})

export const { emptyUpdatedMappedFields } = updateFieldMappingSlice.actions;
export default updateFieldMappingSlice.reducer;