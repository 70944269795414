import React, { CSSProperties, FC, useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'


type AppEditorPropType = {
  value: string
  placeholder: string
  setValue: (editorData: string) => void
  editorStyle?: CSSProperties
  containerStyle?: CSSProperties
}

/**
 * Prop type of AppEditor
 * @type { placeholder: string }
 * @type { value: string } 
 * @type { editorStyle : CSSProperties }
 * @type { containerStyle : CSSProperties }
 * @type { setValue : Function }
 *  
   
*/
const AppEditor: FC<AppEditorPropType> = ({ value, setValue, editorStyle, containerStyle, placeholder = '' }) => {

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      // ['link', 'image'],
      ['clean']
    ],
  }

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
  ]


  return (
    <div style={containerStyle} >
      <ReactQuill
        theme='snow'
        placeholder={placeholder}
        value={value}
        onChange={setValue}
        modules={modules}
        formats={formats}
        style={editorStyle}
      />
    </div>
  )
}

export default AppEditor
