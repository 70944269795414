import React, { Fragment, useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { platformSettings } from './utils'
import axios from 'axios'
import {
    TextAreaComponent,
    BooleanComponent,
    ButtonComponent,
    TextBasedComponent,
    SelectComponent,
    MultipleSelectComponent,
} from './InputComponents/InputComponent'
import { useAppSelector } from 'src/Redux/Store/hooks'

type destinationSettingProps = {
    id: number
    sourceID: number
    destinationID: number
    workflowID: number
    setDestinationLoader: (status: boolean) => void
    setDestinationPayload: (data: { platform: string | number, workflow: string | number, settings: any[] }) => void
}
type dataCollectionArrayValues = {
    name: string, value: string, child: { name: string, value: string }[]
}

function DestinationSetting(props: destinationSettingProps) {
    const { id, sourceID, destinationID, workflowID, setDestinationLoader, setDestinationPayload } = props
    const [rawPlatformSettings, setRowPlatformSettings] = useState<platformSettings[] | []>([])
    const [filteredSetting, setFilteredSetting] = useState<platformSettings>({} as platformSettings)
    const [dataCollectionArray, setDataCollectionArray] = useState<dataCollectionArrayValues[] | []>([])
    const { destinationSideSetting, destinationSideShortCode } = useAppSelector((state) => state.platformSettings)

    // // API Calling:::
    // const getSourcePlatformSetting = async (platform_id: number, workflow_id: number) => {
    //     setDestinationLoader(true)
    //     try {
    //         const response = await axios.get(
    //             `${process.env.REACT_APP_API_URL}/platform-setting?platform_id=${platform_id}&workflow_id=${workflow_id}`
    //         )
    //         setRowPlatformSettings(response?.data?.data)
    //         if (response?.data?.data?.length) {
    //             let filteredSetting = {} as platformSettings
    //             for (let i = 0; i < response?.data?.data?.length; i++) {
    //                 if (response?.data?.data[i]?.workflow_action_flow_id === id) {
    //                     filteredSetting = response?.data?.data[i]
    //                     setFilteredSetting(filteredSetting)
    //                 }
    //             }
    //         }
    //     } catch (error) {
    //         console.log('Unable to get source platform settings', error)
    //     } finally {
    //         setDestinationLoader(false)
    //     }
    // }

    // useEffect(() => {
    //     if (destinationID && workflowID) {
    //         getSourcePlatformSetting(destinationID, workflowID)
    //     }
    // }, [destinationID, workflowID])

    useEffect(() => {
        setDestinationLoader(true)
        setRowPlatformSettings(destinationSideSetting?.data)
        try {
            if (destinationSideSetting?.data?.length) {
                let filteredSetting = {} as platformSettings
                for (let i = 0; i < destinationSideSetting?.data?.length; i++) {
                    if (destinationSideSetting?.data[i]?.workflow_action_flow_id === id) {
                        filteredSetting = destinationSideSetting?.data[i]
                        setFilteredSetting(filteredSetting)
                    }
                }
            }
        } catch (error) { console.log('Somthing Went Worng') }
        finally { setTimeout(() => { setDestinationLoader(false) }, 500) }
    }, [destinationSideSetting])

    // Data Collection:::
    const dataCollectorFun = (data: dataCollectionArrayValues) => {
        setDataCollectionArray((prevValue) => {

            // If the item already exists, update it
            const updatedArray = prevValue.map((item: dataCollectionArrayValues) => (item.name === data.name ? data : item))

            // If the item doesn't exist, add it to the array
            if (!prevValue.some((item: Record<string, any>) => item.name === data.name)) {
                updatedArray.push(data)
            }
            return updatedArray
        })
    }

    const payload_Update_destination = {
        platform: destinationID,
        workflow: workflowID,
        settings: [...dataCollectionArray]
    }
    useEffect(() => {
        setDestinationPayload(payload_Update_destination)
    }, [dataCollectionArray])


    return (
        <Fragment>
            <Container>
                {
                    filteredSetting?.input_type === 'address' ? (
                        <TextAreaComponent
                            apiName={filteredSetting?.name}
                            inputType='textArea'
                            childSetting={filteredSetting?.child}
                            previousValue={filteredSetting?.value}
                            displayName={filteredSetting?.display_name}
                            dataCollectorFun={dataCollectorFun}
                            settings={filteredSetting?.setting_available_options}
                            shortCode={destinationSideShortCode}
                            displayShortcodes={filteredSetting?.displayShortcodes}
                            description={filteredSetting?.description}
                        />
                    ) : filteredSetting?.input_type === 'boolean' ? (
                        <BooleanComponent
                            apiName={filteredSetting?.name}
                            inputType='boolean'
                            childSetting={filteredSetting?.child}
                            previousValue={filteredSetting?.value}
                            displayName={filteredSetting?.display_name}
                            dataCollectorFun={dataCollectorFun}
                            settings={filteredSetting?.setting_available_options}
                            shortCode={destinationSideShortCode}
                            displayShortcodes={filteredSetting?.displayShortcodes}
                            description={filteredSetting?.description}
                        />
                    ) : filteredSetting?.input_type === 'button' ? (
                        <ButtonComponent
                            apiName={filteredSetting?.name}
                            inputType='button'
                            childSetting={filteredSetting?.child}
                            previousValue={filteredSetting?.value}
                            displayName={filteredSetting?.display_name}
                            dataCollectorFun={dataCollectorFun}
                            settings={filteredSetting?.setting_available_options}
                            shortCode={destinationSideShortCode}
                            displayShortcodes={filteredSetting?.displayShortcodes}
                            description={filteredSetting?.description}
                        />
                    ) : (filteredSetting?.input_type === 'email' ||
                        filteredSetting?.input_type === 'text' ||
                        filteredSetting?.input_type === 'integer' ||
                        filteredSetting?.input_type === 'phone') ? (
                        <TextBasedComponent
                            apiName={filteredSetting?.name}
                            inputType={filteredSetting?.input_type}
                            childSetting={filteredSetting?.child}
                            previousValue={filteredSetting?.value}
                            displayName={filteredSetting?.display_name}
                            dataCollectorFun={dataCollectorFun}
                            settings={filteredSetting?.setting_available_options}
                            shortCode={destinationSideShortCode}
                            displayShortcodes={filteredSetting?.displayShortcodes}
                            description={filteredSetting?.description}
                        />
                    ) : filteredSetting?.input_type === 'image' ? (
                        'Image Component'
                    ) : filteredSetting?.input_type === 'multiselect' || filteredSetting?.input_type === 'multiline_text' ? (
                        <MultipleSelectComponent
                            apiName={filteredSetting?.name}
                            inputType={filteredSetting?.input_type}
                            childSetting={filteredSetting?.child}
                            previousValue={filteredSetting?.value}
                            displayName={filteredSetting?.display_name}
                            dataCollectorFun={dataCollectorFun}
                            settings={filteredSetting?.setting_available_options}
                            shortCode={destinationSideShortCode}
                            displayShortcodes={filteredSetting?.displayShortcodes}
                            description={filteredSetting?.description}
                        />
                    ) : filteredSetting?.input_type === 'select' ? (
                        <SelectComponent
                            apiName={filteredSetting?.name}
                            inputType={filteredSetting?.input_type}
                            childSetting={filteredSetting?.child}
                            previousValue={filteredSetting?.value}
                            displayName={filteredSetting?.display_name}
                            dataCollectorFun={dataCollectorFun}
                            settings={filteredSetting?.setting_available_options}
                            shortCode={destinationSideShortCode}
                            displayShortcodes={filteredSetting?.displayShortcodes}
                            description={filteredSetting?.description}
                        />
                    ) : null
                }
            </Container>
        </Fragment>
    )
}

export default DestinationSetting
