import axios from 'axios'
const userId = Number(localStorage.getItem('user'))

const handleSort = async (
    // column: { name: string; id: number },
    column: any,
    sortDirection: string,
    { setData, perPage, logAction, setLoading, searchText,
        workflowID, allEndPoints, setTotalRows,
        setCheckedAll, setAllRowChecked, searchStatus,
    }) => {
    setLoading(true) //:::Before API Call Loader True:::
    try {
        let columnSortByString = ''
        //eslint-disable-next-line @typescript-eslint/no-unused-expressions
        column.name === 'ID'
            ? (columnSortByString = 'api_id')
            : column.name === 'Created At'
                ? (columnSortByString = 'api_created_at')
                : null
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/${allEndPoints[logAction] as any
            }/?user_id=${userId}&page=${1}&limit=${perPage}&workflow_id=${workflowID}&sortby=${columnSortByString}&orderby=${sortDirection}&search_string=${searchText}&destination_sync_status=${searchStatus}`
        )
        setData(response.data?.results)
        setTotalRows(response.data.count)
    } catch (error) {
        console.error('Error fetching users:', error)
    } finally {
        setLoading(false)
        setCheckedAll(false)
        setAllRowChecked(false)
    }
}

export default handleSort;
