import {createSlice, isAnyOf} from '@reduxjs/toolkit'
import {
  availablePlatfromCombunationAsync,
  activePlatfromCombunationAsync,
  getAvailablePlatformsAsync,
  addPlatformActionAsync,
  getPlatformDetailByIdAsync,
  addAvailablePlatformSettingAsync,
  addPlatformAsync,
  availableWorkflowCombunationAsync,
} from './platformAsync'
import {PlatformSliceInitialState} from '../../../_interfaces/PlatformSlice.Interface'

const initialState: PlatformSliceInitialState = {
  loading: false,
  platformCombinations: null,
  getPlatformLoader: false,
  platforms: [],

  // add platform action start..
  addPlatformActionLoader: false,
  // add platform action end..

  // platform detail start...
  platformDetail: null,
  getPlatformDetailLoader: false,
  // platform detail end...

  // add available platform setting start..
  addAvailablePlatformSettingLoader: false,
  // add available platform setting end..

  // add platform start...
  addPlatformLoader: false,
  // add platform end...

  activePlatformLoader: false,
  activePlatfromCombunation: null,
  workflowLoader: false,
  workflowCombination: null,
}

const platformCombinationSlice = createSlice({
  name: 'platformCombinationSlice',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(availablePlatfromCombunationAsync.pending), (state, actions) => {
      state.loading = true
    })
    builder.addMatcher(isAnyOf(availablePlatfromCombunationAsync.fulfilled), (state, actions) => {
      state.loading = false
      state.platformCombinations = actions.payload
    })
    builder.addMatcher(isAnyOf(availablePlatfromCombunationAsync.rejected), (state, actions) => {
      state.loading = false
    })

    // get platforms start
    builder.addMatcher(isAnyOf(getAvailablePlatformsAsync.pending), (state, actions) => {
      // console.log("getAvailablePlatformsAsync in pending state");
      state.getPlatformLoader = true
    })
    builder.addMatcher(isAnyOf(getAvailablePlatformsAsync.rejected), (state, actions) => {
      // console.log("getAvailablePlatformsAsync in rejected state", actions.error);
      state.getPlatformLoader = false
    })
    builder.addMatcher(isAnyOf(getAvailablePlatformsAsync.fulfilled), (state, actions) => {
      // console.log("getAvailablePlatformsAsync in fulfilled state");
      state.platforms = actions.payload.data
      state.getPlatformLoader = false
    })
    // get platform end

    // add platform action start..
    builder.addMatcher(isAnyOf(addPlatformActionAsync.pending), (state, actions) => {
      console.log('addPlatformActionLoader in pending state')
      state.addPlatformActionLoader = true
    })
    builder.addMatcher(isAnyOf(addPlatformActionAsync.rejected), (state, actions) => {
      console.log('addPlatformActionLoader in rejected error ')
      state.addPlatformActionLoader = false
    })
    builder.addMatcher(isAnyOf(addPlatformActionAsync.fulfilled), (state, actions) => {
      console.log('addPlatformActionLoader in fulfilled', actions.payload)
      state.addPlatformActionLoader = false
    })
    // add platform action end..

    // get platform detail by id start..
    builder.addMatcher(isAnyOf(getPlatformDetailByIdAsync.pending), (state, actions) => {
      console.log('getPlatformDetailByIdAsync in pending state..')
      state.getPlatformDetailLoader = true
    })
    builder.addMatcher(isAnyOf(getPlatformDetailByIdAsync.rejected), (state, actions) => {
      console.log('getPlatformDetailByIdAsync in rejected state..')
      state.getPlatformDetailLoader = false
    })
    builder.addMatcher(isAnyOf(getPlatformDetailByIdAsync.fulfilled), (state, actions) => {
      console.log('getPlatformDetailByIdAsync in fulfilled state..')
      console.log(actions.payload.data)
      state.platformDetail = actions.payload.data
      state.getPlatformDetailLoader = false
    })
    // get platform detail by id end..

    // add available platform setting start..
    builder.addMatcher(isAnyOf(addAvailablePlatformSettingAsync.pending), (state, actions) => {
      // console.log('addAvailablePlatformSettingAsync in pending state')
      state.addAvailablePlatformSettingLoader = true
    })
    builder.addMatcher(isAnyOf(addAvailablePlatformSettingAsync.rejected), (state, actions) => {
      // console.log('addAvailablePlatformSettingAsync in rejected state')
      state.addAvailablePlatformSettingLoader = false
    })
    builder.addMatcher(isAnyOf(addAvailablePlatformSettingAsync.fulfilled), (state, actions) => {
      // console.log('addAvailablePlatformSettingAsync in fulfilled state', actions.payload)
      state.addAvailablePlatformSettingLoader = false
    })
    // add available platform setting end..

    // add platform start...
    builder.addMatcher(isAnyOf(addPlatformAsync.pending), (state, actions) => {
      console.log('addPlatformAsync in pending state')
      state.addPlatformLoader = true
    })
    builder.addMatcher(isAnyOf(addPlatformAsync.rejected), (state, actions) => {
      console.log('addPlatformAsync in rejected state')
      state.addPlatformLoader = false
    })
    builder.addMatcher(isAnyOf(addPlatformAsync.fulfilled), (state, actions) => {
      console.log('addPlatformAsync in fulfilled state')
      state.addPlatformLoader = false
    })
    // add platform end...

    // activePlatfromCombunationAsync start...
    builder.addMatcher(isAnyOf(activePlatfromCombunationAsync.pending), (state, actions) => {
      state.activePlatformLoader = true
    })
    builder.addMatcher(isAnyOf(activePlatfromCombunationAsync.rejected), (state, actions) => {
      state.activePlatformLoader = false
    })
    builder.addMatcher(isAnyOf(activePlatfromCombunationAsync.fulfilled), (state, actions) => {
      state.activePlatformLoader = false
      state.activePlatfromCombunation = actions.payload
    })
    // activePlatfromCombunationAsync end...

    builder.addMatcher(isAnyOf(availableWorkflowCombunationAsync.fulfilled), (state, actions) => {
      state.workflowLoader = false
      state.workflowCombination = actions.payload
    })
    builder.addMatcher(isAnyOf(availableWorkflowCombunationAsync.rejected), (state, actions) => {
      state.workflowLoader = false
    })

    builder.addMatcher(isAnyOf(availableWorkflowCombunationAsync.pending), (state, actions) => {
      state.workflowLoader = true
    })
  },
  reducers: {emptyPlatformCombinons: () => initialState},
})

export default platformCombinationSlice.reducer
export const {emptyPlatformCombinons} = platformCombinationSlice.actions
