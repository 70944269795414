import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export function postCreateWorkflow(FormData: object) {
  const CREATE_WORKFLOW_URL = `${API_URL}/workflow`
  return axios.post(CREATE_WORKFLOW_URL, FormData)
}

export function getPlatformTypeDestination(userId: any) {
  const GET_PlatformTypeDestination_URL = `${API_URL}/platform?user=${userId}&type=DESTINATION`
  return axios.get(GET_PlatformTypeDestination_URL)
}

export function getWorkflow(userId: any) {
  const GET_Workflow_URL = `${API_URL}/workflow?user_id=${userId}`
  return axios.get(GET_Workflow_URL)
}

export function getPlatformTypeSource(userId: any) {
  const GET_PlatformTypeSource_URL = `${API_URL}/platform?user=${userId}&type=SOURCE`
  return axios.get(GET_PlatformTypeSource_URL)
}

export function getAvailablePlatformById(Id: any) {
  const GET_AVAILABLE_PLATFORM_URL = `${API_URL}/available-platform?id=${Id}`
  return axios.get(GET_AVAILABLE_PLATFORM_URL)
}

export function getAvailablePlatformAction(Id: any) {
  const GET_AVAILABLE_PLATFORM_Action_URL = `${API_URL}/availableplatform-setting?available_platform_action=${Id}`
  return axios.get(GET_AVAILABLE_PLATFORM_Action_URL)
}

export function getWorkflowDetails(Id: any) {
  const GET_WorkflowDetails_URL = `${API_URL}/workflow?id=${Id}`
  return axios.get(GET_WorkflowDetails_URL)
}

export function deleteWorkflow(Id: any) {
  const deleteWorkflow_URL = `${API_URL}/workflow/${Id}`
  return axios.delete(deleteWorkflow_URL)
}

export function updatePlatformSetting(FormData: object) {
  const update_PlatformSetting_URL = `${API_URL}/platform-setting/update`
  return axios.post(update_PlatformSetting_URL, FormData)
}

export function getPlatformByworkflowAndplatformId(platformId: any, workflowId: any) {
  const GET_PlatformByworkflowAndplatformId_URL = `${API_URL}/platform-setting?platform_id=${platformId}&workflow_id=${workflowId}`
  return axios.get(GET_PlatformByworkflowAndplatformId_URL)
}

export function updateWorkflowStatus(id: any, FormData: object) {
  const update_PlatformSetting_URL = `${API_URL}/workflow/${id}/`
  return axios.put(update_PlatformSetting_URL, FormData)
}