import React, { Fragment, useState, useEffect } from 'react'
import { ErrorMessage } from 'formik'
import TextError from '../../../../../../../ErrorMessage/TextError'
import { Container, Row, Col, Form as BootstrapForm } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
type ParentSelectProps = {
  Formik: any
  parentData: any
  disableFields?: boolean
  source_setFilteredData?: any
  destination_setFilteredData?: any
  sourceplatform_ID?: number
  destinationplatform_ID?: number
}

export function SourceSideParentSelect(props: ParentSelectProps) {
  const { Formik, parentData, disableFields, source_setFilteredData, sourceplatform_ID, destinationplatform_ID } = props
  const WorkflowDetails = useSelector((state: any) => state.Workflow.WorkflowDetails)?.data
  useEffect(() => {
    const selectedFragment = parentData?.filter(
      (frag: any) => frag.id == Formik.values.sourceParentValue
    )
    if (Formik.values.sourceParentValue) {
      source_setFilteredData(...selectedFragment)
    }
  }, [Formik.values.sourceParentValue])

  // Parent Source & Destination Field validation
  useEffect(() => {
    if (Formik.values.sourceParentValue.length && Formik.values.destinationParentValue.length) {
      if (Formik.values.sourceParentValue == Formik.values.destinationParentValue) {
        toast.error("Source & Destination Field Can't Be Same !")
        Formik.setFieldValue('destinationParentValue', '')
      }
    }
  }, [Formik.values.sourceParentValue, Formik.values.destinationParentValue])

  return (
    <Fragment>
      <BootstrapForm.Select
        name='sourceParentValue'
        value={Formik.values.sourceParentValue}
        onChange={(event) => Formik.handleChange(event)}
        disabled={disableFields}
        style={{ backgroundColor: 'white', marginBottom: '10px' }}
      >
        <option>
          Select Field 01
        </option>
        {parentData?.map((items: any) => (
          <option key={items.id} value={items?.id}>
            {`${sourceplatform_ID == items?.platform ? WorkflowDetails?.source_platform
              ?.name
              : WorkflowDetails?.destination_platform
                ?.name} - ` + items?.display_name}
          </option>
        ))}
      </BootstrapForm.Select>
      <ErrorMessage name="sourceParentValue" component={TextError} />
    </Fragment>
  )
}

export function DestinationSideParentSelect(props: ParentSelectProps) {
  const { Formik, parentData, disableFields, destination_setFilteredData, sourceplatform_ID, destinationplatform_ID } = props
  const WorkflowDetails = useSelector((state: any) => state.Workflow.WorkflowDetails)?.data
  useEffect(() => {
    const selectedFragment = parentData?.filter(
      (frag: any) => frag.id == Formik.values.destinationParentValue
    )
    if (Formik.values.destinationParentValue) {
      destination_setFilteredData(...selectedFragment)
    }
  }, [Formik.values.destinationParentValue])
  return (
    <Fragment>
      <BootstrapForm.Select
        name='destinationParentValue'
        value={Formik.values.destinationParentValue}
        onChange={(event) => Formik.handleChange(event)}
        disabled={disableFields}
        style={{ backgroundColor: 'white', marginBottom: '15px' }}
      >
        <option value='' disabled>
          Select Field 02
        </option>
        {parentData?.map((items: any) => (
          <option key={items.id} value={items?.id}>
            {`${sourceplatform_ID == items?.platform ? WorkflowDetails?.source_platform
              ?.name
              : WorkflowDetails?.destination_platform
                ?.name} - ` + items?.display_name}
          </option>
        ))}
      </BootstrapForm.Select>
      <ErrorMessage name="destinationParentValue" component={TextError} />
    </Fragment >
  )
}

type defaultMappingProps = { Formik: any; source_filteredData: any; destination_filteredData: any }
export function DefaultMapping(props: defaultMappingProps) {
  const { Formik, source_filteredData, destination_filteredData } = props
  return (
    <Fragment>
      <Row className='mt-6'>
        <Col xs={12} md={6}>
          <p
            className='mt-4 ms-3 fw-bold fs-6 text-left text-hover-primary'
          > Default Option</p>
        </Col>
        <Col xs={12} md={6}>
          {
            destination_filteredData?.input_type == 'TEXT' ? (
              <Fragment>
                <BootstrapForm.Control
                  type="text"
                  placeholder="Type Default Destination Field"
                  name='default_destinationPlatformData_str'
                  value={Formik.values.default_destinationPlatformData_str}
                  onChange={(event) => Formik.handleChange(event)}
                  style={{ backgroundColor: 'white', color: 'black', fontWeight: '600', border: '1px solid #99A1B7' }}
                />
                <ErrorMessage name="default_destinationPlatformData_str" component={TextError} />
              </Fragment>

            ) : (
              <Fragment>
                <BootstrapForm.Select
                  name='default_destinationPlatformData'
                  value={Formik.values.default_destinationPlatformData}
                  onChange={(event) => Formik.handleChange(event)}
                  style={{ backgroundColor: 'white', color: 'black', fontWeight: '600', border: '1px solid #99A1B7' }}
                >
                  <option value=''>
                    Select Option
                  </option>
                  {destination_filteredData?.field_data?.map((items: any) => (
                    items?.parent_data == null &&
                    <option key={items.id} value={items?.id}>
                      {items?.api_name}
                    </option>
                  ))}
                </BootstrapForm.Select>
                <ErrorMessage name="default_destinationPlatformData" component={TextError} />
              </Fragment>
            )
          }
        </Col>
      </Row>
    </Fragment >
  )
}
