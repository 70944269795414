import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { FormikProps } from 'formik'
import classes from './platform.module.css'
import { useNavigate } from 'react-router-dom'
import { formInitialStateType, oAuthValues, flowIdentifire } from '../utils'
import { useDispatch, useSelector } from 'react-redux'
import { useAppSelector } from 'src/Redux/Store/hooks'
import { Button, Container, Form, Stack } from 'react-bootstrap'
import PlatformNotAvailable from './PlatformNotAvailable'
import {
  getAllDestinationPlatformAsync,
  getAllDestinationActionNameAsync,
} from '../../../../../../Redux/Slices/workflowFormSlice/workflowFormAsync'
import { emptyForm } from 'src/Redux/Slices/workflowFormSlice/workflowFormSlice'
import { clearStatus } from 'src/Redux/Slices/stateSave/connectPlatformViaCreateWorkflow'
import { actionCollection, actionFlows } from 'src/Redux/Slices/stateSave/connectPlatformDataSlice'

type ComponentsDestinationSideProps = {
  Formik: FormikProps<formInitialStateType>
  workflowId: string
  dp_id: string | number
  dp_aid: number[] | null
  isSourcePlatformConnectModalOpen: boolean
  set_isDestinationPlatformConnectModalOpen: (status: boolean) => void
  isDestinationPlatformConnectModalOpen: boolean
  setIframeSrc: (data: string | null) => void
  setIframePlatformName: (data: string | null) => void
  setOpenIFrameModal: (status: boolean) => void
  setOauth_ids: React.Dispatch<React.SetStateAction<oAuthValues>>
  destinationName: string
  actionCollectionArray: actionCollection[]
  action_flows: actionFlows[]

}

type deshboardDirectedPlatforms = {
  id: string | number
  code: string
  name: string
  display_name: string
  platform_info: { id: string | number; name: string; display_name: string; type: string }
  isConnected: boolean
  finishAuth_url: string | null
}
function ComponentsDestinationSide(props: ComponentsDestinationSideProps) {
  const {
    Formik,
    workflowId,
    dp_id,
    dp_aid,
    isSourcePlatformConnectModalOpen,
    set_isDestinationPlatformConnectModalOpen,
    isDestinationPlatformConnectModalOpen,
    setIframeSrc,
    setIframePlatformName,
    setOpenIFrameModal,
    setOauth_ids,
    destinationName,
    actionCollectionArray,
  } = props
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const userId = Number(localStorage.getItem('user'))
  const [deshboardDirectedPlatforms, setDeshboardDirectedPlatforms] = useState<
    deshboardDirectedPlatforms[] | []
  >([])
  const [destinationRecheck, setDestinationRecheck] = useState(false)
  const { destinationActionNameLoader, getPlatformDestinationLoader, getPlatformDestination, getDestinationActionName } =
    useSelector(
      (state: {
        workflowForm: {
          getPlatformDestinationLoader: boolean
          destinationActionNameLoader: boolean
          getPlatformDestination: deshboardDirectedPlatforms[]
          getDestinationActionName: {
            available_platform_action: { action: { id: number; action_name: string }; id: number }[]
          }
        }
      }) => state.workflowForm
    )

  // This Redux State Tells Whether Platform Connected Or Not Via Create Workflow Check Modal
  const { platform_ConnectionStatus } = useAppSelector((state) => state.platformConnectByCreateWorkflow)
  useEffect(() => {
    if (platform_ConnectionStatus.platformType === 'DESTINATION' || platform_ConnectionStatus.platformType === 'BIDIRECTIONAL') {
      setDestinationRecheck(true)
    }
  }, [platform_ConnectionStatus])

  // To get destination platform
  useEffect(() => {
    dispatch(emptyForm())
    dispatch(getAllDestinationPlatformAsync({ userId: userId }) as any)
      .then((response: any) => {
        if (response?.payload?.status_code === 200 && response?.payload?.data?.length === 0) {
          set_isDestinationPlatformConnectModalOpen(true)
        }
      })
  }, [])
  // Refresh API When Platform Connect
  useEffect(() => {
    if (destinationRecheck) {
      dispatch(getAllDestinationPlatformAsync({ userId: userId }) as any)
      dispatch(clearStatus())
      // setDestinationRecheck(false)
    }
  }, [destinationRecheck])
  // Set Destination From Dashboard
  useEffect(() => {
    let modalTimer

    if (dp_id && getPlatformDestination?.length > 0) {
      // console.log('dp_id', dp_id, 'getPlatformDestination', getPlatformDestination)

      let parentObj_destination = getPlatformDestination.filter(
        (item: any) => item?.platform_info?.id == dp_id
      )

      // If Only 1 Value Then Set It In Field Right Now If Not Then Giving Option In Dropdown :::
      if (parentObj_destination?.length === 1) {
        Formik.setFieldValue('destinationPlatform', {
          dp_parentId: parentObj_destination?.[0]?.id,
          da_apiId: parentObj_destination?.[0]?.platform_info?.id,
        })
      }
      if (parentObj_destination?.length > 1) {
        setDeshboardDirectedPlatforms([...parentObj_destination])
      }

      modalTimer = setTimeout(() => {
        if (parentObj_destination?.length === 0) {
          set_isDestinationPlatformConnectModalOpen(true)
        }
      }, 500)

      // To Complete Auth Process If isConnected is false
      if (parentObj_destination[0]?.isConnected === false) {
        setIframePlatformName(parentObj_destination[0]?.display_name)
        setIframeSrc(parentObj_destination[0]?.finishAuth_url)
        setOauth_ids({ available_platform_id: Number(dp_id), platform_id: Number(parentObj_destination[0]?.id) })
        // setTimeout(() => {
        //   setOpenIFrameModal(true)
        // }, 2000);
      }

      if (actionCollectionArray?.length) {
        let destinationActions = [] as number[];
        for (let i = 0; i <= actionCollectionArray.length; i++) {
          if (actionCollectionArray[i]?.p_id === dp_id) {
            destinationActions.push(actionCollectionArray[i]?.a_id)
          }
        }
        Formik.setFieldValue('destinationAvailableActions', destinationActions)
      }
    }

    return () => clearTimeout(modalTimer)
  }, [dp_id, getPlatformDestination])

  // For Workflow_action_flow actionCollectionArray?.p_id and dp_id if it matches it belongs to destination side
  useEffect(() => {
    if (Formik.values.destinationPlatform?.dp_parentId && actionCollectionArray?.length) {
      let flowIdentifire = [] as flowIdentifire[]
      for (let i = 0; i < actionCollectionArray.length; i++) {
        if (actionCollectionArray[i]?.p_id === dp_id) {
          flowIdentifire.push({
            main_id: actionCollectionArray[i]?.main_id,
            action_id: actionCollectionArray[i]?.a_id,
            source_id: Formik.values.destinationPlatform?.dp_parentId
          })
        }
      }
      Formik.setFieldValue('destination_actionFlow', flowIdentifire)
    }
  }, [Formik.values.destinationPlatform, actionCollectionArray])


  return (
    <Container>
      <Stack className={workflowId ? classes.hide : ''}>
        <Form.Label className='ms-1 mt-2'>{destinationName}</Form.Label>
        <Stack className={classes.platform_btn_container}>
          <Form.Select
            // disabled={workflowId ? true : false}
            disabled={deshboardDirectedPlatforms?.length === 0 ? true : false}
            name='destinationPlatform'
            value={Formik.values.destinationPlatform.dp_parentId}
            onChange={(event) => {
              const selectedValue: string = event.target.value
              let parentObj = getPlatformDestination.find(
                (item: Record<string, any>) => item.id == selectedValue
              )
              Formik.setFieldValue('destinationPlatform', {
                dp_parentId: selectedValue,
                da_apiId: parentObj?.platform_info?.id,
              })
              
            }}
            onBlur={Formik.handleBlur}
            isInvalid={
              Formik.touched.destinationPlatform?.dp_parentId &&
              !!Formik.errors.destinationPlatform?.dp_parentId
            }
          >
            <option value='' disabled>
              Select {destinationName} Account
            </option>
            {deshboardDirectedPlatforms?.length > 1
              ? deshboardDirectedPlatforms?.map((dpData: Record<string, any>) => (
                <option key={dpData.id} value={dpData.id}>
                  {dpData?.display_name}
                </option>
              ))
              : getPlatformDestination?.map((dpData: Record<string, any>) => (
                <option key={dpData.id} value={dpData.id}>
                  {dpData?.display_name}
                </option>
              ))}
          </Form.Select>
          <Button
            size='sm'
            title='Connect New Account'
            className='btn ms-1'
            style={{ height: '43px', fontSize: '25px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            onClick={() => {
              set_isDestinationPlatformConnectModalOpen(true)
            }}
          >
            +
          </Button>
        </Stack>
      </Stack>
    </Container>
  )
}

export default ComponentsDestinationSide
