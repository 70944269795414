import React, { Fragment } from 'react'
import UserManagementTab from './settings/UserAccountTabs/UserManagementTab'


function EditProfile() {
    return (
        <Fragment>
            <UserManagementTab />
        </Fragment>
    )
}

export default EditProfile

