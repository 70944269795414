import React, { Fragment } from 'react'
import classes from './platform.module.css'
import { Container, Card, Stack } from 'react-bootstrap'

type PlatformImageProps = {
    sourceImage: string
    destinationImage: string
    destinationName: string
    description: string
    sourceName: string
}

function PlatformImage({ sourceImage, destinationImage, sourceName, destinationName, description }: PlatformImageProps) {
    return (
        <Container>
            <Stack className={classes.container}>
                <div className={classes.img_name}>
                    <img
                        src={sourceImage}
                        style={{
                            marginRight: '20px',
                            width: 'auto',
                            minHeight: '80px',
                            maxHeight: '80px',
                        }}
                    />
                    <p style={{ position: 'relative', left: '-10px' }}>{sourceName}</p>
                </div>

                <span style={{ fontSize: '50px', marginLeft: '-15px', marginRight: '-15px' }}>&rarr;</span>

                <div className={classes.img_name}>
                    <img
                        src={destinationImage}
                        style={{
                            marginLeft: '20px',
                            width: 'auto',
                            minHeight: '80px',
                            maxHeight: '80px',
                        }}
                    />
                    <p style={{ position: 'relative', left: '12px' }}>{destinationName}</p>
                </div>
            </Stack>
            <Stack className={classes.discription}>
                <p dangerouslySetInnerHTML={{ __html: description }}></p>
            </Stack>
        </Container>
    )
}

export default PlatformImage
