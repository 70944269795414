import React, { useState, useEffect } from 'react';
import { image } from '../utils'
import './Slideshow.css';

type slideShowProps = {
    imageArray: image[]
}

const Slideshow = (props: slideShowProps) => {
    const { imageArray } = props
    const [slideIndex, setSlideIndex] = useState(0);

    useEffect(() => {
        showSlides(slideIndex);
    }, [slideIndex]);

    const plusSlides = (n: number) => {
        setSlideIndex((prevIndex) => (prevIndex + n + imageArray?.length) % imageArray?.length);
    };

    const currentSlide = (n: number) => {
        setSlideIndex(n);
    };

    const showSlides = (n: number) => {
        let i;
        const slides = document.getElementsByClassName('mySlides') as HTMLCollectionOf<HTMLElement>;
        const dots = document.getElementsByClassName('dot') as HTMLCollectionOf<HTMLElement>;

        for (i = 0; i < slides.length; i++) {
            slides[i].style.display = 'none';
        }

        for (i = 0; i < dots.length; i++) {
            dots[i].className = dots[i].className.replace(' active', '');
        }

        if (slides.length > 0 && dots.length > 0) {
            slides[n].style.display = 'block';
            dots[n].className += ' active';
        }
    };

    return (
        <div className="slideshow-container">
            {imageArray?.length && imageArray.map((img, index) => (
                <div key={index} className={`mySlides fade ${slideIndex === index ? 'show' : ''}`}>
                    {/* <div className="numbertext">{index + 1} / {imageArray.length}</div> */}
                    <img
                        src={img.image_url}
                        alt='Product Image'
                        style={{ width: 'auto', height: '150px', cursor: 'pointer' }}
                        onClick={() => window.open(img.image_url, '_blank')}
                    />
                    {/* <div className="text">Caption Text</div> */}
                </div>
            ))}

            <div className="middle-bottom">
                <a className="prev" onClick={() => plusSlides(-1)}>
                    ❮
                </a>
                <a className="next" onClick={() => plusSlides(1)}>
                    ❯
                </a>
            </div>

            <div className="text-center">
                {imageArray?.length && imageArray.map((_, index) => (
                    <span
                        key={index}
                        className={`dot ${slideIndex === index ? 'active' : ''} mt-50`}
                        onClick={() => currentSlide(index)}
                    ></span>
                ))}
            </div>
        </div>
    );
};

export default Slideshow;
