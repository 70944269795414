import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import { platformProcessPendingAsync } from './platformProcessAsync'

type platformProcessSLice = {
    platformProcessLoader: boolean
    initilizeProcess: any
}

const initialState: platformProcessSLice = {
    platformProcessLoader: false,
    initilizeProcess: [],
}

const platformProcessSLice = createSlice({
    name: 'platformProcessSLice',
    initialState,
    reducers: { stopInitializationProcess: () => initialState },
    //Pending Statements:
    extraReducers: (builder) => {
        builder.addMatcher(isAnyOf(
            platformProcessPendingAsync.pending,
        ), (state, action) => {
            state.platformProcessLoader = true
        })
        //Success Statements:
        builder.addMatcher(isAnyOf(platformProcessPendingAsync.fulfilled), (state, action) => {
            state.platformProcessLoader = false
            state.initilizeProcess = action.payload
        })
        // Reject Statements:
        builder.addMatcher(isAnyOf(
            platformProcessPendingAsync.rejected,
        ), (state, action) => {
            state.platformProcessLoader = false
            state.initilizeProcess = action.payload
        })
    },
})

export const { stopInitializationProcess } = platformProcessSLice.actions;
export default platformProcessSLice.reducer;
