import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import { updateWorkflowActionAsync } from './workflowActionAsync'

type workflowActionlSlice = {
    loading: boolean
    updateWorkflowAction: {status_code : number | null, massage: string}

}

const initialState: workflowActionlSlice = {
    loading: false,
    updateWorkflowAction: {status_code : null, massage: ''}
}

const workflowActionSlice = createSlice({
    name: 'workflowActionSlice',
    initialState,
    reducers: { emptyworkflowAction: () => initialState },

    extraReducers: (builder) => {

        //Pending Statements:
        builder.addMatcher(isAnyOf(
            updateWorkflowActionAsync.pending,
        ), (state, action) => {
            state.loading = true
        })
        //Success Statements:
        builder.addMatcher(isAnyOf(updateWorkflowActionAsync.fulfilled), (state, action) => {
            state.loading = false
            state.updateWorkflowAction = action.payload
        })
        // Reject Statements:
        builder.addMatcher(isAnyOf(
            updateWorkflowActionAsync.rejected,
        ), (state, action) => {
            state.loading = false
        })
    },
})

export const { emptyworkflowAction } = workflowActionSlice.actions;
export default workflowActionSlice.reducer;
