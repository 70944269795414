import React, { Fragment, useState } from 'react'
import { Button } from 'react-bootstrap'
import ReportParameter from './components/ReportParameter'

type generateReportProps = {
    fetchUsers: (data: number) => void
    workflowID: number
}
function GenerateReport({ fetchUsers, workflowID }: generateReportProps) {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    
    return (
        <Fragment>
            <Button
                size='lg'
                variant='secondary'
                onClick={() => setIsOpen(true)}
                title='Generate Report'
                className='p-3'
                >
                {/* Generate Report */}
                <i className="bi bi-journal-text fs-1"></i>
            </Button>

            <ReportParameter
                modalShow={isOpen}
                setModalShow={setIsOpen}
                fetchUsers={fetchUsers}
                workflowID={workflowID}
            />
        </Fragment>
    )
}

export default GenerateReport
