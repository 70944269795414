import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import {
  getAllSourcePlatformAsync,
  getAllDestinationPlatformAsync,
  getAllSourceActionNameAsync,
  getAllDestinationActionNameAsync,
  createWorkFlowFormDataAsync,
  getSourcePlatformSettingAsync,
  getDestinationPlatformSettingAsync,
  createWorkFlowAsync,
  sourcePlatformProcessAsync,
  destinationPlatformProcessAsync
} from './workflowFormAsync'

type workFlowFormSlice = {
  loading: boolean
  getPlatformSourceloader: boolean
  getPlatformDestinationLoader: boolean
  sourceActionNameLoader: boolean
  destinationActionNameLoader: boolean

  getPlatformSource: any
  getPlatformDestination: any
  getSourceActionName: any
  getDestinationActionName: any
  createWorkFlowFormData: any
  getPlatformSetting_source: any
  getPlatformSetting_destination: any
  createWorkFlow: any
  sourcePlatformProcess: any
  destinationPlatformProcess: any
}

const initialState: workFlowFormSlice = {
  loading: false,
  getPlatformSourceloader: false,
  getPlatformDestinationLoader: false,
  sourceActionNameLoader: false,
  destinationActionNameLoader: false,
  getPlatformSource: [],
  getPlatformDestination: [],
  getSourceActionName: [],
  getDestinationActionName: [],
  createWorkFlowFormData: [],
  getPlatformSetting_source: null,
  getPlatformSetting_destination: null,
  createWorkFlow: [],
  sourcePlatformProcess: [],
  destinationPlatformProcess: [],
}

const workFlowFormSlice = createSlice({
  name: 'workFlowFormSlice',
  initialState,
  reducers: { emptyForm: () => initialState },
  //Pending Statements:
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(
      createWorkFlowFormDataAsync.pending,
      getSourcePlatformSettingAsync.pending,
      getDestinationPlatformSettingAsync.pending,
      createWorkFlowAsync.pending,
      sourcePlatformProcessAsync.pending,
      destinationPlatformProcessAsync.pending,
    ), (state, action) => {
      state.loading = true
    })

    builder.addMatcher(isAnyOf(
      getAllSourcePlatformAsync.pending,
    ), (state, action) => {
      state.getPlatformSourceloader = true
    })
    builder.addMatcher(isAnyOf(
      getAllDestinationPlatformAsync.pending,
    ), (state, action) => {
      state.getPlatformDestinationLoader = true
    })
    builder.addMatcher(isAnyOf(
      getAllSourceActionNameAsync.pending,
    ), (state, action) => {
      state.sourceActionNameLoader = true
    })
    builder.addMatcher(isAnyOf(
      getAllDestinationActionNameAsync.pending,
    ), (state, action) => {
      state.destinationActionNameLoader = true
    })
    //Success Statements:
    builder.addMatcher(isAnyOf(getAllSourcePlatformAsync.fulfilled), (state, action) => {
      state.getPlatformSourceloader = false
      state.getPlatformSource = action.payload?.data
    })
    builder.addMatcher(isAnyOf(getAllDestinationPlatformAsync.fulfilled), (state, action) => {
      state.getPlatformDestinationLoader = false
      state.getPlatformDestination = action.payload?.data
    })
    builder.addMatcher(isAnyOf(getAllSourceActionNameAsync.fulfilled), (state, action) => {
      state.sourceActionNameLoader = false
      state.getSourceActionName = action.payload?.data
    })
    builder.addMatcher(isAnyOf(getAllDestinationActionNameAsync.fulfilled), (state, action) => {
      state.destinationActionNameLoader = false
      state.getDestinationActionName = action.payload?.data
    })
    builder.addMatcher(isAnyOf(createWorkFlowFormDataAsync.fulfilled), (state, action) => {
      state.loading = false
      state.createWorkFlowFormData = action.payload
    })
    builder.addMatcher(isAnyOf(getSourcePlatformSettingAsync.fulfilled), (state, action) => {
      state.loading = false
      state.getPlatformSetting_source = action.payload
    })
    builder.addMatcher(isAnyOf(getDestinationPlatformSettingAsync.fulfilled), (state, action) => {
      state.loading = false
      state.getPlatformSetting_destination = action.payload
    })
    builder.addMatcher(isAnyOf(createWorkFlowAsync.fulfilled), (state, action) => {
      state.loading = false
      state.createWorkFlow = action.payload
    })
    builder.addMatcher(isAnyOf(sourcePlatformProcessAsync.fulfilled), (state, action) => {
      state.loading = false
      state.sourcePlatformProcess = action.payload
    })
    builder.addMatcher(isAnyOf(destinationPlatformProcessAsync.fulfilled), (state, action) => {
      state.loading = false
      state.destinationPlatformProcess = action.payload
    })
    // Reject Statements:
    builder.addMatcher(isAnyOf(
      createWorkFlowFormDataAsync.rejected,
      getSourcePlatformSettingAsync.rejected,
      getDestinationPlatformSettingAsync.rejected,
      createWorkFlowAsync.rejected,
      sourcePlatformProcessAsync.rejected,
      destinationPlatformProcessAsync.rejected,
    ), (state, action) => {
      state.loading = false
    })
    builder.addMatcher(isAnyOf(
      getAllSourcePlatformAsync.rejected,
    ), (state, action) => {
      state.getPlatformSourceloader = false
    })
    builder.addMatcher(isAnyOf(
      getAllDestinationPlatformAsync.rejected,
    ), (state, action) => {
      state.getPlatformDestinationLoader = false
    })
    builder.addMatcher(isAnyOf(
      getAllSourceActionNameAsync.rejected,
    ), (state, action) => {
      state.sourceActionNameLoader = false
    })
    builder.addMatcher(isAnyOf(
      getAllDestinationActionNameAsync.rejected,
    ), (state, action) => {
      state.destinationActionNameLoader = false
    })
  },
})

export const { emptyForm } = workFlowFormSlice.actions;
export default workFlowFormSlice.reducer;
