import React, { Fragment, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Button } from 'react-bootstrap'
import classes from './orderDetail.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import ScreenWidth from '../../../../widgets/components/ScreenWidth'
import { useAppDispatch } from '../../../../../../../src/Redux/Store/hooks'
import { dateTimeFormatter } from '../../../../widgets/components/DateTimeFormater'
import { resyncRejectedOrderAsync } from '../../../../../../Redux/Slices/orderDetails/orderDetailsAsync'

type headerProps = {
  OrderData: any
  orderID: number
  closeModal: (status: boolean) => void
  setOrderID: (data: number) => void
  fetchGetOrderListById: (orderId: number, afterSync: boolean) => Promise<boolean>
  fetchUsers: (page: number, loadingRequired: boolean) => void
  currentPageOfFetchUser: number

}

function Header({ OrderData, closeModal, orderID, setOrderID, fetchGetOrderListById, fetchUsers, currentPageOfFetchUser }: headerProps) {
  const dispatch = useAppDispatch()
  const [screenWidth] = ScreenWidth()
  const [isSyncing, setIsSyncing] = useState(false)
  const [refreshing, setRefreshing] = useState(false)
  const { loading, reSyncOrderState } = useSelector(
    (state: {
      orderStatus: {
        loading: boolean
        reSyncOrderState: { status_code: number; massage: string }
      }
    }) => state.orderStatus
  )

  useEffect(() => {
    if (reSyncOrderState.status_code == 200) {
      toast.success(reSyncOrderState.massage)
    }
  }, [reSyncOrderState])

  return (
    <Fragment>
      <div className={classes.breadcrumbs}>
        <div className={classes.primary_Headercomponent}>
          {/* GO Back Section: */}
          <div className={classes.breadcrumbs_orderDetails}>
            <h3>Order Details</h3>
            <span className={classes.breadcrumbs_orderDetails_span}>
              <p
                className='fw-bold text-primary text-hover-success cursor-pointer'
                onClick={() => {
                  closeModal(false)
                  // navigate('/workflow')
                  window.location.reload()
                }}
              >
                Workflow
              </p>
              &nbsp;<p>-</p>&nbsp;
              <p>Individual Order Details</p>
            </span>
          </div>

          {/* order ID Section: */}
          <div className={classes.breadcrumbs_orderDetails}>
            <span className={classes.breadcrumbs_orderDetails_span}>
              <h2 className='fw-bolder text-hover-primary'>{OrderData?.api_id}</h2> &nbsp;&nbsp;
              <p className={classes.papre_one}>
                <i
                  className='bi bi-circle-fill text-info'
                  style={{ position: 'relative', top: '1px' }}
                ></i>
                &nbsp; {OrderData && OrderData.api_status ? OrderData.api_status : null}
              </p>
              &nbsp;&nbsp;
              <p
                className={classes.papre_two}
                data-tooltip-id='my-tooltip-1' // Tooltip id
                style={{
                  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                  backgroundColor:
                    OrderData && OrderData.destination_sync_status == 'SYNCED'
                      ? 'rgb(10, 120, 29)'
                      : OrderData && OrderData.destination_sync_status == 'PENDING'
                        ? 'rgb(173, 176, 73)'
                        : OrderData && OrderData.destination_sync_status == 'ERROR'
                          ? 'rgb(200, 95, 60)'
                          : 'pink',
                }}
              >
                {OrderData && OrderData.destination_sync_status
                  ? OrderData.destination_sync_status
                  : ''}
              </p>
            </span>
            <span className='d-flex justify-content-between p-1 align-items-center' style={{ marginRight: "80px" }} >

              <p className='fw-bold mb-0 mr-2'>
                Date-Time : {dateTimeFormatter(OrderData?.api_created_at)}
              </p>
              <Button
                size='sm'
                disabled={isSyncing || refreshing}
                //  style={{display:"none"}}
                variant='secondary'
                className=' bg-secondary btn ms-2'
                title='Refresh'
                onClick={() => {
                  setRefreshing(true)
                  fetchGetOrderListById(orderID, true).then((res) => {
                    if (res) {
                      toast.success("Data refreshed")
                    } else {
                      toast.success("Data not refreshed")
                    }
                    setRefreshing(false)
                  })
                    .catch((err) => {
                      console.log('err', err)
                      toast.error("Unable to refresh")
                      setRefreshing(false)
                    })

                }}
              >
                {/* { !refreshing ? <i className="bi bi-arrow-repeat fw-bold fs-2 text-light" ></i> :
              <i className="fa-solid fa-rotate text-light fa-spin" style={{fontSize:"18px"}} ></i>} */}
                {
                  !refreshing ?
                    <i className="bi bi-arrow-repeat fw-bold fs-2"></i> :
                    <i className="fa-solid fa-rotate fa-spin"></i>
                }
              </Button>
            </span>
          </div>
        </div>
        {/* ---------------------------Tooltip Section Start */}
        <ReactTooltip
          id='my-tooltip-1'
          place='top'
          content={
            OrderData && OrderData.destination_sync_status == 'SYNCED'
              ? OrderData?.destination_sync_data?.api_id
              : OrderData && OrderData.destination_sync_status == 'ERROR'
                ? OrderData?.destination_sync_data?.api_message
                : null
          }
        />
        {/* ---------------------------Tooltip Section End */}

        {/* Any Action Section */}
        <div>
          {OrderData && OrderData.destination_sync_status == 'ERROR' && (
            <Button
              variant='success'
              disabled={isSyncing}
              size='sm'
              style={{
                position: 'absolute',
                top: screenWidth >= 992 && screenWidth <= 1199 ? '50px' : '15px',
                right:
                  screenWidth >= 320 && screenWidth <= 991
                    ? '50px'
                    : screenWidth >= 992 && screenWidth <= 1199
                      ? '30px'
                      : '250px',
              }}

              onClick={() => {
                try {
                  setIsSyncing(true)
                  dispatch(resyncRejectedOrderAsync({ id: orderID }))
                    .then((data) => {
                      toast.success(data.payload.message)
                      fetchGetOrderListById(orderID, true)
                      fetchUsers(currentPageOfFetchUser, false)
                      setIsSyncing(false)
                    })
                    .catch((err) => {
                      console.log("error in changed... ", err);
                      setIsSyncing(false)
                    })

                } catch (error) {
                  console.log('error', error)
                  setIsSyncing(false)
                }
              }}
            >
              {isSyncing ? "Re-Syncing..." : "Resync"}
            </Button>
          )}

        </div>

        <div
          onClick={() => {
            closeModal(false)
            // setOrderID(null)
          }}
          className='btn btn-icon btn-sm btn-active-light-primary fs-2 fw-bolder text-hover-danger ms-3'
          style={{ position: 'absolute', top: '0px', right: '0px' }}
        >
          <span className='svg-icon svg-icon svg-icon-2x fw-bolder'>
            <svg width='24' height='24' xmlns='http://www.w3.org/2000/svg' className='mh-50px'>
              <rect
                opacity='0.5'
                x='6'
                y='17.3137'
                width='16'
                height='2'
                rx='1'
                transform='rotate(-45 6 17.3137)'
                fill='currentColor'
              ></rect>
              <rect
                x='7.41422'
                y='6'
                width='16'
                height='2'
                rx='1'
                transform='rotate(45 7.41422 6)'
                fill='currentColor'
              ></rect>
            </svg>
          </span>
        </div>
      </div>
    </Fragment>
  )
}

export default Header
