import React, { Fragment } from 'react';
import ReportFields from './ReportFields';
import classes from './gererateReport.module.css'
import { Modal, Container } from 'react-bootstrap';
import CloseButton from '../../../FieldMapping/components/CloseButton';
type reportParameterProps = {
    modalShow: boolean
    setModalShow: (status: boolean) => void
    fetchUsers: (data: number) => void
    workflowID: number
}

function ReportParameter(props: reportParameterProps) {
    const { modalShow, setModalShow, fetchUsers, workflowID } = props
    return (
        <Fragment>
            <Modal
                show={modalShow}
                onHide={() => setModalShow(true)}
                size='lg'
                centered
                style={{ backgroundColor: '#0007' }}
            >
                <div
                    onClick={() => {
                        setModalShow(false)
                        fetchUsers(1)
                    }}
                >
                    <CloseButton />
                </div>

                <Container className={classes.reportParameter_container}>
                    <h2 className={classes.heading}>Generate Report</h2>
                    <ReportFields {...{ workflowID, setModalShow }} />
                </Container>
            </Modal>
        </Fragment>
    )
}

export default ReportParameter
