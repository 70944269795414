import React, { Fragment, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import classes from '../components/availableFlows.module.css'
import CloseButton from '../../FieldMapping/components/CloseButton'
import { useAppDispatch, useAppSelector } from 'src/Redux/Store/hooks'
import { getAllMappingsByWorkflowIDAsync } from 'src/Redux/Slices/workflowFieldMapping/MappingListAsync'
import CreateMappingFields from './createMappingFields/CreateMappingFields'
import UpdateMappingFields from './updateMappingFields/UpdateMappingFields'
type mappingProps = {
  modalShow: boolean
  displayName: string
  workflow_info: number
  workflowID: number
  setModalShow: (status: boolean) => void
  sourceID: number
  destinationID: number
  modalType: string
}

function Mapping(props: mappingProps) {
  const dispatch = useAppDispatch()
  const { modalShow, setModalShow, workflow_info, workflowID, displayName, sourceID, destinationID, modalType } = props
  const { loading } = useAppSelector((state) => state.availableFlowMapping)


  return (
    <Fragment>
      <Modal
        size='xl' centered
        show={modalShow}
        onHide={() => setModalShow(true)}
        style={{ backgroundColor: '#0007' }}
      >
        <div onClick={() => {
          dispatch(getAllMappingsByWorkflowIDAsync({ workflowID: Number(workflowID), page: 1, limit: 2 }))
          setModalShow(false)
        }}>
          <CloseButton />
        </div>
        <Fragment>
          <Modal.Header className={classes.modal_header}>
            {modalType === 'create' ? 'Field Mapping' : 'Update Mapping'}
          </Modal.Header>
          <Modal.Body>
            {
              modalType === 'create' ? (
                <CreateMappingFields displayName={displayName} sourceID={sourceID} destinationID={destinationID} workflowID={workflowID} />
              ) : (
                <UpdateMappingFields displayName={displayName} sourceID={sourceID} destinationID={destinationID} workflowID={workflowID}/>
              )
            }
          </Modal.Body>
        </Fragment>
      </Modal>
    </Fragment>
  )
}

export default Mapping
