import { configureStore } from '@reduxjs/toolkit'
import AuthSlices from '../../app/redux/slices/AuthSlices'
import PlatformSlices from '../../app/redux/slices/PlatformSlices'
import WorkflowSlice from '../../app/redux/slices/WorkflowSlices'
import DashboardSlice from '../../app/redux/slices/DashboardSlices'
// Newly Added:
import workflowFormSlice from '../Slices/workflowFormSlice/workflowFormSlice'
import stateSaveSlice from '../Slices/stateSave/stateSaveSlice'
import processOauthSlice from '../Slices/processOauth/processOauthSlice'
import orderDetailsSlice from '../Slices/orderDetails/orderDetailsSlice'
import platformCombinationSlice from '../Slices/platform/platformSlice'
import workflowDetailModalSlice from '../Slices/workflowDetailsModal/workflowDetailModalSlice'
import credentialsSection from '../Slices/credentials/credentialsSlice'
import subdomainSlice from '../Slices/subdomain/subdomainSlice'
import inviteUserSlice from '../Slices/inviteUsers/inviteUserSlice'
import loggedInUserSlice from '../Slices/loggedInUser/loggedInUserSlice'
import organizationSlice from '../Slices/organizations/organizationSlice'
import infoSlice from '../Slices/info/infoSlice'
import FieldMappingSlice from '../Slices/workflowFieldMapping/FieldMappingSlice'
import updateFieldMappingSlice from '../Slices/workflowFieldMapping/FieldMappingUpdateSlice'
import platformProcessSlice from '../Slices/platformProcessInitilization/platformProcessSlice'
import graphSlice from '../Slices/Graphs/graphSlice'
import availableWorkflowSlice from '../Slices/availableWorkflow/availableWorkflowSlice'
import organizationPlatformsSlice from '../Slices/platform/organizationPlatform'
import workflowActionSlice from '../Slices/workflowDetailsModal/workflowActionSlice'
import connectPlatformDataSlice from '../Slices/stateSave/connectPlatformDataSlice'
import connectPlatformViaCreateWorkflowSlice from '../Slices/stateSave/connectPlatformViaCreateWorkflow'
import allWorkflowSlice from '../Slices/workflowFormSlice/allWorkflowSlice'
import platformSettingSlice from '../Slices/platformSettings/platformSettingSlice'
import AvailableFlowMappingSlice from '../Slices/workflowFieldMapping/AvailableFlowMappingSlice'
import MappingListSlice from '../Slices/workflowFieldMapping/MappingListSlice'
export const store = configureStore({
  reducer: {
    auth: AuthSlices,
    platform: PlatformSlices,
    Workflow: WorkflowSlice,
    dashboard: DashboardSlice,
    // Newly Added:
    workflowForm: workflowFormSlice,
    useFulStates: stateSaveSlice,
    oauth: processOauthSlice,
    orderStatus: orderDetailsSlice,
    platformCombination: platformCombinationSlice,
    workflowDetailModal: workflowDetailModalSlice,
    credentials: credentialsSection,
    subdomain: subdomainSlice, // slice for accessing subdomain data
    inviteUser: inviteUserSlice, // slice to access invite user
    loggedInUser: loggedInUserSlice, // slice to use data of logged in user
    organization: organizationSlice, // organization slice for handle organization related things
    info: infoSlice, // for showing information as alert form
    MappedFields: FieldMappingSlice,
    updatedMappings: updateFieldMappingSlice,
    platformProcess: platformProcessSlice,
    availableWorkflow: availableWorkflowSlice,
    graphData: graphSlice,
    organizationBasedPlatforms: organizationPlatformsSlice,
    workflowAction: workflowActionSlice,
    createWorkflowCardData: connectPlatformDataSlice,
    platformConnectByCreateWorkflow: connectPlatformViaCreateWorkflowSlice,
    allAvailableWorkflows: allWorkflowSlice,
    platformSettings: platformSettingSlice,
    availableFlowMapping: AvailableFlowMappingSlice,
    mappingList: MappingListSlice,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})


// Setting the type of store from store state
export type RootState = ReturnType<typeof store.getState>

// Setting the type for dispatch from store dispatch 
export type AppDispatch = typeof store.dispatch
