import React from 'react'
import {Modal} from 'react-bootstrap'
import {InviteUserModalProps} from '../Interface'
import InviteUserForm from './InviteUserForm'

const InviteUserModal = ({visible, handleClose}: InviteUserModalProps) => {
  return (
    <Modal
      show={visible}
      onHide={handleClose}
      size='xl'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Invite User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InviteUserForm handleClose={handleClose} />
      </Modal.Body>
    </Modal>
  )
}

export default InviteUserModal
