import React, { Fragment } from 'react'
import { FormikProps } from 'formik'
import { Container } from 'react-bootstrap'
import { initialSateKeys, platformAuthRequirement, platformValues } from './utils'

type formComponentType = {
  Formik: FormikProps<initialSateKeys>
  filteredArray: platformValues | null
  submitFormLoader: boolean
}

function FormComponent(props: formComponentType) {
  const { Formik, filteredArray, submitFormLoader } = props

  function getInputBox(input_type: string, name: string, display_name: string) {
    if (input_type == 'text') {
      return (
        <input
          placeholder={display_name}
          type='text'
          name={name}
          onChange={Formik.handleChange}
          className='form-control form-control-lg form-control-solid'
        />
      )
    }
  }
  return (
    <Fragment>
      <Container className='mt-5'>
        {/* When there is no "auth_route_name" */}
        <div className='row mb-4'>
          <div className='col-12'>
            <input
              type='text'
              placeholder='Friendly Name'
              className='mb-5 form-control form-control-lg form-control-solid'
              name='display_name'
              value={Formik.values.display_name}
              onChange={Formik.handleChange}
            />
          </div>

          {filteredArray?.available_platform_auth_requirement?.map(
            (item: platformAuthRequirement) => {
              const { display_name, href, id, input_type, name, platform } = item
              const inputBox = getInputBox(input_type, name, display_name)
              return (
                <div className='col' key={id}>
                  {inputBox}
                </div>
              )
            }
          )}
        </div>
        {/* When there is "auth_route_name" */}
        {filteredArray?.auth_route_name ? (
          <a href={filteredArray?.auth_route_name} rel='noreferrer' target='_blank'>
            <button
              type='button'
              id='kt_sign_in_submit'
              className='btn btn-md w-100 mb-7'
              style={{
                backgroundColor: '#2CA01C',
                color: 'white',
                lineHeight: '20px',
              }}
              onClick={() =>
                localStorage.setItem('oauthDisplayText', JSON.stringify(Formik.values.display_name))
              }
            >
              Connect To {filteredArray?.display_name}
            </button>
          </a>
        ) : (
          <button
            type='submit'
            id='kt_sign_in_submit'
            className='btn btn-md w-100 mt-7 mb-7'
            style={{ backgroundColor: '#2CA01C', color: 'white', lineHeight: '20px' }}
            disabled={submitFormLoader}
          >
            {!submitFormLoader && (
              <span className='indicator-label'>Connect To {filteredArray?.display_name}</span>
            )}
            {submitFormLoader && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        )}
      </Container>
    </Fragment>
  )
}

export default FormComponent
