import * as Yup from 'yup'

export type flowIdentifire = { main_id: number, source_id: string | number, action_id: number }

export type formInitialStateType = {
    sourcePlatform: { sp_parentId: string; sa_apiId: string }
    destinationPlatform: { dp_parentId: string; da_apiId: string }
    sourceAction: string[]
    destinationAction: string[]
    sourceAvailableActions: number[]
    destinationAvailableActions: number[]
    source_actionFlow: flowIdentifire[] | []
    destination_actionFlow: flowIdentifire[] | []
}

export const initialState: formInitialStateType = {
    sourcePlatform: { sp_parentId: '', sa_apiId: '' },
    destinationPlatform: { dp_parentId: '', da_apiId: '' },
    sourceAction: [],
    destinationAction: [],
    sourceAvailableActions: [],
    destinationAvailableActions: [],
    source_actionFlow: [],
    destination_actionFlow: []
}

export const vadidationSchema = Yup.object().shape({
    sourcePlatform: Yup.object().required('Source Platform is required'),
    destinationPlatform: Yup.object().required('Destination Platform is required'),
    sourceAction: Yup.array().min(1, 'Source Action is required'),
    destinationAction: Yup.array().min(1, 'Destination Platform Action is required'),
})

export type oAuthValues = {
    available_platform_id: number, platform_id: number
}

export type workflowAction = {
    id: number
    isEnabled: boolean
    last_run: any
    runs_in: number
    run_order: number
    platform: number
    workflow: number
    action: {
        id: number
        action: string
        action_type: string
        action_name: string
    }
}
export type action_flows = {
    id: number
    workflow: number
    isEnabled: boolean
    display_name: string
    workflow_action1: number
    workflow_action2: number
}