import React, { Fragment } from 'react'
import { Stack } from 'react-bootstrap'
import classes from './subHeader.module.css'

type selectAllProps = {
  totalRows: number
  allRowChecked: boolean
  setCheckedAll: (status: boolean) => void
  setAllRowChecked: (status: boolean) => void
  setCheckedValue: (data: number[] | []) => void
}

function SelectAll(props: selectAllProps) {
  const { allRowChecked, setCheckedAll, setAllRowChecked, setCheckedValue, totalRows } = props
  const themeMode = localStorage.getItem('kt_theme_mode_value')

  return (
    <Fragment>
      <Stack style={{ position: 'absolute', left: '19px', bottom: '7px' }}>
        <span className={classes.applyAll_checkbox}>
          <input
            type='checkbox'
            checked={allRowChecked}
            onChange={(event) => {
              setCheckedAll(event.target.checked)
              setAllRowChecked(event.target.checked)
              if (event.target.checked === false) {
                setCheckedValue([])
              }
            }}
          />
          <label style={{ color: themeMode === 'light' ? 'black' : 'white' }}>{allRowChecked ? `All ${totalRows} Rows Selected` : 'Select All'}</label>
        </span>
      </Stack>
    </Fragment>
  )
}

export default SelectAll
