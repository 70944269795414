import React, { Fragment, useState } from 'react'
import { Row, Col, Card } from 'react-bootstrap'
import ReactReadMoreReadLess from 'react-read-more-read-less'
import classes from '../../IndividualOrderDetail/components/orderDetail.module.css'

export type lineItems = {
    id: number
    order_line_item: number|null
    line_item_api_id: string
    line_item_name: string|null
    api_id: string
    product_api_id: string
    quantity: number
    created_at?: string
    updated_at?:string
}

type shipmentLineItems = {
    lineItemsList: lineItems[] | []
}

function ShipmentLineItems({ lineItemsList }: shipmentLineItems) {

    return (
        <Fragment>
            <Card>
                <Card.Header className={classes.card_header}>Line Items</Card.Header>
                <Row className='ps-10 pe-8 mt-3'>
                    <Col xs={6}>
                        <Card.Subtitle className={classes.card_subHeading_product}>
                            Product Details
                        </Card.Subtitle>
                    </Col>
                    <Col xs={3}>
                        <Card.Subtitle className={classes.card_subHeading}>Product Key</Card.Subtitle>
                    </Col>
                    <Col xs={3}>
                        <Card.Subtitle className={classes.card_subHeading}>Quantity</Card.Subtitle>
                    </Col>
                </Row>

                {lineItemsList?.map((items: any) => (
                    <Card.Body key={items.id} className='border-bottom border-secondary'>
                        <Row>
                            <Col xs={6}>
                                <Card.Text className={classes.card_text_productDetail}>
                                    <ReactReadMoreReadLess
                                        className='react-read-more-read-less'
                                        charLimit={50}
                                        readMoreText={'▼'}
                                        readLessText={'▲'}
                                    >
                                        {items?.line_item_name ? items?.line_item_name : '' }
                                    </ReactReadMoreReadLess>
                                </Card.Text>
                            </Col>
                            <Col xs={3}>
                                <Card.Text className={classes.card_text}>{items?.product_api_id}</Card.Text>
                            </Col>

                            <Col xs={3}>
                                <Card.Text className={classes.card_text}>{items?.quantity}</Card.Text>
                            </Col>
                        </Row>
                    </Card.Body>
                ))}
            </Card>
        </Fragment>
    )
}

export default ShipmentLineItems
