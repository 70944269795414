import {AxiosClient} from '../../AxiosClient/axiosClient'
import {createAsyncThunk} from '@reduxjs/toolkit'

export const inviteUserAsync = createAsyncThunk(
  'inviteUserAsync',
  async (payload: {name: string, email: string, organization_id:number }, toolkit) => {
    return await AxiosClient('POST', `/invited-user/`, payload, toolkit)
  }
)

export const getInvitedUsersAsync = createAsyncThunk(
  'inviteUserAsync/getInvitedUsersAsync',
  async (params:{}|undefined, toolkit) => {
    return await AxiosClient('GET', `/invited-user/`, [], toolkit, params )
  }
)
