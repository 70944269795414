import React from 'react'

function CloseButton() {
    return (
        <div className="btn btn-icon btn-sm btn-active-light-primary fs-2 fw-bold text-hover-danger ms-3"
            style={{ position: 'absolute', top: '0', right: '0' }}>
            <span className="svg-icon svg-icon svg-icon-2x">
                <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" className="mh-50px">
                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor">
                    </rect>
                    <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor">
                    </rect>
                </svg>
            </span>
        </div>
    )
}

export default CloseButton
