import React, { Fragment, useState, useEffect } from 'react'
import ChildSettingComponent from './ChildSettingComponent'
import { Form, Button, Stack } from 'react-bootstrap'
import AppEditor from 'src/utils/AppEditor'
import Select from 'react-select'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import classes from '../../components/availableFlows.module.css'
type inputComponentProps = {
    apiName: string
    inputType: string
    displayName: string
    previousValue: string
    dataCollectorFun: (data: any) => void
    settings: { api_id: string; display_name: string }[]
    childSetting: {
        name: string
        value: string
        input_type: string
        id: string | number
        display_name: string
        description: string
        displayShortcodes: boolean
        setting_available_options: { api_id: string; display_name: string }[]
    }[]
    shortCode: string[] | []
    displayShortcodes: boolean
    description: string

}
type multiselectValues = { label: string; value: string }
type childDataArrayValues = { name: string; value: string }

export function TextAreaComponent(props: inputComponentProps) {
    const { apiName, displayName, previousValue, dataCollectorFun, shortCode, displayShortcodes, description } = props
    const [getAddress, setAddress] = useState<string>(previousValue || '')

    const [isShortCodeVisible, setShortCodeVisible] = useState(false)
    const [copyMarkup, setCopyMarkup] = useState(false)
    const [buttonString, setButtonString] = useState('')

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setAddress(event.target.value)
    }

    useEffect(() => {
        dataCollectorFun({
            name: apiName,
            value: getAddress,
            child: [],
        })
    }, [getAddress])

    const handleCopyMarkup = (data: string) => {
        setCopyMarkup(true)
        setButtonString(data)

        setTimeout(() => {
            setCopyMarkup(false)
            setButtonString('')
        }, 1000);
    }

    return (
        <Fragment>
            <Form.Group controlId='exampleForm.ControlTextarea1'>
                <Form.Label className='mt-3'>{displayName}</Form.Label>
                <Form.Control value={getAddress} onChange={handleChange} as='textarea' rows={3} />
                {/* Copy To CLipboard Logic--------------------------------------------------- */}
                <Stack className={classes.shortCodeDisplayContainer}>
                    <p className='text-muted text-hover-primary fw-bold mt-1 cursor-pointer'
                        onClick={() => setShortCodeVisible(!isShortCodeVisible)}
                    >
                        {displayShortcodes ? 'View Short Codes' : null}
                    </p>
                </Stack>
                {
                    isShortCodeVisible ? (
                        <Stack className={classes.shortCodes}>
                            {
                                shortCode?.length ? shortCode.map((ele: string) => {
                                    return (
                                        <Fragment key={ele}>
                                            <CopyToClipboard text={ele} >
                                                <Button
                                                    key={ele}
                                                    size='sm'
                                                    variant={(copyMarkup && buttonString === ele) ? 'outline-success' : 'outline-primary'}
                                                    className={`px-3 py-2 border border-1 ${(copyMarkup && buttonString === ele) ? 'border-success' : 'border-primary'} rounded text-hover-dark fs-sm-8 inline-block`}
                                                    onClick={() => handleCopyMarkup(ele)}
                                                >
                                                    {(copyMarkup && buttonString === ele) ? <span className='fs-sm-8'>Copied !</span> : ele}
                                                </Button>
                                            </CopyToClipboard>
                                        </Fragment>
                                    )
                                }) : null
                            }
                        </Stack>
                    ) : null
                }
                {/* Copy To CLipboard Logic--------------------------------------------------- */}
            </Form.Group>
        </Fragment>
    )
}
export function BooleanComponent(props: inputComponentProps) {
    const { apiName, displayName, previousValue, dataCollectorFun, childSetting, shortCode, displayShortcodes, description } = props
    const [isSwitchActive, setIsSwitchActive] = useState(previousValue === 'True' ? true : false)
    const [childDataArray, setChildDataArray] = useState<childDataArrayValues[] | []>([])

    const [isShortCodeVisible, setShortCodeVisible] = useState(false)
    const [copyMarkup, setCopyMarkup] = useState(false)
    const [buttonString, setButtonString] = useState('')
    const childDataCollectionFun = (data: childDataArrayValues) => {
        setChildDataArray((prevValues) => {
            let updatedArray = prevValues.map((item: { name: string; value: string }) =>
                item?.name == data?.name ? data : item
            )
            if (!prevValues.some((item: { name: string; value: string }) => item?.name == data?.name)) {
                updatedArray.push(data)
            }
            return updatedArray
        })
    }

    useEffect(() => {
        dataCollectorFun({
            name: apiName,
            value: isSwitchActive ? 'True' : 'False',
            child: [...childDataArray],
        })
    }, [isSwitchActive, childDataArray])

    const handleCopyMarkup = (data: string) => {
        setCopyMarkup(true)
        setButtonString(data)

        setTimeout(() => {
            setCopyMarkup(false)
            setButtonString('')
        }, 1000);
    }

    return (
        <Fragment>
            <Form className='d-flex justify-content-start align-items-baseline gap-3'>
                <Form.Label className='fs-6 text-muted text-hover-primary'>{displayName}</Form.Label>
                <Form.Check
                    type='switch'
                    className='mt-7'
                    id='custom-switch'
                    // label={displayName}
                    checked={isSwitchActive}
                    onChange={() => setIsSwitchActive(!isSwitchActive)}
                />
                {/* Copy To CLipboard Logic--------------------------------------------------- */}
                <Stack className={classes.shortCodeDisplayContainer}>
                    <p className='text-muted text-hover-primary fw-bold mt-1 cursor-pointer'
                        onClick={() => setShortCodeVisible(!isShortCodeVisible)}
                    >
                        {displayShortcodes ? 'View Short Codes' : null}
                    </p>
                </Stack>
                {
                    isShortCodeVisible ? (
                        <Stack className={classes.shortCodes}>
                            {
                                shortCode?.length ? shortCode.map((ele: string) => {
                                    return (
                                        <Fragment key={ele}>
                                            <CopyToClipboard text={ele} >
                                                <Button
                                                    key={ele}
                                                    size='sm'
                                                    variant={(copyMarkup && buttonString === ele) ? 'outline-success' : 'outline-primary'}
                                                    className={`px-3 py-2 border border-1 ${(copyMarkup && buttonString === ele) ? 'border-success' : 'border-primary'} rounded text-hover-dark fs-sm-8 inline-block`}
                                                    onClick={() => handleCopyMarkup(ele)}
                                                >
                                                    {(copyMarkup && buttonString === ele) ? <span className='fs-sm-8'>Copied !</span> : ele}
                                                </Button>
                                            </CopyToClipboard>
                                        </Fragment>
                                    )
                                }) : null
                            }
                        </Stack>
                    ) : null
                }
                {/* Copy To CLipboard Logic--------------------------------------------------- */}
            </Form>
            {
                description &&
                <Form.Text className='ms-7'><span style={{ color: 'red', fontWeight: 'bolder' }}>*</span>{description}</Form.Text>
            }
            {(childSetting && childSetting?.length) ?
                childSetting.map((inps) => {
                    return (
                        <Stack style={{ display: isSwitchActive ? 'flex' : 'none' }} key={inps?.id}>
                            <ChildSettingComponent
                                apiName={inps?.name}
                                shortCode={shortCode}
                                previousValue={inps?.value}
                                inputType={inps?.input_type}
                                displayName={inps?.display_name}
                                settings={inps?.setting_available_options}
                                childDataCollectionFun={childDataCollectionFun}
                                shortCodeVisibility={inps?.displayShortcodes}
                                description={inps?.description}
                            />
                        </Stack>
                    )
                }) : null}
        </Fragment>
    )
}
export function ButtonComponent(props: inputComponentProps) {
    const { apiName, displayName, previousValue, dataCollectorFun, shortCode } = props
    const [isButtonClicked, setISButtonClicked] = useState(previousValue === 'True' ? true : false)

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setISButtonClicked(!isButtonClicked)
    }

    useEffect(() => {
        dataCollectorFun({
            name: apiName,
            value: isButtonClicked,
            child: [],
        })
    }, [isButtonClicked])

    return (
        <Fragment>
            <Button variant={isButtonClicked ? 'success' : 'primary'} onClick={handleClick}>
                {displayName}
            </Button>
        </Fragment>
    )
}
export function TextBasedComponent(props: inputComponentProps) {
    const { inputType, apiName, displayName, previousValue, dataCollectorFun, shortCode, displayShortcodes } = props
    const [inputValue, setInputValue] = useState<string>(previousValue || '')

    const [isShortCodeVisible, setShortCodeVisible] = useState(false)
    const [copyMarkup, setCopyMarkup] = useState(false)
    const [buttonString, setButtonString] = useState('')
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let enteredValue = event.target.value
        setInputValue(enteredValue)
    }

    useEffect(() => {
        dataCollectorFun({
            name: apiName,
            value: inputValue,
            child: [],
        })
    }, [inputValue])

    const handleCopyMarkup = (data: string) => {
        setCopyMarkup(true)
        setButtonString(data)

        setTimeout(() => {
            setCopyMarkup(false)
            setButtonString('')
        }, 1000);
    }

    return (
        <Fragment>
            <Form.Group>
                <Form.Label className='mt-3'>{displayName}</Form.Label>
                <Form.Control
                    type={
                        inputType == 'email'
                            ? 'email'
                            : inputType == 'integer'
                                ? 'number'
                                : inputType == 'phone'
                                    ? 'number'
                                    : 'text'
                    }
                    placeholder={`${displayName}`}
                    value={inputValue}
                    onChange={handleChange}
                />
                {/* Copy To CLipboard Logic--------------------------------------------------- */}
                <Stack className={classes.shortCodeDisplayContainer}>
                    <p className='text-muted text-hover-primary fw-bold mt-1 cursor-pointer'
                        onClick={() => setShortCodeVisible(!isShortCodeVisible)}
                    >
                        {displayShortcodes ? 'View Short Codes' : null}
                    </p>
                </Stack>
                {
                    isShortCodeVisible ? (
                        <Stack className={classes.shortCodes}>
                            {
                                shortCode?.length ? shortCode.map((ele: string) => {
                                    return (
                                        <Fragment key={ele}>
                                            <CopyToClipboard text={ele} >
                                                <Button
                                                    key={ele}
                                                    size='sm'
                                                    variant={(copyMarkup && buttonString === ele) ? 'outline-success' : 'outline-primary'}
                                                    className={`px-3 py-2 border border-1 ${(copyMarkup && buttonString === ele) ? 'border-success' : 'border-primary'} rounded text-hover-dark fs-sm-8 inline-block`}
                                                    onClick={() => handleCopyMarkup(ele)}
                                                >
                                                    {(copyMarkup && buttonString === ele) ? <span className='fs-sm-8'>Copied !</span> : ele}
                                                </Button>
                                            </CopyToClipboard>
                                        </Fragment>
                                    )
                                }) : null
                            }
                        </Stack>
                    ) : null
                }
                {/* Copy To CLipboard Logic--------------------------------------------------- */}
            </Form.Group>
        </Fragment>
    )
}
export function MultipleSelectComponent(props: inputComponentProps) {
    const { inputType, apiName, displayName, previousValue, dataCollectorFun, settings, shortCode } = props
    const [preSelectedValues, setPreSelectedValues] = useState<multiselectValues[] | []>()
    // Step 01: Preparing Dropdownoptions Getting From API
    useEffect(() => {
        if (settings.length) {
            let dropdownData = settings.map((vals: Record<string, any>) => {
                return {
                    label: vals?.display_name,
                    value: vals?.api_id,
                }
            })
            setPreSelectedValues(dropdownData)
        }
    }, [settings])
    // step 02 : Setting Default Values
    const defaultSelectedValues = settings.filter((option: { api_id: string | number }) => {
        return previousValue.includes(option.api_id.toString())
    })
    const defaultSelectedOptions = defaultSelectedValues.map((value: Record<string, any>) => ({
        value: value.api_id,
        label: value.display_name,
    }))

    const [selectedValues, setSelectedValues] = useState(defaultSelectedOptions)
    // Step 03: Taking All Selected values
    const handleMultiSelectChange = (selectedOptions: any) => {
        setSelectedValues(selectedOptions)
    }

    useEffect(() => {
        let selectedValue_string: string = ''
        selectedValues.map((item: Record<string, any>, index: number) => {
            if (index == selectedValues.length - 1) {
                return (selectedValue_string += item?.value)
            } else {
                return (selectedValue_string += item?.value + ',')
            }
        })
        dataCollectorFun({
            name: apiName,
            value: selectedValue_string || '',
            child: [],
        })
    }, [settings, selectedValues])

    return (
        <Form.Group>
            <Form.Label className='fs-6 mt-5'>{displayName}</Form.Label>
            <Select
                name={displayName}
                placeholder={displayName}
                value={selectedValues}
                options={preSelectedValues}
                isMulti
                className='react-select-container'
                classNamePrefix='react-select'
                onChange={handleMultiSelectChange}
            />
        </Form.Group>
    )
}
export function SelectComponent(props: inputComponentProps) {
    const { inputType, apiName, displayName, previousValue, dataCollectorFun, settings, shortCode } = props
    const [selectdValue, setSelectedValue] = useState<string>(previousValue || '')

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedValue(event.target.value)
    }

    useEffect(() => {
        dataCollectorFun({
            name: apiName,
            value: selectdValue,
            child: [],
        })
    }, [selectdValue])

    return (
        <Fragment>
            <Form.Label className='mt-3'>{displayName}</Form.Label>
            <Form.Select value={selectdValue} onChange={handleChange}>
                <option value='' disabled>
                    Select Option
                </option>
                {settings?.map((items: Record<string, any>) => (
                    <option key={items.id} value={items?.api_id}>
                        {items?.display_name}
                    </option>
                ))}
            </Form.Select>
        </Fragment>
    )
}
