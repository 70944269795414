import { createSlice } from '@reduxjs/toolkit';

const initialState = { error: '' }

const errorSlice = createSlice({
    name: 'error',
    initialState,
    reducers: {
        errorMessage: (state, action) => {
            state.error = action.payload;
        },
        emptyErrorMessage: (state, action) => {
            state.error = "";
        },
    }
});

export const { errorMessage, emptyErrorMessage } = errorSlice.actions;

export default errorSlice.reducer;