import React from 'react'
import { Modal } from 'react-bootstrap'
import { AddPlatformModal } from './Interface'
import AddPlatformForm from './AddPlatformForm'

const AddPlatFormModal = ({visible, handleClose}:AddPlatformModal) => {
  return (
    <Modal
      show={visible}
      onHide={handleClose}
      size='xl'
      aria-labelledby='contained-modal-title-vcenter'
      // centered
      dialogClassName='h-lg-75 overflow-scroll rounded'
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Platform</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AddPlatformForm handleClose={handleClose} />
      </Modal.Body>
    </Modal>
    
  )
}

export default AddPlatFormModal