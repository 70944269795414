import React, { Fragment } from 'react'
import { OrderData_type } from './utils'
import { Container, Row, Col, Card, Stack } from 'react-bootstrap'
import classes from './orderDetail.module.css'

type personalDetailsProps = {
    OrderData: OrderData_type
}

function PersonalDetails({ OrderData }: personalDetailsProps) {
    
    return (
        <Fragment>
            <Card>
                <Card.Title className={`${classes.card_header} ms-3`}>Customer Details</Card.Title>
                {OrderData?.customer?.id ? (
                    <Row className='ps-2 pe-2 mt-15'>
                        <Col xs={12}>
                            <p className='mb-3 text-muted fw-bold'>
                                <i className='bi bi-person-fill fs-4'></i> &nbsp;
                                <span className='text-dark text-hover-primary'>
                                    {OrderData?.customer?.display_name}
                                </span>
                            </p>
                        </Col>

                        <Col xs={12}>
                            <p className='mb-3 text-muted fw-bold'>
                                <i className='bi bi-telephone fs-4'></i> &nbsp;
                                <span className='text-dark text-hover-primary'>
                                    {OrderData?.customer?.phone
                                        ? OrderData?.customer?.phone + ' , ' + OrderData?.customer?.address?.phone
                                        : OrderData?.customer?.address?.phone
                                            ? OrderData?.customer?.address?.phone
                                            : 'Not Available'}
                                </span>
                            </p>
                        </Col>

                        <Col xs={12}>
                            <p className='mb-3 text-muted fw-bold'>
                                <i className='bi bi-envelope fs-4'></i> &nbsp;
                                <span className='text-dark text-hover-primary'>{OrderData?.customer?.email ? OrderData?.customer?.email : 'Not Available'}</span>
                            </p>
                        </Col>

                        <Col xs={12}>
                            <Stack className={classes.address_section}>
                                <i className='bi bi-geo-alt fs-4'></i>
                                {
                                    OrderData?.customer?.address !== null ?
                                        (
                                            <p className='mb-3 text-muted fw-bold'>
                                                <span className='text-dark text-hover-primary fw-bold'>
                                                    {OrderData?.customer?.address?.first_name +
                                                        '  ' +
                                                        OrderData?.customer?.address?.last_name}
                                                </span>{' '}
                                                <br />
                                                <span className='text-dark text-hover-primary fw-bold'>
                                                    {OrderData?.customer?.address?.address_1}
                                                </span>{' '}
                                                &nbsp;
                                                <span className='text-dark text-hover-primary fw-bold'>
                                                    {OrderData?.customer?.address?.address_2}
                                                </span>{' '}
                                                &nbsp;
                                                <span className='text-dark text-hover-primary fw-bold'>
                                                    {OrderData?.customer?.address?.address_3}
                                                </span>
                                                <br />
                                                <span className='text-dark text-hover-primary fw-bold'>
                                                    {OrderData?.customer?.address?.city +
                                                        ' ' +
                                                        OrderData?.customer?.address?.state +
                                                        ' ' +
                                                        OrderData?.customer?.address?.zip}
                                                </span>{' '}
                                                <br />
                                                <span className='text-dark text-hover-primary fw-bold'>
                                                    {OrderData?.customer?.address?.country}
                                                </span>{' '}
                                                <br />
                                            </p>
                                        ) : (<p className='text-dark fw-bold'>Not Available</p>)
                                }

                            </Stack>
                        </Col>
                    </Row>
                ) : (
                    <Stack className={classes.no_record_message}>
                        <p className='mt-12 mb-3 text-dark fw-bold'>Customer Details Not Available !</p>
                    </Stack>
                )}
            </Card>
        </Fragment>
    )
}

export default PersonalDetails
