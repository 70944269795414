import { AxiosClient } from '../../AxiosClient/axiosClient'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const sourceSideSettingAsync = createAsyncThunk(
    'sourceSideSetting/platform-setting',
    async (payload: { platform_id: number, workflow_id: number }, toolkit) => {
        return await AxiosClient(
            'GET', `/platform-setting?platform_id=${payload.platform_id}&workflow_id=${payload.workflow_id}`,
            [], toolkit
        )
    }
);

export const destinationSideSettingAsync = createAsyncThunk(
    'destinationSideSetting/platform-setting',
    async (payload: { platform_id: number, workflow_id: number }, toolkit) => {
        return await AxiosClient(
            'GET', `/platform-setting?platform_id=${payload.platform_id}&workflow_id=${payload.workflow_id}`,
            [], toolkit
        )
    }
);

export const sourceSideShortCodeAsync = createAsyncThunk(
    'shortcodes-list/sourceSideShortCode',
    async (payload: { platform_id: number}, toolkit) => {
        return await AxiosClient(
            'GET', `/shortcodes-list?platform_id=${payload.platform_id}`,
            [], toolkit
        )
    }
);
export const destinationSideShortCodeAsync = createAsyncThunk(
    'shortcodes-list/destinationSideShortCode',
    async (payload: { platform_id: number}, toolkit) => {
        return await AxiosClient(
            'GET', `/shortcodes-list?platform_id=${payload.platform_id}`,
            [], toolkit
        )
    }
);



