import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export function getCountData( userId: any) {
    const GET_COUNTDATA_URL = `${API_URL}/dashboard/?user_id=${userId}`
    return axios.get(GET_COUNTDATA_URL)
  }
export function getgetPlatformCountData( Id: any) {
    const GET_getPlatformCountData_URL = `${API_URL}/platform-order-count/?workflow_id=${Id}`
    return axios.get(GET_getPlatformCountData_URL)
  }
