import React, { useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import CountRecord from './OrderShipmentAdjustmentCard/CountRecord'
import OrderShipmentAdjustmentGraph from './Graphs/OrderShipmentAdjustmentGraph'

type generalInfoProps = {
  workflowID: string | number
}

function GeneralInfo({ workflowID }: generalInfoProps) {
  const [isLoading, setIsLoading] = useState(true)

  return (
    <Container>
      <Row>
        <Col xs={12}>
          <CountRecord workflowID={workflowID} isLoading={isLoading} setIsLoading={setIsLoading} />
        </Col>
        {/* <Col xs={12}>
          <OrderShipmentAdjustmentGraph workflowID={workflowID} isLoading={isLoading} setIsLoading={setIsLoading}/>
        </Col> */}
      </Row>

    </Container>
  )
}
export default GeneralInfo;



