import React, { useEffect, useState } from 'react'
import { CircularLoader } from '../../../../../widgets/components/CustomLoaders'

const NoColumns = () => {
  const [loaderState, setLoaderState] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setLoaderState(false)
    }, 5000)
  }, [])
  return (
    <div style={{ textAlign: 'center', padding: '20px' }}>
      {loaderState ? (
        <CircularLoader
          width='70px'
          height='70px'
          borderWidth='4px'
          marginTop='0px'
          marginBottom='0px'
        />
      ) : (
        <h6 style={{ color: 'black' }}>No Records To Display !</h6>
      )}
    </div>
  )
}

export default NoColumns;