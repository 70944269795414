import React, {FC} from 'react'
import {CloseButton, Modal} from 'react-bootstrap'
import AddAvailableWorkflowForm from './AddAvailableWorkflowForm'
import {AVAILABLE_WORKFLOW} from 'src/_interfaces/AvailableWorkflow.Interface'
import EditAvailableWorkflowForm from './EditAvailableWorkflowForm'

type AddAvailableWorkflowModalProps = {
  visible: boolean
  handleClose: () => void
  editData: AVAILABLE_WORKFLOW | null
}

const AddAvailableWorkflowModal: FC<AddAvailableWorkflowModalProps> = ({
  visible,
  handleClose,
  editData,
}) => {
  return (
    <Modal
      show={visible}
      // onHide={handleClose}
      centered
      size='xl'
      aria-labelledby='contained-modal-title-vcenter'
      dialogClassName='h-lg-75 overflow-scroll rounded'
    >
      <Modal.Header>
        <Modal.Title>{`${editData?.id ? 'Edit' : 'Add'} Available Workflow`}</Modal.Title>
        <CloseButton onClick={handleClose} />
      </Modal.Header>
      <Modal.Body>
        {editData ? (
          <EditAvailableWorkflowForm handleClose={handleClose} editData={editData} />
        ) : (
          <AddAvailableWorkflowForm handleClose={handleClose} editData={null} />
        )}
      </Modal.Body>
    </Modal>
  )
}

export default AddAvailableWorkflowModal
