import {createSlice, isAnyOf} from '@reduxjs/toolkit'
import {order_Shipment_Adjustment_CountAsync} from './graphAsync'

type graphInitialState = {
  orderShilpmentAdjustmentLoader: boolean
  orderShilpmentAdjustmentState: any
}

const initialState: graphInitialState = {
  orderShilpmentAdjustmentLoader: false,
  orderShilpmentAdjustmentState: [],
}

const graphSlice = createSlice({
  name: 'graphSlice',
  initialState,
  reducers: {emptyGraphState: () => initialState},
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(order_Shipment_Adjustment_CountAsync.pending), (state, actions) => {
      state.orderShilpmentAdjustmentLoader = true
    })
    builder.addMatcher(
      isAnyOf(order_Shipment_Adjustment_CountAsync.fulfilled),
      (state, actions) => {
        state.orderShilpmentAdjustmentLoader = false
        state.orderShilpmentAdjustmentState = actions.payload
      }
    )
    builder.addMatcher(isAnyOf(order_Shipment_Adjustment_CountAsync.rejected), (state, actions) => {
      state.orderShilpmentAdjustmentLoader = false
    })
  },
})

export default graphSlice.reducer
export const {emptyGraphState} = graphSlice.actions
