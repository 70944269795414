import React, {Fragment, useEffect, useState} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {Button, Card, ToastContainer} from 'react-bootstrap'
import DashboardInfoCard from './components/DashboardInfoCard'
import { useAppDispatch, useAppSelector } from 'src/Redux/Store/hooks'
import { USER_ROLE } from 'src/utils/constants'
import { getOrganizationsListAsync, getUserListForCreateOrganizationAsync } from 'src/Redux/Slices/organizations/organizationAsync'
import { getAvailablePlatformsAsync } from 'src/Redux/Slices/platform/platformAsync'
import { getAvailableWorkflowsAsync } from 'src/Redux/Slices/availableWorkflow/availableWorkflowAction'
import Loader from 'src/app/modules/loader/Loader'

export type InfoCardProp = {
  id: number
  title:string
  subtitle:string
  count: number
  buttonTitle: string
  buttonLink: string

}

const AdminDashboard = () => {

  const dispatch = useAppDispatch()
  const {loginUser} = useAppSelector((state) => state.loggedInUser)
  const { getUserListLoader,  paginationData, userPaginationData }  = useAppSelector(state=>state.organization)
  const { getPlatformLoader, platforms } = useAppSelector((state) => state.platformCombination)
  const { getAvailableWorkflowLoader, availableWorkflows } = useAppSelector(state=>state.availableWorkflow)

  useEffect(() => {
    dispatch(
      getUserListForCreateOrganizationAsync({
        param: {user_id: loginUser.id, page_size: 100, user_type: USER_ROLE['user']},
        directPath: null,
      })
    )
    dispatch(
      getOrganizationsListAsync(
        `https://useappconnect.com:8005/organizations-list/?user_id=${loginUser.id}&page_size=5&page=1`
      )
    )
    dispatch(getAvailablePlatformsAsync())
    dispatch(getAvailableWorkflowsAsync('https://useappconnect.com:8005/available-workflows'))
  }, [])
  

  const infoCardItems : InfoCardProp[] = [
    {
      id:1,
      title:"Platforms",
      subtitle:"The total registered platforms are.",
      count: platforms.length,
      buttonTitle:"Manage Platforms",
      buttonLink:"/superadmin/list-platforms"

    },
    {
      id:2,
      title:"Organizations",
      subtitle:"The total connected organizations.",
      count: paginationData.count,
      buttonTitle:"Manage Organizations",
      buttonLink:"/superadmin/list-organizations"

    },
    {
      id:3,
      title:"Users",
      subtitle:"The total logged in users.",
      count: userPaginationData.count,
      buttonTitle:"Manage Users",
      buttonLink:"/superadmin/list-users"

    },
    {
      id:4,
      title:"Workflows",
      subtitle:"The total available workflows are.",
      count: availableWorkflows.length,
      buttonTitle:"Manage Workflows",
      buttonLink:"/superadmin/workflows-available"
    },
    
  ]

  if(getUserListLoader || getPlatformLoader || getAvailableWorkflowLoader ) return <Loader/>

  return (
    <>
      <PageTitle breadcrumbs={[]}>Dashboard</PageTitle>
      <div className='card h-50 p-7 mt-10' style={{minHeight: '68vh'}}>
        <div className='d-flex justify-content-between align-items-center '>
          <h4 className='fw-bolder m-0 text-dark align-self-end'>Dashboard</h4>
        </div>
        <div className='separator my-7'></div>
        <div className='d-flex flex-wrap justify-content-around' >
          {
            infoCardItems.map((cardData)=>(<DashboardInfoCard key={"info"+ cardData.id} {...cardData}  />))
          }
        </div>
      </div>
    </>
  )
}

export default AdminDashboard
