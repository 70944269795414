import React, { FC, Fragment, useEffect, useState } from 'react'
import { Button, Form, FormControl, InputGroup, Pagination } from 'react-bootstrap'
import { ToastContainer, toast } from 'react-toastify'
import {
  getOrganizationsListAsync,
  getUserListForCreateOrganizationAsync,
} from 'src/Redux/Slices/organizations/organizationAsync'
import { PageTitle } from 'src/_metronic/layout/core'
import { useAppDispatch, useAppSelector } from 'src/Redux/Store/hooks'
import { RootState } from 'src/Redux/Store/storets'
import { USER_ROLE } from 'src/utils/constants'
import { setCurrentPage } from 'src/Redux/Slices/organizations/organizationSlice'
import Loader from '../../loader/Loader'
import UsersTable from './components/UsersTable'
import AppSearchBar from 'src/utils/AppSearchBar'

const Users: FC = () => {
  const [tableIndexMultiple, setTableIndexMultiple] = useState<number>(0)
  const dispatch = useAppDispatch()
  const [searchString, setSearchString] = useState<string>('')
  const { getUserListLoader, userList, userPaginationData } = useAppSelector(
    (state: RootState) => state.organization
  )
  const { loginUser } = useAppSelector((state: RootState) => state.loggedInUser)

  useEffect(() => {
    if (searchString === '') {
      dispatch(
        getUserListForCreateOrganizationAsync({
          param: {
            user_id: loginUser.id,
            page_size: 10,
            // user_type: USER_ROLE['admin']
          },
          directPath: null,
        })
      )
    }
  }, [searchString])
  const handlePagination = (paginationPath: string | null) => {
    if (!paginationPath) return
    dispatch(setCurrentPage(paginationPath))
    dispatch(getUserListForCreateOrganizationAsync({ param: {}, directPath: paginationPath }))
      .then((data) => {
        if (data.payload) {
          if (userPaginationData.previous == paginationPath) {
            setTableIndexMultiple(tableIndexMultiple > 0 ? tableIndexMultiple - 10 : 0)
          } else {
            setTableIndexMultiple(tableIndexMultiple + 10)
          }
        }
      })
      .catch((err) => {
        console.log('err', err)
      })
  }

  // if (getUserListLoader) return <Loader />

  return (
    <>
      <PageTitle breadcrumbs={[]}>Users</PageTitle>
      <div className='card h-50 py-3 px-5 mt-1'>
        <div className='d-sm-flex justify-content-between align-items-center '>
          <h4 className='fw-bolder m-0 text-dark '> Total Users : {userPaginationData.count} </h4>
          <AppSearchBar
            searchString={searchString}
            setSearchString={setSearchString}
            onSubmit={() => {
              dispatch(
                getUserListForCreateOrganizationAsync({
                  param: {
                    user_id: loginUser.id,
                    page_size: 10,
                    // user_type: USER_ROLE['admin'],
                    search_string: searchString,
                  },
                  directPath: null,
                })
              )

            }}
          />
          {/* <Form
            action=''
            className='w-25'
            onSubmit={(ev) => {
              ev.preventDefault()
            }}
          >
            <InputGroup size='sm' className='justify-content-end'>
              <FormControl
                value={searchString}
                style={{maxWidth: '140px'}}
                type='input'
                size='sm'
                placeholder='search user..'
                onChange={(ev) => {
                  setSearchString(ev.target.value)
                }}
                enterKeyHint='search'
                className='border-right-0'
              />
              <Button
                className={
                  searchString ? 'border border-secondary border-2 border-left-0 ' : 'd-none'
                }
                variant='white'
                onClick={() => setSearchString('')}
              >
                X
              </Button>
              <Button
                size='sm'
                type='submit'
                onClick={() => {
                  if (!searchString) return toast.warning('No text to search yet..')
                  dispatch(
                    getUserListForCreateOrganizationAsync({
                      param: {
                        user_id: loginUser.id,
                        page_size: 10,
                        user_type: USER_ROLE['admin'],
                        search_string: searchString,
                      },
                      directPath: null,
                    })
                  )
                }}
              >
                Search
              </Button>
            </InputGroup>
          </Form> */}
        </div>
        <div className='separator my-2'></div>
        <div>
          {getUserListLoader ? <Loader /> : <UsersTable tableIndexMultiple={tableIndexMultiple} />}
          <Pagination size='lg'>
            <Pagination.Prev
              active={userPaginationData.previous ? true : false}
              className={`bg-secondary fs-4 rounded cursor-pointer`}
              onClick={() => handlePagination(userPaginationData.previous)}
            />
            <Pagination.Next
              active={userPaginationData.next ? true : false}
              className={`bg-secondary fs-4 rounded cursor-pointer `}
              onClick={() => handlePagination(userPaginationData.next)}
            />
          </Pagination>
        </div>
        <ToastContainer />
      </div>
    </>
  )
}

export default Users
