import React, {FC, useState} from 'react'
import {Button, Form} from 'react-bootstrap'
import * as Yup from 'yup'
import * as formik from 'formik'
import {useAppDispatch, useAppSelector} from '../../../../Redux/Store/hooks'
import Loader from '../../loader/Loader'
import {inviteUserAsync} from '../../../../Redux/Slices/inviteUsers/inviteUserAsync'
import {toast} from 'react-toastify'
import {emailRegex} from 'src/utils/constants'

type InitialValues = {
  email: string
  name: string
}

const InviteUserForm: FC<{handleClose: () => void}> = ({handleClose}) => {
  const [validated, setValidated] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const {invitedUserLoader} = useAppSelector((state) => state.inviteUser)
  const {id} = useAppSelector((state) => state.subdomain.data)
  const {Formik} = formik
  const schema = Yup.object().shape({
    email: Yup.string().required().matches(emailRegex, {message: 'Please enter a valid email'}),
    name: Yup.string().required('Name is required to invite user'),
  })

  const handleInviteSubmit = async (
    event: React.FormEvent<HTMLFormElement>,
    inviteVal: InitialValues,
    resetHandler: () => void
  ) => {
    try {
      const form = event.currentTarget
      event.preventDefault()

      if (!id) return alert('organization is required for invite a user...')

      if (!form.checkValidity()) {
        event.stopPropagation()
      } else {
        dispatch(
          inviteUserAsync({name: inviteVal.name, email: inviteVal.email, organization_id: id})
        ).then((data) => {
          if (data.payload.status == '400') {
            toast.error(data.payload.data.error)
          } else {
            toast.success('User invited successfully')
            handleClose()
            resetHandler()
          }
        })
      }
      setValidated(true)
    } catch (error) {
      console.log('error in catch block', error)
      setValidated(true)
    }
    setValidated(true)
  }

  return (
    <Formik
      validationSchema={schema}
      validateOnBlur={true}
      initialValues={{
        email: '',
        name: '',
      }}
      onSubmit={() => console.log('value is submitting')}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        touched,
        errors,
        handleBlur,
        getFieldProps,
        resetForm,
      }) => {
        return (
          <Form
            noValidate
            validated={validated}
            onSubmit={(event) => handleInviteSubmit(event, values, resetForm)}
          >
            <Form.Group className='mb-3'>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type='text'
                placeholder='name'
                name='name'
                required
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                isValid={touched.name && !errors.name}
                isInvalid={false}
              />
              <Form.Control.Feedback type={'invalid'}>
                Name is required for invite a user
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className='mb-3'>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type='email'
                placeholder='email'
                name='email'
                required
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                isValid={touched.email && !errors.email}
                isInvalid={!!errors.email}
              />
              <Form.Control.Feedback type={'invalid'}>
                {errors.email ?? 'Email is required for invite a user'}
              </Form.Control.Feedback>
            </Form.Group>
            <Button disabled={invitedUserLoader} type='submit' variant='primary' size='sm'>
              {invitedUserLoader ? 'Inviting...' : 'Send Invite'}
            </Button>
          </Form>
        )
      }}
    </Formik>
  )
}

export default InviteUserForm
