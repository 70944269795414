import React from 'react'

function Loader() {
  return (
    <div className=' w-100 d-flex justify-content-center align-items-center' style={{height:"70vh"}}>
      <div className='spinner-border text-primary' role='status'>
        <span className='sr-only'>Loading...</span>
      </div>
    </div>
  )
}

export default Loader
