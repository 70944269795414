import React, { useState, useEffect } from 'react'

function ScreenWidth() {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)

    const handleInnerWidth = () => {
        setScreenWidth(window.innerWidth)
    }

    useEffect(() => {
        window.addEventListener('resize', handleInnerWidth)

        return () => window.removeEventListener('resize', handleInnerWidth)
    }, [screenWidth])

    return [screenWidth];
}

export default ScreenWidth
