import React, { ChangeEvent, useState, FC } from 'react'
import { Button, Col, Collapse, Container, Form, Image, InputGroup, Row } from 'react-bootstrap'
import Select from 'react-select'
import { FieldArray, Formik } from 'formik'
import * as Yup from 'yup'
import AddPlatformAuthSectionInputGroup from './AddPlatformAuthSectionInputGroup'
import { AddPlatformInitialValues } from './Interface'
import AddPlatformActionModal from './AddPlatformActionModal'
import { ToastContainer, toast } from 'react-toastify'
import { useAppDispatch, useAppSelector } from '../../../../Redux/Store/hooks'
import { RootState } from '../../../../Redux/Store/storets'
import { addPlatformAsync, getAvailablePlatformsAsync } from '../../../../Redux/Slices/platform/platformAsync'

const AddPlatformForm: FC<{ handleClose: () => void }> = ({ handleClose }) => {
  const [selectedFile, setSelectedFile] = useState<string | undefined>("'https://theme.zdassets.com/theme_assets/11606116/c271c0edf9baeb714db2ccef96cbd6d86a0a296f.png'")
  const [logoPreview, setLogoPreview] = useState<boolean>(false)
  const [validated, setValidated] = useState<boolean>(false)
  const [addPlatformActionVisible, setAddPlatformActionVisible] = useState<boolean>(false)
  const { addPlatformLoader } = useAppSelector((state: RootState) => state.platformCombination)
  const dispatch = useAppDispatch()
  const validUrLRegex =
    /^((https):\/\/)(?!.*(https))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/

  const schema = Yup.object().shape({
    type: Yup.string().required(),
    name: Yup.string().required(),
    display_name: Yup.string().required(),
    logo_ref: Yup.string(), // currently not added this after aws image upload functionality we will cover it .required(),
    auth_route_name: Yup.string().nullable(),
    isOauth: Yup.boolean().required(),
    isActive: Yup.boolean().required(),
    available_platform_auth_requirements: Yup.array().of(
      Yup.object().shape({
        input_type: Yup.string().required(),
        name: Yup.string().required(),
        href: Yup.string().matches(validUrLRegex, { message: 'Enter a valid url' }).nullable(),
        display_name: Yup.string().required(),
      })
    ),
    available_platform_action: Yup.array()
      .of(
        Yup.object().shape({
          action: Yup.number().required('Field is required'),
        })
      )
      .min(1)
      .required(),
  })

  // Dropdown values for platform types
  const platformOptions = [
    { value: 'DESTINATION', label: 'DESTINATION' },
    { value: 'SOURCE', label: 'SOURCE' },
    { value: 'BOTH', label: 'BOTH' },
  ]

  const PLATFORM_ACTIONS = [
    { label: 'Initial', value: 1 },
    { label: 'Orders', value: 2 },
    { label: 'Import Shipments', value: 3 },
    { label: 'Import Products', value: 4 },
    { label: 'Create Orders', value: 5 },
    { label: 'Create Shipments', value: 6 },
    { label: 'Create Products', value: 7 },
    { label: 'Refresh Token', value: 8 },
    { label: 'Import Invoices', value: 9 },
    { label: 'Update Transactions', value: 10 },
    { label: 'Create Transactions', value: 11 },
    { label: 'Import Updated Invoices', value: 12 },
    { label: 'Retry Failed Transactions', value: 13 },
  ]

  const initialValues: AddPlatformInitialValues = {
    type: '',
    name: '',
    display_name: '',
    logo_ref: 'https://theme.zdassets.com/theme_assets/11606116/c271c0edf9baeb714db2ccef96cbd6d86a0a296f.png',
    auth_route_name: null,
    isOauth: false,
    isActive: false,
    available_platform_action: [],
    available_platform_auth_requirements: []
  }

  const handleAddPlatform = (
    event: React.FormEvent<HTMLFormElement>,
    platformData: AddPlatformInitialValues
  ) => {
    const form = event.currentTarget
    console.log('platformData', form.checkValidity())
    event.preventDefault()
    if (form.checkValidity()) {
      dispatch(addPlatformAsync(platformData)).then(() => {
        toast.success("Platform added successfully")
        dispatch(getAvailablePlatformsAsync())
        handleClose()
      })
    } else {
      toast.error("Please field required fields properly")
    }
    setValidated(true)
  }

  return (
    <>
      <Formik
        validationSchema={schema}
        initialValues={initialValues}
        onSubmit={() => console.log('submit')}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFormikState,
          /* and other goodies */
        }) => {

          return (
            <Form noValidate validated={validated} onSubmit={(ev) => handleAddPlatform(ev, values)}>
              <Row className=' mb-4 justify-content-end'>
                <Form.Group as={Col} md='3' className='text-end d-flex flex-column'>
                  <Form.Label htmlFor='add-pltfm-switch-active' >Active</Form.Label>
                  <Form.Check
                    type='switch'
                    id='add-pltfm-switch-active'
                    label=''
                    className='align-self-end'
                    name='isActive'
                    checked={values.isActive}
                    onChange={handleChange}
                    bsPrefix={`float-none`}
                    // isValid={values.isActive}
                  />
                </Form.Group>
              </Row>
              <Row className='mb-3'>
                <Form.Group as={Col} md='6' className='mb-4'>
                  <Form.Label>Platform Display Name</Form.Label>
                  <Form.Control
                    type='text'
                    name='display_name'
                    required
                    placeholder='Platform display name'
                    value={values.display_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!!errors.display_name && touched.display_name}
                    isValid={!!values.display_name && touched.display_name}
                  />
                  <Form.Control.Feedback type='invalid'>
                    Platform display name is required
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md='6' className='mb-4'>
                  <Form.Label>Platform Name</Form.Label>
                  <Form.Control
                    value={values.name}
                    type='text'
                    name='name'
                    placeholder='Platform name'
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!!errors.name && touched.name}
                    isValid={!!values.name && touched.name}
                  />
                  <Form.Control.Feedback type='invalid'>
                    Platform name is required
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md='6' className='mb-4'>
                  <Form.Label>Platform Type</Form.Label>
                  <Form.Select
                    value={values.type}
                    name='type'
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!!errors.type && touched.type}
                    isValid={!!values.type && touched.type}
                  >
                    <option value={''}>Select platform type </option>
                    {platformOptions.map((platform, index) => (
                      <option key={index + 'type'} value={platform.value}>
                        {platform.label}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type='invalid'>
                    Platform type is required
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md='6' className='mb-4'>
                  <Form.Label>Select Platform Logo</Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      area-aria-describedby='selectLogo'
                      type='file'
                      accept='image/*'
                      // required
                      name='logo_ref'
                      multiple={false}
                      onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                        if (ev.target.files?.length) {
                          let fileUrl = URL.createObjectURL(ev.target.files[0])
                          console.log('fileUrl', fileUrl)
                          setSelectedFile(fileUrl)
                        }
                      }}
                    />
                    <InputGroup.Text
                      id='selectLogo'
                      className={`${!selectedFile && validated == true ? 'border-danger' : ''} ${selectedFile && validated == true ? 'border-success was-validated' : ''
                        } `}
                      style={{
                        cursor: 'pointer',
                        borderTopRightRadius: '0.475rem',
                        borderBottomRightRadius: '0.475rem',
                      }}
                      onClick={() => {
                        console.log('!selectedFile && validated', !selectedFile && validated)
                        if (selectedFile) {
                          setLogoPreview(!logoPreview)
                        }
                      }}
                    >
                      Preview
                    </InputGroup.Text>
                    <Form.Control.Feedback type='invalid'>Logo is required</Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Row>
                  <Collapse in={logoPreview}>
                    <Form.Group as={Col} md='4' className='mb-6'>
                      <Image
                        src={selectedFile}
                        alt='selected-image'
                        rounded
                        className='w-100  sm:w-50 h-100 border border-secondary'
                      />
                    </Form.Group>
                  </Collapse>
                </Row>
              </Row>
              <Row md={'6'} className='d-flex flex-column'>
                <Form.Group as={Col} md='2' className='mb-2'>
                  {/* <Form.Label>Is OAuth</Form.Label> */}
                  <Form.Check // prettier-ignore
                    type='switch'
                    id='add-pltfrm-oauth'
                    label='Is Oauth'
                    name='isOauth'
                    checked={values.isOauth}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Collapse in={values.isOauth}>
                  <Form.Group as={Col} md='6'>
                    {/* <Form.Label>Auth Route Name</Form.Label> */}
                    <Form.Control
                      type='text'
                      name='auth_route_name'
                      value={values.auth_route_name ?? ''}
                      placeholder='Auth Route Name'
                      onChange={handleChange}
                      required={values.isOauth}
                    />
                  </Form.Group>
                </Collapse>
              </Row>
              <Form.Group as={Col} md='12' className='py-4'>
                <div className='d-flex justify-content-between mb-2'>
                  <Form.Label>Platform Actions</Form.Label>
                  <Button size='sm' className='d-flex align-items-center' variant='primary'
                    onClick={() => setAddPlatformActionVisible(true)}
                  >
                    {' '}
                    <span className='fs-3 lh-1 me-2'>+</span> <span>Add new action</span>{' '}
                  </Button>
                </div>
                <Select
                  required
                  defaultValue={
                    values.available_platform_action.length
                      ? values.available_platform_action.map((avlPltAct) => {
                        return PLATFORM_ACTIONS.find((item) => item.value == avlPltAct.action)
                      })
                      : []
                  }
                  options={PLATFORM_ACTIONS}
                  aria-invalid='true'
                  name='available_platform_action'
                  isMulti={true}
                  placeholder={'Select Platform Actions...'}
                  onChange={(selectedItems) => {
                    let modifiedArray = selectedItems.map((item) => {
                      return { action: item?.value as number }
                    })
                    setFormikState((pre) => {
                      return { ...pre, values: { ...values, available_platform_action: modifiedArray } }
                    })
                  }}
                  onInputChange={(ev) => {
                    console.log('log for input change', ev)
                  }}
                  onBlur={handleBlur}
                  classNames={{
                    valueContainer: (props) => {
                      return "bg-light"
                    },
                    menu: (state) => {
                      return "bg-light"
                    },
                    control: (state) => {
                      if (state.isFocused) {
                        return 'border border-1 shadow-none border-secondary py-1 bg-light'
                      } else if (!values.available_platform_action.length && validated) {
                        return 'border border-1 shadow-none border-danger bg-dark bg-light '
                      }
                      return "py-1 bg-light"
                    },
                  }}
                />
                <Form.Control.Feedback
                  className={
                    !values.available_platform_action.length && validated ? 'd-block' : 'd-hidden'
                  }
                  type={'invalid'}
                >
                  Platform Actions is required
                </Form.Control.Feedback>
              </Form.Group>
              <div className='p-4 border border-2 mt-4 rounded'>
                <FieldArray
                  name='available_platform_auth_requirements' // do not change the name it is same as key name in values field
                  render={(arrayHelpers) => {
                    return (
                      <>
                        <div className='my-4 border border-top-0 border-left-0 border-right-0 border-bottom-2 pb-3'>
                          <h4>Auth Requirements</h4>
                        </div>
                        <div className='px-4'>
                          {values.available_platform_auth_requirements.length ? (
                            values.available_platform_auth_requirements.map(
                              (authSection, avplIndex) => {
                                return (
                                  <AddPlatformAuthSectionInputGroup
                                    key={'authSectionForPlatform' + avplIndex}
                                    avplIndex={avplIndex}
                                    authSection={authSection}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    removeThisSection={() => arrayHelpers.remove(avplIndex)}
                                    errors={errors}
                                    fieldError={
                                      errors.available_platform_auth_requirements?.length
                                        ? typeof errors.available_platform_auth_requirements[
                                          avplIndex
                                        ] !== 'string'
                                          ? errors.available_platform_auth_requirements[avplIndex]
                                          : undefined
                                        : undefined
                                    }
                                    touched={
                                      touched.available_platform_auth_requirements?.length
                                        ? touched.available_platform_auth_requirements[avplIndex]
                                        : undefined
                                    }
                                  />
                                )
                              }
                            )
                          ) : (
                            <div>
                              <p>Add Authentication fields from below button</p>
                            </div>
                          )}
                          <div className='d-flex justify-content-end'>
                            <Button
                              size='sm'
                              variant='primary'
                              className='mt-4'
                              onClick={() => {
                                arrayHelpers.push({
                                  input_type: '',
                                  name: '',
                                  href: null,
                                  display_name: '',
                                })
                              }}
                            >
                              Add Auth Type
                            </Button>
                          </div>
                        </div>
                      </>
                    )
                  }}
                />
              </div>
              <div className='d-flex justify-content-center'>
                <Button disabled={addPlatformLoader} type='submit' size='sm' className='mt-6 align-self-center w-100'>
                  {addPlatformLoader ? "..." : " Add Platform"}
                </Button>
              </div>
            </Form>
          )
        }}
      </Formik>
      <AddPlatformActionModal
        key={'addPlatformActionModal'}
        visible={addPlatformActionVisible}
        handleClose={() => setAddPlatformActionVisible(false)}
      />
      <ToastContainer />
    </>
  )
}

export default AddPlatformForm
