import React from 'react'
import SelectAll from './SelectAll'
import Searchable from './Searchable'
import SelectAction from './SelectAction'
import classes from './subHeader.module.css'
import { Container, Stack } from 'react-bootstrap'
import ScreenWidth from '../../../../../widgets/components/ScreenWidth'
import GenerateReport from '../GenerateReport/GenerateReport'

type SubHeaderProps = {
    totalRows: number
    workflowID: number
    logAction: string
    searchText: string
    searchStatus: string
    getCheckedAll: boolean
    allRowChecked: boolean
    selectedRowID: number[]
    isSearchClicked: boolean
    handleSearchText: () => void
    setPerPage: (data: number) => void
    setSearchText: (data: any) => void
    fetchUsers: (data: number) => void
    setCurrentPage: (data: number) => void
    setSearchStatus: (data: string) => void
    setCheckedAll: (status: boolean) => void
    setIsSearchClicked: (data: boolean) => void
    setAllRowChecked: (status: boolean) => void
    setCheckedValue: (data: number[] | []) => void
}

function SubHeader(props: SubHeaderProps) {
    const {
        workflowID, totalRows, fetchUsers, searchText, setSearchText, handleSearchText,
        getCheckedAll, setCheckedAll, setCheckedValue, allRowChecked, setAllRowChecked,
        selectedRowID, isSearchClicked, setIsSearchClicked, logAction, searchStatus,
        setSearchStatus, setPerPage, setCurrentPage
    } = props
    const [screenWidth] = ScreenWidth()

    return (
        <Container>
            <Stack
                className={`${classes.subHeader_container} ${screenWidth <= 320 || screenWidth <= 545 ? 'mb-7' : ''
                    }`}
            >
                {
                    logAction === 'IMPORT_ORDERS' ?
                        (
                            <SelectAll {...{ allRowChecked, setCheckedAll, setAllRowChecked, setCheckedValue, totalRows }} />
                        ) : null
                }
                {/* Shifted To Top Of Table */}
                {/* <SelectAction
                    {...{ workflowID, selectedRowID, allRowChecked, totalRows, setCheckedAll, setAllRowChecked, setCheckedValue }}
                /> */}
                <GenerateReport {...{ fetchUsers, workflowID }} />
                <Searchable {...{ searchText, setSearchText, isSearchClicked, setIsSearchClicked, fetchUsers, handleSearchText, searchStatus, setSearchStatus, setPerPage, setCurrentPage }} />
            </Stack>
        </Container>
    )
}

export default SubHeader
