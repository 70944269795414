import React, { useEffect, useState, useMemo } from 'react'
import { Button, Container, Form, Stack } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useAppSelector } from 'src/Redux/Store/hooks'
import { formInitialStateType, oAuthValues, flowIdentifire } from '../utils'
import { useNavigate } from 'react-router-dom'
import { FormikProps } from 'formik'
import Select from 'react-select'
import PlatformNotAvailable from './PlatformNotAvailable'
import {
    getAllSourcePlatformAsync,
    getAllSourceActionNameAsync,
} from '../../../../../../Redux/Slices/workflowFormSlice/workflowFormAsync'
import { getWorkflowApi } from '../../../../../redux/slices/WorkflowSlices'
import { emptyForm } from '../../../../../../Redux/Slices/workflowFormSlice/workflowFormSlice'
import { emptyPlatformCombinons } from '../../../../../../Redux/Slices/platform/platformSlice'
import classes from './platform.module.css'
import { toast } from 'react-toastify'
import { clearStatus } from 'src/Redux/Slices/stateSave/connectPlatformViaCreateWorkflow'
import { actionCollection, actionFlows, emptyDerivedData } from 'src/Redux/Slices/stateSave/connectPlatformDataSlice'


type ComponentsSourceSideProps = {
    Formik: FormikProps<formInitialStateType>
    workflowId: string
    OpenModal: boolean
    applySettings: boolean
    setApplySettings: (data: boolean) => void
    setWorkflowID: (data: string) => void
    closeModal: (data: boolean) => void
    sp_id: string | number
    sp_aid: number[] | null
    set_isSourcePlatformConnectModalOpen: (status: boolean) => void
    set_isDestinationPlatformConnectModalOpen: (status: boolean) => void
    setIframeSrc: (data: string | null) => void
    setIframePlatformName: (data: string | null) => void
    setOpenIFrameModal: (status: boolean) => void
    setOauth_ids: React.Dispatch<React.SetStateAction<oAuthValues>>
    sourceName: string
    actionCollectionArray: actionCollection[]
    action_flows: actionFlows[]
}

type deshboardDirectedPlatforms = {
    id: string | number
    code: string
    name: string
    display_name: string
    platform_info: { id: string | number; name: string; display_name: string; type: string }
    isConnected: boolean
    finishAuth_url: string | null
}
function ComponentsSourceSide(props: ComponentsSourceSideProps) {
    const {
        Formik,
        workflowId,
        setWorkflowID,
        closeModal,
        OpenModal,
        sp_id,
        applySettings,
        setApplySettings,
        sp_aid,
        set_isSourcePlatformConnectModalOpen,
        set_isDestinationPlatformConnectModalOpen,
        setIframeSrc,
        setIframePlatformName,
        setOpenIFrameModal,
        setOauth_ids,
        sourceName,
        actionCollectionArray,
        action_flows,
    } = props
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const userId = Number(localStorage.getItem('user'))
    const [deshboardDirectedPlatforms, setDeshboardDirectedPlatforms] = useState<
        deshboardDirectedPlatforms[] | []
    >([])
    const [sourceRecheck, setSourceRecheck] = useState(false)
    const { sourceActionNameLoader, getSourceActionName, getPlatformSourceloader, getPlatformSource, createWorkFlow } =
        useSelector(
            (state: {
                workflowForm: {
                    getPlatformSourceloader: boolean
                    sourceActionNameLoader: boolean
                    createWorkFlow: { status_code: number; massage: string }
                    getPlatformSource: deshboardDirectedPlatforms[]
                    getSourceActionName: {
                        available_platform_action: { action: { id: number; action_name: string }, id: number }[]
                    }
                }
            }) => state.workflowForm
        )
    const { workflowLoader, workflowCombination } = useAppSelector((state) => state.platformCombination)
    // console.log('workflowCombination', workflowCombination)
    // This Redux State Tells Whether Platform Connected Or Not Via Create Workflow Check Modal
    const { platform_ConnectionStatus } = useAppSelector((state) => state.platformConnectByCreateWorkflow)
    useEffect(() => {
        if (platform_ConnectionStatus.platformType === 'SOURCE' || platform_ConnectionStatus.platformType === 'BIDIRECTIONAL') {
            setSourceRecheck(true)
        }
    }, [platform_ConnectionStatus])

    // To get source platform:
    useEffect(() => {
        dispatch(emptyForm())
        dispatch(getAllSourcePlatformAsync({ userId: userId }) as any)
            .then((response: any) => {
                if (response?.payload?.status_code === 200 && response?.payload?.data?.length === 0) {
                    set_isSourcePlatformConnectModalOpen(true)
                }
            })
    }, [])
    // Refresh API When Platform Connect
    useEffect(() => {
        if (sourceRecheck) {
            dispatch(getAllSourcePlatformAsync({ userId: userId }) as any)
            dispatch(clearStatus())
            // setSourceRecheck(false)
        }
    }, [sourceRecheck])

    // Set Source From Dashboard
    useEffect(() => {
        let modalTimer;
        if (sp_id && getPlatformSource?.length > 0) {
            // console.log('sp_id', sp_id, 'getPlatformSource', getPlatformSource, 'actionCollectionArray', actionCollectionArray)

            let parentObj_source = getPlatformSource.filter((item) => item?.platform_info?.id == sp_id)

            // If Only 1 Value Then Set It In Field Right Now If Not Then Giving Option In Dropdown :::
            if (parentObj_source?.length === 1) {
                Formik.setFieldValue('sourcePlatform', {
                    sp_parentId: parentObj_source?.[0]?.id,
                    sa_apiId: parentObj_source?.[0]?.platform_info?.id,
                })
            }
            if (parentObj_source?.length > 1) {
                setDeshboardDirectedPlatforms([...parentObj_source])
            }

            modalTimer = setTimeout(() => {
                if (parentObj_source?.length === 0) {
                    set_isSourcePlatformConnectModalOpen(true)
                }
            }, 500);

            // To Complete Auth Process If isConnected is false
            if (parentObj_source[0]?.isConnected === false) {
                setIframePlatformName(parentObj_source[0]?.display_name)
                setIframeSrc(parentObj_source[0]?.finishAuth_url)
                setOauth_ids({ available_platform_id: Number(sp_id), platform_id: Number(parentObj_source[0]?.id) })
                // setTimeout(() => {
                //     setOpenIFrameModal(true)
                // }, 2000);
            }

            if (actionCollectionArray?.length) {
                let sourceActions = [] as number[];
                for (let i = 0; i <= actionCollectionArray.length; i++) {
                    if (actionCollectionArray[i]?.p_id === sp_id) {
                        sourceActions.push(actionCollectionArray[i]?.a_id)
                    }
                }
                Formik.setFieldValue('sourceAvailableActions', sourceActions)
            }
        }
        return () => clearTimeout(modalTimer)
    }, [sp_id, getPlatformSource])

    // For Workflow_action_flow matching actionCollectionArray?.p_id and sp_id if it matches it belongs to sorce side
    useEffect(() => {
        if (Formik.values.sourcePlatform.sp_parentId && actionCollectionArray?.length) {
            let flowIdentifire = [] as flowIdentifire[]
            for (let i = 0; i < actionCollectionArray.length; i++) {
                if (actionCollectionArray[i]?.p_id === sp_id) {
                    flowIdentifire.push({
                        main_id: actionCollectionArray[i]?.main_id,
                        action_id: actionCollectionArray[i]?.a_id,
                        source_id: Formik.values.sourcePlatform.sp_parentId
                    })
                }
            }
            Formik.setFieldValue('source_actionFlow', flowIdentifire)
        }
    }, [Formik.values.sourcePlatform, actionCollectionArray])


    // On Successfull Empty All Statues:
    useMemo(() => {
        if (applySettings && (createWorkFlow.status_code >= 200 && createWorkFlow.status_code <= 300)) {
            closeModal(false)
            setApplySettings(false)
            // toast.success(createWorkFlow.massage)
            toast.success('Integration Created Successfully')
            navigate('/workflow', { state: { workflowId: workflowId, tabGroupIndex: 1 } })
        } if (createWorkFlow.status_code > 300) {
            toast.error(createWorkFlow.massage)
        }
        dispatch(emptyForm())
        dispatch(clearStatus())
        // dispatch(emptyDerivedData())
        // dispatch(emptyPlatformCombinons())
        Formik.setFieldValue('sourcePlatform', {})
        Formik.setFieldValue('destinationPlatform', {})
        Formik.setFieldValue('sourceAction', [])
        Formik.setFieldValue('destinationAction', [])
        setWorkflowID('')
    }, [createWorkFlow, OpenModal])


    return (
        <Container>
            <Stack className={workflowId ? classes.hide : ''}>
                <Form.Label className='ms-1 mt-2'>{sourceName}</Form.Label>
                <Stack className={classes.platform_btn_container}>
                    <Form.Select
                        // disabled={workflowId ? true : false}
                        disabled={deshboardDirectedPlatforms?.length === 0 ? true : false}
                        name='sourcePlatform'
                        value={Formik.values.sourcePlatform.sp_parentId}
                        onChange={(event) => {
                            const selectedValue: string = event.target.value
                            let parentObj = getPlatformSource.find(
                                (item: Record<string, any>) => item.id == selectedValue
                            )
                            Formik.setFieldValue('sourcePlatform', {
                                sp_parentId: selectedValue,
                                sa_apiId: parentObj?.platform_info?.id,
                            })
                        }}
                        onBlur={Formik.handleBlur}
                        isInvalid={
                            Formik.touched.sourcePlatform?.sp_parentId && !!Formik.errors.sourcePlatform?.sp_parentId
                        }
                    >
                        <option value='' disabled>
                            Select {sourceName} Account
                        </option>
                        {deshboardDirectedPlatforms?.length > 1
                            ? deshboardDirectedPlatforms?.map((spData: Record<string, any>) => (
                                <option key={spData.id} value={spData.id}>
                                    {spData?.display_name}
                                </option>
                            ))
                            : getPlatformSource?.map((spData: Record<string, any>) => (
                                <option key={spData.id} value={spData.id}>
                                    {spData?.display_name}
                                </option>
                            ))}
                    </Form.Select>
                    <Button
                        size='sm'
                        title='Connect New Account'
                        className='btn ms-1'
                        style={{ height: '43px', fontSize: '25px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                        onClick={() => {
                            set_isSourcePlatformConnectModalOpen(true)
                        }}
                    >
                        +
                    </Button>
                </Stack>
            </Stack>
        </Container>
    )
}

export default React.memo(ComponentsSourceSide)
