export type USER_ROLES = 'ADMIN'|'USER'|'SUPERADMIN'  // user roles

export type PLATFORM_TYPES = 'DESTINATION'|'SOURCE'|'BOTH' // platform types

export const AUTH_FIELDS = {
  BOOLEAN: 'boolean',
  BUTTON: 'button',
  TEXT: 'text',
  INTEGER: 'integer',
  EMAIL: 'email',
  PHONE: 'phone',
  ADDRESS: 'address',
  MULTILINE_TEXT: 'multiline_text',
  IMAGE: 'image',
  SELECT: 'select',
  MULTISELECT: 'multiselect',
}

export const USER_ROLE = {
  user:"USER",
  admin:"ADMIN",
  superadmin:"SUPERADMIN"
}

export const LOCAL_STORAGE_FIELDS = {
  user:"user",
  admin_detail:"admin_detail",
  user_details:"user_details",
  loggedInFromAdmin:"loggedInFromAdmin"  
}

export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const REGISTRATION_TYPE = { form:"FORM", google_oauth:"GOOGLE_AUTH" }