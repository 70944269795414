import React, { Fragment, useState } from 'react'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import classes from '../Profile.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Form, InputGroup, Modal, Button, Stack, Spinner } from 'react-bootstrap'
import { ChangePasswordProfileAsync } from '../../../../../../../../Redux/Slices/credentials/credentialsAsync'
import {
    userDetails_values,
    changePasswordInitialvalues,
    changePasswordVadidationSchema,
    changePasswordInitialvalues_values
} from '../utils'


type changePasswordProps = {
    userDetails_prs: userDetails_values | null
    setIsOpen: (status: boolean) => void
}

export const ChangePassword = ({ userDetails_prs, setIsOpen }: changePasswordProps) => {
    const dispatch = useDispatch()
    const [showPass, setShowPass] = useState(false)
    const { loading, changePasswordProfile } = useSelector((state: {
        credentials: {
            loading: boolean,
            changePasswordProfile: { status: number | string, message: string }
        }
    }) => state.credentials)

    const clickHandler = () => {
        setShowPass((prev) => !prev)
    }

    const onSubmit = async (values: changePasswordInitialvalues_values) => {
        let payload = {
            userID: userDetails_prs!.id,
            previous_password: values.previous_password,
            new_password: values.new_password,
            confirm_new_password: values.confirm_new_password,
        }
        try {
            const response = await dispatch(ChangePasswordProfileAsync(payload) as any);
            if (response && response.error) {
                console.log('response', response)
                toast.error(`Somthing Went Wrong :${response.payload?.data?.error}`);
                setIsOpen(false)
            } else {
                toast.success('Password Changed Successfully')
                setIsOpen(false)
            }
        } catch (error) {
            console.error('An error occurred:', error);
            toast.error('Something Went Wrong');
            setIsOpen(false)
        }
    }

    const formik = useFormik({
        initialValues: changePasswordInitialvalues,
        onSubmit,
        validationSchema: changePasswordVadidationSchema,
    })


    return (
        <Container>
            <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                <Stack>
                    <Form.Group className='mb-3'>
                        <Form.Label>Previous Password</Form.Label>
                        <Form.Control
                            type='password'
                            id="previous_password"
                            name="previous_password"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.previous_password}
                            required
                        />
                    </Form.Group>
                    {formik.touched.previous_password && formik.errors.previous_password && (
                        <Stack className={classes.changePassword_error}>{formik.errors.previous_password}</Stack>
                    )}
                </Stack>

                <Stack>
                    <Form.Label>New Password</Form.Label>
                    <InputGroup className='mb-3'>
                        <Form.Control
                            type={showPass ? 'text' : 'password'}
                            id="new_password"
                            name="new_password"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.new_password}
                            required
                        />
                        <InputGroup.Text onClick={clickHandler}>
                            {showPass ? <i className='bi bi-eye-fill text-primary fs-3 cursor-pointer'></i> : <i className='bi bi-eye-fill fs-3 cursor-pointer'></i>}
                        </InputGroup.Text>
                    </InputGroup>
                    {formik.touched.new_password && formik.errors.new_password && (
                        <Stack className={classes.changePassword_error}>{formik.errors.new_password}</Stack>
                    )}
                </Stack>

                <Stack>
                    <Form.Group>
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control
                            type='password'
                            id="confirm_new_password"
                            name="confirm_new_password"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.confirm_new_password}
                            required
                        />
                    </Form.Group>
                    {formik.touched.confirm_new_password && formik.errors.confirm_new_password && (
                        <Stack className={classes.changePassword_error}>{formik.errors.confirm_new_password}</Stack>
                    )}
                </Stack>

                <Modal.Footer>
                    <Button size='sm' variant='secondary' type='reset' >
                        Reset
                    </Button>
                    <Button size='sm' variant='primary' type='submit'>
                        {loading && (
                            <Spinner className='me-2' as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                        )}
                        {loading ? ' Changing Password...' : 'Change Password'}
                    </Button>
                </Modal.Footer>
            </form>
        </Container>
    );
}

