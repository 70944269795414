import React, { Fragment, useState } from 'react'
import { ChangePassword } from './EditComponents'
import { userDetails_values } from '../utils'

import Modal from 'react-bootstrap/Modal'
type EditModalProps = {
    isOpen: boolean
    editKey: string
    setIsOpen: (status: boolean) => void
    userDetails_prs: userDetails_values | null
}

function EditModal(props: EditModalProps) {
    const { isOpen, editKey, setIsOpen, userDetails_prs } = props

    const handleClose = () => {
        setIsOpen(false)
    }


    return (
        <Fragment>
            <Modal show={isOpen} onHide={handleClose} backdrop='static' keyboard={false} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Change {editKey}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        editKey == 'Password' ? (
                            <ChangePassword userDetails_prs={userDetails_prs} setIsOpen={setIsOpen}/>
                        ) : null
                    }
                </Modal.Body>

            </Modal>
        </Fragment>
    )
}

export default EditModal
