import {AxiosClient} from '../../AxiosClient/axiosClient'
import {createAsyncThunk} from '@reduxjs/toolkit'

export const order_Shipment_Adjustment_CountAsync = createAsyncThunk(
  '/visualize-monthly-reports/order_Shipment_Adjustment_Count',
  async (payload: {id: number| string}, toolkit) => {
    return await AxiosClient(
      'GET',
      `//visualize-monthly-reports/?workflow_id=${payload.id}`,
      payload,
      toolkit
    )
  }
)
