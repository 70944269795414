import React, { Fragment, useEffect } from 'react'
import EditForm from './components/EditForm'
import { Formik, Form } from "formik"
import {
  vadidationSchema,
  initialValue,
  initialValueType
} from './components/utils'
import classes from './components/OrgDetails.module.css'

function OrganizationDetails() {

  const onSubmit = async (values: initialValueType) => {
    console.log('Formik Values', values)
  }

  return (
    <Fragment>
      <h3 className={classes.page_heading}>Organization Details</h3>

      <Formik
        initialValues={initialValue}
        validationSchema={vadidationSchema}
        onSubmit={onSubmit}
      >
        {(Formik) => {
          return (
            <Form>
              <EditForm {...{ Formik }} />
            </Form>
          )
        }}
      </Formik>
    </Fragment>
  )
}

export default OrganizationDetails
