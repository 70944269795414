import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import { resetPasswordAsync, ChangePasswordAsync, ChangePasswordProfileAsync } from './credentialsAsync'

type credentialsSection = {
    loading: boolean
    resetPassword: any
    ChangePassword: any
    changePasswordProfile: any
}

const initialState: credentialsSection = {
    loading: false,
    resetPassword: [],
    ChangePassword: [],
    changePasswordProfile: [],
}

const credentialsSection = createSlice({
    name: 'credentialsSection',
    initialState,
    reducers: { emptycredentials: () => initialState },
    extraReducers: (builder) => {
        builder.addMatcher(isAnyOf(
            resetPasswordAsync.pending,
            ChangePasswordAsync.pending,
            ChangePasswordProfileAsync.pending
        ), (state, actions) => {
            state.loading = true
        })
        builder.addMatcher(isAnyOf(resetPasswordAsync.fulfilled), (state, actions) => {
            state.loading = false
            state.resetPassword = actions.payload
        })
        builder.addMatcher(isAnyOf(ChangePasswordAsync.fulfilled), (state, actions) => {
            state.loading = false
            state.ChangePassword = actions.payload
        })
        builder.addMatcher(isAnyOf(ChangePasswordProfileAsync.fulfilled), (state, actions) => {
            state.loading = false
            state.changePasswordProfile = actions.payload
        })
        builder.addMatcher(isAnyOf(
            resetPasswordAsync.rejected,
            ChangePasswordAsync.rejected,
            ChangePasswordProfileAsync.rejected
        ), (state, actions) => {
            state.loading = false
        })
    }
})

export default credentialsSection.reducer;
export const { emptycredentials } = credentialsSection.actions;
