import React, { useEffect, useState } from 'react'
import { FormikProps } from 'formik'
import { Modal } from 'react-bootstrap'
import { formInitialStateType } from '../utils'
import { KTSVG } from '../../../../../../_metronic/helpers'
import connect from '../../../../../../asset/icons/connect.png'

interface PropType {
  title: string
  showModal: boolean
  description?: string
  setShowModal: (status: boolean) => void
  Formik: FormikProps<formInitialStateType>
  onSave: (data: formInitialStateType) => void
  closeCreateWorkflowModal: (status: boolean) => void
}

const IntegrationConfirmation = (props: PropType) => {
  const { title, description, Formik, onSave, showModal, setShowModal, closeCreateWorkflowModal } = props
  const [show, setShow] = useState(false)

  const handleClose = () => {
    setShow(false);
    setShowModal(false)
  }
  const handleShow = () => setShow(true)

  useEffect(() => {
    setShow(showModal)
  }, [showModal])

  return (
    <Modal show={show} onHide={handleClose}>
      <div className='modal-content'>
        <div className='modal-header pb-2 justify-content-end border-0'>
          <div className='btn btn-icon btn-sm btn-active-light-primary ms-2' onClick={handleClose}>
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
          </div>
        </div>


        <div className='modal-body py-0 text-center'>
          {/* <div>
            <img src={connect} alt="Synced Status" style={{ width: '100px', height: 'auto' }} />
          </div> */}
          <div className='py-4 mt-5'>
            <h1>{title}</h1>
            <p className='mt-4 fw-bold text-muted fs-6'>{description}</p>
          </div>
        </div>


        <div className='modal-footer mb-5 border-0 justify-content-center'>
          <button
            type='button'
            className='btn btn-light'
            data-bs-dismiss='modal'
            onClick={() => {
              handleClose()
              // To Close Workflow Entire Modal
              // closeCreateWorkflowModal(false)
            }}
          >
            Cancel
          </button>

          <button
            type='submit'
            className='btn btn-light-danger'
            data-bs-dismiss='modal'
            onClick={() => {
              handleClose();
              onSave(Formik.values)
            }}
          >
            <div className='px-4'>Yes</div>
          </button>
        </div>
      </div>
    </Modal>
  )
}

export { IntegrationConfirmation }
