import React, { useEffect } from "react"
import { ErrorMessage } from 'formik'
import TextError from '../../../../../../../ErrorMessage/TextError'
import { Container, Row, Col, Form as BootstrapForm } from 'react-bootstrap'


type DefaultTextMappingProps = {
    Formik: any, previousMapping: any, conminedState: any
}
export function DefaultTextMapping(props: DefaultTextMappingProps) {
    const { Formik, conminedState, previousMapping } = props

    useEffect(() => {
        Formik.setFieldValue('field_to_default_text_dropdown', previousMapping?.destination_field)
        Formik.setFieldValue('field_to_default_text_textField', previousMapping?.default_value)
    }, [conminedState, previousMapping])

    return (
        <Container style={{ marginBottom: '10px' }}>
            <Row>
                <Col xs={12} md={6}>
                    <BootstrapForm.Label>Select Field</BootstrapForm.Label>
                    <BootstrapForm.Select
                        name='field_to_default_text_dropdown'
                        value={Formik.values.field_to_default_text_dropdown}
                        onChange={(event) => {
                            let selectedValue = event.target.value;
                            Formik.setFieldValue('field_to_default_text_dropdown', selectedValue)
                        }}

                        style={{ backgroundColor: 'white', color: 'black', fontWeight: '600', border: '1px solid #99A1B7' }}
                    >
                        <option value='' disabled>
                            Select Option
                        </option>
                        {conminedState?.map((items: any) => (
                            <option key={items.id} value={items?.id}>
                                {items?.api_name}&nbsp;- {items?.description}
                            </option>
                        ))}
                    </BootstrapForm.Select>
                    <ErrorMessage name="field_to_default_text_dropdown" component={TextError} />
                </Col>
                <Col xs={12} md={6}>
                    <BootstrapForm.Group className="mb-3" controlId="formBasicEmail">
                        <BootstrapForm.Label>Default Text</BootstrapForm.Label>
                        <BootstrapForm.Control
                            type="text"
                            placeholder="Default Text"
                            name='field_to_default_text_textField'
                            value={Formik.values.field_to_default_text_textField}
                            onChange={(event) => { Formik.handleChange(event) }}
                            style={{ backgroundColor: 'white', color: 'black', fontWeight: '600', border: '1px solid #99A1B7' }}
                        />
                    </BootstrapForm.Group>
                    <ErrorMessage name="field_to_default_text_textField" component={TextError} />

                </Col>
            </Row>
        </Container>
    )
}