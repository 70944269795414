import {createSlice, isAnyOf} from '@reduxjs/toolkit'
import {getSubdomainDetailAsync} from './subdomainAsync'

type SubdomainData = {
  created_at: Date | null
  display_name: string | ''
  id: number| null
  logo: string | undefined
  subdomain: string | ''
  updated_at: Date | null
  user: number|null
}

const initialState: {data: SubdomainData; status: boolean; subdomainLoader: boolean} = {
  data: {
    created_at: null,
    display_name: '',
    id: null,
    logo: '',
    subdomain: '',
    updated_at: null,
    user: null,
  },
  status: false,
  subdomainLoader: false,
}

const subdomainSlice = createSlice({
  name: 'subdomain',
  initialState,
  extraReducers: (builder) => {


    // get subdomain detail start
    builder.addMatcher(isAnyOf(getSubdomainDetailAsync.pending), (state, actions) => {
      console.log('getSubdomainDetailAsync in pending')
      state.subdomainLoader = true
    })

    builder.addMatcher(isAnyOf(getSubdomainDetailAsync.rejected), (state, actions) => {
      console.log('getSubdomainDetailAsync in rejected')
      state.subdomainLoader = false
    })

    builder.addMatcher(isAnyOf(getSubdomainDetailAsync.fulfilled), (state, actions) => {
      console.log(
        'getSubdomainDetailAsync in fulfilled state'
        // actions.payload
      )
      state.data = actions?.payload?.data
      state.status = actions.payload?.status
      document.title = actions?.payload?.data?.display_name
      state.subdomainLoader = false
    })
    // get sub domain detail end


  },
  reducers: {},
})

// export const {} = subdomainSlice.actions  //use for reducers functions
export default subdomainSlice.reducer
