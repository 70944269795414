import React, {Fragment} from 'react'
import { NavLink } from 'react-router-dom'

const EmptyItemTemplate = ({title="", subtitle="" }:{title:string, subtitle?: string }) => {
  return (
    <Fragment>
      <p className='fs-4 col card p-10' style={{backgroundColor: '#DBDFE9'}}>
        {title} <br />
        <span style={{display: 'inline-flex', flexDirection: 'row', flexWrap: 'wrap', fontSize:"14px" }}>
          {subtitle}
        </span>
      </p>
    </Fragment>
  )
}

export default EmptyItemTemplate
