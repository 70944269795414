import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    sourceChildData: null,
    outhDisplayName: null
}
const StateSaveSlice = createSlice({
    name: 'stateSaver',
    initialState,
    reducers: {
        workFlowSourceChildData: (state, action) => {
            state.sourceChildData = action.payload
        },
    }
})

export const { workFlowSourceChildData } = StateSaveSlice.actions;
export default StateSaveSlice.reducer;