import {FC} from 'react'
import Table from 'react-bootstrap/Table'
import {useAppSelector} from 'src/Redux/Store/hooks'
import {AVAILABLE_WORKFLOW} from 'src/_interfaces/AvailableWorkflow.Interface'

const AvailableWorkflowsTable: FC<{
  tableIndexMultiple: number
  openEditModal: (val: boolean) => void
  setEditData: (val: AVAILABLE_WORKFLOW) => void
}> = ({tableIndexMultiple, openEditModal, setEditData}) => {
  const {availableWorkflows} = useAppSelector((state) => state.availableWorkflow)

  return (
    <div>
      <Table responsive striped bordered hover className='h-50' cellPadding={2}>
        <thead className='border border-bottom-2 border-secondary'>
          <tr>
            {[
              '#',
              'Workflow Name',
              'Source Name',
              'Destination Name',
              'Workflow Description',
              'Actions',
            ].map((head, index) => {
              return (
                <th className='fw-bold fs-5 align-middle text-center' key={'availableWorkflowHeader' + index}>
                  {head}
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {availableWorkflows.length ? (
            availableWorkflows.map((availableWorkflow: AVAILABLE_WORKFLOW, avWflIndex: number) => {
              return (
                <tr className='align-middle text-center' key={'org' + avWflIndex}>
                  <td>{avWflIndex + 1 + tableIndexMultiple}</td>
                  <td>{availableWorkflow.name}</td>
                  <td>{availableWorkflow.source_available_platform.display_name}</td>
                  <td>{availableWorkflow.destination_available_platform.display_name}</td>
                  <td style={{ textAlign:"start" }} dangerouslySetInnerHTML={{__html: availableWorkflow.description }} ></td>
                  <td>
                    <i
                      className='bi bi-pencil-square cursor-pointer text-dark text-hover-primary icon-900'
                      style={{fontSize:"20px", fontWeight:"bold"}}
                      title='edit'
                      onClick={() => {
                        openEditModal(true)
                        setEditData(availableWorkflow)
                      }}
                    ></i>
                  </td>
                </tr>
              )
            })
          ) : (
            <tr>
              <td colSpan={6} className='text-center fs-4 fw-bold '>
                No data found
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  )
}

export default AvailableWorkflowsTable
