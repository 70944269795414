import React, { FC } from 'react'
import { Button, Col, Image } from 'react-bootstrap'
import Table from 'react-bootstrap/Table'
import { FormattedDate } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { resetInfoModal, setInfoModalProps } from 'src/Redux/Slices/info/infoSlice'
import { useAppDispatch, useAppSelector } from 'src/Redux/Store/hooks'
import { RootState } from 'src/Redux/Store/storets'
import { ORGANIZATION_DATA, USER_DATA } from 'src/_interfaces/Organization.Interface'

const UsersTable: FC<{ tableIndexMultiple: number }> = ({ tableIndexMultiple }) => {
  const { organizations, userList } = useAppSelector((state: RootState) => state.organization)
  const { modalProp } = useAppSelector((state) => state.info)
  const { loginUser } = useAppSelector((state) => state.loggedInUser)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const handleAccept = (acceptData: USER_DATA) => {
    localStorage.setItem('user', acceptData.id + '')
    localStorage.setItem('user_details', JSON.stringify(acceptData))
    localStorage.setItem('loggedInFromAdmin', 'true')
    localStorage.setItem('admin_detail', JSON.stringify(loginUser))
    dispatch(
      setInfoModalProps({
        ...modalProp,
        isConfirmationDialogue: false,
        visible: true,
        modalIcon: '/media/icons/duotune/general/gen043.svg',
        modalType: 'success',
        cancelLabel: 'Close',
        title: `You are successfully logged in as ${acceptData.email}`,
        onClose: () => {
          dispatch(resetInfoModal())
          navigate('/')
          window.location.reload()
        },
      })
    )
  }

  return (
    <div>
      <Table
        responsive
        striped
        // variant='light'
        bordered
        hover
        className='h-50 consta'
        cellPadding={2}
      >
        <thead className='border border-bottom-2 border-secondary'>
          <tr>
            {[
              '#',
              'Name',
              'Role',
              'Email',
              //   'User Name',
              //   'User Email',
              'Created Date',
              'Action',
            ].map((tableHead, index) => {
              return (
                <th className='fw-bold fs-5 align-middle text-center' key={'head' + index}>
                  {tableHead}
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {userList.length ? (
            userList.map((user: USER_DATA, userIndex: number) => {
              return (
                <tr className='align-middle text-center' key={'org' + userIndex}>
                  <td>{userIndex + 1 + tableIndexMultiple}</td>
                  <td
                    className=' cursor-pointer'
                    title={`Login is ${(user?.username || (user?.first_name + " " + user?.last_name)) ?? 'This user'}`}
                    data-tooltip-place='top'
                    data-tooltip-position-strategy='absolute'
                  >
                    {user?.first_name ? `${user?.first_name} ${user.last_name}` : user.username}
                  </td>
                  <td>{user.user_type}</td>
                  <td>{user.email}</td>
                  <td>
                    <FormattedDate
                      value={new Date(user?.date_joined ?? Date.now())}
                      year='numeric'
                      month='long'
                      day='2-digit'
                    />
                  </td>
                  <td>
                    <Button
                      variant='outline-success'
                      size='sm'
                      onClick={() => {
                        if (loginUser.id == user.id) {
                          return dispatch(
                            setInfoModalProps({
                              ...modalProp,
                              visible: true,
                              isConfirmationDialogue: false,
                              modalIcon: '/media/icons/duotune/general/gen044.svg',
                              modalType: 'warning',
                              cancelLabel: 'Close',
                              title: `You are already logged in with this current user`,
                              onClose: () => {
                                dispatch(
                                  setInfoModalProps({ ...modalProp, isConfirmationDialogue: false })
                                )
                                setTimeout(() => {
                                  dispatch(resetInfoModal())
                                }, 200)
                              },
                            })
                          )
                        }
                        dispatch(
                          setInfoModalProps({
                            ...modalProp,
                            visible: true,
                            modalIcon: '/media/icons/duotune/general/gen044.svg',
                            modalType: 'warning',
                            title: `You will be logging as ${user.username ?? 'this user'} `,
                            subtitle: (
                              <p>
                                and respected email is <b>{user.email}</b> <br /> and role is{' '}
                                <b>{user.user_type}</b>{' '}
                              </p>
                            ),
                            onClose: () => dispatch(resetInfoModal()),
                            onAccept: () => handleAccept(user),
                          })
                        )
                      }}
                      className='px-3 py-2 border border-1 border-primary rounded bg-hover-primary text-hover-white fs-sm-8 inline-block'
                    //  className={`px-3 py-2 border border-1 border-success rounded text-hover-white fs-8 ${user.id==loginUser.id ? "bg-success text-white" : "bg-hover-success" } `}
                    >
                      {/* { user.id==loginUser.id ? "Already Logged in" : `Login as ${user.username ?? 'this user'}`} */}
                      Login
                    </Button>
                  </td>
                </tr>
              )
            })
          ) : (
            <tr>
              <td colSpan={6} className='text-center fs-4 fw-bold '>
                No data found
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  )
}

export default UsersTable
