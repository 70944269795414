import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import {
    getWorkflowDetailsAsync,
    updateActionStatusAsync,
    getSourcePlatformSettingsAsync,
    getDestinationPlatformSettingsAsync
} from './workflowDetailModalAsync'

type workflowModalSlice = {
    loading: boolean
    getWorkflowDetails: any
    updateActionStatus: any
    sp_loader: any
    getSourcePlatformSettings: any
    dp_loader: any
    getDestinationPlatformSettings: any
}

const initialState: workflowModalSlice = {
    loading: false,
    sp_loader: false,
    dp_loader: false,
    getWorkflowDetails: [],
    updateActionStatus: [],
    getSourcePlatformSettings: [],
    getDestinationPlatformSettings: []
}

const workflowModalSlice = createSlice({
    name: 'workflowModalSlice',
    initialState,
    reducers: { emptyworkflowDetails: () => initialState },
    //Pending Statements:
    extraReducers: (builder) => {
        builder.addMatcher(isAnyOf(
            getWorkflowDetailsAsync.pending,
            updateActionStatusAsync.pending,
        ), (state, action) => {
            state.loading = true
        })
        builder.addMatcher(isAnyOf(getSourcePlatformSettingsAsync.pending), (state, action) => { state.sp_loader = true })
        builder.addMatcher(isAnyOf(getDestinationPlatformSettingsAsync.pending), (state, action) => { state.dp_loader = true })
        //Success Statements:
        builder.addMatcher(isAnyOf(getWorkflowDetailsAsync.fulfilled), (state, action) => {
            state.loading = false
            state.getWorkflowDetails = action.payload
        })
        builder.addMatcher(isAnyOf(updateActionStatusAsync.fulfilled), (state, action) => {
            state.loading = false
            state.updateActionStatus = action.payload
        })
        builder.addMatcher(isAnyOf(getSourcePlatformSettingsAsync.fulfilled), (state, action) => {
            state.sp_loader = false
            state.getSourcePlatformSettings = action.payload
        })
        builder.addMatcher(isAnyOf(getDestinationPlatformSettingsAsync.fulfilled), (state, action) => {
            state.dp_loader = false
            state.getDestinationPlatformSettings = action.payload
        })
        // Reject Statements:
        builder.addMatcher(isAnyOf(
            getWorkflowDetailsAsync.rejected,
            updateActionStatusAsync.rejected,
        ), (state, action) => {
            state.loading = false
        })

        builder.addMatcher(isAnyOf(getSourcePlatformSettingsAsync.rejected), (state, action) => { state.sp_loader = false })
        builder.addMatcher(isAnyOf(getDestinationPlatformSettingsAsync.rejected), (state, action) => { state.dp_loader = false })
    },
})

export const { emptyworkflowDetails } = workflowModalSlice.actions;
export default workflowModalSlice.reducer;
