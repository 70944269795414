import React, { Fragment, useMemo } from 'react'
import { formikInitialValues, combination } from './utils'
import { FormikProps, FieldArray, FieldArrayRenderProps, ErrorMessage } from 'formik'
import { mappingData } from 'src/Redux/Slices/workflowFieldMapping/AvailableFlowMappingSlice'
import { Row, Col, Button, Stack } from 'react-bootstrap'
import TextError from 'src/ErrorMessage/TextError'
import Combination_DSub from './Combination_DSub'
import Combination_SSub from './Combination_SSub'
import { KTSVG } from 'src/_metronic/helpers'
import classes from './mapping.module.css'
import { toast } from 'react-toastify'
import { Form } from 'react-bootstrap'

type combinationProps = {
  sourceSideField: mappingData
  destinationSideField: mappingData
  Formik: FormikProps<formikInitialValues>
  sourcePlatformName: string
  sourceOptionName: string
  destinationPlatformName: string
  destinationOptionName: string
}

function Combination(props: combinationProps) {
  const {
    Formik,
    sourceSideField,
    destinationSideField,
    sourceOptionName,
    sourcePlatformName,
    destinationPlatformName,
    destinationOptionName,
  } = props

  function hasDuplicate(arr: combination[]) {
    const seenSourceData = new Set()
    const seenSourceDataStr = new Set()

    return arr.some((cc: combination, index: number) => {
      const sourceData = +cc.source_platform_data
      const sourceDataStr = cc.source_platform_data_str
      if (
        sourceSideField?.input_type == 'TEXT' &&
        sourceDataStr !== null &&
        seenSourceDataStr.has(sourceDataStr)
      ) {
        Formik.setFieldValue(`combination.${index}.source_platform_data`, null)
        Formik.setFieldValue(`combination.${index}.source_platform_data_str`, '')
        Formik.setFieldValue(
          `combination.${index}.destination_platform_data`,
          cc.destination_platform_data
        )
        Formik.setFieldValue(
          `combination.${index}.destination_platform_data_str`,
          cc.destination_platform_data_str
        )
        Formik.setFieldValue(`combination.${index}.isDefault`, false)
        toast.error("Field 01 Can't Be Same !")
        return
      }
      if (
        sourceSideField?.input_type == 'SELECT' &&
        sourceData !== 0 &&
        seenSourceData.has(sourceData)
      ) {
        Formik.setFieldValue(`combination.${index}.source_platform_data`, '')
        Formik.setFieldValue(`combination.${index}.source_platform_data_str`, null)
        Formik.setFieldValue(
          `combination.${index}.destination_platform_data`,
          cc.destination_platform_data
        )
        Formik.setFieldValue(
          `combination.${index}.destination_platform_data_str`,
          cc.destination_platform_data_str
        )
        Formik.setFieldValue(`combination.${index}.isDefault`, false)
        toast.error("Field 01 Can't Be Same !")
        return true
      } else {
        seenSourceData.add(sourceData)
        seenSourceDataStr.add(sourceDataStr)
        return false
      }
    })
  }
  useMemo(() => {
    hasDuplicate(Formik.values.combination)
  }, [Formik.values.combination])

  return (
    <Fragment>
      {/* If No sourcePlatfornName Is Coming From API Then Don't Show Combination Section */}

      <Fragment>
        {sourcePlatformName !== null ? (
          <Fragment>
            {/* Map Internally Start:---------------------------------------------------------------------- */}
            <Stack className={classes.mapInternally_container}>
              <input
                type='checkbox'
                id='map_intr'
                name='map_internally'
                checked={Formik.values.map_internally}
                onChange={(event) => {
                  Formik.handleChange({
                    target: {
                      name: 'map_internally',
                      value: event.target.checked,
                    },
                  })
                  Formik.setFieldValue('combination', [])
                }}
              />
              <label htmlFor='map_intr'>Map Internally</label>
            </Stack>
            {/* Map Internally End:------------------------------------------------------------------------ */}

            {/* Mapping Combination Start:---------------------------------------------------------------------- */}
            <FieldArray name='combination'>
              {(fieldArrayProps: FieldArrayRenderProps) => {
                const { form } = fieldArrayProps
                const { values } = form
                return (
                  <Fragment>
                    <Stack className={classes.comb_btn}>
                      <Button
                        size='sm'
                        onClick={() => {
                          fieldArrayProps.push({
                            isDefault: false,
                            source_platform_data:
                              sourceSideField?.input_type === 'TEXT' ? null : '',
                            destination_platform_data:
                              destinationSideField?.input_type === 'TEXT' ? null : '',
                            source_platform_data_str: null,
                            destination_platform_data_str: null,
                            sourceChild_array: [],
                            destinationChild_array: [],
                          })
                        }}
                      >
                        Add +
                      </Button>
                    </Stack>
                    {values?.combination?.length
                      ? values?.combination?.map((comb: combination, index: number) => (
                        <Row key={index}>
                          {/* Source Side */}
                          <Col xs={12} md={6}>
                            {sourceSideField?.input_type === 'TEXT' ? (
                              <Fragment>
                                <Form.Control
                                  type='text'
                                  name={`combination.${index}.source_platform_data_str`}
                                  placeholder={`Enter ${sourcePlatformName} ${sourceOptionName} ${index + 1}`}
                                  value={
                                    Formik.values.combination[index]?.source_platform_data_str
                                  }
                                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    Formik.setFieldValue(
                                      `combination.${index}.source_platform_data_str`,
                                      event.target.value
                                    )
                                  }}
                                />
                                <ErrorMessage
                                  name={`combination.${index}.source_platform_data_str`}
                                  component={TextError}
                                />
                              </Fragment>
                            ) : (
                              <Fragment>
                                <Form.Select
                                  as='select'
                                  name={`combination.${index}.source_platform_data`}
                                  value={Formik.values.combination[index]?.source_platform_data}
                                  onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                    let selectedValue = event.target.value
                                    Formik.setFieldValue(
                                      `combination.${index}.source_platform_data`,
                                      selectedValue
                                    )
                                  }}
                                >
                                  <option value=''>
                                    Select {sourcePlatformName} {sourceOptionName}
                                  </option>
                                  {sourceSideField?.field_data?.map(
                                    (ele) =>
                                      ele.parent_data === null && (
                                        <option key={ele.id} value={ele.id}>
                                          {ele.api_name}
                                        </option>
                                      )
                                  )}
                                </Form.Select>
                                <Combination_SSub
                                  index={index}
                                  Formik={Formik}
                                  dropdownData={sourceSideField?.field_data}
                                  platformName={sourceSideField?.display_name}
                                  parentID={
                                    Formik.values.combination[index]?.source_platform_data
                                  }
                                  fieldName='sourceChild_array'
                                />
                                <ErrorMessage
                                  name={`combination.${index}.source_platform_data`}
                                  component={TextError}
                                />
                              </Fragment>
                            )}
                          </Col>

                          {/* Destination Side */}
                          <Col xs={12} md={6}>
                            {destinationSideField?.input_type === 'TEXT' ? (
                              <Fragment>
                                <Form.Control
                                  type='text'
                                  placeholder={`Enter ${destinationPlatformName} ${destinationOptionName} ${index + 1}`}
                                  name={`combination.${index}.destination_platform_data_str`}
                                  value={
                                    Formik.values.combination[index]
                                      ?.destination_platform_data_str
                                  }
                                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    Formik.setFieldValue(
                                      `combination.${index}.destination_platform_data_str`,
                                      event.target.value
                                    )
                                  }}
                                />
                                <ErrorMessage
                                  name={`combination.${index}.destination_platform_data_str`}
                                  component={TextError}
                                />
                                <Stack
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    fieldArrayProps.remove(index)
                                  }}
                                  className={classes.comb_cancel}
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/abstract/abs012.svg'
                                    className=' svg-icon-2x text-danger'
                                  />
                                </Stack>
                              </Fragment>
                            ) : (
                              <Fragment>
                                <Form.Select
                                  as='select'
                                  name={`combination.${index}.destination_platform_data`}
                                  value={
                                    Formik.values.combination[index]?.destination_platform_data
                                  }
                                  onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                    let selectedValue = event.target.value
                                    Formik.setFieldValue(
                                      `combination.${index}.destination_platform_data`,
                                      selectedValue
                                    )
                                    Formik.setFieldValue(
                                      `combination.${index}.destinationChild_array`,
                                      []
                                    )
                                  }}
                                >
                                  <option value=''>
                                    Select {destinationPlatformName} {destinationOptionName}
                                  </option>
                                  {destinationSideField?.field_data?.map(
                                    (ele) =>
                                      ele?.parent_data === null && (
                                        <option key={ele.id} value={ele.id}>
                                          {ele.api_name}
                                        </option>
                                      )
                                  )}
                                </Form.Select>
                                <Combination_DSub
                                  index={index}
                                  Formik={Formik}
                                  dropdownData={destinationSideField?.field_data}
                                  platformName={destinationSideField?.display_name}
                                  parentID={
                                    Formik.values.combination[index]?.destination_platform_data
                                  }
                                  fieldName='destinationChild_array'
                                />
                                <ErrorMessage
                                  name={`combination.${index}.destination_platform_data`}
                                  component={TextError}
                                />

                                <Stack
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    fieldArrayProps.remove(index)
                                  }}
                                  className={classes.comb_cancel}
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/abstract/abs012.svg'
                                    className=' svg-icon-2x text-danger'
                                  />
                                </Stack>
                              </Fragment>
                            )}
                          </Col>
                        </Row>
                      ))
                      : null}
                  </Fragment>
                )
              }}
            </FieldArray>
            {/* Mapping Combination End:------------------------------------------------------------------------ */}
          </Fragment>
        ) : null}
      </Fragment>
    </Fragment>
  )
}

export default Combination
