
type invoiceActions = {
    handleProductClick: (product_id: number) => void
    mouseHoverID: string
    setMouseHoverID: (id: string) => void
}

type rowData = {
    id: number
    name: string
    display_name: string | null
    api_id: string
    api_code: string
    api_status: string | null
    api_created_at: string
    destination_sync_status: string
    price_id: number | null
    quantity: number | null
    sku: string | null
    updated_at: string,
    platform_type: string
}

export type columnData = {
    name: string | React.ReactElement
    selector?: (row: rowData) => void
    cell?: (row: rowData) => void
    sortable?: boolean
    width?: string
}

export const productLogs =
    (
        { handleProductClick, mouseHoverID, setMouseHoverID }: invoiceActions
    ): columnData[] => [

            { name: 'ID', selector: (row) => row.id, sortable: false },
            { name: 'Source ID', selector: (row) => row.api_id, sortable: false },
            { name: 'Product Name', selector: (row) => row.name, sortable: false },
            { name: 'Sync Status', selector: (row) => row.destination_sync_status, sortable: false },
            { name: 'Platform', selector: (row) => row.platform_type, sortable: false },
            { 
                name: 'Last Updated At', 
                selector: (row) => {
                    const date = new Date(row.updated_at);
                    return new Intl.DateTimeFormat('en-US', {
                        year: 'numeric', month: 'long', day: '2-digit', 
                        hour: '2-digit', minute: '2-digit', hour12: true
                    }).format(date);
                }, 
                sortable: false 
            },
            {
                name: 'View',
                cell: (row) => (
                    <div onMouseEnter={() => setMouseHoverID(row?.id.toString())} onMouseLeave={() => setMouseHoverID('')}>
                        <i
                            className='bi bi-eye-fill fs-2 cursor-pointer'
                            onClick={() => handleProductClick(row?.id)}
                            style={{ color: mouseHoverID == row?.id.toString() ? '#4098FF' : '' }}
                        ></i>
                    </div>
                ),
                sortable: false,
            },
        ]

export default productLogs;