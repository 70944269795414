import { AxiosClient } from '../../AxiosClient/axiosClient'
import { createAsyncThunk } from '@reduxjs/toolkit'


export const resyncRejectedOrderAsync = createAsyncThunk(
    'resync-order/resyncRejectedOrder', async (payload: { id: number }, toolkit) => {
        return await AxiosClient(
            'POST',
            `/resync-order/`,
            { order_id: payload.id },
            toolkit
        )
    }
)


export const platformOrderEnabilityAsync = createAsyncThunk(
    'platform-orders/platformOrderEnability',
    async (payload: { action_str: string, workflowID: string | number, platform_order_ids: number[], allSelected: boolean, order_type: string }, toolkit) => {
        let payloadBody = { workflow_id: payload.workflowID, platform_order_ids: payload.platform_order_ids, allSelected: payload.allSelected, order_type: payload.order_type }
        return await AxiosClient(
            'POST', `/platform-orders/${payload.action_str}/`, payloadBody, toolkit
        )
    }
)

export const generateWorkflowReportAsync = createAsyncThunk(
    'workflow-export-request/generateWorkflowReportAsync',
    async (payload: { from_date_: string, to_date: string, type: string, destination_sync_status: string, workflow_id: number }, toolkit) => {
        return await AxiosClient(
            'POST', `/workflow-export-request/`, payload, toolkit
        )
    }
)
