export const customStyles_light = {
    title: {
        style: {
            fontSize: '24px',
        },
    },
    subHeader: {
        style: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            whiteSpace: 'normal',
        },
    },
    headRow: {
        style: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            whiteSpace: 'normal',
            fontWeight: 'bolder'
        },
    },
    headCells: {
        style: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            whiteSpace: 'normal',
        },
    },
    rows: {
        style: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            whiteSpace: 'normal',
        },
    },
    cells: {
        style: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            whiteSpace: 'normal',
            paddingLeft: '8px',
            paddingRight: '8px',
        },
    },
}

export const customStyles_dark = {
    title: {
        style: {
            fontSize: '24px',
        },
    },
    subHeader: {
        style: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            whiteSpace: 'normal',
            backgroundColor: 'black',
            color: 'white'
        },
    },
    headRow: {
        style: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            whiteSpace: 'normal',
            backgroundColor: '#0095E8',
            color: 'white',
            fontWeight: 'bolder'
        },
    },
    headCells: {
        style: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            whiteSpace: 'normal',
            backgroundColor: '#0095E8',
            color: 'white',
        },
    },
    rows: {
        style: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            whiteSpace: 'normal',
            backgroundColor: 'black',
            color: 'white',
        },
    },
    cells: {
        style: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            whiteSpace: 'normal',
            paddingLeft: '8px',
            paddingRight: '8px',
            backgroundColor: 'black',
            color: 'white',
        },
    },
    pagination: {
        style: {
            backgroundColor: '#0095E8',
            color: 'white',
        },
    },
}




