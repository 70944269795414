import React, { Fragment, useEffect, useState } from 'react'
import { ErrorMessage } from 'formik'
import TextError from '../../../../../../../ErrorMessage/TextError'
import { Container, Row, Col, Form as BootstrapForm } from 'react-bootstrap'

type DefaultMappedValueProps = { defaultMapping: any; Formik: any; getMappedDataByID: any }
export function DefaultMappedValue(props: DefaultMappedValueProps) {
  const { Formik, defaultMapping, getMappedDataByID } = props
  const [updateID, setUpdateID] = useState<any>('')

  useEffect(() => {
    //eslint-disable-next-line @typescript-eslint/no-unused-expressions
    let filter_processID = getMappedDataByID?.data?.workflow_field_mapping_data?.find(
      (fltr: any) => {
        return fltr?.source_platform_data == null && fltr?.source_platform_data_str == null && fltr?.isDefault == true
      }
    )
    setUpdateID(filter_processID?.id)

    if (defaultMapping?.input_type == 'SELECT') {
      Formik.setFieldValue('defaultDestination', {
        isDefault: true,
        id: filter_processID?.id,
        source_platform_data: null,
        source_platform_data_str: null,
        destination_platform_data_str: null,
        destination_platform_data: filter_processID?.destination_platform_data?.id,
      })
    }
    if (defaultMapping?.input_type == 'TEXT') {
      Formik.setFieldValue('defaultDestination', {
        isDefault: true,
        id: filter_processID?.id,
        source_platform_data: null,
        source_platform_data_str: null,
        destination_platform_data: null,
        destination_platform_data_str: filter_processID?.destination_platform_data_str,
      })
    }
  }, [getMappedDataByID, defaultMapping])

  return (
    <Container style={{ marginBottom: '10px' }}>
      <Row>
        <Col xs={12} md={6}>
          <p className='mt-3 fw-bold fs-5 text-justify text-hover-primary'>
            Default Option
          </p>
        </Col>
        <Col xs={12} md={6}>
          {defaultMapping?.input_type == 'TEXT' ? (
            <Fragment>
              <BootstrapForm.Control
                type='text'
                placeholder='Type Default Destination Field'
                name='defaultDestination'
                value={Formik.values?.defaultDestination?.destination_platform_data_str}
                onChange={(event) => {
                  let selectedValue = event.target.value
                  Formik.setFieldValue('defaultDestination', {
                    id: updateID,
                    source_platform_data: null,
                    source_platform_data_str: null,
                    destination_platform_data: null,
                    destination_platform_data_str: selectedValue,
                    isDefault: true,
                  })
                }}
                style={{
                  backgroundColor: 'white',
                  color: 'black',
                  fontWeight: '600',
                  border: '1px solid #99A1B7',
                }}
              />
              <ErrorMessage name='defaultDestination' component={TextError} />
            </Fragment>
          ) : (
            <Fragment>
              <BootstrapForm.Select
                name='defaultDestination'
                value={Formik.values.defaultDestination.destination_platform_data}
                onChange={(event) => {
                  let selectedValue = event.target.value
                  Formik.setFieldValue('defaultDestination', {
                    id: updateID,
                    source_platform_data: null,
                    source_platform_data_str: null,
                    destination_platform_data: selectedValue,
                    destination_platform_data_str: null,
                    isDefault: true,
                  })
                }}
                style={{
                  backgroundColor: 'white',
                  color: 'black',
                  fontWeight: '600',
                  border: '1px solid #99A1B7',
                }}
              >
                <option value='' disabled>
                  Select Default Option
                </option>
                {defaultMapping?.field_data?.map((items: any) => (
                  items?.parent_data === null &&
                  <option key={items.id} value={items?.id}>
                    {items?.api_name}
                  </option>
                ))}
              </BootstrapForm.Select>
              <ErrorMessage name='defaultDestination' component={TextError} />
            </Fragment>
          )}
        </Col>
      </Row>
    </Container>
  )
}
