import React, { useState, useEffect, Fragment } from 'react'
import axios from 'axios'
import classes from './logs.module.css'
import DataTable from 'react-data-table-component'
import { Stack } from 'react-bootstrap'
import { CircularLoader } from '../../../../widgets/components/CustomLoaders'
import IndividualOrderDetail from '../../IndividualOrderDetail/IndividualOrderDetails'
import IndividualShipmentDetails from '../../IndividualShipmentDetail/IndividualShipmentDetails'
import { FilterLogsComponent, allEndPoints, ReloadButtonComponent } from './FilterLogsComponent'
// Working Area:::::
import {
  handleSort, handleSelectedRows, handlePerRowsChange,
  handleSearchText, handlePageChange
} from './Operations/OperationComponent'
import IndividualProductDetails from './IndividualDetailsComponent/IndividualProductDetails/IndividualProductDetails'
import { orderLogs, shipmentLogs, invoiceLogs, productLogs, NoColumns } from './ColumnComponents/ColumnComponents'
import { customStyles_light, customStyles_dark } from './ColumnComponents/ColumnStyle'
import { shipmentData } from './ColumnComponents/ShipmentLogs'
import GenerateReport from './GenerateReport/GenerateReport'
import SelectAction from './SubHeaderComponent/SelectAction'
import SubHeader from './SubHeaderComponent/SubHeader'
type logProps = {
  workflowID: number
}

function Logs(props: logProps) {
  const { workflowID } = props
  const userId = Number(localStorage.getItem('user'))
  // ::: React Data Table Page & Limit State
  const [perPage, setPerPage] = useState(10) // Data Limit i.e. 10,15,20...
  const [totalRows, setTotalRows] = useState(0) // Data Count
  const [currentPage, setCurrentPage] = useState(1) // Current Page Number

  // ::: Loader
  const [loading, setLoading] = useState(false)
  const [reload, setReload] = useState(false)

  // ::: API Data State
  const [data, setData] = useState<any | []>([])
  const [shipmentDataCollection, setShipmentDataCollection] = useState({} as shipmentData)
  const [individualShipmentID, setIndividualShipmentID] = useState<number | null>(null)
  const [individualProductID, setIndividualProductID] = useState<number | null>(null)
  // ::: Row Selection Checkbox State
  const [getCheckedAll, setCheckedAll] = useState(false)
  const [allRowChecked, setAllRowChecked] = useState(false)
  const [getCheckedValue, setCheckedValue] = useState<number[] | []>([])
  const [selectedRowID, setSelectdRowID] = useState<number[]>([])

  // ::: Row Order ID To Open Individual-Order-Detail Modal
  const [getOrderID, setOrderID] = useState<number>()

  // ::: Open Individual-Order-Detail Modal :: State
  const [openOrderDetailModal, setOpenOrderDetailModal] = useState(false)
  // ::: Open Shipment-Detail Modal :: State
  const [openShipmentDetailModal, setOpenShipmentDetailModal] = useState(false)
  // ::: Open Product-Detail Modal :: State
  const [openProductDetailModal, setOpenProductDetailModal] = useState(false)
  //::: Sub-Header Section
  const [isSearchClicked, setIsSearchClicked] = useState(false)
  const [searchText, setSearchText] = useState<string>('')
  const [searchStatus, setSearchStatus] = useState<string>('')
  // ::: Logs Dropdown 
  const [logAction, setLogAction] = useState('')
  // ::: Mouse Hover Highlighting Effect
  const [mouseHoverID, setMouseHoverID] = useState<string>('')
  // :::Theme Mode => light/dark
  const themeMode = localStorage.getItem('kt_theme_mode_value')

  // --------------------------------------------------------------First Time Data Calling On Table Start
  const fetchUsers = async (page: number, loadingRequired = true) => {
    if (loadingRequired) {
      setLoading(true)
    }
    console.log('allEndPoints[logAction]', allEndPoints[logAction])
    try {
      if (allEndPoints[logAction] !== undefined) {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/${allEndPoints[logAction]
          }/?user_id=${userId}&page=${page}&limit=${perPage}&workflow_id=${workflowID}&search_string=${searchText}&destination_sync_status=${searchStatus}`
        )
        setData(response.data?.results)
        setTotalRows(response.data.count)
        setCurrentPage(page)
      }
    } catch (error) {
      console.error('Error fetching users:', error)
    } finally {
      setLoading(false)
      setReload(false)
    }
  }

  useEffect(() => {
    if (workflowID) {
      fetchUsers(1) // Fetch Page 1
    }
  }, [workflowID, logAction, reload])


  //::: Single Select Checkbox
  const handleChangeCheckbox = (rowID: string) => {
    let checkedID = Number(rowID)
    setCheckedValue((prevValue) => {
      let index = prevValue.findIndex((id) => id === checkedID)
      if (index === -1) {
        return [...prevValue, checkedID]
      }
      if (index !== -1) {
        let filteredData = prevValue.filter((id) => id !== checkedID)
        return filteredData
      } else {
        return []
      }
    })
    setAllRowChecked(false)
  }
  // ::: Handles CheckedALL As Per All Single Selection
  useEffect(() => {
    if (getCheckedValue?.length === data?.platform_orders?.length) {
      setCheckedAll(true)
    }
    if (getCheckedValue?.length !== data?.platform_orders?.length) {
      setCheckedAll(false)
    }
  }, [getCheckedValue?.length, data?.platform_orders?.length])

  //::: Multi-Select Checkbox
  const handleCheckedAll = (checkedStatus: boolean) => {
    const orderList = data?.platform_orders
    if (checkedStatus === true) {
      const ids = orderList.map((collection: { id: number }) => collection.id)
      setCheckedValue(ids)
    } else {
      setCheckedValue([])
    }
    setCheckedAll(checkedStatus)
    if (checkedStatus === false) {
      setAllRowChecked(checkedStatus)
    }
  }
  // ::: Handle From Subheader 
  useEffect(() => {
    if (getCheckedAll) {
      const orderList = data?.platform_orders
      const ids = orderList?.map((collection: { id: number }) => collection.id)
      setCheckedValue(ids)
    }
  }, [getCheckedAll])

  //::: View Order Details
  const handleOrderClick = (api_id: string, order_id: string) => {
    if (order_id) {
      const orderID = Number(order_id)
      setOpenOrderDetailModal(true)
      setOrderID(orderID)
    }
  }
  //::: View Shipment Details
  const handleShipmentClick = (data: shipmentData) => {
    setOpenShipmentDetailModal(true)
    setShipmentDataCollection(data)
    if (data?.id) { setIndividualShipmentID(data?.id) }
  }

  // ::: View Product Details
  const handleProductClick = (product_id: number) => {
    setOpenProductDetailModal(true)
    setIndividualProductID(product_id)
  }


  let columnData: any = []
  let mainData: any = []
  console.log("logaction---- - - - -",logAction)
  if (logAction == 'IMPORT_ORDERS') {
    mainData = data?.platform_orders
    columnData = orderLogs({
      handleOrderClick, mouseHoverID, setMouseHoverID, getCheckedAll,
      getCheckedValue, handleCheckedAll, handleChangeCheckbox
    })
  }
  if (logAction == 'IMPORT_BASIC_ORDERS') {
    mainData = data?.platform_orders
    columnData = orderLogs({
      handleOrderClick, mouseHoverID, setMouseHoverID, getCheckedAll,
      getCheckedValue, handleCheckedAll, handleChangeCheckbox
    })
  }
  if (logAction == 'IMPORT_SHIPMENTS') {
    mainData = data
    columnData = shipmentLogs({ handleShipmentClick, mouseHoverID, setMouseHoverID })
  }
  if (logAction == 'CUSTOM_IMPORT_SHIPMENTS') {
    mainData = data
    columnData = shipmentLogs({ handleShipmentClick, mouseHoverID, setMouseHoverID })
  }
  if (logAction == 'CUSTOM_CREATE_SHIPMENTS') {
    mainData = data
    columnData = shipmentLogs({ handleShipmentClick, mouseHoverID, setMouseHoverID })
  }
  if (logAction == 'IMPORT_INVOICE') {
    mainData = data?.platform_orders
    columnData = invoiceLogs({ handleOrderClick, mouseHoverID, setMouseHoverID })
  } if (logAction == 'IMPORT_PRODUCTS') {
    console.log("datav = = = = ", data)
    mainData = data[0]?.product
    columnData = productLogs({ handleProductClick, mouseHoverID, setMouseHoverID })
  }

  if (logAction == 'IMPORT_INVENTORY') {
    mainData = data?.platform_product
    columnData = productLogs({ handleProductClick, mouseHoverID, setMouseHoverID })
  }

  return (
    <Fragment>
      <Stack className={classes.rander_container}>
        {/* <GenerateReport {...{ fetchUsers }} /> */}
        <SelectAction
          selectedRowID={getCheckedValue}
          {...{ workflowID, allRowChecked, totalRows, setCheckedAll, setAllRowChecked, setCheckedValue }} />
        <Stack className={classes.rander_segment}>
          {/* Doropdown Section---Start */}
          <FilterLogsComponent logAction={logAction} setLogAction={setLogAction} />
          {/* Doropdown Section-----End */}
          <ReloadButtonComponent setReload={setReload} setSearchStatus={setSearchStatus} />
        </Stack>
      </Stack>
      <div className='row'>
        <div className='col'>
          <div className='mt-7 border rounded'>
            <DataTable
              // title='Workflow-Details'
              columns={columnData}
              data={mainData}
              progressPending={loading}
              progressComponent={<CircularLoader width='70px' height='70px' borderWidth='4px' marginTop='20px' marginBottom='20px' />}
              responsive
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              onChangeRowsPerPage={(newPerPage, page) =>
                handlePerRowsChange(newPerPage, page, {
                  ...{
                    workflowID, searchText, allEndPoints, logAction,
                    setLoading, setData, setPerPage, setCurrentPage,
                    setCheckedAll, setAllRowChecked, searchStatus,
                  },
                })
              }
              onChangePage={(page) => handlePageChange(page, { ...{ fetchUsers, setCheckedAll, setAllRowChecked } })}
              noDataComponent={<NoColumns />} // Custom Messaging When No Data Found
              sortServer
              onSort={(column, sortDirection) =>
                handleSort(column, sortDirection, {
                  ...{
                    setData, perPage, logAction, setLoading,
                    searchText, workflowID, allEndPoints,
                    setTotalRows, setCheckedAll, setAllRowChecked,
                    searchStatus,
                  },
                })
              }
              highlightOnHover={themeMode === 'light' ? true : false}
              pointerOnHover
              customStyles={themeMode == 'light' ? customStyles_light : customStyles_dark}
              // customStyles={customStyles}
              subHeader // It Is To Provide Subheader
              subHeaderComponent={
                <SubHeader
                  totalRows={totalRows}
                  logAction={logAction}
                  fetchUsers={fetchUsers}
                  workflowID={workflowID}
                  searchText={searchText}
                  setSearchText={setSearchText}
                  selectedRowID={getCheckedValue}
                  isSearchClicked={isSearchClicked}
                  handleSearchText={() => handleSearchText({
                    ...{
                      workflowID, searchText, currentPage, perPage,
                      isSearchClicked, setLoading, allEndPoints,
                      logAction, setData, setTotalRows, setCurrentPage,
                      setIsSearchClicked, setCheckedAll, setAllRowChecked,
                      searchStatus,
                    }
                  })}
                  searchStatus={searchStatus}
                  getCheckedAll={getCheckedAll}
                  setCheckedAll={setCheckedAll}
                  allRowChecked={allRowChecked}
                  setCheckedValue={setCheckedValue}
                  setAllRowChecked={setAllRowChecked}
                  setIsSearchClicked={setIsSearchClicked}
                  setSearchStatus={setSearchStatus}
                  setPerPage={setPerPage}
                  setCurrentPage={setCurrentPage}
                />
              }
            // selectableRows
            // onSelectedRowsChange={(selectedRows) => handleSelectedRows(selectedRows, setSelectdRowID)}
            />
          </div>
        </div>
      </div>
      <IndividualOrderDetail
        OpenModal={openOrderDetailModal}
        closeModal={setOpenOrderDetailModal}
        orderID={getOrderID!}
        setOrderID={setOrderID}
        fetchUsers={fetchUsers}
        currentPageOfFetchUser={currentPage}
      />
      <IndividualShipmentDetails
        OpenModal={openShipmentDetailModal}
        closeModal={setOpenShipmentDetailModal}
        shipmentData={shipmentDataCollection}
        individualShipmentID={individualShipmentID}
      />
      <IndividualProductDetails
        OpenModal={openProductDetailModal}
        closeModal={setOpenProductDetailModal}
        product_ID={individualProductID}
      />
    </Fragment>
  )
}


export default Logs

