import axios from 'axios'
import { REGISTRATION_TYPE, USER_ROLE } from 'src/utils/constants'

const API_URL = process.env.REACT_APP_API_URL


export function login(loginData: any) {
  const LOGIN_URL = `${API_URL}/user/login`
  // console.log("API UR:")
  // console.log(LOGIN_URL)
  // console.log(API_URL)
  const email = loginData.email
  const password = loginData.password
  const organization_id = loginData.organization_id

  return axios.post(LOGIN_URL, {
    email,
    password,
    organization_id,
  })
}

export function register(registerData: any) {
  const REGISTER_URL = `${API_URL}/user/register`
  const first_name = registerData.first_name
  const last_name = registerData.last_name
  const password = registerData.password
  const email = registerData.email
  const organization_id = registerData.organization_id
  const registerFrom = registerData?.request_via

  return axios.post(REGISTER_URL, {
    first_name,
    last_name,
    email,
    password,
    organization_id,
    user_type: organization_id ? USER_ROLE['admin'] : USER_ROLE["user"],
    request_via: registerFrom ? registerFrom : REGISTRATION_TYPE['form']   // will add in later this is a flag to identify where from user logged in normally registered or from google oauth
  })
}
