import React, { FC, useEffect, useState, Fragment } from 'react'
import { PageTitle } from '../../../_metronic/layout/core'
import { useSelector, useDispatch } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import { StatisticsWidget7 } from '../../../_metronic/partials/widgets'
import {
  deletePlatformApi,
  getAvailablePlatformApi,
  getConnectedPlatformApi,
} from '../../redux/slices/PlatformSlices'
import { FormModal } from './components/FormModal'
import { ConfirmationModal } from '../workflow/components/ConfirmationModal'
import { deleteWorkflowApi } from '../../redux/slices/WorkflowSlices'
import Loader from '../loader/Loader'
import { useLocation, useNavigate } from 'react-router-dom'
import { emptyOauth } from '../../../Redux/Slices/processOauth/processOauthSlice'
import { useAppSelector } from 'src/Redux/Store/hooks'
import { organizationBasedPlatformAsync } from 'src/Redux/Slices/platform/platformAsync'
import AppSearchBar from 'src/utils/AppSearchBar'

const PlatformPage: FC = () => {
  const dispatch = useDispatch<any>()
  const location: any = useLocation()
  const navigate = useNavigate()
  const [spinner, setSpinner] = useState(true)
  const [spinner2, setSpinner2] = useState(true)
  const [getOauthURL, setOauthURL] = useState<any>()
  const [getOnlyDisplayText, setOnlyDisplayText] = useState('')
  const [getButtonName, setButtonName] = useState('')
  const [searchString, setSearchString] = useState<string>('')
  const userDetails = localStorage.getItem('user_details')
  const userDetailsParsed = JSON.parse(userDetails || '')
  const organizationId = userDetailsParsed.organization[0]
  
  // -------------------fetchAvailablePlatformGetApi---------------------
console.log("Organmizatiormgdfkshgfkjsdghfashjdbfsdf", organizationId)
  const fetchAvailablePlatformGetApi = () => {
    dispatch(getAvailablePlatformApi({ search_string: searchString })).then((response: any) => {
      if (response) {
        setSpinner(false)
      }
    })
  }

  useEffect(() => {
    if (searchString === '') {
      fetchAvailablePlatformGetApi()
      setSpinner(true)
    }
    dispatch(organizationBasedPlatformAsync({ organizationID: organizationId }))
  }, [searchString])

  const PlatformDetails = useSelector((state: any) => state.platform.data)?.data
  const { data, ConnectedPlatformdDetails } = useAppSelector((state) => state.platform)
  const { loading, organizationPlatforms } = useAppSelector((state) => state.organizationBasedPlatforms)
  // ----------------------fetchPlatformGetApi---------------------------

  const fetchConnectedPlatformGetApi = () => {
    const userId = Number(localStorage.getItem('user'))

    dispatch(getConnectedPlatformApi(userId)).then((response: any) => {
      if (response) {
        setSpinner2(false)
      }
    })
  }

  useEffect(() => {
    fetchConnectedPlatformGetApi()
  }, [])


  // ---------------------------------------------------------------------

  const [showModal, setShowModal] = useState(false)
  const [ModalData, setModalData] = useState(null)
  const [selectedCardId, setSelectedCardId] = useState<any>()

  const closeModal = () => {
    setShowModal(false)
    setOnlyDisplayText('')
  }

  const OnCardClick = (id: any, oauthUrl: any, buttonName: string) => {
    setShowModal(true)
    setModalData(id)
    setOauthURL(oauthUrl)
    setButtonName(buttonName)
  }

  const [showModalConfirmationModal, setShowModalConfirmationModal] = useState(false)

  const OnDeleteClick = (id: number) => {
    setSelectedCardId(id)
    setShowModalConfirmationModal(!showModalConfirmationModal)
  }

  const handleDelete = () => {
    dispatch(deletePlatformApi(selectedCardId)).then((response: any) => {
      if (response.payload.status == 200) {
        toast.success('Platform deleted successfully')
        fetchConnectedPlatformGetApi()
      } else {
        toast.error('Error')
      }
    })
    setShowModalConfirmationModal(false)
    setSelectedCardId(null)
  }

  // Message From Oauth Page----------------------------------------------------------Start
  useEffect(() => {
    if (location?.state?.type == 'success') {
      fetchAvailablePlatformGetApi()
      toast.success('Platform Connected Successfully !!')
      dispatch(emptyOauth())
    }
    if (location?.state?.type == 'error') {
      fetchAvailablePlatformGetApi()
      toast.error(location?.state.message)
      dispatch(emptyOauth())
    }
  }, [])
  // Message From Oauth Page----------------------------------------------------------End

  // Platform Connectiong From Workflow Page:::
  useEffect(() => {
    if (PlatformDetails?.length > 0) {
      if (location?.state?.message === 'fromWorkflowPage') {
        const filtered_platform = PlatformDetails?.filter((item: { id: number }) => item?.id === location?.state?.id)
        setShowModal(true)
        setModalData(filtered_platform?.[0]?.id)
        setOauthURL(filtered_platform?.[0]?.auth_route_name)
        setButtonName(filtered_platform?.[0]?.display_name)
      }
    }
  }, [PlatformDetails])

  // Clear State That Holdes Message Comming From Workflow Page:::
  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('', { state: { ...location.state, message: undefined } });
    }, 5000);

    return () => clearTimeout(timer);
  }, [navigate, location.state]);


  return (
    <Fragment>
      {/* <PageTitle breadcrumbs={[]}>Platform</PageTitle> */}
      <ConfirmationModal
        icon='/media/icons/duotune/general/gen044.svg'
        title='Are you sure?'
        description='Are you sure you want to Delete!'
        onSave={handleDelete}
        showModal={showModalConfirmationModal}
        setShowModal={setShowModalConfirmationModal}
      />
      {/* Work Area For oauthcallback----------------------Start */}
      <FormModal
        OpenModal={showModal} closeModal={closeModal}
        data={ModalData} oauthURL={getOauthURL}
        getOnlyDisplayText={getOnlyDisplayText}
        setOnlyDisplayText={setOnlyDisplayText}
        displayButtonName={getButtonName}
      />
      {/* Work Area For oauthcallback----------------------End */}


      {/* Available Platform Is Commented For Now Start */}
      {/* <div className='card p-7'>
        <div className='d-sm-flex justify-content-between align-items-center '>
          <h4 className='fw-bolder  text-dark'>Available Platform</h4>
          <AppSearchBar
            searchString={searchString}
            setSearchString={(st) => { setSearchString(st) }}
            onSubmit={() => {
              setSpinner(true)
              fetchAvailablePlatformGetApi()
            }}
            placeholder='Search Platform...'
          />
        </div>
        <div className='separator my-7'></div>

        <div className='row g-5 g-xl-8 my-2 '>
          {spinner ? (
            <Loader />
          ) : (
            <Fragment>
              {
                // Organization Based Platforms
                organizationPlatforms?.data && organizationPlatforms?.data?.length > 0 ?
                  organizationPlatforms?.data?.map((item) => {
                    return (
                      <div
                        className='col-sm-6 col-lg-4 col-xl-3 m-0'
                        key={item?.id}
                        onClick={() => OnCardClick(item?.id, item?.auth_route_name, item?.display_name)}
                      >
                        <StatisticsWidget7
                          className={`card-xl-stretch mb-5 mb-xl-8 bg-light-info bg-hover-secondary cursor-pointer`}
                          onDelete={() => OnDeleteClick(item?.id)}
                          Logo={item?.logo_ref}
                          display_name={item?.display_name}
                          type={item?.type}
                          isActive={null}
                          showActiveStatus={false}
                        />
                      </div>
                    )
                  }) :
                  // If No Organization Based Platforms Then Show This
                  data?.map((item: any) => {
                    return (
                      <div
                        className='col-sm-6 col-lg-4 col-xl-3 m-0'
                        key={item?.id}
                        onClick={() => OnCardClick(item?.id, item?.auth_route_name, item?.display_name)}
                      >
                        <StatisticsWidget7
                          className={`card-xl-stretch mb-5 mb-xl-8 bg-light-info bg-hover-secondary cursor-pointer`}
                          onDelete={() => OnDeleteClick(item?.id)}
                          Logo={item?.logo_ref}
                          display_name={item?.display_name}
                          type={item?.type}
                          isActive={null}
                          showActiveStatus={false}
                        />
                      </div>
                    )
                  })}
            </Fragment>
          )}
        </div>
      </div> */}
      {/* Available Platform Is Commented For Now End */}

      {ConnectedPlatformdDetails?.length > 0 ? (
        <div className='card p-7 '>
          <h4 className='fw-bolder  text-dark'>Connected Platforms</h4>
          <div className='separator my-7'></div>

          <div className='row g-5 g-xl-8 my-2 '>
            {spinner2 ? (
              <Loader />
            ) : (
              <Fragment>
                {ConnectedPlatformdDetails?.map((item: any) => {
                  return (
                    <div className='col-sm-6 col-lg-4 col-xl-4 m-0' key={item?.id}>
                      <StatisticsWidget7
                        className={`card-xl-stretch mb-5 mb-xl-8 bg-light-info`}
                        Logo={item?.platform_info?.logo_ref}
                        display_name={item?.display_name}
                        type={item?.type}
                        isActive={item?.isActive}
                        showActiveStatus={true}
                        onDelete={() => OnDeleteClick(item?.id)}
                        isConnected={item?.isConnected}
                        availablePlatformID={item?.id}
                        platformID={item?.platform_info?.id}
                        oauthURL={item?.finishAuth_url}
                      />
                    </div>
                  )
                })}
              </Fragment>
            )}
          </div>
        </div>
      ) : (
        ''
      )}
      <ToastContainer />
    </Fragment>
  )
}

export default PlatformPage
