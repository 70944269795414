
// ::: Pagenation
const handlePageChange = (page: number, { fetchUsers, setCheckedAll, setAllRowChecked }: {
    fetchUsers: (page: number) => void
    setCheckedAll: (status: boolean) => void
    setAllRowChecked: (status: boolean) => void
}) => {
    fetchUsers(page) // Page Number i.e. 1,2,3...
    setCheckedAll(false)
    setAllRowChecked(false)
}

export default handlePageChange;