import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import {
    getSourcePlatformFieldsAsync,
    getDestinationPlatformFieldsAsync,
    createWorkflowFieldMappingAsync,
    getWorkflowWiseMappingAsync,
    getMappedDataByIDAsync,
    updateWorkflowFieldMappingAsync,
} from './FieldMappingAsync'

type fieldMappingSlice = {
    loading: boolean
    getWorkflowWiseMappingLoader: boolean
    createLoader: boolean
    getSourceFields: any
    getDestinationFields: any
    createFieldMapping: any
    getWorkflowWiseMapping: any
    getMappedDataByID: any
    deleteLoader: boolean
    deleteMappedData: any
}

const initialState: fieldMappingSlice = {
    loading: false,
    createLoader: false,
    deleteLoader: false,
    getWorkflowWiseMappingLoader: false,
    getSourceFields: [],
    getDestinationFields: [],
    createFieldMapping: [],
    getWorkflowWiseMapping: [],
    getMappedDataByID: [],
    deleteMappedData: [],
}

const fieldMappingSlice = createSlice({
    name: 'fieldMappingSlice',
    initialState,
    reducers: { emptyMappedFields: () => initialState },
    //Pending Statements:
    extraReducers: (builder) => {
        builder.addMatcher(isAnyOf(
            getSourcePlatformFieldsAsync.pending,
            getDestinationPlatformFieldsAsync.pending,
            getMappedDataByIDAsync.pending,
        ), (state, action) => {
            state.loading = true
        })
        builder.addMatcher(isAnyOf(
            createWorkflowFieldMappingAsync.pending,
        ), (state, action) => {
            state.createLoader = true
        })
        builder.addMatcher(isAnyOf(
            getWorkflowWiseMappingAsync.pending,
        ), (state, action) => {
            state.getWorkflowWiseMappingLoader = true
        })
        //Success Statements:
        builder.addMatcher(isAnyOf(getSourcePlatformFieldsAsync.fulfilled), (state, action) => {
            state.loading = false
            state.getSourceFields = action.payload
        })
        builder.addMatcher(isAnyOf(getDestinationPlatformFieldsAsync.fulfilled), (state, action) => {
            state.loading = false
            state.getDestinationFields = action.payload
        })
        builder.addMatcher(isAnyOf(createWorkflowFieldMappingAsync.fulfilled), (state, action) => {
            state.createLoader = false
            state.createFieldMapping = action.payload
        })
        builder.addMatcher(isAnyOf(getWorkflowWiseMappingAsync.fulfilled), (state, action) => {
            state.getWorkflowWiseMappingLoader = false
            state.getWorkflowWiseMapping = action.payload
        })
        builder.addMatcher(isAnyOf(getMappedDataByIDAsync.fulfilled), (state, action) => {
            state.loading = false
            state.getMappedDataByID = action.payload
        })
        // Reject Statements:
        builder.addMatcher(isAnyOf(
            getSourcePlatformFieldsAsync.rejected,
            getDestinationPlatformFieldsAsync.rejected,
            getMappedDataByIDAsync.rejected,
        ), (state, action) => {
            state.loading = false
        })
        builder.addMatcher(isAnyOf(
            createWorkflowFieldMappingAsync.rejected,
        ), (state, action) => {
            state.createLoader = false
            state.createFieldMapping = action.payload
        })
        builder.addMatcher(isAnyOf(
            getWorkflowWiseMappingAsync.rejected,
        ), (state, action) => {
            state.getWorkflowWiseMappingLoader = false
        })
    },
})

export const { emptyMappedFields } = fieldMappingSlice.actions;
export default fieldMappingSlice.reducer;
