import React, {FC, useEffect, useState} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {ToastContainer, toast} from 'react-toastify'
import {Button} from 'react-bootstrap'
import InviteUserModal from './components/InviteUserModal'
import {KTIcon} from '../../../_metronic/helpers'
import EmptyItemTemplate from '../../../utils/EmptyItemTemplate'
import { useAppDispatch, useAppSelector } from 'src/Redux/Store/hooks'
import { getInvitedUsersAsync } from 'src/Redux/Slices/inviteUsers/inviteUserAsync'
import InvitedUserTable from './components/InvitedUserTable'
import Loader from '../loader/Loader'

const InviteUserPage = () => {
  const [inviteUserModalVisible, setInviteUserModalVisible] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const { data } = useAppSelector(state=>state.subdomain)
  const { getInvitedUserLoader } = useAppSelector(state=>state.inviteUser)

  useEffect(() => {
      dispatch(getInvitedUsersAsync({organization_id: data.id }))    
  }, [])

  if(getInvitedUserLoader) return <Loader/>

  return (
    <>
      {/* <PageTitle breadcrumbs={[]}>Invited Users</PageTitle> */}
      <div className='card h-50 p-7 mt-10'>
        <div className='d-flex justify-content-between align-items-center '>
          <h4 className='fw-bolder m-0 text-dark align-self-end'>Invited Users</h4>
          <Button variant='primary' className='py-1' size='sm' onClick={() => setInviteUserModalVisible(true)}>
            <span className='d-flex flex-row' >
              <h1 className='text-white mb-0' >+</h1>
              <p className='mb-0 align-self-center ms-2' >Invite User</p>
            </span>
          </Button>
        </div>
        <div className='separator my-7'></div>
        <div>
          <InvitedUserTable tableIndexMultiple={0} />
        </div>
        <ToastContainer />
        <InviteUserModal
          visible={inviteUserModalVisible}
          handleClose={() => setInviteUserModalVisible(false)}
        />
      </div>
    </>
  )
}

export default InviteUserPage
