import React, { Fragment, useState } from 'react'
import { Card, Stack } from 'react-bootstrap'
import classes from './Profile.module.css'
import { userDetails_values } from './utils'
import EditModal from './ChangePassword/EditModal'

function UserProfile() {
    let userDetails: string | null = localStorage.getItem('user_details')
    let userDetails_prs: userDetails_values | null = userDetails ? JSON.parse(userDetails) : null
    // console.log('userDetails_prs', userDetails_prs)
    const [isOpen, setIsOpen] = useState(false)
    const [editKey, setEditKey] = useState<string>('')
    const handleEdit = (event: React.MouseEvent<HTMLSpanElement>, editKey: string) => {
        setEditKey(editKey)
        setIsOpen(true)
    }
    return (
        <Fragment>
            <Card>
                <Card.Header className={classes.editProfile_cardHeader}>Profile</Card.Header>
                <Card.Body>
                    <Stack className={classes.editProfile_card_div}>
                        <Stack className={classes.editProfile_card_detailName}>
                            <p>Full Name</p>
                            <p>E-Mail</p>
                            <p>Password</p>
                        </Stack>
                        <Stack className={classes.editProfile_card_detailValues}>
                            <p>:&nbsp; {(userDetails_prs?.first_name && userDetails_prs?.last_name) ? (userDetails_prs?.first_name + " " + userDetails_prs?.last_name) : userDetails_prs?.username} </p>
                            <p>:&nbsp; {userDetails_prs?.email}</p>
                            <p>:&nbsp; <span style={{ position: 'relative', top: '4px' }}>**************</span>
                                <span
                                    onClick={(event) => handleEdit(event, 'Password')}
                                    style={{ position: 'relative', left: '10px', top: '-4px' }}>
                                    <i className="bi bi-pencil-fill fs-3 text-hover-primary cursor-pointer"></i>
                                </span>
                            </p>
                        </Stack>
                    </Stack>
                </Card.Body>
            </Card>
            <EditModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                editKey={editKey}
                userDetails_prs={userDetails_prs}
            />
        </Fragment>
    )
}

export default UserProfile
