import React, { Fragment, useState, useEffect } from 'react'
import CloseButton from '../components/CloseButton'
import FieldDefault from '../CreateMapping/FieldSelectionText'
import FieldSelection from '../CreateMapping/FieldSelectionDropdown'
import classes from '../components/FieldMapping.module.css'
import { Modal, Form as BootstrapForm, Container } from 'react-bootstrap'

type createMappingProps = {
    modalShow: boolean
    setModalShow: (data: boolean) => void
    workflowID: number
    sourceplatform_ID: number
    destinationplatform_ID: number
}
function CreateMapping(props: createMappingProps) {
    const { modalShow, setModalShow, workflowID, sourceplatform_ID, destinationplatform_ID } = props
    const [fieldToFieldMapping, setFieldToFieldMapping] = useState(true)
    return (
        <Container>
            <Modal
                show={modalShow}
                onHide={() => setModalShow(true)}
                size='xl'
                centered
                style={{ backgroundColor: '#0007' }}
            >
                <div
                    onClick={() => {
                        setModalShow(false)
                    }}
                >
                    <CloseButton />
                </div>

                {/* Main Modal Body Start*/}
                <div className={classes.switchSection}>
                    <p
                        className={`mt-10 fw-bold fs-5 text-center ${!fieldToFieldMapping ? 'text-primary' : 'text-muted'
                            }`}
                    >
                        Field To Default Text
                    </p>
                    {/* <Form className='text-primary'>
                            <Form.Check
                                type='switch'
                                id='custom-switch'
                                checked={fieldToFieldMapping}
                                onChange={(event) => setFieldToFieldMapping(event.target.checked)}
                            />
                        </Form> */}
                    <label className={`${classes.switch} ${fieldToFieldMapping ? classes.round : ''}`}>
                        <input type="checkbox" checked={fieldToFieldMapping} onChange={(event) => setFieldToFieldMapping(event.target.checked)} />
                        <span className={classes.slider}></span>
                    </label>
                    <p
                        className={`mt-10 fw-bold fs-5 text-center ${fieldToFieldMapping ? 'text-primary' : 'text-muted'
                            }`}
                    >
                        Field To Field Mapping
                    </p>
                    {/* Test Switch Start------------- */}

                </div>
                {fieldToFieldMapping ? (
                    <FieldSelection
                        workflowID={workflowID}
                        sourceplatform_ID={sourceplatform_ID}
                        destinationplatform_ID={destinationplatform_ID}
                        setModalShow={setModalShow}
                    />
                ) : (
                    <FieldDefault
                        workflowID={workflowID}
                        sourceplatform_ID={sourceplatform_ID}
                        destinationplatform_ID={destinationplatform_ID}
                        setModalShow={setModalShow}
                    />
                )}
            </Modal >
        </Container >
    )
}

export default CreateMapping
