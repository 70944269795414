import { AxiosClient } from '../../AxiosClient/axiosClient'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const platformProcessPendingAsync = createAsyncThunk(
    'platform-init-process/platformProcessPending',
    async (payload: { workflodID: number}, toolkit) => {
        return await AxiosClient(
            'GET', `/platform-init-process?workflow_id=${payload.workflodID}&status=PENDING`,
            payload, toolkit
        )
    }
);
