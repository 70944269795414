import React, { Fragment, useState, useEffect } from 'react'
import { Form } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { ConfirmationModal } from '../../../../components/ConfirmationModal'
import { useAppDispatch, useAppSelector } from '../../../../../../../Redux/Store/hooks'
import { emptyOrderDetails } from '../../../../../../../Redux/Slices/orderDetails/orderDetailsSlice'
import { platformOrderEnabilityAsync } from '../../../../../../../Redux/Slices/orderDetails/orderDetailsAsync'

type selectActionProps = {
    workflowID: number
    totalRows: number
    selectedRowID: number[]
    allRowChecked: boolean
    setCheckedAll: (status: boolean) => void
    setAllRowChecked: (status: boolean) => void
    setCheckedValue: (data: number[] | []) => void
}
type payload_val = {
    action_str: string,
    order_type: string,
    workflowID: number,
    allSelected: boolean,
    platform_order_ids: number[] | [],
}


function SelectAction(props: selectActionProps) {
    const { workflowID, totalRows, selectedRowID, allRowChecked, setCheckedAll, setCheckedValue, setAllRowChecked } = props
    
    const dispatch = useAppDispatch()
    const [displayMessage, setDisplayMessage] = useState<string>('')
    const [selectedEnability, setSelectedEnability] = useState<string>('')
    const [showModalConfirmationModal, setShowModalConfirmationModal] = useState(false)
    const { loading, platformOrderEnability } = useAppSelector((state) => state.orderStatus)

    const handleSelectEvent = (event: React.ChangeEvent<HTMLSelectElement>) => {
        let inputValue = event.target.value
        setSelectedEnability(inputValue)
        if (selectedRowID?.length && (inputValue === 'isEnabled' || inputValue === 'isDisabled')) {
            setDisplayMessage(
                `Are You Sure Want To ${inputValue === 'isEnabled' ? 'Enable' : 'Disable'} Selected Orders.`
            )
            setShowModalConfirmationModal(true)
        }
        if (selectedRowID?.length == 0 && !allRowChecked) {
            setDisplayMessage('Please Select Atleast One Order Row!')
            setShowModalConfirmationModal(true)
            setSelectedEnability('')
        }
        if (allRowChecked && (inputValue === 'isEnabled' || inputValue === 'isDisabled')) {
            setDisplayMessage(`Are You Sure, You Want To ${inputValue === 'isEnabled' ? 'Enable' : 'Disable'} All ${totalRows} Orders.`)
            setShowModalConfirmationModal(true)
        }
    }

    const enabilityAsync = () => {
        let payload = {} as payload_val;
        if (selectedRowID?.length !== 0 && !allRowChecked) {
            payload = {
                workflowID: workflowID,
                order_type: 'SALESORDER',
                allSelected: allRowChecked,
                action_str: selectedEnability,
                platform_order_ids: selectedRowID,
            }
        }
        if (allRowChecked) {
            payload = {
                workflowID: workflowID,
                platform_order_ids: [],
                order_type: 'SALESORDER',
                allSelected: allRowChecked,
                action_str: selectedEnability,
            }
        }
        dispatch(platformOrderEnabilityAsync(payload) as any)
    }

    useEffect(() => {
        if (platformOrderEnability.status_code >= 200 && platformOrderEnability.status_code <= 300) {
            toast.success(platformOrderEnability.message)
        }
        dispatch(emptyOrderDetails())
    }, [platformOrderEnability])

    useEffect(() => {
        if (!showModalConfirmationModal) {
            setSelectedEnability('')
            setAllRowChecked(false)
            setCheckedAll(false)
            setCheckedValue([])
        }
    }, [showModalConfirmationModal])

    return (
        <Fragment>
            <Form.Select
                value={selectedEnability}
                style={{ width: '20%', minWidth: '150px', backgroundColor: 'white' }}
                onChange={(event) => handleSelectEvent(event)}
            >
                <option value=''>Actions</option>
                <option value='isEnabled'>Enable Selected Orders</option>
                <option value='isDisabled'>Disable Selected Orders</option>
            </Form.Select>

            <ConfirmationModal
                icon='/media/icons/duotune/general/gen044.svg'
                title={displayMessage}
                onSave={enabilityAsync}
                showModal={showModalConfirmationModal}
                setShowModal={setShowModalConfirmationModal}
            />
        </Fragment>
    )
}

export default SelectAction
