import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import { allWorkflowCombunationAsync } from './allWorkflowsAsync'

type allWorkflowSlice = {
    loading: boolean
    all_WorkflowCombinations : any
}

const initialState: allWorkflowSlice = {
    loading: false,
    all_WorkflowCombinations: [],
}

const allWorkflowSlice = createSlice({
    name: 'allWorkflowSlice',
    initialState,
    reducers: { emptyWorkflowCombinations: () => initialState },
    
    //Pending Statements:
    extraReducers: (builder) => {
        builder.addMatcher(isAnyOf(
            allWorkflowCombunationAsync.pending,
        ), (state, action) => {
            state.loading = true
        })

        //Success Statements:
        builder.addMatcher(isAnyOf(allWorkflowCombunationAsync.fulfilled), (state, action) => {
            state.loading = false
            state.all_WorkflowCombinations = action.payload
        })
       
        // Reject Statements:
        builder.addMatcher(isAnyOf(
            allWorkflowCombunationAsync.rejected,
        ), (state, action) => {
            state.loading = false
        })
    },
})

export const { emptyWorkflowCombinations } = allWorkflowSlice.actions;
export default allWorkflowSlice.reducer;
