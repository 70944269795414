/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import { KTSVG } from '../../../helpers'
import { Row, Col } from 'react-bootstrap'
type Props = {
  className: string
  display_name: string
  type: string
  Logo: string
  isActive: any
  showActiveStatus: any
  onDelete: () => void
  isConnected?: boolean
  availablePlatformID?: number
  platformID?: number
  oauthURL?: any
}

const StatisticsWidget7: React.FC<Props> = ({
  className,
  display_name,
  type,
  Logo,
  isActive,
  showActiveStatus,
  onDelete,
  availablePlatformID,
  platformID,
  isConnected,
  oauthURL
}) => {


  const handleSaveID = (a_id: any, p_id: any) => {
    if (a_id && p_id) {
      let availablePlatformID: any = { available_platform_id: p_id, platform_id: a_id }
      localStorage.setItem('processOauth_ID', JSON.stringify(availablePlatformID))
    }
  }
  return (
    <div className={`card position-relative  ${className}`}>
      {/* begin::Body */}
      <div className='card-body align-items-center p-7   '>
        {showActiveStatus ? (
          <div className='d-flex justify-content-between'>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
              {isActive &&
                <div className={`bg-${isActive ? 'success' : 'secondary'} rounded-circle border border-3 border-white h-15px w-15px`}></div>
              }
              {
                !isConnected &&
                <span
                  style={{
                    display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'baseline',
                    position: 'absolute', top: '0', left: '15px'
                  }}
                >
                  <b className="bi bi-exclamation-circle-fill fs-2 text-warning"></b>
                  <span
                    onClick={() => handleSaveID(availablePlatformID, platformID)}
                    style={{
                      color: 'black', fontSize: '15px',
                      borderRadius: '5px',
                      padding: '2px 5px',
                      cursor: 'pointer',
                    }}>
                    <a href={oauthURL} target='_blank' style={{ color: 'black' }}>
                      Complete Authentication</a></span>
                </span>
              }
            </div>

            <div className=''>
              <div
                onClick={onDelete}
                className=' cursor-pointer'

              >
                <KTSVG
                  path='/media/icons/duotune/abstract/abs012.svg'
                  className=' svg-icon-2x text-danger'
                />
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
        <Row className=' d-flex'>
          <Col xs={6} lg={7} xxl={8}>
            <div className='d-flex flex-column flex-grow-1 py-2 py-lg-9 me-2 w-100'>
              <div className='fw-bolder  fs-4 mb-2 ' style={{ fontWeight: 'bold', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{display_name}</div>

              {/* <span className='fw-bold fs-5' dangerouslySetInnerHTML={{ __html: type }}></span> */}
            </div>
          </Col>
          <Col xs={6} lg={5} xxl={4} style={{ display: 'flex', alignItems: 'flex-end' }}>
            <div>
              <img src={Logo} alt='' style={{ width: '100%' }} />
            </div>
          </Col>
        </Row>
      </div>
      {/* end::Body */}
    </div>
  )
}

export { StatisticsWidget7 }
