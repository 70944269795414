import React, { Fragment } from 'react'
import { OrderData_type } from './utils'
import { Row, Col, Card } from 'react-bootstrap'
import ReactReadMoreReadLess from 'react-read-more-read-less'
import classes from './orderDetail.module.css'

type orderLineItemProps = {
  OrderData: OrderData_type
}

function OrderLineItem({ OrderData }: orderLineItemProps) {
  return (
    <Fragment>
      <Card>
        <Card.Header className={classes.card_header}>Line Items</Card.Header>
        <Row className='ps-10 pe-8 mt-3'>
          <Col xs={8}>
            <Card.Subtitle className={classes.card_subHeading_product}>
              Product Details
            </Card.Subtitle>
          </Col>
          <Col xs={2}>
            <Card.Subtitle className={classes.card_subHeading}>SKU</Card.Subtitle>
          </Col>
          <Col xs={2}>
            <Card.Subtitle className={classes.card_subHeading}>Quantity</Card.Subtitle>
          </Col>
        </Row>

        {OrderData?.line_items?.map((items: any) => (
          <Card.Body key={items.id} className='border-bottom border-secondary'>
            <Row>
              <Col xs={8}>
                <Card.Text className={classes.card_text_productDetail}>
                  <ReactReadMoreReadLess
                    className='react-read-more-read-less'
                    charLimit={50}
                    readMoreText={'▼'}
                    readLessText={'▲'}
                  >
                    {items.name}
                  </ReactReadMoreReadLess>
                </Card.Text>
              </Col>

              <Col xs={2}>
                <Card.Text className={classes.card_text}>{items.sku}</Card.Text>
              </Col>

              <Col xs={2}>
                <Card.Text className={classes.card_text}>{items.quantity}</Card.Text>
              </Col>
            </Row>
          </Card.Body>
        ))}
      </Card>
    </Fragment>
  )
}

export default OrderLineItem
