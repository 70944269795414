/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { getUserByToken, register } from '../core/_requests'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { PasswordMeterComponent } from '../../../../_metronic/assets/ts/components'
import { useAuth } from '../core/Auth'
import { loginApi, signupApi } from '../../../redux/slices/AuthSlices'
import { useDispatch } from 'react-redux'
import { Button } from 'react-bootstrap'
import GoogleIcon from './GoogleIcon'
import { toast } from 'react-toastify'
import { TokenResponse, useGoogleLogin } from '@react-oauth/google'
import { REGISTRATION_TYPE, USER_ROLE } from 'src/utils/constants'
import ReCAPTCHA from "react-google-recaptcha";
import { useAppSelector } from 'src/Redux/Store/hooks'
const initialValues = {
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  acceptTerms: true,
}

const registrationSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('First Name is required'),
  last_name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Last Name is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  // acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
})


export function Registration() {
  const dispatch = useDispatch<any>()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false)
  const { saveAuth, setCurrentUser } = useAuth()
  const [captchaValidation, setCaptchaValidation] = useState(false)
  const subdomaindata = useAppSelector(state=>state.subdomain)

  async function getUserDetail(userData: TokenResponse) {
    setLoading(true)
    const userInfo: any = await new Promise((resolve) => {
      const xhr = new XMLHttpRequest()

      xhr.open('GET', `https://www.googleapis.com/oauth2/v3/userinfo`)
      xhr.setRequestHeader('Authorization', `Bearer ${userData.access_token}`)
      xhr.onload = function () {
        if (this.status >= 200 && this.status < 300) resolve(JSON.parse(this.responseText))
        else resolve({ err: '404' })
      }
      xhr.send()
    })

    let orgId = subdomaindata.data.id

    if (userInfo) {
      dispatch(loginApi({ email: userInfo?.email, password: userInfo.sub })).then((response: any) => {
        // console.log('response', response)
        if (response.payload.error.response.data.status_code == 400) {
          dispatch(signupApi({
            first_name: userInfo?.given_name,
            last_name: userInfo?.family_name,
            email: userInfo?.email,  //userInfo?.email,
            password: userInfo?.sub,
            organization_id: orgId,
            user_type: USER_ROLE["user"],
            request_via: REGISTRATION_TYPE['google_oauth']
          })

          ).then((res) => {
            dispatch(loginApi({ email: userInfo?.email, password: userInfo.sub }))
            setLoading(false)
          })
            .catch((er) => {
              console.log('er', er)
            })

          setLoading(false)
        } else {
          setLoading(false)
        }

      }).catch((err) => {
        console.log("login error -------> ", err);
        setLoading(false)
      })
    }
  }


  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      getUserDetail(codeResponse)
    },
    onError: (error) => console.log('Login Failed:', error),
    onNonOAuthError: (err) => {
      console.log('err', err)
    },
    error_callback(nonOAuthError) {
      toast.info("Authentication process dismissed")
    },
  });

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        // let orgId = searchParams.get("organization")
        let orgId = subdomaindata.data.id

        dispatch(signupApi({ ...values, organization_id: orgId })).then((response: any) => {
          if ('error' in response.payload) {
            if (response.payload.error && 'massage' in response.payload.error.response.data) {
              setLoading(false)
              setStatus(response.payload.error.response.data.massage)
            } else {
              setStatus('Unidentified error occurred. Please try again after sometime')
            }
          } else if (
            'status' in response.payload &&
            response.payload.status > 200 &&
            response.payload.status < 299
          ) {
            setStatus(
              'User created successfully. You can login using the credentials used to signup.'
            )
            dispatch(loginApi(values)).then((response: any) => {
              if (response.payload.error.response.data.status_code == 400) {
                setStatus(
                  'User created successfully. Error encountered while logging in. Redirection to login page.'
                )
                // toast.error(response.payload.error.response.data.massage)
                setLoading(false)
                navigate('/auth')
              } else {
                setStatus('Logged in successfully')
                // toast.success('Logged in successfully')
                setLoading(false)
              }
            })
          }
        })
      } catch (error) {
        console.error(error)
      }
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  // Re-Captcha function:::
  function onChange(value) {
    console.log("Captcha value:", value);
    if (value) {
      setCaptchaValidation(true)
    }
  }
  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>Sign Up</h1>
        {/* end::Title */}
      </div>
      {/* end::Heading */}

      {formik.status && (
        <div className='mb-lg-15'>
          <div className='alert-text font-weight-bold text-danger'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group Firstname */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>First Name</label>
        <input
          placeholder='First Name'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('first_name')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.first_name && formik.errors.first_name,
            },
            {
              'is-valid': formik.touched.first_name && !formik.errors.first_name,
            }
          )}
        />
        {formik.touched.first_name && formik.errors.first_name && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.first_name}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Lastname */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Last Name</label>
        <input
          placeholder='Last Name'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('last_name')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.last_name && formik.errors.last_name,
            },
            {
              'is-valid': formik.touched.last_name && !formik.errors.last_name,
            }
          )}
        />
        {formik.touched.last_name && formik.errors.last_name && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.last_name}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Email */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Email</label>
        <input
          placeholder='Email'
          type='email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Password */}
      <div className='fv-row mb-8' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>Password</label>
          <div className='position-relative mb-3'>
            <input
              type='password'
              placeholder='Password'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          {/* begin::Meter */}
          <div
            className='d-flex align-items-center mb-3'
            data-kt-password-meter-control='highlight'
          >
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
          </div>
          {/* end::Meter */}
        </div>
        <div className='text-muted'>
          Use 8 or more characters with a mix of letters, numbers & symbols.
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      {/* <div className='fv-row mb-8'>
        <label className='form-check form-check-inline' htmlFor='kt_login_toc_agree'>
          <input
            className='form-check-input'
            type='checkbox'
            id='kt_login_toc_agree'
            {...formik.getFieldProps('acceptTerms')}
          />
          <span>
            I Accept the{' '}
            <a
              href='https://keenthemes.com/metronic/?page=faq'
              target='_blank'
              className='ms-1 link-primary'
            >
              Terms
            </a>
            .
          </span>
        </label>
        {formik.touched.acceptTerms && formik.errors.acceptTerms && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.acceptTerms}</span>
            </div>
          </div>
        )}
      </div> */}
      <div className='d-flex align-items-center justify-content-center'>
        <p style={{ fontSize: '12.5px' }}>By signing up, you agree to our <span className='text-primary fw-bold'><a href="https://constacloud.com/tou.html" target='_blank'>Terms and Conditions</a></span> and <span className='text-primary fw-bold'><a href="https://constacloud.com/privacy-policy.html" target='_blank'>Privacy Policy</a></span></p>
      </div>
      {/* end::Form group */}

      {/* Google Re-Captcha */}
      <ReCAPTCHA
        sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
        size="normal"
        onChange={onChange}
      />
      {/* begin::Form group */}
      <div className='text-center'>
        <button type='submit' id='kt_sign_up_submit' className='btn btn-lg btn-primary w-100 mb-5 mt-5'
          disabled={Object.keys(formik.errors).length > 0 || !captchaValidation}
        >
          {!loading && <span className='indicator-label'>Submit</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <div className='mb-4 w-100 d-flex justify-content-center'  >
          <Button
            variant='secondary'
            className='w-100'
            onClick={() => googleLogin()}

          >
            <span className='fw-bold fs-md-6' style={{ fontSize: "12px" }} >
              <GoogleIcon /> &nbsp; Sign up with google
            </span>
          </Button>
        </div>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-1'
          >
            Cancel
          </button>
        </Link>
      </div>
      {/* end::Form group */}
    </form>
  )
}
