import * as Yup from 'yup'


export type subCombination = { parent_ID: string, child_ID: string }
export type combinationPayload = {
    isDefault: boolean,
    source_platform_data: string | null,
    destination_platform_data: string | null,
    source_platform_data_str: string | null,
    destination_platform_data_str: string | null,
}

export type combination = {
    isDefault: boolean,
    source_platform_data: string,
    destination_platform_data: string,
    source_platform_data_str: any,
    destination_platform_data_str: any,
    sourceChild_array: subCombination[] | [],
    destinationChild_array: subCombination[] | [],
}

export type formikInitialValues = {
    map_internally: boolean
    combination: combination[]
    default_destinationOption: {
        isDefault: boolean,
        source_platform_data: null,
        destination_platform_data: any,
        destination_platform_data_str: any,
    }
}

export const initialState: formikInitialValues = {
    map_internally: false,
    combination: [{
        isDefault: false,
        source_platform_data: '',
        destination_platform_data: '',
        source_platform_data_str: null,
        destination_platform_data_str: null,
        sourceChild_array: [],
        destinationChild_array: [],
    }],
    default_destinationOption: {
        isDefault: true,
        source_platform_data: null,
        destination_platform_data: null,
        destination_platform_data_str: null,
    }
}






// ---------------------------------------------------------------------------------------//
// Case 01 : When Only Destination Side and Input Type is 'Select' 
export const destinationSelect = Yup.object({
    combination: Yup.array()
        .of(
            Yup.object().shape({
                destination_platform_data: Yup.string().required('Required'),
            })
        )
        .required('') // these constraints are shown if and only if inner constraints are satisfied
})

// Case 02 : When Only Destination Side and Input Type is 'Select' 
export const destinationString = Yup.object({
    combination: Yup.array()
        .of(
            Yup.object().shape({
                destination_platform_data: Yup.string().required('Required'),
            })
        )
        .required('') // these constraints are shown if and only if inner constraints are satisfied
})

//:::Case 03 When Conbination: Source Input Type Is 'SELECT' And Destination Input Type Is 'TEXT'
export const sourceSelect_destinationText = Yup.object({
    // default_destinationPlatformData_str: Yup.string().required('Destination Option Is Required'),
    combination: Yup.array()
        .of(
            Yup.object().shape({
                source_platform_data: Yup.string().required('Required'),
                destination_platform_data_str: Yup.string().required('Required'),
            })
        )
        .required('') // these constraints are shown if and only if inner constraints are satisfied
})

//:::Case 04 When Conbination: Source Input Type Is 'TEXT' And Destination Input Type Is 'SELECT'
export const sourceText_destinationSelect = Yup.object({
    // default_destinationPlatformData: Yup.string().required('Destination Option Is Required'),
    combination: Yup.array()
        .of(
            Yup.object().shape({
                source_platform_data_str: Yup.string().required('Required'),
                destination_platform_data: Yup.string().required('Required'),
            })
        )
        .required('') // these constraints are shown if and only if inner constraints are satisfied
})

//:::Case 05  When Conbination: Source And Destination Input Type Are 'TEXT'
export const sourceDestinationString = Yup.object({
    // default_destinationPlatformData_str: Yup.string().required('Destination Option Is Required'),
    combination: Yup.array()
        .of(
            Yup.object().shape({
                source_platform_data_str: Yup.string().required('Required'),
                destination_platform_data_str: Yup.string().required('Required'),
            })
        )
        .required('') // these constraints are shown if and only if inner constraints are satisfied
})

//:::Case 06 When Conbination: Source And Destination Input Type Are 'SELECT'
export const sourceDestinationSelect = Yup.object({
    // default_destinationPlatformData: Yup.string().required('Destination Option Is Required'),
    combination: Yup.array()
        .of(
            Yup.object().shape({
                source_platform_data: Yup.string().required('Required'),
                destination_platform_data: Yup.string().required('Required'),
            })
        )
        .required('') // these constraints are shown if and only if inner constraints are satisfied
})

