import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import AdminDashboard from '../pages/dashboard/AdminDashboard'
import AdminPlatformPage from '../modules/platform/AdminPlatformPage'
import Organizations from '../modules/superadmin/organization/Organizations'
import Users from '../modules/superadmin/users/Users'
import AvailableWorkflows from '../modules/superadmin/available-workflows/AvailableWorkflows'
import MyProfile from '../modules/profile/MyProfile'

const AdminRoutes = () => {
  return (
    <Routes>
      <Route path='superadmin/' element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route index element={<Navigate to='/superadmin/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<AdminDashboard />} />
        <Route path='list-organizations' element={<Organizations />} />
        <Route path='list-users' element={<Users />} />
        <Route path='workflows-available' element={<AvailableWorkflows />} />

        {/* Lazy Modules */}

        <Route
          path='list-platforms'
          element={
            <SuspensedView>
              <AdminPlatformPage />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path='/superadmin/*' element={<Navigate to='/error/404' />} />
        <Route
          path='profile/*'
          element={
            <SuspensedView>
              <MyProfile />
            </SuspensedView>
          }
        />
      </Route>
      <Route path='/*' element={<Navigate to='/error/404' />} />
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {AdminRoutes}
