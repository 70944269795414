import React, { Fragment, useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Stack } from 'react-bootstrap'
import { formInitialStateType } from '../utils'
import { FormikProps } from 'formik'
import {
  createWorkFlowAsync,
  destinationPlatformProcessAsync,
  getDestinationPlatformSettingAsync,
} from '../../../../../../Redux/Slices/workflowFormSlice/workflowFormAsync'
import ScreenWidth from '../../../../widgets/components/ScreenWidth'
import classes from '../CreateWorkflowCSS.module.css'
import {
  ButtonComponent,
  BooleanComponent,
  ContactNumberComponent,
  EmailComponent,
  ImageComponent,
  IntegerComponent,
  MultipleSelectComponent,
  SelectComponent,
  TextAreaComponent,
  TextComponent,
} from './InputComponents'

import { useAppDispatch, useAppSelector } from 'src/Redux/Store/hooks'
import axios from 'axios'

type SettingDestinationProps = {
  Formik: FormikProps<formInitialStateType>
  workflowId: string
  applySettings: boolean
  action_id: number
  dataCollectorFun_destination: any
  setDestinationLoader: (status: boolean) => void
  destinationName: string
  shortCode: string[] | []
}
type rowSettingsProps = {
  name: string
  display_name: string
  input_type: string
  is_required: boolean
  child: any
  available_platform_setting_options: any
}
type dataCollectionArrayProps = {
  name: string
  value: string | boolean
  child: { name: string; value: string | number | boolean }[]
}

function SettingDestination(props: SettingDestinationProps) {
  const { Formik, workflowId, applySettings, action_id, dataCollectorFun_destination, setDestinationLoader, destinationName, shortCode } = props
  const dispatch = useAppDispatch()
  const propRef = useRef<{ propd_actionID: number | null }>({ propd_actionID: null })
  const [screenWidth] = ScreenWidth()
  const [rawSettings, setRawSettings] = useState<rowSettingsProps[]>([])
  const [dataCollectionArray, setDataCollectionArray] = useState<dataCollectionArrayProps[]>([])

  const { getPlatformSetting_destination } = useSelector(
    (state: {
      workflowForm: {
        getPlatformSetting_destination: { data: rowSettingsProps[] }
      }
    }) => state.workflowForm
  )

  const [flowSettings, setFlowSettings] = useState<rowSettingsProps[] | []>([])
  //Fetching Destination Platform Settings :::
  const getSourceSetting = async (action_id: number) => {
    const platform_id = Number(Formik.values.destinationPlatform.dp_parentId)
    setDestinationLoader(true)
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/availableplatform-setting?action_id=${action_id}&platform_id=${platform_id}`
      )
      setFlowSettings(response?.data?.data)
    }
    catch (error) { console.log('Somthing Went Wrong', error) }
    finally { setDestinationLoader(false) }
  }

  useEffect(() => {
    if (action_id) {
      if (JSON.stringify(action_id) !== JSON.stringify(propRef.current.propd_actionID)) {
        getSourceSetting(action_id)
        propRef.current.propd_actionID = action_id;
      }
    }
  }, [action_id])

  const dataCollectorFun = (data: {
    name: string
    value: string | boolean
    child: { name: string; value: string | number | boolean }[]
  }) => {
    setDataCollectionArray((prevValue) => {
      let index = prevValue?.findIndex((item: Record<string, any>) => item?.name === data?.name)
      if (index === -1) {
        return [...prevValue, data]
      }
      if (index !== -1) {
        let updatedArray = [...prevValue]
        updatedArray[index] = { name: data?.name, value: data?.value, child: data?.child }
        return updatedArray
      } else {
        return []
      }
    })
  }

  const payload_destination = {
    platform: Formik.values.destinationPlatform.dp_parentId,
    workflow: workflowId,
    settings: [...dataCollectionArray]
  }

  // useEffect(() => {
  //   if (applySettings) {
  //     // console.log('payload_destination', payload_destination)
  //     dispatch(createWorkFlowAsync(payload_destination) as any)
  //     dispatch(destinationPlatformProcessAsync(
  //       { platform: Formik.values.destinationPlatform.dp_parentId, workflow: workflowId }) as any)
  //   }
  // }, [applySettings])

  // console.log('dataCollectionArray_destination', dataCollectionArray)

  return (
    <Container>
      {workflowId ? (
        <Stack className={(screenWidth > 767) ? classes.hide : ''}>
          <p className='fw-bold text-muted text-hover-primary mb-n2 mt-7'>{destinationName} Setting</p>
        </Stack>
      ) : null}

      {flowSettings.map((item, index) => {
        return (
          <Fragment key={index}>
            {item?.input_type === 'address' ? (
              <TextAreaComponent
                settingOptions={item?.available_platform_setting_options}
                dataCollectorFun={dataCollectorFun_destination}
                // dataCollectorFun={dataCollectorFun}
                display_name={item?.display_name}
                is_required={item?.is_required}
                applySettings={applySettings}
                childData={item?.child}
                api_name={item?.name}
                shortCode={shortCode}
                displayShortcodes={item?.displayShortcodes}
                description={item?.description}
              />
            ) : item?.input_type === 'boolean' ? (
              <BooleanComponent
                settingOptions={item?.available_platform_setting_options}
                dataCollectorFun={dataCollectorFun_destination}
                // dataCollectorFun={dataCollectorFun}
                display_name={item?.display_name}
                is_required={item?.is_required}
                applySettings={applySettings}
                childData={item?.child}
                api_name={item?.name}
                shortCode={shortCode}
                displayShortcodes={item?.displayShortcodes}
                description={item?.description}
              />
            ) : item?.input_type === 'button' ? (
              <ButtonComponent
                settingOptions={item?.available_platform_setting_options}
                dataCollectorFun={dataCollectorFun_destination}
                // dataCollectorFun={dataCollectorFun}
                display_name={item?.display_name}
                is_required={item?.is_required}
                applySettings={applySettings}
                childData={item?.child}
                api_name={item?.name}
                shortCode={shortCode}
                displayShortcodes={item?.displayShortcodes}
                description={item?.description}
              />
            ) : item?.input_type === 'email' ? (
              <EmailComponent
                settingOptions={item?.available_platform_setting_options}
                dataCollectorFun={dataCollectorFun_destination}
                // dataCollectorFun={dataCollectorFun}
                display_name={item?.display_name}
                is_required={item?.is_required}
                applySettings={applySettings}
                childData={item?.child}
                api_name={item?.name}
                shortCode={shortCode}
                displayShortcodes={item?.displayShortcodes}
                description={item?.description}
              />
            ) : item?.input_type === 'image' ? (
              <ImageComponent
                settingOptions={item?.available_platform_setting_options}
                dataCollectorFun={dataCollectorFun_destination}
                // dataCollectorFun={dataCollectorFun}
                display_name={item?.display_name}
                is_required={item?.is_required}
                applySettings={applySettings}
                childData={item?.child}
                api_name={item?.name}
                shortCode={shortCode}
                displayShortcodes={item?.displayShortcodes}
                description={item?.description}
              />
            ) : item?.input_type === 'integer' ? (
              <IntegerComponent
                settingOptions={item?.available_platform_setting_options}
                dataCollectorFun={dataCollectorFun_destination}
                // dataCollectorFun={dataCollectorFun}
                display_name={item?.display_name}
                is_required={item?.is_required}
                applySettings={applySettings}
                childData={item?.child}
                api_name={item?.name}
                shortCode={shortCode}
                displayShortcodes={item?.displayShortcodes}
                description={item?.description}
              />
            ) : item?.input_type === 'multiselect' ? (
              <MultipleSelectComponent
                settingOptions={item?.available_platform_setting_options}
                dataCollectorFun={dataCollectorFun_destination}
                // dataCollectorFun={dataCollectorFun}
                display_name={item?.display_name}
                is_required={item?.is_required}
                applySettings={applySettings}
                childData={item?.child}
                api_name={item?.name}
                shortCode={shortCode}
                displayShortcodes={item?.displayShortcodes}
                description={item?.description}
              />
            ) : item?.input_type === 'multiline_text' ? (
              <MultipleSelectComponent
                settingOptions={item?.available_platform_setting_options}
                dataCollectorFun={dataCollectorFun_destination}
                // dataCollectorFun={dataCollectorFun}
                display_name={item?.display_name}
                is_required={item?.is_required}
                applySettings={applySettings}
                childData={item?.child}
                api_name={item?.name}
                shortCode={shortCode}
                displayShortcodes={item?.displayShortcodes}
                description={item?.description}
              />
            ) : item?.input_type === 'phone' ? (
              <ContactNumberComponent
                settingOptions={item?.available_platform_setting_options}
                dataCollectorFun={dataCollectorFun_destination}
                // dataCollectorFun={dataCollectorFun}
                display_name={item?.display_name}
                is_required={item?.is_required}
                applySettings={applySettings}
                childData={item?.child}
                api_name={item?.name}
                shortCode={shortCode}
                displayShortcodes={item?.displayShortcodes}
                description={item?.description}
              />
            ) : item?.input_type === 'select' ? (
              <SelectComponent
                settingOptions={item?.available_platform_setting_options}
                dataCollectorFun={dataCollectorFun_destination}
                // dataCollectorFun={dataCollectorFun}
                display_name={item?.display_name}
                is_required={item?.is_required}
                applySettings={applySettings}
                childData={item?.child}
                api_name={item?.name}
                shortCode={shortCode}
                displayShortcodes={item?.displayShortcodes}
                description={item?.description}
              />
            ) : item?.input_type === 'text' ? (
              <TextComponent
                settingOptions={item?.available_platform_setting_options}
                dataCollectorFun={dataCollectorFun_destination}
                // dataCollectorFun={dataCollectorFun}
                display_name={item?.display_name}
                is_required={item?.is_required}
                applySettings={applySettings}
                childData={item?.child}
                api_name={item?.name}
                shortCode={shortCode}
                displayShortcodes={item?.displayShortcodes}
                description={item?.description}
              />
            ) : null}
          </Fragment>
        )
      })}
    </Container>
  )
}

export default SettingDestination

