import { dateTimeFormatter } from '../../../../../widgets/components/DateTimeFormater'

type orderActions = {
    handleOrderClick: (api_id: string, order_id: string,) => void,
    mouseHoverID: string,
    setMouseHoverID: (data: string) => void,
    getCheckedAll: boolean,
    getCheckedValue: number[],
    handleCheckedAll: (status: boolean) => void,
    handleChangeCheckbox: (data: string) => void
}

type rowData = {
    id: number
    api_id: string
    api_code: string
    api_status: string
    api_created_at: string
    destination_sync_status: string
}

export type columnData = {
    name: string | React.ReactElement
    selector?: (row: rowData) => void
    cell?: (row: rowData) => void
    sortable?: boolean
    width?: string
}

export const orderLogs =
    (
        {
            handleOrderClick, mouseHoverID, setMouseHoverID, getCheckedAll, getCheckedValue,
            handleCheckedAll, handleChangeCheckbox }: orderActions
    ): columnData[] => [
            {
                width: '50px',
                name: <span>
                    <input type='checkbox'
                        checked={getCheckedAll}
                        onChange={(event) => handleCheckedAll(event.target.checked)}
                    />
                </span>,
                selector: (row) =>
                    <input type='checkbox'
                        value={row.id}
                        onChange={(event) => handleChangeCheckbox(event.target.value)}
                        checked={getCheckedValue.findIndex((id) => id == row.id) !== -1}
                    />,
            },



            { name: 'ID', selector: (row) => row.api_id, sortable: true },
            { name: 'Order Number', selector: (row) => (row.api_code == null ? 'N/A' : row.api_code) },
            { name: 'Status', selector: (row) => row.api_status, sortable: false },
            {
                name: 'Created At',
                selector: (row) => dateTimeFormatter(row.api_created_at),
                sortable: true,
            },
            {
                name: 'Sync Status',
                cell: (row) => row.destination_sync_status,
                sortable: false,
            },
            {
                name: 'View',
                cell: (row) => (
                    <div onMouseEnter={() => setMouseHoverID(row.id.toString())} onMouseLeave={() => setMouseHoverID('')}>
                        <i
                            className='bi bi-eye-fill fs-2 cursor-pointer'
                            onClick={() => handleOrderClick(row.api_id, row?.id?.toString())}
                            style={{ color: mouseHoverID == row?.id?.toString() ? '#4098FF' : '' }}
                        ></i>
                    </div>

                ),
                sortable: false,
            },
        ]

export default orderLogs;