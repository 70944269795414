import {FC, useEffect, useState} from 'react'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {resetInfoModal, setInfoModalProps} from 'src/Redux/Slices/info/infoSlice'
import {useAppDispatch, useAppSelector} from 'src/Redux/Store/hooks'
import {setInvitedUserData, verifyInviteUserAuthAsync} from 'src/app/redux/slices/AuthSlices'
import Loader from '../../loader/Loader'

const OrganizationConnect:FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, _] = useSearchParams()
  const [loading, setLoading] = useState<boolean>(true)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const {modalProp} = useAppSelector((state) => state.info)

  useEffect(() => {
    (async function () {
      let org_id = searchParams.get('organization')
      let invite_token = searchParams.get('token')
      if (org_id && invite_token) {
        dispatch(verifyInviteUserAuthAsync({organization: org_id, token: invite_token}))
          .then((data) => {
            if (data.payload.data.email) {
              dispatch(
                setInvitedUserData({email: data.payload.data.email, name: data.payload.data.name})
              )
              dispatch(
                setInfoModalProps({
                  ...modalProp,
                  visible: true,
                  isConfirmationDialogue: false,
                  modalIcon: '/media/icons/duotune/general/gen048.svg',
                  modalType: 'success',
                  cancelLabel: 'Continue',
                  title: 'Invitation Accepted',
                  subtitle: "You'll be redirected to the sign-up page for registration",
                  onClose: () => {
                    dispatch(
                      setInfoModalProps({
                        ...modalProp,
                        visible: false,
                        isConfirmationDialogue: false,
                        modalIcon: '/media/icons/duotune/general/gen048.svg',
                        modalType: 'success',
                        cancelLabel: 'Close',
                        title: data.payload.data.message,
                      })
                    )
                    setTimeout(() => {
                      dispatch(resetInfoModal())
                    }, 200)
                    setLoading(false)
                    navigate(`/auth/registration?organization=${org_id}`)
                  },
                })
              )
              setTimeout(() => {
                navigate(`/auth/registration?organization=${org_id}`)
                dispatch(resetInfoModal())
              }, 5000)
            } else {
              // alert(data.payload.data.message)
              dispatch(
                setInfoModalProps({
                  ...modalProp,
                  visible: true,
                  isConfirmationDialogue: false,
                  modalIcon: '/media/icons/duotune/general/gen044.svg',
                  modalType: 'warning',
                  cancelLabel: 'Close',
                  title: "Invalid link or taken may be expired",
                  onClose: () => {
                    dispatch(
                      setInfoModalProps({
                        ...modalProp,
                        visible: false,
                        isConfirmationDialogue: false,
                        modalIcon: '/media/icons/duotune/general/gen044.svg',
                        modalType: 'warning',
                        cancelLabel: 'Close',
                        title: data.payload.data.message,
                      })
                    )
                    setTimeout(() => {
                      dispatch(resetInfoModal())
                    }, 200)
                    navigate(`/auth/login`)
                  },
                })
              )
            }
            setLoading(false)
          })
          .catch((err) => {
            console.log('err', err)
            setLoading(false)
          })
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) return <Loader />
  return null
}

export default OrganizationConnect
