import React, { Fragment, useState, useEffect } from 'react'
import './Tab.css'
import CustomTab from './CustomTab'
import { useSelector } from 'react-redux'
import { Stack, Row, Col, Spinner } from 'react-bootstrap'
import Logs from '../IndividualWorkflowTabs/Logs/Logs'
import OverView from '../IndividualWorkflowTabs/GeneralInfo/OverView'
import FieldMapping from '../IndividualWorkflowTabs/FieldMapping/FieldMapping'
import ExportStatus from '../IndividualWorkflowTabs/BulkOperations/ExportStatus'
import WorkflowSettings from '../IndividualWorkflowTabs/WorkflowSettings/WorkflowSettings'
import { AnimatedLoader, CircularLoader } from 'src/app/modules/widgets/components/CustomLoaders'
import AvailableWorkFlows from '../IndividualWorkflowTabs/AvailableFlows/AvailableWorkFlows'
type TabGroupProps = {
    workflowID: number | null
    sourceplatform_ID: number
    destinationplatform_ID: number
    tabGroupIndex: any
}

const tabs = [
    { id: 1, lable: 'Overview' },
    { id: 2, lable: 'Available Flows' },
    { id: 3, lable: 'Logs' },
    // { id: 4, lable: 'Settings' },
    { id: 4, lable: 'Field Mapping' },
    { id: 5, lable: 'Bulk Operations' },
]

function TabGroup(props: TabGroupProps) {
    const { workflowID, sourceplatform_ID, destinationplatform_ID, tabGroupIndex } = props
    const [activeTab, setActiveTab] = useState(tabs[tabGroupIndex]?.id || tabs[0]?.id);
    const [displayTextFlag, setDisplayTextFlag] = useState(false)

    const handleTabClick = (tabId: number) => {
        setActiveTab(tabId);
    };

    // This Redux States Checks Source & Destination PlatformProcess Status Which Gets It's Value As Soon As Tab Opens & API Hits In PlatformInitProcess.tsx Page.
    const { sourcePlatformProcess, destinationPlatformProcess } = useSelector(
        (state: any) => state.workflowForm
    )
    const { platformProcessLoader, initilizeProcess } = useSelector(
        (state: { platformProcess: { platformProcessLoader: boolean; initilizeProcess: { results: [] } } }) =>
            state.platformProcess
    )

    // When initprocess API Is Hit The Show Status Bar i.e. Data Is Being Fetched
    useEffect(() => {
        if (
            (sourcePlatformProcess.status_code > 199 && sourcePlatformProcess.status_code < 300) ||
            (destinationPlatformProcess.status_code > 199 && destinationPlatformProcess.status_code < 300)
        ) { setDisplayTextFlag(true) }
        if (initilizeProcess?.results?.length > 0) {
            setDisplayTextFlag(true)
        }
        if (initilizeProcess?.results?.length == 0) {
            setDisplayTextFlag(false)
        }
    }, [sourcePlatformProcess, destinationPlatformProcess, initilizeProcess])



    const renderPage = () => {
        if (activeTab === 1) {
            return <Fragment><OverView workflowID={workflowID as number} /></Fragment>
        } else if (activeTab === 2) {
            return <Fragment><AvailableWorkFlows workflowID={workflowID as number} /></Fragment>;
        }
        else if (activeTab === 3) {
            return <Fragment><Logs workflowID={workflowID as number} /></Fragment>;
        }
        // else if (activeTab === 4) {
        //     return <Fragment><WorkflowSettings workflowID={workflowID as number } sourceplatform_ID={sourceplatform_ID} destinationplatform_ID={destinationplatform_ID} /></Fragment>;
        // }
        else if (activeTab === 4) {
            return <Fragment><FieldMapping workflowID={workflowID as number} sourceplatform_ID={sourceplatform_ID} destinationplatform_ID={destinationplatform_ID} /></Fragment>;
        }
        else if (activeTab === 5) {
            return <Fragment><ExportStatus workflowID={workflowID as number} /></Fragment>;
        }
        return null;
    }

    return (
        <Fragment>
            <div className='tab-group'>
                {
                    tabs.map((tab) => {
                        return (
                            <CustomTab
                                key={tab.id}
                                label={tab.lable}
                                active={tab.id === activeTab}
                                onClick={() => handleTabClick(tab.id)}
                            />
                        )
                    })
                }
            </div>
            <div className='integrationLoaderContainer'>
                {
                    (platformProcessLoader || displayTextFlag) ?
                        (
                            <Stack className='integrationLoader'>
                                <span>{displayTextFlag ? 'Collecting Integration Data, This Might Take Few Seconds To 1-2 Minutes, You Can Wait Or Can Also Close This Window And Check Back Again.' : ''}</span>
                                <span style={{ width: '70px' }}>
                                    <CircularLoader height='60px'
                                        width='60px'
                                        marginTop='5px'
                                        marginBottom='5px'
                                        borderWidth='4px' />
                                </span>
                            </Stack>

                        ) : renderPage()
                }
            </div>
        </Fragment>
    )
}

export default TabGroup


// Things needed platformProcessPendingAsync loader