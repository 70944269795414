import { PlatformDataForAddPlatform } from '../../../_interfaces/PlatformSlice.Interface';
import { ADD_PLATFORM_SETTING, AddPlatformInitialValues } from '../../../app/modules/platform/components/Interface';
import { AxiosClient } from '../../AxiosClient/axiosClient'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const availablePlatfromCombunationAsync = createAsyncThunk(
  'platform-combinations/availablePlatfromCombunation',
  async (payload: { id: number; page: number; limit: number }, toolkit) => {
    return await AxiosClient(
      'GET',
      `/platform-combinations/?user_id=${payload.id}&page=${payload.page}&limit=${payload.limit}`,
      payload,
      toolkit
    )
  }
)

export const getAvailablePlatformsAsync = createAsyncThunk(
  'platform/getAvailablePlatformsAsync',
  async (_, toolkit) => {
    return await AxiosClient('GET', '/available-platform', [], toolkit)
  }
)

// add platform actions for add platform
export const addPlatformActionAsync = createAsyncThunk(
  'platform/addPlatformActionAsync',
  async (payload: { action: string; action_type: string; action_name: string }, toolkit) => {
    return await AxiosClient('POST', '/action', payload, toolkit)
  }
)

//  for getting platform details by their id
export const getPlatformDetailByIdAsync = createAsyncThunk(
  "platform/getPlatformDetailByIdAsync",
  async (params: { platformId: number }, toolkit) => {
    return await AxiosClient("GET", `/available-platform?id=${params.platformId}`, [], toolkit)
  }

)

// for adding available platform setting
export const addAvailablePlatformSettingAsync = createAsyncThunk(
  "platform/addAvailablePlatformSettingAsync",
  async (payload: ADD_PLATFORM_SETTING, toolkit) => {
    return await AxiosClient("POST", "/availableplatform-setting", payload, toolkit)
  }
)

export const addPlatformAsync = createAsyncThunk(
  "platform/addPlatformAsync",
  async function (payload: AddPlatformInitialValues, toolkit) {
    return await AxiosClient("POST", "/available-platform", payload, toolkit)
  }
)

export const activePlatfromCombunationAsync = createAsyncThunk(
  'platform-combinations/activePlatfromCombunation', async (payload: { id: number, page: number, limit: number }, toolkit) => {
    return await AxiosClient(
      'GET', `/platform-combinations/?user_id=${payload.id}`,
      payload, toolkit
    )
  }
)

export const availableWorkflowCombunationAsync = createAsyncThunk(
  'available-workflows/availableWorkflowCombunationAsync', async (params:{}|undefined, toolkit) => {
    return await AxiosClient(
      'GET', `/available-workflows`,
      [], toolkit, params
    )
  }
)

// Organization-wise Platforms
export const organizationBasedPlatformAsync = createAsyncThunk(
  'organization-available-platforms/organizationBasedPlatformAsync',
  async (payload: { organizationID: number }, toolkit) => {
    return await AxiosClient(
      'GET', `/organization-available-platforms/?organization_id=${payload.organizationID}`,
      payload, toolkit
    )
  }
)




