import {FC} from 'react'
import Table from 'react-bootstrap/Table'
import {useAppSelector} from 'src/Redux/Store/hooks'
import {AVAILABLE_WORKFLOW} from 'src/_interfaces/AvailableWorkflow.Interface'
import { InvitedUserType } from '../Interface'

const InvitedUserTable: FC<{
  tableIndexMultiple: number
//   openEditModal: (val: boolean) => void
//   setEditData: (val: AVAILABLE_WORKFLOW) => void
}> = ({
    tableIndexMultiple, 
    // openEditModal, 
    // setEditData
}) => {
  const { invitedUsers } = useAppSelector((state) => state.inviteUser)
  const intl = new Intl.DateTimeFormat( "IN", { day:"2-digit", month:"short", year:"2-digit", })
  const successClass = 'border bg-hover-success border-success py-2 px-3 text-dark text-hover-white fw-bold rounded rounded-4'
  const failedClass =  'border bg-hover-danger border-danger py-2 px-3 text-dark text-hover-white fw-bold rounded rounded-4'

  return (
    <div>
      <Table responsive striped bordered hover className='h-50' cellPadding={2}>
        <thead className='border border-bottom-2 border-secondary'>
          <tr>
            {[
              '#',
              'User Name',
              'Email',
              'User Accepted',
              'Invited date',
              'Actions',
            ].map((head, index) => {
              return (
                <th className='fw-bold fs-5 align-middle text-center' key={'availableWorkflowHeader' + index}>
                  {head}
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {invitedUsers.length ? (
            invitedUsers.map(( invitedUser:InvitedUserType , invitedUserIndex: number) => {
              return (
                <tr className='align-middle text-center' key={'org' + invitedUserIndex}>
                  <td>{invitedUserIndex + 1 + tableIndexMultiple}</td>
                  <td>{invitedUser.name}</td>
                  <td>{invitedUser.email}</td>
                  <td>{invitedUser.hasAccepted ? <p className={successClass} >Accepted</p> : <p className={failedClass} >Not Accepted</p>  }</td>
                  <td> {intl.format( new Date(invitedUser.created_at))} </td>
                  <td>
                    <i
                      className='bi bi-pencil-square cursor-pointer text-dark text-hover-success icon-900'
                      style={{fontSize:"20px", fontWeight:"bold"}}
                      onClick={() => {
                        // openEditModal(true)
                        // setEditData(availableWorkflow)
                      }}
                    ></i>
                  </td>
                </tr>
              )
            })
          ) : (
            <tr>
              <td colSpan={6} className='text-center fs-4 fw-bold '>
                No data found
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  )
}

export default InvitedUserTable
