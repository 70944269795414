import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import MyProfile from '../modules/profile/MyProfile'

// New One:::
import OrganizationDashboard from '../modules/Organization/Dashboard/OrganizationDashboard'
import OrganizationDetails from '../modules/Organization/OrganizationDetails/OrganizationDetails'
import InviteUserPage from '../modules/invite-users/InviteUserPage'

const OrganizationRoutes = () => {
    return (
        <Routes>
            <Route path='admin/' element={<MasterLayout />}>
                {/* Redirect to Dashboard after success login/registartion */}
                <Route index element={<Navigate to='/admin/dashboard' />} />
                {/* Pages */}
                <Route path='dashboard' element={<OrganizationDashboard />} />
                {/* Lazy Modules */}
                <Route
                    path='orginazation'
                    element={
                        <SuspensedView>
                            <OrganizationDetails />
                        </SuspensedView>
                    }
                />
                <Route
                    path='invite-users'
                    element={
                        <SuspensedView>
                            <InviteUserPage />
                        </SuspensedView>
                    }
                />
                <Route
                    path='profile/*'
                    element={
                        <SuspensedView>
                            <MyProfile />
                        </SuspensedView>
                    }
                />
            </Route>
            <Route path='/admin/*' element={<Navigate to='/error/404' />} />
        </Routes>
    )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
    const baseColor = getCSSVariableValue('--bs-primary')
    TopBarProgress.config({
        barColors: {
            '0': baseColor,
        },
        barThickness: 1,
        shadowBlur: 5,
    })
    return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { OrganizationRoutes }
