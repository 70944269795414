import React, { useState, useEffect } from 'react'
import Spinner from 'react-bootstrap/Spinner'
import { Row, Col, Stack } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import classes from './formModal.module.css'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { connectOauthAsync } from '../../../../Redux/Slices/processOauth/processOauthAsync'
import { emptyOauth } from '../../../../Redux/Slices/processOauth/processOauthSlice'
import axios from 'axios'

function ProcessOauth() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [currentColor, setCurrentColor] = useState('danger');
    const queryString = window.location.search;
    const userId = Number(localStorage.getItem('user'))
    const availableIDs = JSON.parse(localStorage.getItem('processOauth_ID') || 'null');
    const { connectOauth } = useSelector((state: any) => state.oauth)
    const [loading, setLoading] = useState<boolean>(false)
    // Query String Parsing:::
    const parseQueryString = (queryString: string) => {
        const parems = new URLSearchParams(queryString)

        const code = parems.get('code')
        const hmac = parems.get('hmac')
        const host = parems.get('host')
        const shop = parems.get('shop')
        const state = parems.get('state')
        const timeStamp = parems.get('timestamp')

        return { code, host, hmac, shop, state, timeStamp }
    }
    const { code, host, hmac, shop, state, timeStamp } = parseQueryString(queryString)


    // For Tik-Tok And Others:::
    useEffect(() => {
        let payload = {}
        if (queryString) {
            if (availableIDs?.platform_id) {
                payload = {
                    query_string: queryString,
                    user_id: userId,
                    available_platform_id: availableIDs.available_platform_id,
                    platform_id: availableIDs.platform_id
                }
            }
            else {
                payload = {
                    query_string: queryString,
                    user_id: userId,
                    available_platform_id: availableIDs?.available_platform_id
                }
            }
            dispatch(connectOauthAsync(payload) as any)
        }

    }, [queryString])

    useEffect(() => {
        const colors = ['success', 'danger', 'warning'];
        let currentIndex = 0;
        const interval = setInterval(() => {
            currentIndex = (currentIndex + 1) % colors.length;
            setCurrentColor(colors[currentIndex]);
        }, 1000);
        return () => { clearInterval(interval) };
    }, []);

    async function conveyMessage() {
        let response = await connectOauth?.massage
        if (connectOauth?.status_code === 200) {
            toast.success(response)
            // navigate('/dashboard', { state: { message: response, type: 'success' } })
            dispatch(emptyOauth())
            setTimeout(() => { window.close() }, 5000)

        } if (connectOauth?.data?.status_code > 300) {
            toast.success(connectOauth?.data?.massage)
            // navigate('/dashboard', { state: { message: connectOauth?.data?.massage, type: 'error' } })
            dispatch(emptyOauth())
            setTimeout(() => { window.close() }, 5000)
        }
    }
    
    useEffect(() => { conveyMessage() }, [connectOauth])
    return (
        <div className={classes.visible_content}>
            <Row>
                <Col xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Spinner
                        animation='border'
                        variant={currentColor}
                        style={{
                            width: '100px',
                            height: '100px',
                            marginBottom: '50px',
                            borderWidth: '7px',
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <h1>Please Wait</h1>
                    <h1>Your Information Is Being Proceed...</h1>
                </Col>
            </Row>
            <ToastContainer />
        </div>
    )
}

export default ProcessOauth;


