import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit'
import {
  getPlatformTypeSource,
  postCreateWorkflow,
  getPlatformTypeDestination,
  getAvailablePlatformById,
  getAvailablePlatformAction,
  getWorkflowDetails,
  deleteWorkflow,
  updatePlatformSetting,
  getPlatformByworkflowAndplatformId,
  getWorkflow,
  updateWorkflowStatus,
} from '../../services/WorkflowService'

interface WorkflowState {
  loader: boolean
  data: any
  PlatformTypeSourceDetails: any
  PlatformTypeDestinationDetails: any
  AvailablePlatformSourceDetails: any
  AvailablePlatformDestinationDetails: any
  AvailablePlatformActionDetails: any
  WorkflowData: any
  WorkflowDetails: any
  PlatformByUserAndIdData: any
  error: string | null | unknown
}

const initialState: WorkflowState = {
  loader: false,
  data: {},
  error: null,
  WorkflowDetails: null,

  PlatformByUserAndIdData: null,
  PlatformTypeSourceDetails: null,
  PlatformTypeDestinationDetails: null,
  AvailablePlatformSourceDetails: null,
  AvailablePlatformDestinationDetails: null,
  AvailablePlatformActionDetails: null,
  WorkflowData: null,
}

export const postCreateWorkflowApi = createAsyncThunk(
  'create-workflow/api/post',

  async (FormData: any, { rejectWithValue }) => {
    try {
      const result = await postCreateWorkflow(FormData)
      return result.data
    } catch (error) {
      return rejectWithValue({ error })
    }
  }
)

export const getPlatformTypeSourceApi = createAsyncThunk(
  'PlatformTypeSource/api/get',
  async (userId: any, { rejectWithValue, fulfillWithValue }) => {
    try {
      const result = await getPlatformTypeSource(userId)
      return result.data
    } catch (error) {
      return rejectWithValue({ error })
    }
  }
)
export const getWorkflowApi = createAsyncThunk(
  'Workflow/api/get',
  async (userId: any, { rejectWithValue, fulfillWithValue }) => {
    try {
      const result = await getWorkflow(userId)
      return result.data
    } catch (error) {
      return rejectWithValue({ error })
    }
  }
)

export const getPlatformTypeDestinationApi = createAsyncThunk(
  'PlatformTypeDestination/api/get',
  async (userId: any, { rejectWithValue, fulfillWithValue }) => {
    try {
      const result = await getPlatformTypeDestination(userId)
      return result.data
    } catch (error) {
      return rejectWithValue({ error })
    }
  }
)
export const getAvailablePlatformByIdApi = createAsyncThunk(
  'AvailablePlatformById/api/get',
  async (userId: any, { rejectWithValue, fulfillWithValue }) => {
    try {
      const result = await getAvailablePlatformById(userId)
      return result.data
    } catch (error) {
      return rejectWithValue({ error })
    }
  }
)
export const getAvailablePlatformActionApi = createAsyncThunk(
  'AvailablePlatformAction/api/get',
  async (Id: any, { rejectWithValue, fulfillWithValue }) => {
    try {
      const result = await getAvailablePlatformAction(Id)
      return result.data
    } catch (error) {
      return rejectWithValue({ error })
    }
  }
)

export const getWorkflowDetailsApi = createAsyncThunk(
  'WorkflowDetails/api/get',
  async (Id: any, { rejectWithValue, fulfillWithValue }) => {
    try {
      const result = await getWorkflowDetails(Id)
      return result.data
    } catch (error) {
      return rejectWithValue({ error })
    }
  }
)
export const deleteWorkflowApi = createAsyncThunk(
  'Workflow/api/delete',
  async (Id: any, { rejectWithValue, fulfillWithValue }) => {
    try {
      const result = await deleteWorkflow(Id)
      return result.data
    } catch (error) {
      return rejectWithValue({ error })
    }
  }
)

export const updatePlatformSettingApi = createAsyncThunk(
  'PlatformSetting/api/update',

  async (FormData: any, { rejectWithValue }) => {
    try {
      const result = await updatePlatformSetting(FormData)
      return result.data
    } catch (error) {
      return rejectWithValue({ error })
    }
  }
)
export const getPlatformByworkflowAndplatformIdApi = createAsyncThunk(
  'PlatformByUserAndId/api/get',

  async ({ platformId, workflowId }: { platformId: any; workflowId: any }, { rejectWithValue }) => {
    try {
      const result = await getPlatformByworkflowAndplatformId(platformId, workflowId)
      return result.data
    } catch (error) {
      return rejectWithValue({ error })
    }
  }
)
export const updateWorkflowStatusApi = createAsyncThunk(
  'updateWorkflowStatus/api/PUT',

  async ({ id, FormData }: { id: any; FormData: any }, { rejectWithValue }) => {
    try {
      const result = await updateWorkflowStatus(id, FormData)
      return result.data
    } catch (error) {
      return rejectWithValue({ error })
    }
  }
)

export const WorkflowSlice: any = createSlice({
  name: 'Workflow',
  initialState,
  reducers: {
    emplyWorkflowDetails: (state) => {
      return {
        ...state,
        WorkflowDetails: [],
      }
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(getWorkflowApi.fulfilled, (state, action) => {
        state.WorkflowData = action.payload
      })
      .addCase(getPlatformByworkflowAndplatformIdApi.fulfilled, (state, action) => {
        state.PlatformByUserAndIdData = action.payload
      })
      .addCase(getWorkflowDetailsApi.fulfilled, (state, action) => {
        state.WorkflowDetails = action.payload
      })
      .addCase(getAvailablePlatformActionApi.fulfilled, (state, action) => {
        state.AvailablePlatformActionDetails = action.payload
      })
      .addCase(getPlatformTypeSourceApi.fulfilled, (state, action) => {
        state.PlatformTypeSourceDetails = action.payload
      })
      .addCase(getPlatformTypeDestinationApi.fulfilled, (state, action) => {
        state.PlatformTypeDestinationDetails = action.payload
      })
      .addCase(getAvailablePlatformByIdApi.fulfilled, (state, action) => {
        if (action.payload.data.type === 'SOURCE') {
          state.AvailablePlatformSourceDetails = action.payload
        }
        if (action.payload.data.type === 'DESTINATION') {
          state.AvailablePlatformDestinationDetails = action.payload
        }
      })
  },
})
export const { emplyWorkflowDetails } = WorkflowSlice.actions;
export default WorkflowSlice.reducer
