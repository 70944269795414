import clsx from 'clsx'
import { KTIcon } from '../../../helpers'
import { ThemeModeSwitcher } from '../../../partials'
import { useLayout } from '../../core'
import { useNavigate } from "react-router-dom";

const itemClass = 'ms-1 ms-md-4'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px'
const userAvatarClass = 'symbol-35px'
const btnIconClass = 'fs-2'

const Navbar = () => {
  const { config } = useLayout()
  // let user_details_string: any = sessionStorage.getItem('user_details')
  let user_details_string: any = localStorage.getItem('user_details')

  const user_details: any = JSON.parse(user_details_string)
  const user_username: any = user_details?.username
  const user_firstName: any = user_details?.first_name
  const user_email: any = user_details?.email


  const navigate = useNavigate()
  const handleProfile =()=>{
   navigate('profile/')
  }
  const logout = () => {
    localStorage.clear()
    sessionStorage.clear()
    navigate('/auth')
    window.location.reload()
  }
  

  return (
    <div className='app-navbar flex-shrink-0'>
      <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <div className='d-flex bg-success align-items-center justify-content-center text-white  h-50px  rounded fs-2 fw-bolder' style={{ width: '50px ' }} >
            {user_username?.charAt(0).toUpperCase() || user_firstName?.charAt(0).toUpperCase()}
          </div>
          {/*<img src={toAbsoluteUrl('/media/avatars/300-3.jpg')} alt='' />*/}
        </div>
        <div
          className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
          data-kt-menu='true'
        >
          <div className='menu-item px-3'>
            <div className='menu-content d-flex align-items-center px-3'>
              <div className='symbol symbol-50px me-2'>
                <div className='d-flex bg-success align-items-center justify-content-center text-white  h-50px  rounded fs-2 fw-bolder' style={{ width: '50px ' }} >
                  {user_username?.charAt(0).toUpperCase() || user_firstName?.charAt(0).toUpperCase()}
                </div>
                {/*<img alt='Logo' src={toAbsoluteUrl('/media/avatars/300-3.jpg')} />*/}
              </div>

              <div className='p-2'>
                <div className='fw-bolder  fs-5'>
                  {user_username ?? "User" }
                  {/*<span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>Pro</span>*/}
                </div>
                <p style={{wordBreak:'break-all', lineHeight:1.1 }} className='mb-0 fw-bold text-muted text-hover-primary fs-7'>
                  {user_email}
                </p>
              </div>
            </div>
          </div>

          <div className='separator my-2'></div>
          <div className='menu-item px-5'>
            <a onClick={handleProfile} className='menu-link px-5'>Manage Account</a>
            <a onClick={logout} className='menu-link px-5'>
              Sign Out
            </a>
          </div>
        </div>

      </div>

      {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTIcon iconName='text-align-left' className={btnIconClass} />
          </div>
        </div>
      )}
    </div>
  )
}

export { Navbar }
