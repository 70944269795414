import React, { Fragment, useEffect, useState, useMemo } from 'react'
import './style.css'
import { toast } from 'react-toastify'
import { Container, Button } from 'react-bootstrap'
import { Field, FieldArray, ErrorMessage } from 'formik'
import { KTSVG } from '../../../../../../../_metronic/helpers'
import TextError from '../../../../../../../ErrorMessage/TextError'
import { ConfirmationModal } from '../../../ConfirmationModal'
import ScreenWidth from '../../../../../widgets/components/ScreenWidth'
import axios from 'axios'
import SubCombinationSource from './SubCombinationSource'
import SubCombinationDestination from './SubCombinationDestination'
import classes from '../components/FieldMapping.module.css'

type ReselectCombinationProps = { conminedState: any; Formik: any; getMappedDataByID: any }
export function ReselectCombination(props: ReselectCombinationProps) {
  const { Formik, conminedState, getMappedDataByID } = props
  const [screenWidth] = ScreenWidth()
  const [source_filtered, set_source_filtered] = useState<any | []>([])
  const [destination_filtered, set_destination_filtered] = useState<any | []>([])
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [deleteItemID, setDeleteItemID] = useState<number | null>(null)
  const [deleteItemIndex, setDeleteItemIndex] = useState<number | null>(null)
  const [fieldArrayState, setFieldArrayState] = useState<any>()

  useEffect(() => {
    //eslint-disable-next-line @typescript-eslint/no-unused-expressions
    const s_filtered = conminedState?.find(
      (sflt: any) => sflt?.id == getMappedDataByID?.data?.source_field?.id
    )
    set_source_filtered(s_filtered)
    //eslint-disable-next-line @typescript-eslint/no-unused-expressions
    const d_filtered = conminedState?.find(
      (sflt: any) => sflt?.id == getMappedDataByID?.data?.destination_field?.id
    )
    set_destination_filtered(d_filtered)
  }, [conminedState, getMappedDataByID])

  useEffect(() => {
    const filtered_combinationData = getMappedDataByID?.data?.workflow_field_mapping_data?.filter(
      (cc: any) => cc.source_platform_data !== null || cc.source_platform_data_str !== null
    )

    // Finding It's Absolute Parent:
    function process(id: string, array) {
      let search_parent = [] as { parent_id: number | null; child_id: number | null }[]
      let id_string_value;
      id_string_value = array?.find((item) => item?.id === id)

      array?.filter((ele) => {
        if (ele?.id === id) {
          search_parent[0] = { parent_id: ele?.parent_data, child_id: ele?.id }
        }
      })

      if (search_parent[0]?.parent_id) {
        array?.filter((ele) => {
          if (ele?.id === search_parent[0]?.parent_id && ele?.parent_data) {
            search_parent[1] = { parent_id: ele?.parent_data, child_id: ele?.id }
          }
        })
      } else { return id }


      if (search_parent[1]?.parent_id) {
        array?.filter((ele) => {
          if (ele?.id === search_parent[1]?.parent_id && ele?.parent_data) {
            search_parent[2] = { parent_id: ele?.parent_data, child_id: ele?.id }
          }
        })
      } else {
        return {
          left_field: search_parent[search_parent?.length - 1]?.parent_id,
          rigth_field: id_string_value?.api_name,
          child_created_whileMappingFormation: id,
        }
      }


      if (search_parent[2]?.parent_id) {
        array?.filter((ele) => {
          if (ele?.id === search_parent[2]?.parent_id && ele?.parent_data) {
            search_parent[3] = { parent_id: ele?.parent_data, child_id: ele?.id }
          }
        })
      } else {
        return {
          left_field: search_parent[search_parent?.length - 1]?.parent_id,
          rigth_field: id_string_value?.api_name,
          child_created_whileMappingFormation: id,
        }
      }


      if (search_parent[3]?.parent_id) {
        array?.filter((ele) => {
          if (ele?.id === search_parent[3]?.parent_id && ele?.parent_data) {
            search_parent[4] = { parent_id: ele?.parent_data, child_id: ele?.id }
          }
        })
      } else {
        return {
          left_field: search_parent[search_parent?.length - 1]?.parent_id,
          rigth_field: id_string_value?.api_name,
          child_created_whileMappingFormation: id,
        }
      }

      if (search_parent[4]?.parent_id) {
        array?.filter((ele) => {
          if (ele?.id === search_parent[4]?.parent_id && ele?.parent_data) {
            search_parent[5] = { parent_id: ele?.parent_data, child_id: ele?.id }
          }
        })
      } else {
        return {
          left_field: search_parent[search_parent?.length - 1]?.parent_id,
          rigth_field: id_string_value?.api_name,
          child_created_whileMappingFormation: id,
        }
      }

      if (search_parent[5]?.parent_id) {
        array?.filter((ele) => {
          if (ele?.id === search_parent[5]?.parent_id && ele?.parent_data) {
            search_parent[6] = { parent_id: ele?.parent_data, child_id: ele?.id }
          }
        })
      } else {
        return {
          left_field: search_parent[search_parent?.length - 1]?.parent_id,
          rigth_field: id_string_value?.api_name,
          child_created_whileMappingFormation: id,
        }
      }

      if (search_parent[6]?.parent_id) {
        array?.filter((ele) => {
          if (ele?.id === search_parent[6]?.parent_id && ele?.parent_data) {
            search_parent[7] = { parent_id: ele?.parent_data, child_id: ele?.id }
          }
        })
      } else {
        return {
          left_field: search_parent[search_parent?.length - 1]?.parent_id,
          rigth_field: id_string_value?.api_name,
          child_created_whileMappingFormation: id,
        }
      }

      let display_id = search_parent[search_parent?.length - 1]?.parent_id
      return {
        left_field: display_id,
        rigth_field: id_string_value?.api_name,
        child_created_whileMappingFormation: id,
      }
    }

    filtered_combinationData?.map((cc: any, index: number) => {
      Formik.setFieldValue(`combination.${index}.id`, cc?.id)
      Formik.setFieldValue(`combination.${index}.isDefault`, cc?.isDefault)
      // When Source & Destination Both Have Select Options
      // Formik.setFieldValue(`combination.${index}.source_platform_data`,cc?.source_platform_data?.id || null)
      Formik.setFieldValue(`combination.${index}.source_platform_data`,
        process(cc?.source_platform_data?.id, source_filtered?.field_data) || null)
      // Formik.setFieldValue(`combination.${index}.destination_platform_data`, cc?.destination_platform_data?.id || null)
      Formik.setFieldValue(`combination.${index}.destination_platform_data`,
        process(cc?.destination_platform_data?.id, destination_filtered?.field_data) || null
      )
      // When Source Or Destination Or Both Have Text Field
      Formik.setFieldValue(`combination.${index}.source_platform_data_str`, cc?.source_platform_data_str || null)
      Formik.setFieldValue(`combination.${index}.destination_platform_data_str`, cc?.destination_platform_data_str || null)
      // sourceChild_array and destinationChild_array is initially empty array
      Formik.setFieldValue(`combination.${index}.sourceChild_array`, [])
      Formik.setFieldValue(`combination.${index}.destinationChild_array`, [])
    })
  }, [getMappedDataByID, source_filtered?.field_data, destination_filtered])

  function hasDuplicate(arr) {
    const seenSourceData = new Set()
    const seenSourceDataStr = new Set()

    return arr.some((cc, index) => {
      const sourceData = +cc.source_platform_data
      const sourceDataStr = cc.source_platform_data_str

      if (
        source_filtered?.input_type == 'TEXT' &&
        sourceDataStr !== null &&
        seenSourceDataStr.has(sourceDataStr)
      ) {
        Formik.setFieldValue(`combination.${index}.source_platform_data`, null)
        Formik.setFieldValue(`combination.${index}.source_platform_data_str`, '')
        Formik.setFieldValue(
          `combination.${index}.destination_platform_data`,
          cc.destination_platform_data
        )
        Formik.setFieldValue(
          `combination.${index}.destination_platform_data_str`,
          cc.destination_platform_data_str
        )
        Formik.setFieldValue(`combination.${index}.isDefault`, false)
        toast.error("Field 01 Can't Be Same !")
        return
      }
      if (
        source_filtered?.input_type == 'SELECT' &&
        sourceData !== 0 &&
        seenSourceData.has(sourceData)
      ) {
        Formik.setFieldValue(`combination.${index}.source_platform_data`, '')
        Formik.setFieldValue(`combination.${index}.source_platform_data_str`, null)
        Formik.setFieldValue(
          `combination.${index}.destination_platform_data`,
          cc.destination_platform_data
        )
        Formik.setFieldValue(
          `combination.${index}.destination_platform_data_str`,
          cc.destination_platform_data_str
        )
        Formik.setFieldValue(`combination.${index}.isDefault`, false)
        toast.error("Field 01 Can't Be Same !")
        return true
      } else {
        seenSourceData.add(sourceData)
        seenSourceDataStr.add(sourceDataStr)
        return false
      }
    })
  }

  useMemo(() => {
    hasDuplicate(Formik.values.combination)
  }, [Formik.values.combination])

  const handleDeleteEvent = (fieldArrayProps: any, index: number) => {
    // console.log('Values at index', index, fieldArrayProps.form.values.combination[index]);
    setDeleteItemID(fieldArrayProps.form.values.combination[index]?.id)
    setDeleteItemIndex(index)
    setFieldArrayState(fieldArrayProps)
    setShowConfirmationModal(!showConfirmationModal)
  }
  const handleDelete = async () => {
    fieldArrayState.remove(deleteItemIndex)
    if (deleteItemID) {
      try {
        const response = await axios.delete(
          `${process.env.REACT_APP_API_URL}/workflowfieldmappingdata/delete/${deleteItemID}`
        )
        console.log('response', response)
        if (response.status === 204) {
          toast.success('Field Deleted Successfully')
        }
      } catch (error) {
        toast.error(`${error}`)
      }
    }
    setShowConfirmationModal(!showConfirmationModal)
  }

  return (
    <Fragment>
      <div className={`${classes.mapInternally_container} mb-7`}>
        <input type='checkbox'
          id='map_intr'
          name='map_internally'
          onChange={(event) => {
            Formik.handleChange(event);
            Formik.setFieldValue('combination', []);
          }}
        />
        <label htmlFor="map_intr">Map Internally</label>
      </div>
      {
        Formik.values.map_internally === false ? (
          <FieldArray name='combination'>
            {(fieldArrayProps: any) => {
              const { form } = fieldArrayProps
              const { values } = form
              return (
                <div>
                  <div id='combination_button_div'>
                    <Button
                      size='sm'
                      style={{ backgroundColor: '#487fbe' }}
                      onClick={() => {
                        fieldArrayProps.push({
                          id: null,
                          isDefault: false,
                          source_platform_data: source_filtered?.input_type == 'TEXT' ? null : '',
                          source_platform_data_str: null,
                          destination_platform_data:
                            destination_filtered?.input_type == 'TEXT' ? null : '',
                          destination_platform_data_str: null,
                          sourceChild_array: [],
                          destinationChild_array: [],
                        })
                      }}
                    >
                      Add +
                    </Button>
                  </div>
                  <Container id='combination_container'>
                    {values.combination.map((comb: any, index: number) => {
                      return (
                        <Fragment>
                          <div
                            key={index}
                            id='combination_parent_div'
                            style={{
                              flexWrap: screenWidth <= 320 || screenWidth <= 500 ? 'wrap' : 'nowrap',
                            }}
                          >
                            <div id='combination_sourceField_div'>
                              {source_filtered?.input_type == 'TEXT' ? (
                                <Fragment>
                                  <Field
                                    type='text'
                                    placeholder='Field 01'
                                    id='combination_sourceField_str'
                                    name={`combination.${index}.source_platform_data_str`}
                                  />
                                  <ErrorMessage
                                    name={`combination.${index}.source_platform_data_str`}
                                    component={TextError}
                                  />
                                </Fragment>
                              ) : (
                                <Fragment>
                                  <Field
                                    as='select'
                                    id='combination_sourceField'
                                    name={`combination.${index}.source_platform_data`}
                                    onChange={(event) => {
                                      const selectedValue = event.target.value;
                                      Formik.setFieldValue(
                                        `combination.${index}.source_platform_data`,
                                        selectedValue
                                      );
                                      Formik.setFieldValue(`combination.${index}.sourceChild_array`, []);
                                    }}
                                    value={Formik.values.combination[index]?.source_platform_data?.left_field ?
                                      Formik.values.combination[index]?.source_platform_data?.left_field :
                                      Formik.values.combination[index]?.source_platform_data
                                    }
                                  >
                                    <option value=''>Select Field 01</option>
                                    {source_filtered?.field_data?.map((items: any) => {
                                      if (items?.parent_data == null) {
                                        const isSelected =
                                          items?.id ===
                                          Formik.values.combination[index]?.source_platform_data?.left_field;

                                        return (
                                          <option key={items.id} value={items?.id}>
                                            {items?.api_name}
                                            {isSelected && ( // Render if this option is selected
                                              <>
                                                &nbsp;&nbsp;-&nbsp;&nbsp;
                                                {Formik.values.combination[index]?.source_platform_data
                                                  ?.rigth_field ?? ''}
                                              </>
                                            )}
                                          </option>
                                        );
                                      }
                                      return null; // If not a valid option, return null
                                    })}
                                  </Field>
                                  <SubCombinationSource
                                    Formik={Formik}
                                    index={index}
                                    parentID={Formik.values.combination?.[index]?.source_platform_data}
                                    data={source_filtered?.field_data}
                                  />
                                  <ErrorMessage
                                    name={`combination.${index}.source_platform_data`}
                                    component={TextError}
                                  />
                                </Fragment>
                              )}
                            </div>
                            <div
                              style={{
                                display: screenWidth <= 320 || screenWidth <= 500 ? 'none' : 'block',
                              }}
                            >
                              <span className='previouslyMapped_arrow'>&rarr;</span>
                            </div>
                            <div id='combination_destinationField_div'>
                              {destination_filtered?.input_type == 'TEXT' ? (
                                <Fragment>
                                  <Field
                                    type='text'
                                    placeholder='Field 02'
                                    id='combination_destinationField_str'
                                    name={`combination.${index}.destination_platform_data_str`}
                                  />
                                  <ErrorMessage
                                    name={`combination.${index}.destination_platform_data_str`}
                                    component={TextError}
                                  />
                                </Fragment>
                              ) : (
                                <Fragment>
                                  <Field
                                    as='select'
                                    id='combination_destinationField'
                                    name={`combination.${index}.destination_platform_data`}
                                    onChange={(event) => {
                                      const selectedValue = event.target.value;
                                      Formik.setFieldValue(
                                        `combination.${index}.destination_platform_data`, selectedValue
                                      );
                                      Formik.setFieldValue(`combination.${index}.destinationChild_array`, []);
                                    }}
                                    value={Formik.values.combination[index]?.destination_platform_data?.left_field ?
                                      Formik.values.combination[index]?.destination_platform_data?.left_field :
                                      Formik.values.combination[index]?.destination_platform_data
                                    }
                                  >
                                    <option value=''>Select Field 01</option>
                                    {destination_filtered?.field_data?.map((items: any) => {
                                      if (items?.parent_data == null) {
                                        const isSelected =
                                          items?.id ===
                                          Formik.values.combination[index]?.destination_platform_data?.left_field;

                                        return (
                                          <option key={items.id} value={items?.id}>
                                            {items?.api_name}
                                            {isSelected && ( // Render if this option is selected
                                              <>
                                                &nbsp;&nbsp;-&nbsp;&nbsp;
                                                {Formik.values.combination[index]?.destination_platform_data
                                                  ?.rigth_field ?? ''}
                                              </>
                                            )}
                                          </option>
                                        );
                                      }
                                      return null; // If not a valid option, return null
                                    })}
                                  </Field>
                                  <SubCombinationDestination
                                    Formik={Formik}
                                    index={index}
                                    parentID={
                                      Formik.values.combination?.[index]?.destination_platform_data
                                    }
                                    data={destination_filtered?.field_data}
                                  />
                                  <ErrorMessage
                                    name={`combination.${index}.destination_platform_data`}
                                    component={TextError}
                                  />
                                </Fragment>
                              )}
                            </div>
                            <span
                              onClick={() => {
                                // fieldArrayProps.remove(index)
                                handleDeleteEvent(fieldArrayProps, index)
                              }}
                              style={{ cursor: 'pointer' }}
                            >
                              <KTSVG
                                path='/media/icons/duotune/abstract/abs012.svg'
                                className=' svg-icon-2x text-danger'
                              />
                            </span>
                          </div>
                          <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderBottom: '2px solid #BDBDBD',
                            marginLeft: '30%',
                            marginRight: '30%',
                            marginTop: '12px'
                          }}>{""}</div>
                        </Fragment>
                      )
                    })}
                  </Container>
                </div>
              )
            }}
          </FieldArray>
        ) : null
      }

      <ConfirmationModal
        icon='/media/icons/duotune/general/gen044.svg'
        title='Are you sure?'
        description='Are you sure you want to Delete!'
        onSave={handleDelete}
        showModal={showConfirmationModal}
        setShowModal={setShowConfirmationModal}
      />
    </Fragment>
  )
}
