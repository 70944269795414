import React, { Fragment, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { platformProcessPendingAsync } from '../../../../../Redux/Slices/platformProcessInitilization/platformProcessAsync'
import classes from './styles.module.css'
import { Container, Spinner } from 'react-bootstrap'
type platformInitProcessProps = {
    workflowID: number | null
}

function PlatformInitProcess(props: platformInitProcessProps) {
    const { workflowID } = props
    const dispatch = useDispatch()
    const [displayTextFlag, setDisplayTextFlag] = useState(false)

    const { sourcePlatformProcess, destinationPlatformProcess } = useSelector(
        (state: any) => state.workflowForm
    )

    const { platformProcessLoader, initilizeProcess } = useSelector(
        (state: { platformProcess: { platformProcessLoader: boolean; initilizeProcess: { results: [] } } }) =>
            state.platformProcess
    )


    // Run When WorkflowDetails Modal Open For The 1st Time And 'Refetch Data' Button Is Clicked In Field Mapping Tab:::
    useEffect(() => {
        if (workflowID) {
            dispatch(platformProcessPendingAsync({ workflodID: workflowID }) as any)
        }
        console.log('sourcePlatformProcess', sourcePlatformProcess, 'destinationPlatformProcess', destinationPlatformProcess)
        console.log('initilizeProcess', initilizeProcess)
    }, [sourcePlatformProcess, destinationPlatformProcess])

    // When initprocess API Is Hit The Show Status Bar i.e. Data Is Being Fetched
    useEffect(() => {
        if (
            (sourcePlatformProcess.status_code > 199 && sourcePlatformProcess.status_code < 300) ||
            (destinationPlatformProcess.status_code > 199 && destinationPlatformProcess.status_code < 300)
        ) {
            setDisplayTextFlag(true)
        }
    }, [sourcePlatformProcess, destinationPlatformProcess])

    useEffect(() => {
        let displayStatusBarInterval
        // Show Status Bar Utill Lenght nitilizeProcess.results.length === 0
        if (initilizeProcess?.results?.length == 0) {
            setDisplayTextFlag(false)
        }
        // If Lenght initilizeProcess.results.length > 0 Then Hit API Again & Again Untill length === 0
        if (initilizeProcess?.results?.length > 0) {
            displayStatusBarInterval = setInterval(() => {
                if (workflowID) {
                    dispatch(platformProcessPendingAsync({ workflodID: workflowID }) as any)
                }
                setDisplayTextFlag(true)
            }, 5 * 1000)
        }
        return () => {
            clearInterval(displayStatusBarInterval)
        }
    }, [workflowID, initilizeProcess?.results?.length])

    return (
        <Fragment>
            {/* {displayTextFlag && (
                <div className={classes.platform_status}>
                    <span>Please wait while your data is being fetched...</span>
                    <span>
                        <CustomLoader />
                    </span>
                </div>
            )} */}
            {""}
        </Fragment>
    )
}

export default PlatformInitProcess

export function CustomLoader() {
    return (
        <Container
            style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                columnGap: '5px',
            }}
        >
            <Spinner
                animation='grow'
                variant='primary'
                style={{
                    width: '10px',
                    height: '10px',
                    borderWidth: '2px',
                }}
            />
            <Spinner
                animation='grow'
                variant='success'
                style={{
                    width: '10px',
                    height: '10px',
                    borderWidth: '2px',
                }}
            />{' '}
            <Spinner
                animation='grow'
                variant='danger'
                style={{
                    width: '10px',
                    height: '10px',
                    borderWidth: '2px',
                }}
            />
        </Container>
    )
}
