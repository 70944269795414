import * as Yup from 'yup'


export type userDetails_values = {
    email: string
    username: string
    user_type: string
    last_login: string
    first_name: string
    date_joined: string
    id: number | string
    last_name: string | null
    is_active: boolean | null
    organization: string | null
    is_superuser: boolean | null
    forgot_password_token: string
}

export type changePasswordInitialvalues_values = {
    previous_password: string | number, new_password: string | number, confirm_new_password: string | number
}
export const changePasswordInitialvalues: changePasswordInitialvalues_values = {
    previous_password: '', new_password: '', confirm_new_password: ''
}

export const changePasswordVadidationSchema = Yup.object().shape({
    previous_password: Yup.string().required('Previous password is required'),
    new_password: Yup.string()
        .min(6, 'Password must have at least 6 characters')
        .required('Password is required'),

    confirm_new_password: Yup.string()
        .oneOf([Yup.ref('new_password'), ''], 'Password must match')
        .required('Confirm password is required'),
})