import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import {
    resyncRejectedOrderAsync,
    platformOrderEnabilityAsync,
    generateWorkflowReportAsync,
} from './orderDetailsAsync'

type orderDetailSliceInitialState = {
    loading: boolean
    reSyncOrderState: any
    platformOrderEnability: any
    generateWorkflowReport: any
}

const initialState: orderDetailSliceInitialState = {
    loading: false,
    reSyncOrderState: [],
    platformOrderEnability: [],
    generateWorkflowReport: [],
}

const orderDetailsSlice = createSlice({
    name: 'orderDetailsSlice',
    initialState,
    reducers: { emptyOrderDetails: () => initialState },
    extraReducers: (builder) => {
        builder.addMatcher(isAnyOf(
            resyncRejectedOrderAsync.pending,
            platformOrderEnabilityAsync.pending,
            generateWorkflowReportAsync.pending,
        ), (state, actions) => {
            state.loading = true
        })
        builder.addMatcher(isAnyOf(resyncRejectedOrderAsync.fulfilled), (state, actions) => {
            state.loading = false
            state.reSyncOrderState = actions.payload
        })
        builder.addMatcher(isAnyOf(platformOrderEnabilityAsync.fulfilled), (state, actions) => {
            state.loading = false
            state.platformOrderEnability = actions.payload
        })
        builder.addMatcher(isAnyOf(generateWorkflowReportAsync.fulfilled), (state, actions) => {
            state.loading = false
            state.generateWorkflowReport = actions.payload
        })
        builder.addMatcher(isAnyOf(
            resyncRejectedOrderAsync.rejected,
            platformOrderEnabilityAsync.rejected,
            generateWorkflowReportAsync.rejected,
        ), (state, actions) => {
            state.loading = false
        })
    }
})

export default orderDetailsSlice.reducer;
export const { emptyOrderDetails } = orderDetailsSlice.actions;
