import React, { Fragment, useState, useMemo, useEffect } from 'react'
import { available_platform_action_type, parent_available_action_type } from './utils'
import { useNavigate } from 'react-router-dom'
import { Card, Button } from 'react-bootstrap'
import classes from './style.module.css'
import { useAppDispatch, useAppSelector } from 'src/Redux/Store/hooks'
import { dataDerivedFromCard } from 'src/Redux/Slices/stateSave/connectPlatformDataSlice'

type platformCardProps = {
    flowID : number
    sourcePlatformID: number
    destinationPlatformID: number
    description?: string
    sourceImage: string
    destinationImage: string
    sourcePlatformName: string
    destinationPlatformName: string
    isActive?: boolean
    workflowID?: number
    destinationPlatformAction?: available_platform_action_type[]
    sourcePlatformAction?: available_platform_action_type[]
    available_actions: any
    parent_available_action_type: any
}
type actionCollection = { main_id: number, p_name: string, p_type: string, p_id: number, a_name: string, a_id: number }
type actionFlows = { available_workflow: number, available_workflow_action1: number, available_workflow_action2: number, display_name: string }

function PlatformCard(props: platformCardProps) {
    const {
        flowID,
        workflowID,
        isActive,
        sourcePlatformID,
        destinationPlatformID,
        sourcePlatformName,
        destinationPlatformName,
        description,
        sourceImage,
        destinationImage,
        sourcePlatformAction,
        destinationPlatformAction,
        available_actions,
        parent_available_action_type,
    } = props
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [sourceActionIDs, setSourceActionIDs] = useState<number[] | []>([])
    const [destinationActionIDs, setDestinationActionIDs] = useState<number[] | []>([])
    const [isHovered, setIsHovered] = useState(false)
    const { unActiveCardData } = useAppSelector((state) => state.createWorkflowCardData)
    const [openModal, setOpenModal] = useState(false)
    const [actionCollectionArray, setActionCollectionArray] = useState<actionCollection[] | []>([])
    const [getActionFlows, setActionFlows] = useState<actionFlows[] | []>([])
    const handleHover = () => {
        setIsHovered(true)
    }
    const handleUnhover = () => {
        setIsHovered(false)
    }


    // This is from source_available_platform and destination_available_platform
    useMemo(() => {
        if (sourcePlatformAction && destinationPlatformAction) {
            if (sourcePlatformAction.length && destinationPlatformAction.length) {
                let sa_ids = sourcePlatformAction.map((item) => item?.id)
                let da_ids = destinationPlatformAction.map((item) => item?.id)
                setSourceActionIDs(sa_ids)
                setDestinationActionIDs(da_ids)
            }
        }
    }, [sourcePlatformAction, destinationPlatformAction])

    // This is from available_actions
    useMemo(() => {
        if (available_actions.length) {
            let actionCollection = [] as actionCollection[];
            for (let i = 0; i < available_actions.length; i++) {
                actionCollection.push({
                    main_id: available_actions[i]?.id,
                    p_name: available_actions[i]?.platform?.name,
                    p_type: available_actions[i]?.platform?.type,
                    p_id: available_actions[i]?.platform?.id,
                    a_name: available_actions[i]?.action?.action_name,
                    a_id: available_actions[i]?.action?.id
                })
            }
            setActionCollectionArray(actionCollection)
        }
    }, [available_actions])

    useMemo(() => {
        if (parent_available_action_type.length) {
            let actionFlows = [] as actionFlows[]
            for (let i = 0; i < parent_available_action_type.length; i++) {
                actionFlows.push({
                    display_name: parent_available_action_type[i]?.display_name,
                    available_workflow: parent_available_action_type[i]?.available_workflow,
                    available_workflow_action1: parent_available_action_type[i]?.available_workflow_action1,
                    available_workflow_action2: parent_available_action_type[i]?.available_workflow_action2,
                })
            }
            setActionFlows(actionFlows)
        }
    }, [parent_available_action_type])

    const handleUnActiveCard = (
        sourcePlatformID: number, destinationPlatformID: number,
        sourceActionIDs: number[], destinationActionIDs: number[],
        sourceImage: string, destinationImage: string,
        sourcePlatformName: string, destinationPlatformName: string,
        description: string, actionCollectionArray: actionCollection[],
        getActionFlows: actionFlows[], flowID: number,
    ) => {
        dispatch(dataDerivedFromCard({
            dashboardIntegratedModal: true,
            sp_id: sourcePlatformID,
            dp_id: destinationPlatformID,
            sp_aid: sourceActionIDs,
            dp_aid: destinationActionIDs,
            sourceImage: sourceImage,
            destinationImage: destinationImage,
            sourceName: sourcePlatformName,
            destinationName: destinationPlatformName,
            description: description,
            actionCollection: actionCollectionArray,
            action_flows: getActionFlows,
            flowID: flowID,
        }))
    }
    return (
        <Fragment>
            <Card
                onMouseEnter={handleHover}
                onMouseLeave={handleUnhover}
                style={{
                    transition: 'box-shadow 0.3s',
                    boxShadow: isHovered
                        ? '0 16px 32px rgba(20, 20, 20, 0.3)'
                        : '0 12px 25px rgba(15, 15, 15, 0.15)',
                    backgroundColor: isHovered ? '#DBDFE9' : '',
                    width: '100%',
                }}
            >
                {isActive ? (
                    <span>
                        <p
                            style={{
                                position: 'absolute',
                                right: 2,
                                display: 'inline',
                                fontWeight: 'bold',
                                color: 'green',
                                padding: '7px',
                            }}
                        >
                            <span>
                                <i
                                    className='bi bi-circle-fill text-success'
                                    style={{ fontSize: '7px', position: 'relative', top: '-2px', right: '-4px' }}
                                ></i>
                            </span>
                            &nbsp; Connected
                        </p>
                    </span>
                ) : null}

                <span
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '10px',
                        position: 'relative',
                    }}
                >
                    <Card.Img
                        src={sourceImage}
                        style={{
                            marginRight: '20px',
                            width: '90px',
                            minHeight: '80px',
                            maxHeight: '80px',
                            marginTop: '25px',
                        }}
                    />
                    <span style={{ fontSize: '50px' }}>&harr;</span>

                    <Card.Img
                        src={destinationImage}
                        style={{
                            marginLeft: '20px',
                            width: '90px',
                            minHeight: '80px',
                            maxHeight: '80px',
                            marginTop: '25px',
                        }}
                    />
                </span>

                <Card.Body
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '10px',
                        borderBottom: description ? '1px solid #E8E8E8' : '',
                    }}
                >
                    <Card.Title>
                        {sourcePlatformName}&nbsp;+&nbsp;{destinationPlatformName}
                    </Card.Title>

                    <Card.Text
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: '7px',
                            width: '70%',
                        }}
                    >
                        <Button
                            size='sm'
                            variant={isActive ? 'success' : 'primary'}
                            style={{ width: '200%' }}
                            onClick={() => {
                                isActive
                                    ? navigate('/workflow', { state: { id: workflowID } })
                                    : handleUnActiveCard(
                                        sourcePlatformID, destinationPlatformID,
                                        sourceActionIDs, destinationActionIDs,
                                        sourceImage, destinationImage, sourcePlatformName,
                                        destinationPlatformName, description ?? '',
                                        actionCollectionArray, getActionFlows, flowID
                                    )
                                setOpenModal(true)
                            }
                                // navigate('/workflow', {
                                //     state: {
                                //         message: true,
                                //         sourcePlatformID: sourcePlatformID,
                                //         destinationPlatformID: destinationPlatformID,
                                //         sourceActionIDs: sourceActionIDs,
                                //         destinationActionIDs: destinationActionIDs,
                                //         sourceImage: sourceImage,
                                //         destinationImage: destinationImage,
                                //         sourceName: sourcePlatformName,
                                //         destinationName: destinationPlatformName,
                                //         description: description,
                                //     },
                                // })
                            }
                        >
                            {isActive ? (
                                <b className='bi bi-check2-square fs-6'> &nbsp; View</b>
                            ) : (
                                <b className='bi bi-lock-fill fs-6'> &nbsp; Connect</b>
                            )}
                        </Button>
                    </Card.Text>
                </Card.Body>
                {
                    description && (
                        <Card.Body>
                            <Card.Text
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    marginTop: '-15px',
                                    width: '100%',
                                    textAlign: description ? 'justify' : 'left',
                                    fontWeight: '500',
                                }}
                                dangerouslySetInnerHTML={{ __html: description ? ` <div class="${classes.textCollapse}" > ${description} </div>` : "" }}
                            >
                            </Card.Text>
                        </Card.Body>
                    )
                }

            </Card>
        </Fragment>
    )
}

export default PlatformCard
