import React, { Fragment } from 'react'
import classes from './shipment.module.css'
import { Row, Col, Card } from 'react-bootstrap'
import ScreenWidth from '../../../../widgets/components/ScreenWidth'

type shipmentTrackingProps = {
    shipmentData: {
        orderID: string
        shipmentID: string | number
        trackingNumber: string
        trackingCompany: string
        shipmentStatus: string
    }
    rawData: any
}
function ShipmentTracking({ shipmentData, rawData }: shipmentTrackingProps) {
    const [screenWidth] = ScreenWidth()

    return (
        <Fragment>
            <Card className='mt-5'>
                <Card.Header className={classes.card_header}>Order ID: {rawData?.platform_order?.order_api_id}</Card.Header>

                <Row className={`ps-10 pe-8 ${screenWidth <= 349 ? 'mt-10' : 'mt-3'}`}>
                    <Col xs={4}>
                        <Card.Subtitle className={classes.card_subHeading_product}>
                            Tracking Number
                        </Card.Subtitle>
                    </Col>
                    <Col xs={4}>
                        <Card.Subtitle className={classes.card_subHeading}>Company</Card.Subtitle>
                    </Col>
                    <Col xs={4}>
                        <Card.Subtitle className={classes.card_subHeading}>Shipment Status</Card.Subtitle>
                    </Col>
                </Row>

                <Card.Body className='border-bottom border-secondary'>
                    <Row>
                        <Col xs={4}>
                            <Card.Text className={classes.card_text_productDetail}>
                                {rawData?.tracking_number}
                            </Card.Text>
                        </Col>
                        <Col xs={4}>
                            <Card.Text className={classes.card_text}>{rawData?.tracking_company}</Card.Text>
                        </Col>
                        <Col xs={4}>
                            <Card.Text className={classes.card_text}>{rawData?.shipment_status}</Card.Text>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Fragment>
    )
}

export default ShipmentTracking
