import React, { Fragment, useEffect, useMemo } from 'react'
import { Button, Container, Modal, Stack } from 'react-bootstrap'
import CloseButton from '../../IndividualWorkflowTabs/FieldMapping/components/CloseButton'
import { AnimatedLoader, CircularLoader } from 'src/app/modules/widgets/components/CustomLoaders'
import completeOauthImage from '../../../../../../asset/icons/4.png'
import { oAuthValues } from '../utils'
type platformOauthProps = {
    modalShow: boolean
    setModalShow: (status: boolean) => void
    platformName: string | null
    iframeSrc: string | null
    setIframeSrc: (data: string | null) => void
    oauth_ids: oAuthValues
    closeModal: (status: boolean) => void
}

function PlatformOauth(props: platformOauthProps) {
    const { modalShow, setModalShow, platformName, iframeSrc, setIframeSrc, oauth_ids, closeModal } = props

    const openAuthorizationPage = () => {
        if (iframeSrc) {
            // localStorage.removeItem('processOauth_ID')
            const width = 800;
            const height = 600;
            const left = window.innerWidth / 2 - width / 2;
            const top = window.innerHeight / 2 - height / 2;

            const newWIndow = window.open(iframeSrc, '_blank', `width=${width},height=${height},left=${left},top=${top}`);

        }
        setModalShow(false)
        setTimeout(() => {
            closeModal(false)
            setIframeSrc(null)
        }, 10 * 1000)
    };

    useMemo(() => {
        if (oauth_ids?.available_platform_id && oauth_ids?.platform_id) {
            let availablePlatformID: oAuthValues = {
                available_platform_id: oauth_ids?.available_platform_id,
                platform_id: oauth_ids?.platform_id
            }
            localStorage.setItem('processOauth_ID', JSON.stringify(availablePlatformID))
        }
    }, [oauth_ids])

    return (
        <Fragment>
            <Modal
                show={modalShow}
                onHide={() => setModalShow(true)}
                size='lg'
                centered
                style={{ backgroundColor: '#0007' }}
            >
                <Fragment>
                    {/* Close Button  Start*/}
                    {/* <div onClick={() => { setModalShow(false) }}>
                        <CloseButton />
                    </div> */}
                    {/* Close Button  Start*/}

                    {/* Modal Heading Start*/}
                    <Modal.Header>
                        <Modal.Title className='fs-4 mb-n3 text-hover-primary'>
                            Please Complete {platformName} Platform OAuth Process
                        </Modal.Title>
                    </Modal.Header>
                    {/* Modal Heading End*/}

                    <Fragment>
                        <Stack
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                columnGap: '7px',
                                flexWrap: 'wrap',
                            }}
                        >
                            <img
                                src={completeOauthImage}
                                alt="Complete Oauth Image"
                                style={{ height: '350px', width: 'auto' }}
                            />
                            {iframeSrc && (
                                <Button className='mb-7' onClick={openAuthorizationPage}>
                                    Complete {platformName} Authentication
                                </Button>
                            )}
                        </Stack>
                    </Fragment>
                </Fragment>
            </Modal>
        </Fragment>
    )
}

export default PlatformOauth

// <iframe
//     title="Embedded Content"
//     src={iframeSrc}
//     width="100%"
//     height="100%"
//     style={{ border: 0 }}
//     allowFullScreen
// />
