import {createSlice} from '@reduxjs/toolkit'
import {ReactNode} from 'react'

type ALERT_MODAL_PROPS_FOR_INFO_TYPE = {
  modalIcon: string
  size?: 'sm' | 'lg' | 'xl' | '' | null
  visible: boolean
  title: string
  subtitle: ReactNode | string
  onClose: () => void
  isConfirmationDialogue?: boolean
  infoLabel?: string
  cancelLabel?: string
  acceptanceLabel?: string
  onCancel?: () => void
  onAccept?: () => void
  modalType: 'success' | 'warning' | 'danger'
}

const initialState: {modalProp: ALERT_MODAL_PROPS_FOR_INFO_TYPE} = {
  modalProp: {
    modalIcon: '/media/icons/duotune/general/gen043.svg',
    size: '',
    acceptanceLabel: 'Ok',
    cancelLabel: 'Cancel',
    infoLabel: 'Ok',
    onClose: () => null,
    title: '',
    subtitle: '',
    visible: false,
    onAccept: () => null,
    onCancel: () => null,
    isConfirmationDialogue: true,
    modalType: 'success',
  },
}

const infoSlice = createSlice({
  name: 'infoSlice',
  initialState,
  reducers: {
    setInfoModalProps: (state, action: {payload: ALERT_MODAL_PROPS_FOR_INFO_TYPE}) => {
      state.modalProp = action.payload
    },
    resetInfoModal: (state) => {
      state.modalProp.visible = false
      state.modalProp = {
        ...state.modalProp,
        size: '',
        acceptanceLabel: 'Ok',
        cancelLabel: 'Cancel',
        infoLabel: 'Ok',
        onClose: () => null,
        title: '',
        subtitle: '',
        onAccept: () => null,
        onCancel: () => null,
        isConfirmationDialogue: true,
        modalType: 'success',
      }
      
    },
  },
})

export const {setInfoModalProps, resetInfoModal  } = infoSlice.actions
export default infoSlice.reducer
