import React, {Fragment} from 'react'
import {OrderData_type} from './utils'
import {Row, Col, Card, Stack} from 'react-bootstrap'
import classes from './orderDetail.module.css'
import {dateTimeFormatter} from '../../../../widgets/components/DateTimeFormater'
type paymentProps = {
  OrderData: OrderData_type
}

function Payment({OrderData}: paymentProps) {
  return (
    <Fragment>
      <Card className='mt-5'>
        <Card.Header className={classes.card_header}>Payment Details</Card.Header>

        {OrderData?.platform_order_payments?.length !== 0 ? (
          <Fragment>
            <Row className='ps-10 pe-8 mt-3'>
              <Col xs={6}>
                <Card.Subtitle className={classes.card_subHeading_product}>
                  Payment ID
                </Card.Subtitle>
              </Col>
              <Col xs={3}>
                <Card.Subtitle className={classes.card_subHeading}>Paid Amount</Card.Subtitle>
              </Col>
              <Col xs={3}>
                <Card.Subtitle className={classes.card_subHeading}>Payment Mode</Card.Subtitle>
              </Col>
            </Row>

            <Row className='ps-10 pe-8 mt-3'>
              <Col xs={6}>
                <Card.Text className={classes.card_text_productDetail}>
                  {OrderData?.platform_order_payments?.[0]?.api_id}
                </Card.Text>
              </Col>
              <Col xs={3}>
                <Card.Text className={classes.card_text}>
                  {OrderData?.platform_order_payments?.[0]?.paid_amount}
                </Card.Text>
              </Col>

              <Col xs={3}>
                <Card.Text className={classes.card_text}>
                  {OrderData?.platform_order_payments?.[0]?.payment_mode}
                </Card.Text>
              </Col>

              <Stack className={classes.track_order_button}>
                <p className='mt-7 text-muted fw-bold'>
                  <span>Paid On:&nbsp;</span>
                  <span className='text-dark text-hover-primary'>
                    {dateTimeFormatter(OrderData?.platform_order_payments?.[0]?.paid_on)}
                  </span>
                </p>
              </Stack>
            </Row>
          </Fragment>
        ) : (
          <Stack className={classes.no_record_message}>
            <p className='mb-3 text-dark fw-bold'>Currently No Payment Records !</p>
          </Stack>
        )}
      </Card>
    </Fragment>
  )
}

export default Payment
