import { createSlice } from '@reduxjs/toolkit'

type connecteParameter = {
    platformType: string
    connected: boolean
}

type initialStateVal = {
    platform_ConnectionStatus: connecteParameter
}

const initialState: initialStateVal = {
    platform_ConnectionStatus: {
        platformType: '',
        connected: false
    }
}


const connectPlatformViaCreateWorkflowSlice = createSlice({
    name: 'connectPlatformViaCreateWorkflowSlice',
    initialState,
    reducers: {
        isPlatformConnected: (state, action) => {
            state.platform_ConnectionStatus = action.payload
        },
        clearStatus: () => initialState
    }
})

export const { isPlatformConnected, clearStatus } = connectPlatformViaCreateWorkflowSlice.actions;
export default connectPlatformViaCreateWorkflowSlice.reducer;