import React, { Fragment, useEffect } from 'react'
import { initialValueType } from './utils'
import classes from './OrgDetails.module.css'
import { FormikProps, ErrorMessage } from 'formik'
import TextError from 'src/ErrorMessage/TextError'
import { useAppSelector } from 'src/Redux/Store/hooks'
import { Form as BootstrapForm, Row, Col, Button, Card } from 'react-bootstrap'
type editFormType = {
    Formik: FormikProps<initialValueType>
}

function EditForm({ Formik }: editFormType) {
    const { subdomainLoader, data } = useAppSelector((state) => state.subdomain)

    useEffect(() => {
        Formik.setFieldValue('displayName', data?.display_name)
        Formik.setFieldValue('subDomain', data?.subdomain)
        Formik.setFieldValue('logoURL', data?.logo)
    }, [data])

    return (
        <Fragment>
            <Card>
                <Card.Body>
                    <Card.Subtitle className={classes.card_subTitle}>Update Organization Details</Card.Subtitle>
                    <Row>
                        <Col xs={12} lg={6} className={classes.col_cls}>
                            <BootstrapForm.Label htmlFor="subDomain">Sub Domain</BootstrapForm.Label>
                            <BootstrapForm.Control
                                disabled
                                type="text"
                                id="subDomain"
                                name='subDomain'
                                value={Formik.values.subDomain}
                                onChange={Formik.handleChange}
                            /></Col>
                        <Col xs={12} lg={6} className={classes.col_cls}>
                            <BootstrapForm.Label htmlFor="displayName">Display Name</BootstrapForm.Label>
                            <BootstrapForm.Control
                                type="text"
                                id="displayName"
                                name='displayName'
                                value={Formik.values.displayName}
                                onChange={Formik.handleChange}
                                onBlur={Formik.handleBlur}
                                className={Formik.touched.displayName && Formik.errors.displayName ? classes.border : ''}
                            />
                            <ErrorMessage name='displayName' component={TextError} />

                        </Col>
                        <Col xs={12} className={classes.col_cls}>
                            <BootstrapForm.Label htmlFor="logoURL">Logo URL</BootstrapForm.Label>
                            <BootstrapForm.Control
                                type="text"
                                id="logoURL"
                                name='logoURL'
                                value={Formik.values.logoURL}
                                onChange={Formik.handleChange}
                                onBlur={Formik.handleBlur}
                                className={Formik.touched.logoURL && Formik.errors.logoURL ? classes.border : ''}
                            />
                            <ErrorMessage name='logoURL' component={TextError} />
                        </Col>

                        <Col xs={12} className={classes.button_container}>
                            <Button
                                size='sm'
                                variant='primary'
                                type='submit'
                            >Update Details
                            </Button>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Fragment>
    )
}

export default EditForm
