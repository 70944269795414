import {FC, useEffect} from 'react'
import {ILayout, useLayout} from '../../core'
import {MenuInner} from './header-menus'
import {useAppDispatch, useAppSelector} from 'src/Redux/Store/hooks'
import {RootState} from 'src/Redux/Store/storets'
import {useNavigate} from 'react-router-dom'
import {resetInfoModal, setInfoModalProps} from 'src/Redux/Slices/info/infoSlice'
import { LOCAL_STORAGE_FIELDS } from 'src/utils/constants'

const Header: FC = () => {
  const {config} = useLayout()
  useEffect(() => {
    updateDOM(config)
  }, [config])

  const {loggedInByAdminData, loggedInByAdmin} = useAppSelector(
    (state: RootState) => state.loggedInUser
  )
  const {modalProp} = useAppSelector((state) => state.info)

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const handleLoginAsAdmin = () => {
    localStorage.removeItem(LOCAL_STORAGE_FIELDS.admin_detail)
    localStorage.removeItem(LOCAL_STORAGE_FIELDS.loggedInFromAdmin)
    localStorage.setItem(LOCAL_STORAGE_FIELDS.user, loggedInByAdminData.id + '')
    localStorage.setItem(LOCAL_STORAGE_FIELDS.user_details, JSON.stringify(loggedInByAdminData))
    dispatch(resetInfoModal())
    navigate('/')
    window.location.reload()
  }

  const openInformationModal = () => {
    dispatch(
      setInfoModalProps({
        ...modalProp,
        isConfirmationDialogue: false,
        visible: true,
        modalIcon: '/media/icons/duotune/general/gen049.svg',
        modalType: 'warning',
        cancelLabel: 'Close',
        title: `You are logging in as ${loggedInByAdminData.email}`,
        onClose: () => {
          handleLoginAsAdmin()
        },
      })
    )
  }

  return (
    <div
      className='
        menu
        menu-rounded
        menu-column
        menu-lg-row
        my-5
        my-lg-0
        align-items-stretch
        fw-semibold
        px-2 px-lg-0
    '
      id='kt_app_header_menu'
      data-kt-menu='true'
    >
      {loggedInByAdmin && (
        <div
          className={'px-4 py-2 bg-primary bg-hover-success h-min-30px position-md-absolute '}
          style={{left: '30%', cursor: 'pointer'}}
          onClick={openInformationModal}
        >
          <span className='fs-8 text-white fw-bold '>
            {' '}
            Switch to {':)'}{' '}
            <p className='fs-6 text-white text-decoration-underline'>{loggedInByAdminData.email}</p>{' '}
          </span>
        </div>
      )}
      <MenuInner />
    </div>
  )
}

const updateDOM = (config: ILayout) => {
  if (config.app?.header?.default?.fixed?.desktop) {
    document.body.setAttribute('data-kt-app-header-fixed', 'true')

    document.body.setAttribute('data-kt-app-header-minimize', 'on')
  }

  if (config.app?.header?.default?.fixed?.mobile) {
    document.body.setAttribute('data-kt-app-header-fixed-mobile', 'true')
  }

  if (config.app?.header?.default?.stacked) {
    document.body.setAttribute('data-kt-app-header-stacked', 'true')
  }

  const appHeaderDefaultStickyEnabled = config.app?.header?.default?.sticky?.enabled
  let appHeaderDefaultStickyAttributes: {[attrName: string]: string} = {}
  if (appHeaderDefaultStickyEnabled) {
    appHeaderDefaultStickyAttributes = config.app?.header?.default?.sticky?.attributes as {
      [attrName: string]: string
    }
  }

  const appHeaderDefaultMinimizeEnabled = config.app?.header?.default?.minimize?.enabled
  let appHeaderDefaultMinimizeAttributes: {[attrName: string]: string} = {}
  if (appHeaderDefaultMinimizeEnabled) {
    appHeaderDefaultMinimizeAttributes = config.app?.header?.default?.minimize?.attributes as {
      [attrName: string]: string
    }
  }

  setTimeout(() => {
    const headerElement = document.getElementById('kt_app_header')
    // header
    if (headerElement) {
      const headerAttributes = headerElement
        .getAttributeNames()
        .filter((t) => t.indexOf('data-') > -1)
      headerAttributes.forEach((attr) => headerElement.removeAttribute(attr))

      if (appHeaderDefaultStickyEnabled) {
        for (const key in appHeaderDefaultStickyAttributes) {
          if (appHeaderDefaultStickyAttributes.hasOwnProperty(key)) {
            headerElement.setAttribute(key, appHeaderDefaultStickyAttributes[key])
          }
        }
      }

      if (appHeaderDefaultMinimizeEnabled) {
        for (const key in appHeaderDefaultMinimizeAttributes) {
          if (appHeaderDefaultMinimizeAttributes.hasOwnProperty(key)) {
            headerElement.setAttribute(key, appHeaderDefaultMinimizeAttributes[key])
          }
        }
      }
    }
  }, 0)
}

export {Header}
