import React, { useState, useEffect } from 'react'
import LogoHeader from './LogoHeader'
import classes from './styles.module.css'
import EnableWorkflow from './EnableWorkflow'
import TabGroup from '../WorkflowTabs/TabGroup'
import { useDispatch, useSelector } from 'react-redux'
import { KTSVG } from '../../../../../_metronic/helpers'
import { Modal } from 'react-bootstrap'
import { getWorkflowDetailsApi } from '../../../../redux/slices/WorkflowSlices'
import { useAppDispatch } from '../../../../../Redux/Store/hooks'
import PlatformInitProcess from './PlatformInitProcess'

interface WorkflowDetailModalProps {
  data: number | null
  OpenModal?: boolean
  closeModal?: () => void
  tabGroupIndex: number
}

function WorkflowDetailModal(props: WorkflowDetailModalProps) {
  const { data, OpenModal, closeModal, tabGroupIndex } = props
  const dispatch = useAppDispatch()
  const [tabIndex, setTabIndex] = useState<number>()
  const [displayTextFlag, setDisplayTextFlag] = useState(false)
  const WorkflowDetails = useSelector((state: any) => state.Workflow.WorkflowDetails)?.data

  const { sourcePlatformProcess, destinationPlatformProcess } = useSelector(
    (state: any) => state.workflowForm
  )
  const { loading, initilizeProcess } = useSelector(
    (state: { platformProcess: { loading: boolean; initilizeProcess: { results: [] } } }) =>
      state.platformProcess
  )

  useEffect(() => {
    if (data) {
      dispatch(getWorkflowDetailsApi(data))
    }
  }, [data, OpenModal])

  // To Open Specific Tab::
  useEffect(() => {
    if (tabGroupIndex !== undefined) {
      setTabIndex(tabGroupIndex)
    }
    setTimeout(() => {
      setTabIndex(0)
    }, 5000)
  }, [tabGroupIndex])

  return (
    <Modal show={OpenModal} onHide={closeModal} size='xl' centered fullscreen={true}>
      <div className='modal-content'>
        <div className='modal-body py-7'>
          {/* Platform Status--------div */}
          <PlatformInitProcess workflowID={data} />
          {/* Platform Status--------div */}

          <div className='d-flex justify-content-end'>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary '
              onClick={closeModal}
              style={{ position: 'absolute', top: '26px' }}
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='container'>
            <LogoHeader
              sourceLogo={WorkflowDetails?.source_platform?.platform_info?.logo_ref}
              destinationLogo={WorkflowDetails?.destination_platform?.platform_info?.logo_ref}
              sourceName={WorkflowDetails?.source_platform?.display_name}
              destinationName={WorkflowDetails?.destination_platform?.display_name}
            />
            <div className={classes.enableWorkflow_div}>
              <EnableWorkflow id={data} />
            </div>

            <div className='row'>
              <div className='col'>
                <div className='mt-5'>
                  <TabGroup
                    workflowID={data}
                    sourceplatform_ID={WorkflowDetails?.source_platform?.id}
                    destinationplatform_ID={WorkflowDetails?.destination_platform?.id}
                    tabGroupIndex={tabIndex}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default WorkflowDetailModal


