import React, { useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import CreateWorkflow from './CreateWorkflow'
import { useAppDispatch } from 'src/Redux/Store/hooks'
import { KTSVG } from '../../../../../_metronic/helpers'
import { emptyDerivedData } from 'src/Redux/Slices/stateSave/connectPlatformDataSlice'
import { actionCollection, actionFlows } from 'src/Redux/Slices/stateSave/connectPlatformDataSlice'

type createWorkflowModalProps = {
    OpenModal: boolean
    closeModal: (state: boolean) => void
    dashboardIntegratedModal: boolean
    sp_id: string | number
    dp_id: string | number
    sp_aid: number[] | null
    dp_aid: number[] | null
    sourceImage: string
    destinationImage: string
    destinationName: string
    description: string
    sourceName: string
    actionCollectionArray: actionCollection[]
    action_flows: actionFlows[]
    flowID: number | null
}

function CreateWorkflowModal(props: createWorkflowModalProps) {
    const dispatch = useAppDispatch()
    const {
        OpenModal,
        closeModal,
        sp_id,
        dp_id,
        dashboardIntegratedModal,
        sp_aid,
        dp_aid,
        sourceImage,
        destinationImage,
        sourceName, destinationName, description,
        actionCollectionArray, action_flows, flowID
    } = props

    return (
        <Modal show={OpenModal} onHide={() => closeModal(true)} size='xl' centered>
            <div className='modal-content'>
                <Modal.Header>
                    <Modal.Title>Configure Integration</Modal.Title>
                    <div
                        className='btn btn-icon btn-sm btn-active-light-primary '
                        onClick={() => {
                            closeModal(false)
                            dispatch(emptyDerivedData())
                        }}
                        style={{ marginTop: '-10px' }}
                    >
                        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
                    </div>
                </Modal.Header>

                <Modal.Body>
                    <CreateWorkflow
                        closeModal={closeModal}
                        OpenModal={OpenModal}
                        sp_id={sp_id}
                        dp_id={dp_id}
                        sp_aid={sp_aid}
                        dp_aid={dp_aid}
                        sourceImage={sourceImage}
                        destinationImage={destinationImage}
                        sourceName={sourceName}
                        destinationName={destinationName}
                        description={description}
                        actionCollectionArray={actionCollectionArray}
                        action_flows={action_flows}
                        flowID={flowID}
                    />
                </Modal.Body>
            </div>
        </Modal>
    )
}

export default CreateWorkflowModal
