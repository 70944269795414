import axios from "axios";
import { INITIAL_VALUE_FOR_CREATE_ORG, PARAMS_FOR_GET_USER_LIST } from "../../../_interfaces/Organization.Interface";
import { AxiosClient } from "../../AxiosClient/axiosClient";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { isJson } from "../../AxiosClient/isJson";
import { errorMessage } from "../../AxiosClient/isErrorSlice";

export const createOrganizationAsync = createAsyncThunk(
    "organization/createOrganizationAsync",
    async function(payload:INITIAL_VALUE_FOR_CREATE_ORG, toolkit) {
        return await AxiosClient("POST", "/organizations/", payload,toolkit )
    }
)


export const getUserListForCreateOrganizationAsync = createAsyncThunk(
    "organization/getUserListForCreateOrganizationAsync",
    async function(params  :{ param : PARAMS_FOR_GET_USER_LIST|{}, directPath: string|null }, toolkit ){
        return await AxiosClient("GET",`/users-list`,[], toolkit, params.param, params?.directPath  )
    }
)

export const getOrganizationsListAsync = createAsyncThunk(
    "organization/getOrganizationsListAsync",
    async function( path:string , toolkit){
        return await axios({
            method: "GET",
            url: path,
            headers: {
              'Content-Type': "Application/json",
              Authorization: isJson(localStorage.getItem('auth'))
                ? JSON.parse(localStorage.getItem('auth') as any)?.token
                : null,
            },
          })
            .then((response) => {
              return toolkit.fulfillWithValue(response.data)
            })
            .catch((error) => {
              toolkit.dispatch(errorMessage(error.response.data.message))
              // return toolkit.rejectWithValue(error.response.data.message)
              return toolkit.rejectWithValue(error.response)
        
            }) 
    }
)

export const getOrganizationsListWithoutLoadingAsync = createAsyncThunk(
  "organization/getOrganizationsListAsync",
  async function( path:string , toolkit){
      return await axios({
          method: "GET",
          url: path,
          headers: {
            'Content-Type': "Application/json",
            Authorization: isJson(localStorage.getItem('auth'))
              ? JSON.parse(localStorage.getItem('auth') as any)?.token
              : null,
          },
        })
          .then((response) => {
            return toolkit.fulfillWithValue(response.data)
          })
          .catch((error) => {
            toolkit.dispatch(errorMessage(error.response.data.message))
            // return toolkit.rejectWithValue(error.response.data.message)
            return toolkit.rejectWithValue(error.response)
      
          }) 
  }
)