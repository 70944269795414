import React, { Fragment, useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { FormikProps } from 'formik'
import { formikInitialValues } from './utils'
import { mappingData, field_data } from 'src/Redux/Slices/workflowFieldMapping/AvailableFlowMappingSlice'

type defaultOptionMapping = {
    existingMappindData: any
    destinationSideMapping: mappingData
    Formik: FormikProps<formikInitialValues>
    destinationPlatformName: string
    destinationOptionName: string
}


function DefaultOptionMapping(props: defaultOptionMapping) {
    const { Formik, existingMappindData, destinationSideMapping, destinationOptionName, destinationPlatformName } = props
    const [defaultMappingID, setDefaultMappingID] = useState<number | null>(null)
    useEffect(() => {
        let filteredDefaultOption = existingMappindData?.workflow_field_mapping_data?.find((ele) => ele?.isDefault === true)
        Formik.setFieldValue('default_destinationOption', {
            id: filteredDefaultOption?.id,
            isDefault: true,
            source_platform_data: null,
            source_platform_data_str: null,
            destination_platform_data: filteredDefaultOption?.destination_platform_data?.id,
            destination_platform_data_str: filteredDefaultOption?.destination_platform_data_str,
        })
        setDefaultMappingID(filteredDefaultOption?.id)
    }, [existingMappindData])
    return (
        <Fragment>
            {destinationSideMapping?.input_type === 'TEXT' ? (
                <Form.Control
                    type='text'
                    placeholder={`Enter ${destinationPlatformName} ${destinationOptionName}`}
                    name='default_destinationOption'
                    value={Formik.values.default_destinationOption.destination_platform_data_str}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        Formik.setFieldValue('default_destinationOption', {
                            isDefault: true,
                            id: defaultMappingID,
                            source_platform_data: null,
                            source_platform_data_str: null,
                            destination_platform_data: null,
                            destination_platform_data_str: event.target.value,
                        })
                    }}
                />
            ) : (
                <Form.Select
                    as='select'
                    name='default_destinationOption'
                    value={Formik.values.default_destinationOption.destination_platform_data}
                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                        let selectedValue = event.target.value
                        Formik.setFieldValue('default_destinationOption', {
                            isDefault: true,
                            id: defaultMappingID,
                            source_platform_data: null,
                            source_platform_data_str: null,
                            destination_platform_data: selectedValue,
                            destination_platform_data_str: null,
                        })
                    }}
                >
                    <option value=''>Default {destinationPlatformName} {destinationOptionName}</option>
                    {destinationSideMapping?.field_data?.map((ele) => (
                        ele?.parent_data === null &&
                        <option key={ele.id} value={ele.id}>
                            {ele.api_name}
                        </option>

                    ))}
                </Form.Select>
            )}
        </Fragment>
    )
}

export default DefaultOptionMapping;
